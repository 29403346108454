import { Checkbox } from "@/component/shadcn/ui/checkbox";

import { Input } from "@/component/shadcn/ui/input";

import { Textarea } from "@/component/shadcn/ui/textarea";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import CalendarPopup from "@/design/CalendarPopup";
import {
    type OnboardingChecklist,
    OnboardingChecklistStatus,
    type PersonalTasks,
    onboardingStatuses,
} from "@/interfaces/onboardingServerData";
import type { GetUserResponse } from "@/interfaces/serverData";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import * as Toggle from "@radix-ui/react-toggle";
import type { ColumnDef } from "@tanstack/react-table";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { OnboardingAssigneeDropdown } from "../AssigneeDropdown";
import { StatusDropdown } from "../StatusDropdown";

export type OnboardingItem = {
    id: string;
    name: string;
    description: string;
    status: OnboardingChecklistStatus;
    assignee: string;
    start_date: string;
    end_date: string;
};

const inputFocusStyle = "focus:ring-2 focus:ring-blue-500 focus:ring-offset-0";
const inputStyle = "ring-2 ring-blue-500 ring-offset-0";

export const dataSample: OnboardingItem[] = [
    {
        id: "task-001",
        name: "Initial Setup",
        description: "Set up development environment and tools",
        status: OnboardingChecklistStatus.Completed,
        assignee: "js",
        start_date: "2024-01-01",
        end_date: "2024-01-03",
    },
    {
        id: "task-002",
        name: "Requirements Gathering",
        description: "Collect and document project requirements",
        status: OnboardingChecklistStatus.InProgress,
        assignee: "sw",
        start_date: "2024-01-04",
        end_date: "2024-01-10",
    },
    {
        id: "task-003",
        name: "Database Design",
        description: "Design database schema and relationships",
        status: OnboardingChecklistStatus.NotStarted,
        assignee: "mj",
        start_date: "2024-01-11",
        end_date: "2024-01-15",
    },
    {
        id: "task-004",
        name: "UI Mockups",
        description: "Create user interface mockups",
        status: OnboardingChecklistStatus.Paused,
        assignee: "eb",
        start_date: "2024-01-05",
        end_date: "2024-01-12",
    },
    {
        id: "task-005",
        name: "Testing Plan",
        description: "Develop testing strategy and test cases",
        status: OnboardingChecklistStatus.NA,
        assignee: "js",
        start_date: "2024-01-16",
        end_date: "2024-01-20",
    },
];

const TableInputCalendarCell = ({
    getValue,
    row,
    column,
    table,
    deadline = false,
}: {
    getValue: () => any;
    row: { index: number; original: OnboardingChecklist };
    column: { id: string };
    table: any;
    deadline?: boolean;
}) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    // Get the start date if this is a deadline cell
    let minDate = deadline ? row.original.start_date : undefined;
    if (typeof minDate === "string") {
        minDate = new Date(minDate);
    }
    return (
        <div
            className={`w-full h-full flex items-center px-0 mx-0 ${inputFocusStyle}`}
        >
            <CalendarPopup
                date={value}
                onSelect={(date) => {
                    setValue(date);
                    table.options.meta?.updateData(row.index, column.id, date);
                }}
                buttonText="Select date"
                className="w-full h-full text-xs border-none rounded-none bg-transparent !px-2 !justify-start hover:bg-transparent"
                iconColor={deadline ? "text-red-500" : "slate-200"}
                startDate={minDate}
            />
        </div>
    );
};

const TableInputCell = ({
    getValue,
    row,
    column,
    table,
}: {
    getValue: () => any;
    row: { index: number };
    column: { id: string };
    table: any;
}) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const [showPopup, setShowPopup] = useState(false);
    const cellRef = useRef<HTMLDivElement>(null);
    const [cellWidth, setCellWidth] = useState(0);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (!cellRef.current) return;

        const updateWidth = () => {
            setCellWidth(cellRef.current?.offsetWidth ?? 0);
        };

        updateWidth();

        const observer = new ResizeObserver(updateWidth);
        observer.observe(cellRef.current);

        return () => observer.disconnect();
    }, []);

    const onBlur = () => {
        table.options.meta?.updateData(row.index, column.id, value);
    };

    const handleDoubleClick = () => {
        setShowPopup(true);
    };

    return (
        <div
            ref={cellRef}
            onDoubleClick={handleDoubleClick}
            className={`w-full h-full flex items-center px-0 mx-0 ${inputFocusStyle}`}
        >
            {showPopup ? (
                <div
                    className={`absolute z-50 bg-white shadow-lg rounded-md p-2 ${inputStyle}`}
                    style={{ minWidth: cellWidth }}
                >
                    <Textarea
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        onBlur={() => {
                            onBlur();
                            setShowPopup(false);
                        }}
                        className="w-full min-h-[60px] text-xs p-1 whitespace-pre-wrap break-words resize-vertical overflow-auto border-none rounded"
                        autoFocus
                    />
                </div>
            ) : (
                <Input
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onBlur={onBlur}
                    className="h-full w-full text-xs border-none rounded-none bg-transparent px-2 cursor-default"
                />
            )}
        </div>
    );
};

export function generateOnboardingColumns(
    users: GetUserResponse[],
    userID: string,
    isPersonalTasks = false,
): ColumnDef<OnboardingChecklist>[] {
    const AssigneeDropdownCell = memo(
        ({
            getValue,
            row,
            column,
            table,
        }: {
            getValue: () => any;
            row: { index: number };
            column: { id: string };
            table: any;
        }) => {
            const [assignee, setAssignee] = useState(getValue());

            const onBlur = useCallback(
                (assignee: GetUserResponse) => {
                    setAssignee(assignee);
                    table.options.meta?.updateData(
                        row.index,
                        column.id,
                        assignee.id,
                    );
                },
                [row.index, column.id, table.options.meta],
            );

            return (
                <OnboardingAssigneeDropdown
                    assigneeUserID={assignee}
                    users={users}
                    userID={userID}
                    saveAssignee={onBlur}
                    className="h-full outline-none border-none"
                />
            );
        },
    );

    AssigneeDropdownCell.displayName = "AssigneeDropdownCell";

    const StatusDropdownCell = memo(
        ({
            getValue,
            row,
            column,
            table,
        }: {
            getValue: () => any;
            row: { index: number };
            column: { id: string };
            table: any;
        }) => {
            const [status, setStatus] = useState(getValue());

            const onBlur = useCallback(
                (status: OnboardingChecklistStatus) => {
                    setStatus(status);
                    table.options.meta?.updateData(
                        row.index,
                        column.id,
                        status,
                    );
                },
                [row.index, column.id, table.options.meta],
            );

            return (
                <StatusDropdown
                    incomingStatus={status}
                    userID={userID}
                    saveStatus={onBlur}
                    statuses={onboardingStatuses}
                    className="h-full outline-none"
                />
            );
        },
    );

    StatusDropdownCell.displayName = "StatusDropdownCell";

    const columns: ColumnDef<OnboardingChecklist>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <div className="w-full h-full flex items-center justify-center">
                    <Checkbox
                        checked={
                            table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() &&
                                "indeterminate")
                        }
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        aria-label="Select all"
                        className="shadow-none outline outline-1 outline-slate-200 mr-2"
                    />
                </div>
            ),
            cell: ({ row }) => (
                <div className="w-full h-full flex items-center justify-center">
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(value) => row.toggleSelected(!!value)}
                        aria-label="Select row"
                        className="shadow-none outline outline-1 outline-slate-200"
                    />
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
            size: 10,
        },
        {
            accessorKey: "name",
            header: "Task Name",
            size: 150,
            cell: TableInputCell,
        },
        // Only include the company column if we're dealing with PersonalTasks
        ...(isPersonalTasks
            ? [
                  {
                      accessorKey: "company_id",
                      header: "Company",
                      size: 150,
                      cell: (props: any) => {
                          const row = props.row;
                          const personalTask =
                              row.original as unknown as PersonalTasks;

                          return (
                              <div className="flex items-center gap-2 mx-2">
                                  {personalTask.image_url &&
                                      personalTask.image_url !== "" && (
                                          <div className="lb-avatar rounded-sm w-5 h-5">
                                              <img
                                                  className="lb-avatar-image rounded-sm"
                                                  src={personalTask.image_url}
                                                  alt={
                                                      personalTask.entity_name?.slice(
                                                          0,
                                                          2,
                                                      ) ?? "A"
                                                  }
                                              />
                                          </div>
                                      )}
                                  <p className="text-xs font-medium">
                                      {personalTask.entity_name}
                                  </p>

                                  <TooltipProvider>
                                      <Tooltip>
                                          <TooltipTrigger asChild>
                                              <Toggle.Root
                                                  aria-label="Open company page"
                                                  className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                  onClick={(e) => {
                                                      e.stopPropagation();
                                                      window.open(
                                                          `/accounts/${personalTask.onboarding_company_id}/milestones`,
                                                          "_blank",
                                                      );
                                                  }}
                                              >
                                                  <OpenInNewWindowIcon className="h-3 w-3" />
                                              </Toggle.Root>
                                          </TooltipTrigger>
                                          <TooltipContent className="bg-[#5B5BD6]">
                                              <p>
                                                  Open
                                                  <span className="mx-1">
                                                      Company
                                                  </span>
                                                  Page
                                              </p>
                                          </TooltipContent>
                                      </Tooltip>
                                  </TooltipProvider>
                              </div>
                          );
                      },
                  },
              ]
            : []),
        {
            accessorKey: "description",
            header: "Task Description",
            size: 300,
            cell: TableInputCell,
        },
        {
            accessorKey: "task_status",
            header: "Status",
            size: 150,
            cell: ({ row, getValue, column, table }) => {
                return (
                    <div className={`w-full h-full ${inputFocusStyle}`}>
                        <StatusDropdownCell
                            getValue={getValue}
                            row={row}
                            column={column}
                            table={table}
                        />
                    </div>
                );
            },
        },
        {
            accessorKey: "assignee_user_id",
            header: "Assignee",
            size: 150,
            cell: ({ row, getValue, column, table }) => (
                <div className={`w-full h-full ${inputFocusStyle}`}>
                    <AssigneeDropdownCell
                        getValue={getValue}
                        row={row}
                        column={column}
                        table={table}
                    />
                </div>
            ),
        },
        {
            accessorKey: "start_date",
            header: "Start Date",
            size: 150,
            cell: TableInputCalendarCell,
        },
        {
            accessorKey: "deadline",
            header: "Deadline",
            size: 150,
            cell: (props) => (
                <TableInputCalendarCell {...props} deadline={true} />
            ),
        },
    ];
    return columns;
}
