import { CreateIssuePopup } from "@/IssuesTable/CreateIssuePopup";
import { FilterItems } from "@/IssuesTable/FilterItems";
import IssuesList from "@/IssuesTable/IssuesList";
import { ticketStatuses } from "@/IssuesTable/constants";
import FileUploadForCreateTicket from "@/component/FileUploadForCreateTicket";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
    Tabs,
    TabsList,
    TabsTrigger,
} from "@/component/shadcn/ui/tabs";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, TeamsAPI, URLS } from "@/constant";
import useInteractionTypes from "@/hooks/use-interactionTypes";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    CustomerGroup,
    CustomerGroupMetadata,
    GetTopicsResponse,
    GetUserResponse,
    Integration,
    InteractionCountsPayload,
    InteractionCountsResponse,
    InteractionFilters,
    KeyName,
    OrgInfoResponse,
    QueriesWithPaginationResponse,
    Query,
    ScopeResponse,
    Teams,
} from "@/interfaces/serverData";
import {
    SavedViewBadge,
    combineChannelsAnd,
    getBadgeForTeam,
    getStatusIcon,
    getTopicColors,
    updateChannelMap,
} from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import {
    PlusIcon,
    ViewHorizontalIcon,
    ViewVerticalIcon,
} from "@radix-ui/react-icons";
import { Box, Callout, Flex, Heading } from "@radix-ui/themes";
import {
    type InfiniteData,
    RefetchOptions,
    useInfiniteQuery,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { SearchIcon } from "lucide-react";
import { LayoutGrid } from "lucide-react";
import { memo, useEffect, useRef, useState } from "react";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TeamBadges } from "../WorkspacePreferences/TeamBadges";
import AdminKanbanViewPage from "./AdminKanbanViewPage";

export interface TopicDisplay {
    color: string;
    label: string;
    value: string;
}

const areEqual = (
    prevProps: AdminQueryPageProps,
    nextProps: AdminQueryPageProps,
) => {
    return (
        prevProps.userID === nextProps.userID &&
        prevProps.listType === nextProps.listType &&
        prevProps.tableView === nextProps.tableView &&
        prevProps.filters === nextProps.filters &&
        prevProps.groups === nextProps.groups &&
        prevProps.onFilterChange === nextProps.onFilterChange &&
        prevProps.onGroupChange === nextProps.onGroupChange &&
        prevProps.name === nextProps.name &&
        prevProps.description === nextProps.description &&
        prevProps.listHeight === nextProps.listHeight &&
        prevProps.viewId === nextProps.viewId &&
        prevProps.tempTeamId === nextProps.tempTeamId
    );
};
export enum IssueListType {
    Issues = "issues",
    Inbox = "inbox",
    Team = "team",
    TeamViewer = "teamViewer",
    View = "view",
    CreateView = "createView",
}

interface AdminQueryPageProps {
    userID: string;
    listType: IssueListType;
    tableView: boolean;
    hidePopup: boolean;
    filters?: Map<string, Set<string>>;
    groups?: string;
    onFilterChange?: (filter: Map<string, Set<string>>) => void;
    onGroupChange?: (group: string) => void;
    name?: string;
    description?: string;
    viewId?: string;
    listHeight?: number;
    tempTeamId?: string; // just used to identify if this page is switched between teams
}

const AdminQueriesPage: React.FC<AdminQueryPageProps> = ({
    userID,
    listType,
    tableView,
    hidePopup,
    onFilterChange,
    onGroupChange,
    filters: propsFilters,
    groups,
    name,
    description,
    viewId,
    listHeight,
    tempTeamId,
}) => {
    const { team_id } = useParams(); // team ID
    const api = useApi();
    const queryClient = useQueryClient();
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [toggleTable, setToggleTable] = useState<boolean>(() => {
        const savedView = localStorage.getItem('preferredTableView');
        return savedView !== null ? savedView === 'true' : tableView;
    });

    useEffect(() => {
        localStorage.setItem('preferredTableView', toggleTable.toString());
    }, [toggleTable]);
    const statusOrder = [
        "Breaching",
        "NeedsResponse",
        "Escalated",
        "Open",
        "Closed",
    ];
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [inputFilters, setInputFilters] = useState<Map<string, Set<string>>>(
        () => {
            const defaultFilters = new Map<string, Set<string>>();
            defaultFilters.set("Status", new Set());
            if (listType !== IssueListType.Inbox) {
                defaultFilters.set("Assignee", new Set());
            }
            defaultFilters.set("Source", new Set());
            const mergedFilters = propsFilters
                ? new Map<string, Set<string>>(propsFilters)
                : new Map<string, Set<string>>();
            // For each default filter, ensure it's present in the merged filters (but only if missing)
            for (const [key, value] of defaultFilters.entries()) {
                if (!mergedFilters.has(key)) {
                    mergedFilters.set(key, value);
                }
            }

            return mergedFilters;
        },
    );

    useEffect(() => {
        if (listType === IssueListType.Inbox) {
            inputFilters?.delete("Assignee")
        }
    }, [listType]);



    const [interactionFilters, setInteractionFilters] =
        useState<InteractionFilters>({});
    const [teamIDsFromFilter, setTeamIDsFromFilter] = useState<string[]>();
    const [filters, setFilters] = useState<Map<string, Set<string>>>(
        inputFilters ?? new Map(),
    );

    useEffect(() => {
        setFilters(inputFilters ?? new Map());
    }, [viewId, inputFilters]);

    useEffect(() => {
        // Convert filters to interaction filters
        const newInteractionFilters: InteractionFilters = {};
        const entries = Array.from(filters.entries());
        const sourceChannelsMap = new Map<string, KeyName[]>();
        const customerGroupsMap = new Map<string, KeyName[]>();
        let updatedTeamOwner = false;
        for (const [type, values] of entries) {
            if (values.size !== 0) {
                // Add the filters
                switch (type) {
                    case "Assignee": {
                        const updatedValues = Array.from(values).map((val) =>
                            (val === "noAssignee" || val === "") ? "NULL" : val,
                        );
                        newInteractionFilters.assignee_user_id = updatedValues;
                        break;
                    }
                    case "Tag": {
                        const category_ids: string[] = Array.from(values).map(
                            (val) => {
                                const category = (
                                    categoryOptionsQuery.data ?? []
                                ).find((opt) => opt.name === val);
                                return category ? category.id : ""; // Extract category_id or return an empty string
                            },
                        );
                        newInteractionFilters.category_id = category_ids;
                        break;
                    }
                    case "InteractionType": {
                        const interaction_types_ids: string[] = Array.from(values).map(
                            (val) => {
                                const interactionType = (
                                    interactionTypesQuery.data ?? []
                                ).find((opt) => opt.name === val);
                                return interactionType ? interactionType.id : ""; // Extract interaction type id or return an empty string
                            },
                        );
                        newInteractionFilters.interaction_type_id = interaction_types_ids;
                        break;
                    }
                    case "Status":
                        newInteractionFilters.status = Array.from(values);
                        break;
                    case "Assembly Bot":
                        newInteractionFilters.assembly_responded = [true];
                        break;
                    case "Company":
                        newInteractionFilters.company_id = Array.from(values);
                        break;
                    case "Customer":
                        newInteractionFilters.customer_id = Array.from(values);
                        break;
                    case "Topic": {
                        const tag_ids: string[] = Array.from(values).map(
                            (val) => {
                                const tag = getTopicColors(topicsQuery.data ?? []).find((opt) => opt.label === val);
                                return tag ? tag.key : ""; // Extract category_id or return an empty string
                            },
                        );
                        newInteractionFilters.tag_id = tag_ids;
                        break;
                    }
                    case "TeamOwner": {
                        updatedTeamOwner = true;
                        const updatedValues = Array.from(values).map((val) =>
                            val === "noTeamOwner" ? "NULL" : val,
                        );
                        setTeamIDsFromFilter(updatedValues);
                        break;
                    }
                    case "Source":
                        for (const sourceFilter of values) {
                            const parts = sourceFilter.split(" -- ");
                            if (parts.length === 3) {
                                // i.e. "Slack -- general -- C07FH76LPN1"
                                const key = parts[2];
                                const name = parts[1];
                                if (sourceChannelsMap.has(parts[0])) {
                                    const existingArray: KeyName[] =
                                        sourceChannelsMap.get(parts[0]) ?? [];
                                    existingArray.push({ key, name });
                                } else {
                                    sourceChannelsMap.set(parts[0], [
                                        { key, name },
                                    ]);
                                }
                            } else {
                                sourceChannelsMap.set(parts[0], []); // i.e. "ChatWidget", "Slack -- All Channels"
                            }
                        }
                        break;
                    case "Customer Group": {
                        const customerGroups = customerGroupsQuery.data ?? [];
                        for (const value of Array.from(values)) {
                            const cg = customerGroups.find(
                                (elem) => elem.group_name === value,
                            );
                            let slackChannels: KeyName[] = [];
                            let discordChannels: KeyName[] = [];
                            let communitySlackChannels: KeyName[] = [];
                            if (cg?.metadata) {
                                const metadata: CustomerGroupMetadata =
                                    JSON.parse(cg?.metadata ?? "");
                                slackChannels =
                                    metadata.Slack?.map((elem) => ({
                                        key: elem.key ?? "",
                                        name: elem.name ?? "",
                                    })) ?? [];
                                discordChannels =
                                    metadata.Discord?.map((elem) => ({
                                        key: elem.key ?? "",
                                        name: elem.name ?? "",
                                    })) ?? [];
                                communitySlackChannels =
                                    metadata.CommunitySlack?.map((elem) => ({
                                        key: elem.key ?? "",
                                        name: elem.name ?? "",
                                    })) ?? [];
                                updateChannelMap(
                                    "Slack",
                                    slackChannels,
                                    customerGroupsMap,
                                );
                                updateChannelMap(
                                    "Discord",
                                    discordChannels,
                                    customerGroupsMap,
                                );
                                updateChannelMap(
                                    "CommunitySlack",
                                    communitySlackChannels,
                                    customerGroupsMap,
                                );
                            } else if (
                                cg?.group_name === "All Slack Accounts"
                            ) {
                                customerGroupsMap.set("Slack", []);
                            } else if (
                                cg?.group_name === "All Discord Accounts"
                            ) {
                                customerGroupsMap.set("Discord", []);
                            } else if (
                                cg?.group_name ===
                                "All Community Slack Accounts"
                            ) {
                                customerGroupsMap.set("CommunitySlack", []);
                            }
                        }
                    }
                }
            }
            if (sourceChannelsMap.size === 0 && customerGroupsMap.size !== 0) {
                // Only customer groups map has items so just use that
                const customerGroupsObj: Record<string, KeyName[]> = {};
                customerGroupsMap.forEach((value, key) => {
                    customerGroupsObj[key] = value;
                });
                newInteractionFilters.source_channels = customerGroupsObj;
            } else if (
                sourceChannelsMap.size !== 0 &&
                customerGroupsMap.size === 0
            ) {
                // Only source channels map has items so just use that
                const sourceChannelsObj: Record<string, KeyName[]> = {};
                sourceChannelsMap.forEach((value, key) => {
                    sourceChannelsObj[key] = value;
                });
                newInteractionFilters.source_channels = sourceChannelsObj;
            } else if (
                sourceChannelsMap.size !== 0 &&
                customerGroupsMap.size !== 0
            ) {
                // Both have items so combine the two
                const combinedMap = combineChannelsAnd(
                    sourceChannelsMap,
                    customerGroupsMap,
                );
                newInteractionFilters.source_channels = combinedMap;
            }
        }
        setInteractionFilters(newInteractionFilters);
        if (!updatedTeamOwner) {
            setTeamIDsFromFilter(undefined);
        }
    }, [filters]);

    const updateFilters = (newFilters: Map<string, Set<string>>) => {
        setInputFilters(newFilters);
        if (onFilterChange) {
            onFilterChange(newFilters); // Pass filters up to parent
        }
    };

    // Only showing teams that the member is apart of in the filter
    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const useTeamQuery =
        [IssueListType.Team, IssueListType.TeamViewer].includes(listType) ||
        (listType === IssueListType.View && filters?.has("Team"));

    const fetchQueries = async ({
        pageParam = { status: "Breaching", cursor: 0, isNewStatus: true },
    }: {
        pageParam?: { status: string; cursor: number; isNewStatus: boolean };
    }): Promise<QueriesWithPaginationResponse> => {
        if (
            listType === IssueListType.View &&
            Object.keys(interactionFilters).length === 0 &&
            !interactionFilters &&
            !teamIDsFromFilter
        ) {
            // Make sure views filters are loaded in before calling fetch queries
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: pageParam.status,
            };
        }

        try {
            if (
                interactionFilters.status &&
                interactionFilters.status.length > 0 &&
                !interactionFilters.status.includes(pageParam.status)
            ) {
                // Skip to next status without making an API call
                const currentStatusIndex = statusOrder.indexOf(
                    pageParam.status,
                );
                return {
                    data: [],
                    has_next_page: false,
                    next_cursor: 0,
                    currentStatus: pageParam.status,
                    nextStatus: statusOrder[currentStatusIndex + 1],
                };
            }

            const copyOfFilters: InteractionFilters = { ...interactionFilters };
            copyOfFilters.status = [pageParam.status];
            if (listType === IssueListType.Inbox) {
                copyOfFilters.assignee_user_id = [userID];
            }
            const requestData: InteractionCountsPayload = {
                filters: copyOfFilters,
            };
            if (useTeamQuery) {
                requestData.team_ids = [tempTeamId ?? team_id ?? ""];
                if (listType === IssueListType.TeamViewer) {
                    requestData.team_filter_type = "viewer";
                } else {
                    requestData.team_filter_type = "owner";
                }
            } else if (teamIDsFromFilter && teamIDsFromFilter.length !== 0) {
                requestData.team_ids = teamIDsFromFilter;
                requestData.team_filter_type = "owner";
            }

            const response: AxiosResponse<{
                data: QueriesWithPaginationResponse;
            }> = await api.post(
                `${URLS.serverUrl}${API.interactionsWithPagination}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 500,
                        offset: pageParam.cursor,
                    },
                },
            );
            if (response.status === 200) {
                const currentStatusIndex = statusOrder.indexOf(
                    pageParam.status,
                );

                return {
                    ...response.data.data,
                    currentStatus: pageParam.status,
                    nextStatus: statusOrder[currentStatusIndex + 1],
                };
            }
            setLoadingState(2);
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: pageParam.status,
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: pageParam.status,
            };
        }
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch,
        isLoading,
    } = useInfiniteQuery<
        QueriesWithPaginationResponse,
        Error,
        InfiniteData<QueriesWithPaginationResponse>,
        string[],
        { status: string; cursor: number; isNewStatus: boolean }
    >({
        queryKey:
            listType === IssueListType.View
                ? [`viewQueries_${viewId}`]
                : listType === IssueListType.Team
                    ? [`teamQueries_${tempTeamId ?? team_id}_owner`]
                    : listType === IssueListType.TeamViewer
                        ? [`teamQueries_${tempTeamId ?? team_id}_viewer`]
                        : listType === IssueListType.Inbox
                            ? [`inboxQueries_${userID}`]
                            : ["queries"],
        queryFn: fetchQueries,
        getNextPageParam: (
            lastPage,
        ):
            | { status: string; cursor: number; isNewStatus: boolean }
            | undefined => {
            if (!lastPage.has_next_page) {
                // Move to next status
                if (lastPage.nextStatus) {
                    return {
                        status: lastPage.nextStatus,
                        cursor: 0,
                        isNewStatus: true,
                    };
                }
                return undefined;
            }

            // Continue fetching current status
            return {
                status: lastPage.currentStatus,
                cursor: lastPage.next_cursor,
                isNewStatus: false,
            };
        },
        initialPageParam: {
            status: "Breaching",
            cursor: 0,
            isNewStatus: true,
        },
        refetchInterval: 30000, // 30 seconds
    });


    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    const fetchInteractionCounts =
        async (): Promise<InteractionCountsResponse> => {
            if (listType === IssueListType.Inbox) {
                interactionFilters.assignee_user_id = [userID];
            }
            const requestData: InteractionCountsPayload = {
                filters: interactionFilters,
                header_type: "Status",
                headers: [
                    "Breaching",
                    "NeedsResponse",
                    "Escalated",
                    "Open",
                    "Closed",
                ],
            };
            if (useTeamQuery) {
                requestData.team_ids = [tempTeamId ?? team_id ?? ""];
                if (listType === IssueListType.TeamViewer) {
                    requestData.team_filter_type = "viewer";
                } else {
                    requestData.team_filter_type = "owner";
                }
            } else if (teamIDsFromFilter && teamIDsFromFilter.length !== 0) {
                requestData.team_ids = teamIDsFromFilter;
                requestData.team_filter_type = "owner";
            }

            const response: AxiosResponse<{ data: InteractionCountsResponse }> =
                await api.post(
                    `${URLS.serverUrl}${API.interactionCounts}`,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    },
                );
            if (response.status === 200) {
                const ic: InteractionCountsResponse = response.data.data;
                if (!ic?.header_counts) {
                    ic.header_counts = new Map();
                } else {
                    ic.header_counts = new Map(
                        Object.entries(ic.header_counts),
                    );
                }
                return ic;
            }
            throw new Error("Failed to fetch data");
        };

    const {
        data: interactionCounts,
        isLoading: icIsLoading,
        isError: icIsError,
        refetch: icRefetch,
    } = useQuery<InteractionCountsResponse>({
        queryKey:
            listType === IssueListType.View
                ? [`viewInteractionCounts_${viewId}`]
                : listType === IssueListType.Team
                    ? [`teamInteractionCounts_${tempTeamId ?? team_id}_owner`]
                    : listType === IssueListType.TeamViewer
                        ? [`teamInteractionCounts_${tempTeamId ?? team_id}_viewer`]
                        : listType === IssueListType.Inbox
                            ? [`inboxInteractionCounts_${userID}`]
                            : ["interactionCounts"],
        queryFn: fetchInteractionCounts,
        refetchInterval: 30000, // 30 seconds (matches queries page refresh)
        enabled:
            listType !== IssueListType.View ||
            Object.keys(interactionFilters).length > 0 ||
            teamIDsFromFilter !== undefined ||
            useTeamQuery,
    });

    useEffect(() => {
        const countsQueryKey =
            listType === IssueListType.View
                ? [`viewInteractionCounts_${viewId}`]
                : listType === IssueListType.Team
                    ? [`teamInteractionCounts_${tempTeamId ?? team_id}_owner`]
                    : listType === IssueListType.TeamViewer
                        ? [`teamInteractionCounts_${tempTeamId ?? team_id}_viewer`]
                        : listType === IssueListType.Inbox
                            ? [`inboxInteractionCounts_${userID}`]
                            : ["interactionCounts"];
        const currentQueriesKey =
            listType === IssueListType.View
                ? [`viewQueries_${viewId}`]
                : listType === IssueListType.Team
                    ? [`teamQueries_${tempTeamId ?? team_id}_owner`]
                    : listType === IssueListType.TeamViewer
                        ? [`teamQueries_${tempTeamId ?? team_id}_viewer`]
                        : listType === IssueListType.Inbox
                            ? [`inboxQueries_${userID}`]
                            : ["queries"];
        queryClient.cancelQueries({ queryKey: currentQueriesKey });
        queryClient.invalidateQueries({ queryKey: countsQueryKey });

        // Replace with empty data structure that matches your infinite query format
        queryClient.setQueryData(currentQueriesKey, {
            pages: [
                {
                    data: [],
                    has_next_page: false,
                    next_cursor: 0,
                    currentStatus: "Breaching",
                },
            ],
            pageParams: [{ status: "Breaching", cursor: 0, isNewStatus: true }],
        });


        setTimeout(() => {
            refetch();
        }, 0);
    }, [interactionFilters, teamIDsFromFilter, useTeamQuery, listType]);

    const refetchQueriesAndCounts = (_?: RefetchOptions) => {
        icRefetch();

        return refetch();
    };

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();
            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: [
                        "Slack",
                        "CommunitySlack",
                        "Discord",
                        "Google",
                        "API",
                    ],
                }),
            });

            if (res.ok) {
                const orgInfo: OrgInfoResponse = (await res.json()).data;

                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels.concat(
                                        res.data.data
                                            ?.trigger_based_ticket_channels,
                                    );
                                theMap.set("Slack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle CommunitySlack scopes asynchronously
                if (orgInfo.CommunitySlack) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/CommunitySlack`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels.concat(
                                        res.data.data
                                            ?.trigger_based_ticket_channels,
                                    );
                                theMap.set("CommunitySlack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Discord", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab Google integrations
                if (orgInfo.Google) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/Google`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("Gmail", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab API integrations
                if (orgInfo.API) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/API`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("API", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }
            }

            return theMap;
        },
    });

    const interactionTypesQuery = useInteractionTypes();

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    // TODO: stop after 2.5k tickets have been loaded
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            const lastPage = data?.pages[data.pages.length - 1];
            const totalFetchedTickets =
                data?.pages.reduce((acc, page) => acc + page.data?.length, 0) ??
                0;

            // Base progressive delay based on total tickets
            const delay = Math.min(
                2000,
                Math.floor(100 * (1 + totalFetchedTickets / 200)),
            );

            const timer = setTimeout(() => {
                if (
                    hasNextPage &&
                    !isFetchingNextPage &&
                    totalFetchedTickets < 2500
                ) {
                    fetchNextPage();
                }
            }, delay);
            return () => clearTimeout(timer);
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage, data]);

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const topicsQuery = useQuery<GetTopicsResponse[]>({
        queryKey: team_id ? [`teamTopics_${team_id}`] : ["topics"],
        queryFn: team_id ? () => fetchTeamTopics() : () => fetchTopics(),
    });

    const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const fetchTeamTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTopics}/team/${team_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const categoryOptionsQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    const customerGroupsQuery = useQuery<CustomerGroup[]>({
        queryKey: ["customer_groups"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            const customerGroups: CustomerGroup[] = data.data;
            return customerGroups;
        },
    });

    const getTopicMap = (topicArray: GetTopicsResponse[]) => {
        const map = new Map<string, GetTopicsResponse>();

        for (const topic of topicArray) {
            map.set(topic.topic_name, topic);
        }

        return map;
    };

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);


    useEffect(() => {
        // Only reset to defaults if filters is empty/undefined or listType changes
        const defaultFilters = new Map<string, Set<string>>();
        defaultFilters.set("Status", new Set());
        if (listType !== IssueListType.Inbox) {
            defaultFilters.set("Assignee", new Set());
        }
        defaultFilters.set("Source", new Set());
        const mergedFilters = filters
            ? new Map<string, Set<string>>(filters)
            : new Map<string, Set<string>>();
        // For each default filter, ensure it's present in the merged filters (but only if missing)
        for (const [key, value] of defaultFilters.entries()) {
            if (!mergedFilters.has(key)) {
                mergedFilters.set(key, value);
            }
        }

        setInputFilters(mergedFilters);
    }, [listType, propsFilters, team_id]);

    useEffect(() => {
        if (listType === IssueListType.Inbox) {
            inputFilters?.delete("Assignee");
        }
    }, [listType]);

    const [isFirstDialogOpen, setIsFirstDialogOpen] = useState(false);
    const [isSecondDialogOpen, setIsSecondDialogOpen] = useState(false);



    const [activeTab, setActiveTab] = useState<string>("all");
    // Filter issues based on the current filters, search query, and active tab
    const visibleIssues = useMemo(() => combinedData.filter((issue) => {
        // Check if the issue matches the search query
        const matchesSearch =
            searchQuery.trim() === "" ||
            issue.title
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            issue.query
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
            `${issue.ticket_identifier}-${issue.ticket_number}`
                .toLowerCase()
                .includes(searchQuery.toLowerCase());

        // Check if the issue matches the active tab
        const matchesTab =
            activeTab === "all" ||
            issue.ticket_status === activeTab;

        return matchesSearch && matchesTab;
    }), [combinedData, searchQuery, activeTab, interactionFilters]);

    useEffect(() => {
        if (listType !== IssueListType.Inbox) {
            setActiveTab("all")
        }
    }, [listType])

    return (
        <div>
            <Flex
                direction="column"
                align="center"
                justify="center"
                className="h-screen w-full"
            >
                {" "}
                {/* Make Flex container take full height and prevent overflow */}
                <Box
                    height="100%" // Ensure it takes full height of its container
                    width="98%"
                    mt={`${hidePopup ? "0" : "6"}`}
                    className="h-full w-full overflow-hidden"
                >
                    <Box className="block md:flex md:items-center justify-between md:px-8 px-2">
                        {!hidePopup && (
                            <Flex
                                align="start"
                                direction="column"
                                justify="start"
                                className="overflow-hidden"
                            >
                                <Heading
                                    size="5"
                                    align="left"
                                    className="flex items-center gap-2"
                                >
                                    {listType === IssueListType.Inbox
                                        ? "Inbox"
                                        : listType === IssueListType.TeamViewer
                                            ? "Watch"
                                            : "Interactions"}
                                    {
                                        // For Team view
                                        (listType === IssueListType.Team &&
                                            team_id && (
                                                <TeamBadges
                                                    teams={(
                                                        teamsQuery.data ?? []
                                                    ).filter(
                                                        (team) =>
                                                            team.id === team_id,
                                                    )}
                                                    defaultIsWorkspace={false}
                                                />
                                            )) ||
                                        // For Team Watch view
                                        (listType ===
                                            IssueListType.TeamViewer &&
                                            team_id && (
                                                <TeamBadges
                                                    teams={(
                                                        teamsQuery.data ??
                                                        []
                                                    ).filter(
                                                        (team) =>
                                                            team.id ===
                                                            team_id,
                                                    )}
                                                    defaultIsWorkspace={
                                                        false
                                                    }
                                                />
                                            )) ||
                                        // For regular Issues view
                                        (listType ===
                                            IssueListType.Issues &&
                                            getBadgeForTeam("General")) ||
                                        // For Saved View
                                        (listType === IssueListType.View &&
                                            name &&
                                            SavedViewBadge(name))
                                    }
                                </Heading>

                                {/* <Text mb="10px" size="2">
                                    {listType === IssueListType.Inbox &&
                                        "Track and manage interactions assigned to me"}
                                    {listType === IssueListType.Issues &&
                                        !name &&
                                        "Manage the interactions that Assembly identifies and creates."}
                                    {listType === IssueListType.Team &&
                                        "Manage the interactions that Assembly identifies and creates for your team."}
                                    {listType === IssueListType.TeamViewer &&
                                        "Track the interactions that are viewable but not assigned to your team."}
                                    {name && description}
                                </Text> */}
                            </Flex>
                        )}

                        {listType !== IssueListType.CreateView && (
                            <div className="relative">
                                <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                                <Input
                                    placeholder="Search"
                                    className="w-[500px] border border-[#F2EFFA] bg-[#545A92] bg-opacity-5 rounded-xl pl-10"
                                    value={searchQuery}
                                    onChange={(e) =>
                                        setSearchQuery(e.target.value)
                                    }
                                />
                            </div>
                        )}

                        {!hidePopup && (
                            <div className="flex items-center gap-1.5">
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="hover:bg-muted px-2"
                                    onClick={() => {
                                        setToggleTable((prev) => !prev);
                                    }}
                                >
                                    <TooltipProvider>
                                        {toggleTable ? (
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <ViewVerticalIcon />
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Kanban View</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <ViewHorizontalIcon />
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>List View</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        )}
                                    </TooltipProvider>
                                </Button>
                                <DropdownMenu modal={false}>
                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                                            size="sm"
                                            variant="outline"
                                        >
                                            Create Issue
                                            <PlusIcon className="w-3 h-3" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="w-52 mx-4">
                                        <DropdownMenuItem
                                            onClick={() =>
                                                setIsFirstDialogOpen(true)
                                            }
                                            className="text-xs px-2 py-1.5 hover:bg-muted"
                                        >
                                            Create Single Issue
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() =>
                                                setIsSecondDialogOpen(true)
                                            }
                                            className="text-xs px-2 py-1.5 hover:bg-muted"
                                        >
                                            Bulk Upload Issues
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                {!teamsQuery.isLoading && (
                                    <CreateIssuePopup
                                        topics={getTopicColors(
                                            topicsQuery.data ?? [],
                                        )}
                                        categories={
                                            categoryOptionsQuery.data ?? []
                                        }
                                        userID={userID}
                                        queryClient={queryClient}
                                        dialogIsOpen={isFirstDialogOpen}
                                        setDialogIsOpen={setIsFirstDialogOpen}
                                        teams={(teamsQuery.data ?? []).filter(
                                            (team) =>
                                                !(
                                                    team.team_name ===
                                                    "General" &&
                                                    team.id === team.org_id
                                                ),
                                        )}
                                        teamID={team_id}
                                    />
                                )}
                                <FileUploadForCreateTicket
                                    userID={userID}
                                    dialogIsOpen={isSecondDialogOpen}
                                    setDialogIsOpen={setIsSecondDialogOpen}
                                />
                            </div>
                        )}
                    </Box>

                    {listType === IssueListType.Inbox && <div className="px-8 my-4">
                        <Tabs defaultValue="all" value={activeTab} onValueChange={setActiveTab}>
                            <TabsList className="bg-[#545A92] bg-opacity-5 h-8 items-center rounded-[10px]">
                                <TabsTrigger value="all" className={`text-xs h-5 py-1.5 px-2 rounded-[8px] font-normal ${activeTab === "all" ? "bg-white text-[#2C2E4A] border border-solid border-[#F2EFFA]" : "bg-transparent text-[#191A2C] border border-transparent"}`}>
                                    <LayoutGrid className="h-4 w-4 mr-2" />
                                    All Issues
                                    <span className="ml-1 rounded-[4px] bg-[#545A92] bg-opacity-5 px-2 py-0.5 text-xs font-normal h-6 items-center justify-center flex">
                                        {interactionCounts?.total_count ?? 0}
                                    </span>
                                </TabsTrigger>
                                {["Breaching", "NeedsResponse", "Escalated", "Open", "Closed"].map(status => {
                                    const Icon = getStatusIcon(status);
                                    const label = ticketStatuses.find(s => s.value === status)?.label || status;
                                    const count = interactionCounts?.header_counts?.get(status);

                                    return (
                                        <TabsTrigger key={status} value={status} className={`text-xs h-5 py-1.5 px-2 font-normal rounded-[8px]  ${activeTab === status ? "bg-white text-[#2C2E4A] border border-solid border-[#F2EFFA]" : "bg-transparent text-[#191A2C] border border-transparent"}`}>
                                            {Icon && <Icon className="h-4 w-4 mr-2" />}
                                            {label}
                                            {count !== undefined && (
                                                <span className="ml-1 rounded-[4px] bg-[#545A92] bg-opacity-5 px-2 py-0.5 text-xs font-normal h-6 items-center justify-center flex">
                                                    {count}
                                                </span>
                                            )}
                                        </TabsTrigger>
                                    );
                                })}
                            </TabsList>
                        </Tabs>
                    </div>}
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex mx-8 my-3 flex-wrap gap-1">
                            <FilterItems
                                filters={inputFilters ?? new Map()}
                                setFilters={updateFilters}
                                categories={categoryOptionsQuery.data ?? []}
                                topics={getTopicColors(topicsQuery.data ?? [])}
                                users={usersQuery.data ?? []}
                                customerGroups={customerGroupsQuery.data ?? []}
                                teams={teamsQuery.data ?? []}
                                savedViewFilters={inputFilters ?? new Map()}
                                isGeneralTeam={
                                    listType === IssueListType.Issues ||
                                    listType === IssueListType.CreateView ||
                                    listType === IssueListType.Inbox
                                }
                                channels={channelsQuery?.data ?? new Map()}
                                interactionTypes={interactionTypesQuery.data ?? []}
                                defaultFilter={propsFilters}
                                listType={listType}
                            />
                        </div>
                        {hidePopup && (
                            <Button
                                type="button"
                                variant="ghost"
                                className="hover:bg-muted px-2"
                                onClick={() => {
                                    setToggleTable((prev) => !prev);
                                }}
                            >
                                <TooltipProvider>
                                    {toggleTable ? (
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <ViewVerticalIcon />
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-[#5B5BD6]">
                                                <p>Kanban View</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <ViewHorizontalIcon />
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-[#5B5BD6]">
                                                <p>List View</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    )}
                                </TooltipProvider>
                            </Button>)}
                    </div>

                    <div className="flex-1 flex-col md:flex overflow-hidden">
                        {(loadingState === 1 || loadingState === 0) &&
                            !topicsQuery.isLoading &&
                            !usersQuery.isLoading &&
                            !isLoading &&
                            toggleTable && (
                                <IssuesList
                                    issues={visibleIssues}
                                    topics={getTopicColors(
                                        topicsQuery.data ?? [],
                                    )}
                                    topicsMap={getTopicMap(
                                        topicsQuery.data ?? [],
                                    )}
                                    userID={userID}
                                    listType={listType}
                                    usersQuery={usersQuery}
                                    customerGroupsQuery={customerGroupsQuery}
                                    teamsQuery={teamsQuery}
                                    onFilterChange={onFilterChange}
                                    onGroupChange={onGroupChange}
                                    inputFilters={inputFilters ?? new Map()}
                                    inputGrouping={groups}
                                    viewID={viewId}
                                    viewName={name}
                                    teamID={team_id}
                                    listHeight={listHeight}
                                    refetch={refetchQueriesAndCounts}
                                    channelsQuery={channelsQuery}
                                    searchQuery={searchQuery}
                                    interactionCounts={interactionCounts}
                                    setInteractionFilters={
                                        setInteractionFilters
                                    }
                                    setTeamIDsFromFilter={setTeamIDsFromFilter}
                                    interactionTypesQuery={interactionTypesQuery}
                                    activeStatusTab={activeTab !== "all" ? activeTab : undefined}
                                />
                            )}
                        {loadingState === 1 &&
                            data &&
                            !toggleTable && (
                                <AdminKanbanViewPage
                                    issues={visibleIssues}
                                    users={usersQuery.data ?? []}
                                    refetch={refetchQueriesAndCounts}
                                    listType={listType}
                                    teamId={team_id ?? ""}
                                    viewId={viewId}
                                    teams={teamsQuery.data ?? []}
                                    categories={categoryOptionsQuery.data ?? []}
                                    topics={getTopicColors(
                                        topicsQuery.data ?? [],
                                    )}
                                    userID={userID}
                                    searchQuery={searchQuery}
                                    interactionCounts={interactionCounts}
                                />
                            )}
                        {loadingState === 2 && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                    </div>
                </Box>
            </Flex>
        </div>
    );
};

export default memo(AdminQueriesPage, areEqual);