import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import Nango from "@nangohq/frontend";
import {
    type WithAuthInfoProps,
    useAuthInfo,
    withRequiredAuthInfo,
} from "@propelauth/react";
import { Box, Button, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { Terminal } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GoogleSheetSvg } from "../../../images/integrations/googlesheet.svg";
import googleSheetIntegrationImage from "../../../images/spreadsheet_help.png";
import IntegrationHeader from "./IntegrationHeader";

export const NANGO_GOOGLE_SHEET_INTEGRATION_ID = "google-sheet";

export const GoogleSheetIntegration = withRequiredAuthInfo(
    (props: WithAuthInfoProps) => {
        const navigate = useNavigate();
        const loremIpsum =
            "Lorem ipsum sit, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

        const { user } = useAuthInfo();

        const [integrationEnabled, setIntegrationEnabled] =
            useState<boolean>(false);
        const [error, setError] = useState<string | undefined>(undefined);

        const api = useApi();
        const { toast } = useToast();

        const [loadingState, setLoadingState] = useState<number>(0);

        const connect = () => {
            const requestData = {
                user_id: user?.userId,
                user_email: user?.email,
                allowed_integrations: [NANGO_GOOGLE_SHEET_INTEGRATION_ID],
            };
            // first, use search server to generate a NangoSession Token
            api.post(
                URLS.serverUrl + API.createNangoSessionToken,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((getSessionTokenResult) => {
                    // then, use Nango Auth to connect to user account using OAuth
                    if (getSessionTokenResult.status === 200) {
                        const nango = new Nango({
                            connectSessionToken:
                                getSessionTokenResult.data.data.token,
                        });
                        nango
                            .auth(NANGO_GOOGLE_SHEET_INTEGRATION_ID)
                            .then((nangoAuthResult) => {
                                console.log("successfully connected to nango!");
                                const createIntegrationData = {
                                    connection_id: nangoAuthResult.connectionId,
                                    provider_config_key:
                                        nangoAuthResult.providerConfigKey,
                                    integration_type: "GoogleSheet",
                                };
                                // nango OAuth returns a connection_id. We need to store this connection ID in DB
                                // use this connectionID to create a Nango Integration
                                api.post(
                                    URLS.serverUrl + API.createNangoIntegration,
                                    createIntegrationData,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    },
                                ).then((createIntegrationResult) => {
                                    if (
                                        createIntegrationResult.status === 200
                                    ) {
                                        toast({
                                            title: "Successfully created Google Sheet Integration",
                                            description:
                                                "The page will refresh shortly.",
                                        });
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 3000); // 3 seconds and then force refreesh the screen
                                    } else {
                                        toast({
                                            title: "Failed to create Google Sheet Integration",
                                            description:
                                                "Please try again at a later time.",
                                            variant: "destructive",
                                        });
                                    }
                                });
                            })
                            .catch((error) => {
                                // Show failure UI.
                                console.log(error);
                                toast({
                                    title: "Failed to initialize Google Sheet Integration",
                                    description:
                                        "Please try again at a later time.",
                                    variant: "destructive",
                                });
                            });
                    } else {
                        toast({
                            title: "Failed to initialize Google Sheet Integration",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                        setLoadingState(2);
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Failed to get Nango Session Token",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                    setLoadingState(2);
                });
        };

        useEffect(() => {
            const requestData = {
                types: ["GoogleSheet"],
            };
            api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.data) {
                            const orgInfo: OrgInfoResponse = res.data.data;
                            if (orgInfo.GoogleSheet) {
                                setIntegrationEnabled(true);
                            }
                            setLoadingState(1);
                        }
                    } else {
                        console.log("failed to get results");
                        setLoadingState(2);
                    }
                })
                .catch((res) => {
                    console.log("failed to get repository");
                    setLoadingState(2);
                });
        }, [api]);

        return (
            <div>
                <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                    <Flex direction={"column"} align={"start"} gap="6">
                        <IntegrationHeader
                            integrationType="Google Sheet"
                            description="Connect your Google Sheet account to see customer data in Assembly."
                            SvgIcon={GoogleSheetSvg}
                        />
                        <Flex className="w-full justify-end">
                            <div className="flex flex-col gap-2">
                                {!integrationEnabled ? (
                                    <Button onClick={connect}>Enable</Button>
                                ) : (
                                    <Button disabled>Enabled</Button>
                                )}
                            </div>
                        </Flex>

                        {integrationEnabled && (
                            <Alert>
                                <Terminal className="h-4 w-4" />
                                <AlertTitle>Heads up!</AlertTitle>
                                <AlertDescription>
                                    To use this integration properly, you'll
                                    need to enable the Apps Script plugin on
                                    google sheets
                                </AlertDescription>
                                <img
                                    src={googleSheetIntegrationImage}
                                    alt="Google Sheet Integration"
                                    className="py-2 px-4"
                                />
                            </Alert>
                        )}
                    </Flex>

                    {loadingState === 0 && (
                        <Skeleton>
                            <Text>
                                {[...Array(2)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Text>
                        </Skeleton>
                    )}

                    {(loadingState === 2 || error) && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}
                </Box>
            </div>
        );
    },
);
