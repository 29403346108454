import { MyUser, MyUserInfo } from "@/Ticket/User";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import type {
    GetUserResponse,
    Label,
    Query,
    Teams,
} from "@/interfaces/serverData";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    arraysAreEqual,
    cleanText,
    formatEmojis,
    getHtmlStringFromReactContent,
    hasIssueChanged,
} from "@/utilities/methods";
import { AvatarIcon } from "@radix-ui/react-icons";
import { Text } from "@radix-ui/themes";
import parse from "html-react-parser";
import type React from "react";
import {
    memo,
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkEmoji from "remark-emoji";
import remarkGfm from "remark-gfm";
import { toHTML } from "slack-markdown";

import AttributesBadge from "@/component/AttributesBadge";
import Clock from "@/component/Timer";
import { Checkbox } from "@/component/shadcn/ui/checkbox";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { useApi } from "@/interfaces/api";
import { CheckIcon, MailIcon, MessageCircleReply } from "lucide-react";
import { CardAvatar } from "./CardAvatar";

const areEqual = (
    prevProps: IssuesListCardProps,
    nextProps: IssuesListCardProps,
) => {
    return (
        hasIssueChanged(prevProps.issue, nextProps.issue) &&
        arraysAreEqual(prevProps.users, nextProps.users) &&
        arraysAreEqual(prevProps.teams, nextProps.teams) &&
        prevProps.listType === nextProps.listType &&
        prevProps.refetch === nextProps.refetch &&
        prevProps.teamID === nextProps.teamID &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.onSelect === nextProps.onSelect &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.openContextMenuForSingleIssue ===
        nextProps.openContextMenuForSingleIssue &&
        prevProps.interactionTypes === nextProps.interactionTypes
    );
};

interface IssuesListCardProps {
    issue: Query;
    interactionTypes: Label[];
    users: GetUserResponse[];
    teams: Teams[];
    listType: IssueListType;
    refetch: () => void;
    teamID?: string;
    viewID?: string;
    onSelect: (
        checked: boolean,
        issueId: string,
        group: string,
        event?: React.MouseEvent,
    ) => void;
    isSelected: boolean;
    openContextMenuForSingleIssue: (issue: Query) => void;
    noCheckbox?: boolean;
}

function IssuesListCard({
    issue: issueState,
    interactionTypes,
    users,
    listType,
    teamID,
    viewID,
    onSelect,
    isSelected,
    openContextMenuForSingleIssue,
    noCheckbox
}: IssuesListCardProps) {
    const navigate = useNavigate();
    const api = useApi();

    // Memoize the onSelect handler to prevent unnecessary re-renders
    const handleSelect = useCallback(
        (checked: boolean, event?: React.MouseEvent) => {
            onSelect(checked, issueState.id, issueState.ticket_status || "", event);
        },
        [onSelect, issueState.id, issueState.ticket_status]
    );

    // Memoize the context menu handler
    const handleContextMenu = useCallback(() => {
        openContextMenuForSingleIssue(issueState);
    }, [openContextMenuForSingleIssue, issueState]);

    const foundUser: GetUserResponse | undefined = useMemo(
        () => users.find((user) => user.id === issueState.assignee_user_id),
        [issueState.assignee_user_id, users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () =>
            integrationBackEndDataMappingToSvg.get(
                issueState.source ?? "Unknown",
            ),
        [issueState.source],
    );

    const ticketUpdatedDate: string = useMemo(() => {
        let updatedDate = new Date(issueState.ticket_updated_at);
        if (
            Number.isNaN(updatedDate.getTime()) ||
            !issueState.ticket_updated_at
        ) {
            updatedDate = new Date();
        }
        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [issueState.ticket_updated_at]);

    const handleRowClick = useCallback(
        (id: string) => {
            let from: string;
            if ((listType === IssueListType.Team || listType === IssueListType.TeamViewer) && teamID) {
                from = "team";
                navigate(`/teams/${teamID}/issue/${id}`, { state: { from } });
            } else if (listType === IssueListType.View && viewID) {
                from = "view";
                navigate(`/views/${viewID}/issue/${id}`, { state: { from } });
            } else {
                from = listType === IssueListType.Inbox ? "inbox" : "issues";
                navigate(`/issue/${id}`, { state: { from } });
            }
        },
        [listType, teamID, navigate],
    );

    const timestampCutoff = "2024-09-30T23:50:00.000000Z";
    const parsedTimestampCutoff = new Date(timestampCutoff);
    const commentParsedTimestamp = new Date(issueState.created_at);

    const [truncatedText, setTruncatedText] = useState<string>(
        cleanText(formatEmojis(issueState.query ?? "")),
    );
    const containerRef = useRef<HTMLDivElement | null>(null);
    const truncateTextToFit = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;

            // Clean and format the text before truncating
            const formattedText = cleanText(
                formatEmojis(issueState.query ?? ""),
            );

            // Create a span to measure the width of the full text without rendering it in the DOM
            const tempSpan = document.createElement("span");
            tempSpan.style.visibility = "hidden";
            tempSpan.style.whiteSpace = "nowrap";
            document.body.appendChild(tempSpan);

            let currentText = "";
            const words = formattedText.split(" ");
            let index = 0;

            // Measure word by word until the text exceeds the container width
            while (index < words.length) {
                currentText += `${words[index]} `;
                tempSpan.textContent = currentText.trim();
                if (tempSpan.offsetWidth > containerWidth) {
                    break;
                }
                index++;
            }

            // Set the truncated text (with ellipsis if needed)
            const truncated = words.slice(0, index).join(" ");
            setTruncatedText(truncated);

            // Clean up the temporary span
            document.body.removeChild(tempSpan);

            // If the text is truncated and was cut off, add ellipsis
            if (words.length > index) {
                setTruncatedText((prevText) => `${prevText}...`);
            }
        }
    };

    useLayoutEffect(() => {
        // Truncate text initially
        truncateTextToFit();

        // Adjust truncation when the container resizes
        const handleResize = () => {
            truncateTextToFit();
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [issueState.query]);

    const handleCheckboxClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            handleSelect(
                !isSelected,
                e,
            );
        },
        [isSelected, handleSelect],
    );

    const [interactionType, setInteractionType] = useState<Label | undefined>(
        interactionTypes.find((it) => it.id === issueState.interaction_type_id),
    );

    // Memoize the Clock component to prevent unnecessary re-renders
    const memoizedClock = useMemo(() => {
        if (!issueState?.breaching) return null;

        return (
            <Clock
                targetTime={issueState.breaching}
                status={issueState.ticket_status}
                businessHoursID={issueState.business_hours_id}
                paused={issueState.paused}
                whenRestart={issueState.when_restart}
                timeLeftSeconds={issueState.time_left_seconds}
                api={api}
            />
        );
    }, [
        issueState?.breaching,
        issueState?.ticket_status,
        issueState?.business_hours_id,
        issueState?.paused,
        issueState?.when_restart,
        issueState?.time_left_seconds,
        api
    ]);

    return (
        <Card
            className="bg-transparent h-full flex w-full border-none shadow-none pr-1 mt-1 hover:bg-[#545A92] hover:bg-opacity-5 "
            onClick={() =>
                handleRowClick(
                    `${issueState.ticket_identifier}-${issueState.ticket_number}`,
                )
            }
            onContextMenu={handleContextMenu}
        >
            <CardContent className="p-0 w-full rounded-[8px] px-3">
                <div className="text-xs bg-transparent border-none p-0 cursor-pointer w-full h-full">
                    <div className="flex items-center w-full h-full gap-3">
                        <div className="flex items-center gap-2 flex-shrink-0">
                            {!noCheckbox && <div
                                className="h-[100%] flex-shrink-0"
                                onClick={handleCheckboxClick}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" || e.key === " ") {
                                        e.stopPropagation();
                                    }
                                }}
                            >
                                <Checkbox
                                    checked={isSelected}
                                    className={`${isSelected ? "opacity-100" : "opacity-0"
                                        } hover:opacity-100 hover:shadow-[0_0_10px_4px] hover:shadow-iris5 lb-comment-details data-[state=checked]:bg-[#5e6ad2] data-[state=checked]:text-[#eceefb] outline-1 outline hover:outline-iris10 outline-[#eceefb] cursor-pointer mr-1`}
                                    data-checked={isSelected}
                                />
                            </div>}
                            <CardAvatar issueState={issueState} />
                            <div className="flex flex-col gap-0.5 w-full">
                                <div className="flex items-center gap-2">
                                    <div className="w-[110px]">
                                        {issueState.customer_info?.id &&
                                            issueState.customer_info.id !== "" &&
                                            issueState.customer_info.id !== "00000000-0000-0000-0000-000000000000" &&
                                            issueState.company_info?.id &&
                                            issueState.company_info.id !== "" &&
                                            issueState.company_info.id !== "00000000-0000-0000-0000-000000000000" ? (
                                            <div>
                                                <MyUserInfo
                                                    user={issueState.customer_info}
                                                    className="lb-comment-author text-[11px] max-w-[100px] truncate text-[#424562]"
                                                />
                                                <MyUserInfo
                                                    user={issueState.company_info}
                                                    className="lb-comment-author text-xs max-w-[100px] truncate text-[#737595]"
                                                />
                                            </div>
                                        ) : issueState.company_info?.id &&
                                            issueState.company_info.id !== "" &&
                                            issueState.company_info.id !== "00000000-0000-0000-0000-000000000000" ? (
                                            <MyUserInfo
                                                user={issueState.company_info}
                                                className="lb-comment-author text-xs max-w-[100px] truncate text-[#737595]"
                                            />
                                        ) : issueState.customer_info?.id &&
                                            issueState.customer_info.id !== "" &&
                                            issueState.customer_info.id !== "00000000-0000-0000-0000-000000000000" ? (
                                            <MyUserInfo
                                                user={issueState.customer_info}
                                                className="lb-comment-author text-xs max-w-[100px] truncate text-[#424562]"
                                            />
                                        ) : issueState.user_info?.id !== "" ? (
                                            <MyUser
                                                user={issueState.user_info}
                                                className="lb-comment-author text-xs max-w-[100px] truncate text-[#424562]"
                                                truncate={true}
                                            />
                                        ) : <div className="lb-comment-author text-xs max-w-[100px] truncate text-[#424562]">Anonymous</div>}
                                    </div>

                                    {interactionType && (
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <MailIcon
                                                        color={
                                                            interactionType?.color ??
                                                            "gray"
                                                        }
                                                        className="w-3.5 h-3.5 -ml-0.5 flex-shrink-0"
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent className="text-[11px] max-w-[150px] flex flex-col">
                                                    {interactionType.name}
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    )}
                                    {issueState.owner_replies +
                                        issueState.customer_replies !==
                                        0 && (
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <div>
                                                            <AttributesBadge className="py-0.5">
                                                                <div className="flex items-center gap-1 text-[11px] text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                                    {issueState.owner_replies +
                                                                        issueState.customer_replies}
                                                                    <MessageCircleReply className="w-3.5 h-3.5" />
                                                                </div>
                                                            </AttributesBadge>
                                                        </div>
                                                    </TooltipTrigger>
                                                    <TooltipContent className="text-[11px] max-w-[150px] flex flex-col">
                                                        <div className="flex items-center gap-1">
                                                            {`Customer: ${issueState.customer_replies}`}
                                                            <MessageCircleReply className="w-3.5 h-3.5" />
                                                        </div>
                                                        <div className="flex items-center gap-1">
                                                            {`Team: ${issueState.owner_replies} `}
                                                            <MessageCircleReply className="w-3.5 h-3.5" />
                                                        </div>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow min-w-0 mr-1">
                            {(issueState.source === "Slack" ||
                                issueState.source === "CommunitySlack") &&
                                commentParsedTimestamp < parsedTimestampCutoff ? (
                                <div className="text-[14px] text-[#5A5C7B] font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm, remarkEmoji]}
                                        rehypePlugins={[rehypeRaw]}
                                        className="markdown-content whitespace-nowrap overflow-hidden text-ellipsis"
                                    >
                                        {getHtmlStringFromReactContent(
                                            parse(
                                                toHTML(
                                                    cleanText(
                                                        issueState.query ?? "",
                                                    ),
                                                ),
                                            ),
                                        )}
                                    </ReactMarkdown>
                                </div>
                            ) : issueState.source === "Gmail" ? (
                                <div
                                    ref={containerRef}
                                    className="text-[14px] text-[#5A5C7B] w-full font-medium whitespace-nowrap overflow-hidden text-ellipsis flex flex-row gap-2"
                                >
                                    <div className="">
                                        Subject: {issueState.title}
                                    </div>
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm, remarkEmoji]}
                                        rehypePlugins={[rehypeRaw]}
                                        className="w-full whitespace-nowrap overflow-hidden text-ellipsis text-[#737595] font-normal"
                                        components={{
                                            a: ({
                                                node,
                                                children,
                                                ...props
                                            }) => (
                                                <a
                                                    {...props}
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                        color: "#5B5BD6",
                                                    }}
                                                >
                                                    {children}
                                                </a>
                                            ),
                                        }}
                                    >
                                        {truncatedText}
                                    </ReactMarkdown>
                                </div>
                            ) : (
                                <div
                                    ref={containerRef}
                                    className="text-[14px] text-[#5A5C7B] w-full font-medium whitespace-nowrap overflow-hidden text-ellipsis"
                                >
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm, remarkEmoji]}
                                        rehypePlugins={[rehypeRaw]}
                                        className="w-full whitespace-nowrap overflow-hidden text-ellipsis"
                                        components={{
                                            a: ({
                                                node,
                                                children,
                                                ...props
                                            }) => (
                                                <a
                                                    {...props}
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                        color: "#5B5BD6",
                                                    }}
                                                >
                                                    {children}
                                                </a>
                                            ),
                                        }}
                                    >
                                        {truncatedText}
                                    </ReactMarkdown>
                                </div>
                            )}
                        </div>

                        <div className="flex items-center gap-4 justify-end flex-shrink-0">
                            {memoizedClock}
                            {issueState.ticket_status === "Open" &&
                                issueState.owner_replies > 0 && (
                                    <div className="flex items-center bg-[#545A92] bg-opacity-5 h-6 px-2 rounded-sm text-xs text-[#006971]">
                                        <CheckIcon className="w-4 h-4" />
                                        <span>Replied</span>
                                    </div>
                                )}
                            <div className="flex flex-row items-center gap-2 flex-shrink-0">
                                {SourceSvgImage && (
                                    <SourceSvgImage className="w-4 h-4" />
                                )}

                                {/* {issueState.owner_replies +
                                            issueState.customer_replies !==
                                            0 ? (  
                                    <div className="flex flex-row items-start gap-0.5">
                                        <Text className="text-[#737595] text-[11px] font-medium">
                                                Created {createdDate}
                                        </Text>
                                        <Text className="text-[#737595] text-[11px] font-medium">
                                            Replied {ticketUpdatedDate}
                                        </Text>
                                    </div>
                                ) : (
                                    <Text className="text-[#737595] text-[11px] font-medium">
                                        Created {ticketUpdatedDate}
                                    </Text>
                                )} */}
                                <Text className="text-[#737595] text-[11px] font-medium">
                                    {ticketUpdatedDate}
                                </Text>
                            </div>
                            <div className="flex flex-row items-center flex-shrink-0">
                                {issueState.assignee_user_id &&
                                    issueState.assignee_user_id !== "noAssignee" ? (
                                    <div>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div className="lb-avatar rounded-full w-6 h-6">
                                                        {pictureURL && (
                                                            <img
                                                                className="lb-avatar-image"
                                                                src={pictureURL}
                                                                alt={userName}
                                                            />
                                                        )}
                                                        <span>{userName ?? ""}</span>
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent side="top" align="start" className="text-[11px] z-50">
                                                    <p className="leading-normal">{userName}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                ) : (
                                    <AvatarIcon className="w-6 h-6" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}


const MemoizedIssuesListCard = memo(IssuesListCard, areEqual);
export default MemoizedIssuesListCard;
