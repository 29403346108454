import IssuesList from "@/IssuesTable/IssuesList";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, ContactsAPI, TeamsAPI, URLS } from "@/constant";
import useInteractionTypes from "@/hooks/use-interactionTypes";
import LinkedinSvg from "@/images/linkedin_svg.png";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    CompanyEnrichmentResponse,
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    Integration,
    InteractionCountsPayload,
    InteractionCountsResponse,
    InteractionFilters,
    ListCustomersResponse,
    OrgInfoResponse,
    QueriesWithPaginationResponse,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import {
    convertAccountLabelsToMap,
    healthScoreBadge,
} from "@/utilities/methods";
import { type OrgMemberInfo, useAuthInfo } from "@propelauth/react";
import { GlobeIcon, ImageIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { Badge, Box, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { Tabs } from "@radix-ui/themes";
import { DropdownMenu } from "@radix-ui/themes";
import {
    type InfiniteData,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import {
    BookUserIcon,
    CircleGauge,
    MailboxIcon,
    NotepadTextIcon,
    SquareCheckIcon,
    TableProperties,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IssueListType } from "../AdminQueriesPage";
import AccountPopup from "./AccountPopup";
import type { AccountsListType } from "./Accounts";
import { AttributesPage } from "./AttributesPage";
import { Company360 } from "./Company360";
import { Company360New } from "./Company360New";
import { CustomersTable } from "./CustomersTable/CustomersTable";
import LinkContactsPopup from "./LinkContactsPopup";
import { NotesPage } from "./NotesPage";
import { OnboardingPage } from "./Onboarding/Onboarding";
export interface ContactsProps {
    org: OrgMemberInfo;
    userID: string;
    listType: AccountsListType;
    tab?: string;
}

export const CompanyPage = ({ org, userID, listType, tab }: ContactsProps) => {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const { id } = useParams<{ id: string }>();
    console.log("id is", id)

    const api = useApi();

    const [loadingState, setLoadingState] = useState<number>(0);
    const [needsResponseCount, setNeedsResponseCount] = useState<number>();
    const [breachingCount, setBreachingCount] = useState<number>();
    const [interactionFilters, setInteractionFilters] =
        useState<InteractionFilters>({});
    const [teamIDsFromFilter, setTeamIDsFromFilter] = useState<string[]>();
    const [loadingEnrichment, setLoadingEnrichment] = useState<boolean>(false);

    const fetchCompany = async () => {
        const { url } = ContactsAPI.getCompany;
        const response = await api.get(`${URLS.serverUrl}${url}/${id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        const company: Account = response.data.data;

        return company;
    };

    const {
        data: company,
        isLoading: loadingCompany,
        isError,
        refetch: refetchCompany,
    } = useQuery({
        queryKey: ["company", id],
        queryFn: fetchCompany,
    });
    console.log("company is", company)

    const [enrichedData, setEnrichedData] = useState<
        CompanyEnrichmentResponse | undefined
    >(undefined);

    useEffect(() => {
        // Remove debugger statements as they pause execution
        const fetchEnrichment = async () => {
            if (company && !loadingCompany) {
                // Fixed indentation and logic issue - this block was incorrectly structured
                if (
                    company.enrichment === undefined ||
                    company.enrichment === null
                ) {
                    // If no enrichment data exists, don't return early - continue to fetch it
                    setLoadingEnrichment(true);
                    try {
                        const { url } = ContactsAPI.getCompany;
                        // Make sure company.domain exists before making the API call
                        if (company.domain) {
                            const enrichmentResponse = await api.get(
                                `${URLS.serverUrl}${url}/${id}/${company.domain}/enrichment`,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                },
                            );
                            setEnrichedData(enrichmentResponse.data.data);
                        } else {
                            console.warn(
                                "Cannot fetch enrichment: company domain is missing",
                            );
                        }
                    } catch (error) {
                        console.error("Error fetching enrichment data:", error);
                    } finally {
                        setLoadingEnrichment(false);
                    }
                } else {
                    // If enrichment data already exists, use it directly
                    setEnrichedData(company.enrichment);
                }
            }
        };

        // Add console log to track when this effect runs
        console.log("Enrichment effect running with company:", company?.name);
        fetchEnrichment();
    }, [company, loadingCompany, id, api]);

    const fetchContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(
                `${URLS.serverUrl}${url}/company/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: contactsData,
        fetchNextPage: fetchContactsNextPage,
        hasNextPage: contactsHasNextPage,
        isFetchingNextPage: isFetchingContactsNextPage,
        refetch: refetchContacts,
        isLoading: contactsIsLoading,
        isError: contactsIsError,
    } = useInfiniteQuery({
        queryKey: ["contacts"],
        queryFn: fetchContacts,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });

    // Fetch all the data
    useEffect(() => {
        if (contactsHasNextPage && !isFetchingContactsNextPage) {
            fetchContactsNextPage();
        }
    }, [
        contactsHasNextPage,
        isFetchingContactsNextPage,
        fetchContactsNextPage,
    ]);

    const contactsCombinedData =
        contactsData && Array.isArray(contactsData.pages)
            ? contactsData.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    useEffect(() => {
        if (company) {
            company.associated_customers = contactsCombinedData;
        }
    }, [contactsCombinedData]);

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();
            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: [
                        "Slack",
                        "CommunitySlack",
                        "Discord",
                        "Google",
                        "API",
                    ],
                }),
            });

            if (res.ok) {
                const orgInfo: OrgInfoResponse = (await res.json()).data;

                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels.concat(
                                        res.data.data
                                            ?.trigger_based_ticket_channels,
                                    );
                                theMap.set("Slack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle CommunitySlack scopes asynchronously
                if (orgInfo.CommunitySlack) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/CommunitySlack`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels.concat(
                                        res.data.data
                                            ?.trigger_based_ticket_channels,
                                    );
                                theMap.set("CommunitySlack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Discord", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab Google Integrations
                if (orgInfo.Google) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/Google`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("Gmail", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab API integrations
                if (orgInfo.API) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/API`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("API", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }
            }

            return theMap;
        },
    });

    // Only showing teams that the member is apart of in the filter
    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const fetchCompanyTickets = async ({
        pageParam = { status: "Breaching", cursor: 0, isNewStatus: true },
    }: {
        pageParam?: { status: string; cursor: number; isNewStatus: boolean };
    }): Promise<QueriesWithPaginationResponse> => {
        const statusOrder = [
            "Breaching",
            "NeedsResponse",
            "Escalated",
            "Open",
            "Closed",
        ];
        try {
            const copyOfFilters: InteractionFilters = { ...interactionFilters };
            copyOfFilters.status = [pageParam.status];
            if (company?.id) {
                copyOfFilters.company_id = [company?.id];
            }
            console.log("copy of filters are", copyOfFilters)
            const requestData: InteractionCountsPayload = {
                filters: copyOfFilters,
            };
            const response: AxiosResponse<{
                data: QueriesWithPaginationResponse;
            }> = await api.post(
                `${URLS.serverUrl}${API.interactionsWithPagination}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 500,
                        offset: pageParam.cursor,
                    },
                },
            );
            if (response.status === 200) {
                const currentStatusIndex = statusOrder.indexOf(
                    pageParam.status,
                );

                return {
                    ...response.data.data,
                    currentStatus: pageParam.status,
                    nextStatus: statusOrder[currentStatusIndex + 1],
                };
            }
            setLoadingState(2);
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: pageParam.status,
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: pageParam.status,
            };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        refetch: refetchCompanyTickets,
    } = useInfiniteQuery<
        QueriesWithPaginationResponse,
        Error,
        InfiniteData<QueriesWithPaginationResponse>,
        string[],
        { status: string; cursor: number; isNewStatus: boolean }
    >({
        queryKey: [`company_tickets_${id}`],
        queryFn: fetchCompanyTickets,
        getNextPageParam: (
            lastPage,
        ):
            | { status: string; cursor: number; isNewStatus: boolean }
            | undefined => {
            if (!lastPage.has_next_page) {
                // Move to next status
                if (lastPage.nextStatus) {
                    return {
                        status: lastPage.nextStatus,
                        cursor: 0,
                        isNewStatus: true,
                    };
                }
                return undefined;
            }

            // Continue fetching current status
            return {
                status: lastPage.currentStatus,
                cursor: lastPage.next_cursor,
                isNewStatus: false,
            };
        },
        initialPageParam: {
            status: "Breaching",
            cursor: 0,
            isNewStatus: true,
        },
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
        enabled: !!company?.id,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                )
            : [];

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const fetchInteractionCounts =
        async (): Promise<InteractionCountsResponse> => {
            if (company?.id) {
                interactionFilters.company_id = [company?.id];
            }
            const requestData: InteractionCountsPayload = {
                filters: interactionFilters,
                header_type: "Status",
                headers: [
                    "Breaching",
                    "NeedsResponse",
                    "Escalated",
                    "Open",
                    "Closed",
                ],
            };

            const response: AxiosResponse<{ data: InteractionCountsResponse }> =
                await api.post(
                    `${URLS.serverUrl}${API.interactionCounts}`,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    },
                );
            if (response.status === 200) {
                const ic: InteractionCountsResponse = response.data.data;
                if (!ic?.header_counts) {
                    ic.header_counts = new Map();
                } else {
                    ic.header_counts = new Map(
                        Object.entries(ic.header_counts),
                    );
                }
                return ic;
            }
            throw new Error("Failed to fetch data");
        };

    const {
        data: interactionCounts,
        isLoading: icIsLoading,
        isError: icIsError,
        refetch: icRefetch,
    } = useQuery<InteractionCountsResponse>({
        queryKey: [`companyInteractionCounts_${company?.id}`],
        queryFn: fetchInteractionCounts,
        refetchInterval: 30000, // 30 seconds (matches queries page refresh)
    });

    const queryClient = useQueryClient();
    useEffect(() => {
        const queryKey = [`companyInteractionCounts_${company?.id}`];
        queryClient.resetQueries({ queryKey });
    }, [interactionFilters, teamIDsFromFilter]);

    useEffect(() => {
        let nrCount = 0;
        let bCount = 0;

        for (const interaction of combinedData) {
            if (interaction.ticket_status === "NeedsResponse") {
                nrCount += 1;
            }
            if (interaction.ticket_status === "Breaching") {
                bCount += 1;
            }
        }
        setNeedsResponseCount(nrCount);
        setBreachingCount(bCount);
    }, [combinedData]);

    const updateData = async (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => {
        if (company) {
            refetchCompany();
        }
        if (contacts) {
            refetchContacts();
        }
        if (tickets) {
            refetchCompanyTickets();
        }
    };

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    const [topics, setTopics] = useState<Topic[]>([]);
    const [topicsMap, setTopicsMap] = useState(new Map());

    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: Topic[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                            key: topic.id,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(newTopics);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const customerGroupsQuery = useQuery<CustomerGroup[]>({
        queryKey: ["customer_groups"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            const customerGroups: CustomerGroup[] = data.data;
            return customerGroups;
        },
    });

    const navigate = useNavigate();

    const interactionTypesQuery = useInteractionTypes();

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const location = useLocation();
    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="7" height="100%" width="98%">
                <div className="flex flex-row items-center gap-2.5 pb-4 ml-2">
                    {company?.image_url !== "" &&
                        company?.image_url !== undefined ? (
                        <div className="lb-avatar rounded w-8 h-8">
                            <img
                                className="lb-avatar-image"
                                src={company.image_url}
                                alt={company.name}
                            />

                            <span>{company.name ?? ""}</span>
                        </div>
                    ) : (
                        <ImageIcon className="w-7 h-7 ml-2" />
                    )}
                    <div className="flex items-center gap-1 font-semibold text-lg">
                        {company?.name}
                        {company?.domain !== "" && (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger
                                        asChild
                                        onClick={() =>
                                            window.open(
                                                `https://${company?.domain}`,
                                                "_blank",
                                            )
                                        }
                                    >
                                        <GlobeIcon className="text-blue-600 w-4 h-4" />
                                    </TooltipTrigger>
                                    <TooltipContent
                                        sideOffset={5}
                                        className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                                        asChild
                                    >
                                        <Card className="bg-white text-gray11 px-3 py-1.5 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                            {`https://${company?.domain}`}
                                        </Card>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </div>
                    {company?.health_score !== undefined &&
                        company?.health_score !== null &&
                        healthScoreBadge(company.health_score, true)}
                    {!loadingEnrichment &&
                        enrichedData &&
                        enrichedData?.linkedin_url !== undefined &&
                        enrichedData?.linkedin_url !== null && (
                            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                            <img
                                src={LinkedinSvg}
                                className="w-5 h-5 cursor-pointer rounded-sm"
                                alt="LinkedIn"
                                onClick={() => {
                                    if (company?.enrichment?.linkedin_url) {
                                        // Ensure the URL has the proper protocol
                                        const url = `https://${company?.enrichment?.linkedin_url}`;
                                        window.open(url, "_blank");
                                    }
                                }}
                            />
                        )}
                    <div className="flex flex-row gap-2">
                        {!loadingEnrichment &&
                            enrichedData &&
                            enrichedData?.size !== undefined &&
                            enrichedData?.size !== null && (
                                <Badge
                                    variant="soft"
                                    color="green"
                                    className="h-6"
                                >
                                    {enrichedData?.size} employees
                                </Badge>
                            )}
                        {!loadingEnrichment &&
                            enrichedData &&
                            enrichedData?.industry !== undefined &&
                            enrichedData?.industry !== null &&
                            enrichedData?.industry !== "" && (
                                <Badge
                                    variant="soft"
                                    color="gray"
                                    className="h-6"
                                >
                                    {enrichedData?.industry} industry
                                </Badge>
                            )}
                    </div>
                </div>
                <div className="flex items-center absolute top-4 right-4 my-4">
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                            <Button
                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                size="sm"
                                variant="outline"
                            >
                                Edit
                                <Pencil2Icon />
                            </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                            {!loadingCompany && (
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Edit Company
                                        </DropdownMenu.Item>
                                    }
                                    editing={true}
                                    type="Company"
                                    updateData={updateData}
                                    editingObject={company}
                                    labels={convertAccountLabelsToMap(
                                        company?.labels ?? [],
                                        "Company",
                                    )}
                                    tags={company?.tags}
                                    teamsQuery={teamsQuery}
                                    usersQuery={usersQuery}
                                    userID={userID}
                                    orgID={org.orgId}
                                    contacts={contactsCombinedData}
                                    listType={listType}
                                />
                            )}
                            {!loadingCompany && (
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Create New Contact
                                        </DropdownMenu.Item>
                                    }
                                    editing={false}
                                    type="Customer"
                                    updateData={updateData}
                                    company={company}
                                    teamsQuery={teamsQuery}
                                    usersQuery={usersQuery}
                                    userID={userID}
                                    orgID={org.orgId}
                                    listType={listType}
                                    teams={company?.teams}
                                    notEditableFields={["Teams"]}
                                />
                            )}
                            {!loadingCompany && (
                                <LinkContactsPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Link Contacts
                                        </DropdownMenu.Item>
                                    }
                                    updateData={updateData}
                                    listType={listType}
                                    company={company}
                                    contacts={contactsCombinedData}
                                    userID={userID}
                                />
                            )}
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>

                <Tabs.Root
                    defaultValue={tab ?? "360"}
                    onValueChange={(value) => {
                        const pathnameParts =
                            window.location.pathname.split("/");
                        const lastPart =
                            pathnameParts[pathnameParts.length - 1] || "";
                        if (value !== "360") {
                            if (
                                [
                                    "contacts",
                                    "interactions",
                                    "milestones",
                                    "notes",
                                    "attributes",
                                ].includes(lastPart)
                            ) {
                                pathnameParts[pathnameParts.length - 1] = value;
                                const newPath = pathnameParts.join("/");
                                navigate(newPath, {
                                    replace: true,
                                });
                            } else {
                                navigate(`${location.pathname}/${value}`);
                            }
                        } else {
                            // Remove /onboarding from the URL if switching away
                            if (location.pathname.endsWith("/milestones")) {
                                const newPath = location.pathname.replace(
                                    "/milestones",
                                    "",
                                );
                                navigate(newPath, { replace: true });
                            }
                            if (
                                [
                                    "contacts",
                                    "interactions",
                                    "milestones",
                                    "notes",
                                    "attributes",
                                ].includes(lastPart)
                            ) {
                                const newPath = location.pathname.replace(
                                    `/${lastPart}`,
                                    "",
                                );
                                navigate(newPath, { replace: true });
                            } else {
                                navigate(`${location.pathname}`);
                            }
                        }
                    }}
                >
                    <Tabs.List className="flex flex-row items-start gap-4 ">
                        <Tabs.Trigger value="360">
                            <div className="flex flex-row items-center gap-1.5">
                                <CircleGauge strokeWidth={2} size={16} />
                                <p>360</p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger value="contacts">
                            <div className="flex flex-row items-center gap-1.5">
                                <BookUserIcon strokeWidth={2} size={16} />
                                <p>Contacts</p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger value="interactions">
                            <div className="flex flex-row items-center gap-1.5">
                                <MailboxIcon strokeWidth={2} size={16} />
                                <p>Interactions</p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger value="milestones">
                            <div className="flex flex-row items-center gap-1.5">
                                <SquareCheckIcon strokeWidth={2} size={16} />
                                <p>Milestones</p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger value="notes">
                            <div className="flex flex-row items-center gap-1.5">
                                <NotepadTextIcon strokeWidth={2} size={16} />
                                <p>Notes</p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger value="attributes">
                            <div className="flex flex-row items-center gap-1.5">
                                <TableProperties strokeWidth={2} size={16} />
                                <p>Attributes</p>
                            </div>
                        </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content className="pt-3" value="360">
                        {company &&
                            ([
                                "fc12e902-f8ee-4176-947b-7f716469d1d6",
                                "01HQSDHJ9ZR783V0VKT59M110V",
                                "7631a851-e28d-46ac-bb57-0805f570f7fa",
                            ].includes(org.orgId) ? (
                                <Company360New
                                    account={company}
                                    loadingEnrichment={loadingEnrichment}
                                    tickets={combinedData}
                                    contacts={contactsCombinedData}
                                    updateData={updateData}
                                    usersQuery={usersQuery}
                                    teamsQuery={teamsQuery}
                                    channelsQuery={channelsQuery}
                                    customerGroupsQuery={customerGroupsQuery}
                                    interactionTypesQuery={
                                        interactionTypesQuery
                                    }
                                    topics={topics}
                                    topicsMap={topicsMap}
                                    setInteractionFilters={
                                        setInteractionFilters
                                    }
                                    setTeamIDsFromFilter={setTeamIDsFromFilter}
                                    listType={listType}
                                    orgID={org.orgId}
                                    userID={userID}
                                    enrichedData={enrichedData}
                                />
                            ) : (
                                <Company360
                                    account={company}
                                    loadingEnrichment={loadingEnrichment}
                                    tickets={combinedData}
                                    contacts={contactsCombinedData}
                                    updateData={updateData}
                                    usersQuery={usersQuery}
                                    teamsQuery={teamsQuery}
                                    channelsQuery={channelsQuery}
                                    listType={listType}
                                    orgID={org.orgId}
                                    userID={userID}
                                    enrichedData={enrichedData}
                                />
                            ))}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="contacts">
                        {contactsIsLoading && (
                            <Flex
                                maxWidth="85%"
                                style={{ paddingLeft: "20px" }}
                            >
                                <Text>
                                    <Skeleton maxWidth="85%">
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Skeleton>
                                </Text>
                            </Flex>
                        )}
                        {contactsIsError && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                        {!contactsIsLoading && contactsCombinedData && (
                            <div className="flex flex-col gap-2 px-7">
                                <CustomersTable
                                    userID=""
                                    forceUpdate={updateData}
                                    users={[]}
                                    customers={contactsCombinedData}
                                    company={company}
                                    usersQuery={usersQuery}
                                    teamsQuery={teamsQuery}
                                    orgID={org.orgId}
                                    listType={listType}
                                />
                            </div>
                        )}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="interactions">
                        {loadingState === 0 && (
                            <Flex
                                maxWidth="85%"
                                style={{ paddingLeft: "20px" }}
                            >
                                <Text>
                                    <Skeleton maxWidth="85%">
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Skeleton>
                                </Text>
                            </Flex>
                        )}
                        {loadingState === 2 && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                        {loadingState === 1 && combinedData.length !== 0 && (
                            <div className="flex flex-col gap-2 px-2">
                                <IssuesList
                                    issues={combinedData}
                                    topics={topics}
                                    topicsMap={topicsMap}
                                    userID={userID}
                                    usersQuery={usersQuery}
                                    customerGroupsQuery={customerGroupsQuery}
                                    listType={IssueListType.Issues}
                                    channelsQuery={channelsQuery}
                                    teamsQuery={teamsQuery}
                                    refetch={refetchCompanyTickets}
                                    interactionCounts={interactionCounts}
                                    setInteractionFilters={
                                        setInteractionFilters
                                    }
                                    setTeamIDsFromFilter={setTeamIDsFromFilter}
                                    searchQuery={""}
                                    interactionTypesQuery={
                                        interactionTypesQuery
                                    }
                                />
                            </div>
                        )}

                        {loadingState === 1 && combinedData.length === 0 && (
                            <div className="flex w-full h-[50%] justify-center items-center">
                                <p className="text-md text-gray12">
                                    No interactions yet.
                                </p>
                            </div>
                        )}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="milestones">
                        {company && (
                            <OnboardingPage
                                org={org}
                                userID={userID}
                                accountProp={company}
                                accountType="Company"
                            />
                        )}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="notes">
                        {company && (
                            <NotesPage
                                orgID={org.orgId}
                                userID={userID}
                                accountProp={company}
                                accountType="Company"
                            />
                        )}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="attributes">
                        {company && (
                            <AttributesPage
                                orgID={org.orgId}
                                userID={userID}
                                account={company}
                                accountType="Company"
                                contacts={contactsCombinedData}
                                usersQuery={usersQuery}
                                channelsQuery={channelsQuery}
                                updateData={updateData}
                                loadingEnrichment={loadingEnrichment}
                                enrichedData={enrichedData}
                            />
                        )}
                    </Tabs.Content>
                </Tabs.Root>
            </Box>
        </Flex>
    );
};
