import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    HistoryResponse,
    IssueScore,
    SharedInfo,
    Teams,
    Ticket,
} from "@/interfaces/serverData";
import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import {
    type QueryObserverResult,
    type RefetchOptions,
    type UseQueryResult,
    useQuery,
} from "@tanstack/react-query";
import { memo } from "react";
import SidebarCardContent from "./SidebarCardContent";

interface SidebarCardProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarLabels: string;
    type: string;
    analytics: Ticket;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    userID: string;
    customerUserInfo: SharedInfo | undefined;
    account?: Account;
    similarIssues?: IssueScore[];
    loadingSimilarIssues?: boolean; // false if loaded
    genTitle?: string;
    genDescription?: string;
    teamID?: string;
    orgID?: string;
    teamsQuery?: UseQueryResult<Teams[], Error>;
    alertNum?: number;
    sidebarSize?: number;
}

function SidebarCard({
    isOpen,
    setIsOpen,
    sidebarLabels,
    type,
    analytics,
    refetchTicketData,
    refetchThreadData,
    userID,
    customerUserInfo,
    account,
    similarIssues,
    loadingSimilarIssues,
    genTitle,
    genDescription,
    alertNum,
    teamID,
    orgID,
    teamsQuery,
    sidebarSize,
}: SidebarCardProps) {
    const api = useApi();
    const timerEnabledQuery = useQuery({
        queryKey: ["timerEnabled"],
        queryFn: async () => {
            const resp = await api.get(URLS.serverUrl + API.getTimerEnabled, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            if (resp.status === 200) {
                return resp.data.data;
            }
            return false;
        },
    });

    return (
        <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col px-3 py-0 pb-1 shadow-sm border relative bg-white">
            <div
                className={`flex items-center justify-between ${isOpen && "mb-1.5"}`}
            >
                <div className={`flex items-center gap-1.5 ${sidebarLabels}`}>
                    {type}
                    {alertNum !== undefined && alertNum > 0 && (
                        <Badge
                            variant="outline"
                            color="gray"
                            className="text-[9px] p-1 py-0"
                        >
                            {alertNum}
                        </Badge>
                    )}
                </div>
                <Button
                    type="button"
                    variant="ghost"
                    className="text-xs p-0"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? (
                        <CaretUpIcon className="w-4 h-4 py-0" />
                    ) : (
                        <CaretDownIcon className="w-4 h-4 py-0" />
                    )}
                </Button>
            </div>
            {isOpen && (
                <SidebarCardContent
                    type={type}
                    analytics={analytics}
                    refetchTicketData={refetchTicketData}
                    refetchThreadData={refetchThreadData}
                    userID={userID}
                    customerUserInfo={customerUserInfo}
                    account={account}
                    similarIssues={similarIssues}
                    loadingSimilarIssues={loadingSimilarIssues}
                    teamID={teamID}
                    orgID={orgID}
                    teamsQuery={teamsQuery}
                    genTitle={genTitle ?? ""}
                    genDescription={genDescription ?? ""}
                    sidebarSize={sidebarSize}
                    timerEnabled={
                        timerEnabledQuery.data &&
                        timerEnabledQuery.data !== false
                    }
                />
            )}
        </Card>
    );
}

export default memo(SidebarCard);
