import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import { Badge, Callout } from "@radix-ui/themes";
import { Handle, Position } from "@xyflow/react";
import { BoxIcon, BuildingIcon, PlayIcon, Terminal } from "lucide-react";
import { ActionsIcon } from "../Icons";
import { ReactComponent as SlackSvg } from "../../../../images/integrations/slack.svg";
import { ReactComponent as DiscordSvg } from "../../../../images/integrations/discord.svg";


import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from "@/component/shadcn/ui/separator";
import { Textarea } from "@/component/shadcn/ui/textarea";
import {
    type AccountsLabel,
    type Category,
    type CustomerGroup,
    type GetTopicsResponse,
    type GetUserResponse,
    type Teams,
    type Template,
    type Workflow,
    WorkflowKind,
} from "@/interfaces/serverData";
import {
    WorkflowNodesDropdownTrigger,
    getTopicColors,
    getTypeFromActionType,
} from "@/utilities/methods";
import { CaretSortIcon, CheckIcon, InfoCircledIcon, Pencil2Icon } from "@radix-ui/react-icons";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { handleStyle } from "../Icons";
import { Alert, AlertDescription, AlertTitle } from "@/component/shadcn/ui/alert";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";

const ActionNode: React.FC<{
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: any;
    isConnectable: boolean;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    onUpdate: (id: string, metadata: any) => void;
    workflowKind: WorkflowKind;
    categoriesQuery: UseQueryResult<Category[], Error>;
    topicsQuery: UseQueryResult<GetTopicsResponse[], Error>;
    accountsLabelsQuery: UseQueryResult<AccountsLabel[], Error>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    customerGroupsQuery: UseQueryResult<CustomerGroup[], Error>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    templatesQuery: UseQueryResult<Template[], Error>;
    workflow?: Workflow;
}> = ({
    data,
    isConnectable,
    onUpdate,
    workflowKind,
    categoriesQuery,
    topicsQuery,
    accountsLabelsQuery,
    teamsQuery,
    customerGroupsQuery,
    usersQuery,
    templatesQuery,
    workflow,
}) => {
        const NON_FILTER_DROPDOWN_ACTIONS = [
            "send_message",
            "send_internal_message",
            "send_new_message",
            "send_ai_response",
        ];
        const [actionType, setActionType] = useState<string>(
            data.metadata?.subtype ?? (workflow ? undefined : "send_template"),
        );
        const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");
        const [filters, setFilters] = useState<
            Map<
                string,
                Set<{ label: string; value: string; key: string; color: string }>
            >
        >(data.metadata?.filters ?? new Map());
        const [messageVal, setMessageVal] = useState<string>(
            data.metadata?.message ?? "",
        );
        const [newMessageRecipient, setNewMessageRecipient] = useState<string>(
            () => {
                const recipientData = data.metadata?.filters?.get("Recipient");
                if (recipientData) {
                    const option = Array.from(recipientData)[0] as {
                        label: string;
                        value: string;
                        key: string;
                        color: string;
                    };
                    return option.label ?? "";
                }
                return "";
            },
        );

        // Update node's metadata
        const handleSelectChange = (value: string) => {
            if (data.metadata?.message) {
                data.metadata.message = "";
                setMessageVal("");
            }
            setNewMessageRecipient("");
            setActionType(value);
            const newFilters = new Map();
            setFilters(newFilters);
            const updatedMetadata = {
                ...data.metadata,
                filters: newFilters,
                subtype: value,
                item_types: []
            };
            onUpdate(data.id, updatedMetadata);
        };

        const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const updatedMetadata = { ...data.metadata, name: e.target.value };
            onUpdate(data.id, updatedMetadata);
            setNameVal(e.target.value);
        };

        const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const updatedMetadata = { ...data.metadata, message: e.target.value };
            onUpdate(data.id, updatedMetadata);
            setMessageVal(e.target.value);
        };

        const handleItemSelect =
            (
                type: string,
                option: {
                    label: string;
                    value: string;
                    key: string;
                    color: string;
                },
            ) =>
                () => {
                    const newFilters = new Map(filters);
                    newFilters.set(type, new Set([option]));
                    setFilters(newFilters);
                    const updatedMetadata = { ...data.metadata, filters: newFilters };
                    onUpdate(data.id, updatedMetadata);
                };

        const handleNewMessageRecipientChange = (value: string) => {
            setNewMessageRecipient(value);
            const newFilters = new Map(filters);
            const option = { label: value, value: value, key: value, color: value };
            newFilters.set("Recipient", new Set([option]));
            setFilters(newFilters);
            const updatedMetadata = {
                ...data.metadata,
                filters: newFilters,
            };
            onUpdate(data.id, updatedMetadata);
        };

        // Set the populated topic's colors once topics query is updated
        useEffect(() => {
            if (filters.has("Topic")) {
                const newTopics = new Set<{
                    label: string;
                    value: string;
                    key: string;
                    color: string;
                }>();
                const existingTopics = Array.from(filters.get("Topic") ?? []);
                for (const topic of existingTopics) {
                    const fullTopic: GetTopicsResponse | undefined = (
                        topicsQuery.data ?? []
                    ).find((t) => t.id === topic.key);
                    if (fullTopic) {
                        newTopics.add({
                            color: fullTopic.color ?? topic.color,
                            label: fullTopic.topic_name ?? topic.label,
                            value: fullTopic.topic_name ?? topic.value,
                            key: topic.key,
                        });
                    } else {
                        newTopics.add(topic);
                    }
                }
                setFilters(new Map([["Topic", newTopics]]));
            }
        }, [topicsQuery.data]);

        return (
            <div className="flex flex-col items-start">
                <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
                    <div className="flex flex-row items-center justify-center gap-1">
                        <PlayIcon className="text-[#5e6ad2] h-3 w-3" />
                        <p className="text-xs">Action</p>
                    </div>
                </Badge>
                <Card
                    className={`w-[475px] shadow-none border rounded-lg z-10 ${data.errorStyle}`}
                >
                    <CardHeader className="flex flex-col items-start gap-2">
                        <div className="flex items-center space-x-2">
                            <ActionsIcon />
                            <CardTitle>
                                <div className="flex items-center gap-2">
                                    <p className="text-xs font-medium flex-shrink-0">
                                        Then
                                    </p>
                                    <Select
                                        defaultValue={actionType}
                                        onValueChange={handleSelectChange}
                                    >
                                        <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1] w-[250px]">
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {workflowKind ===
                                                WorkflowKind.Interactions && (
                                                    <>
                                                        <SelectItem value="send_template">
                                                            Reply with a Template
                                                        </SelectItem>
                                                        <SelectItem value="send_ai_response">
                                                            Reply with an AI Response
                                                        </SelectItem>
                                                        <SelectItem value="send_message">
                                                            Reply to a Customer
                                                            Interaction
                                                        </SelectItem>
                                                        <SelectItem value="send_internal_message">
                                                            Reply with an Internal
                                                            Message
                                                        </SelectItem>
                                                    </>
                                                )}
                                            <SelectItem value="send_template_new_message">
                                                Send New Templated Checkin
                                            </SelectItem>
                                            <SelectItem value="send_new_message">
                                                Send New Checkin
                                            </SelectItem>
                                            <SelectItem value="add_assignee">
                                                Add Assignee
                                            </SelectItem>
                                            {workflowKind === WorkflowKind.Interactions &&
                                                <>
                                                    <SelectItem value="add_category">
                                                        Add Category
                                                    </SelectItem>
                                                    <SelectItem value="add_tag">
                                                        Add Tag
                                                    </SelectItem>
                                                    <SelectItem value="change_status">
                                                        Change Status
                                                    </SelectItem>
                                                </>
                                            }
                                            {workflowKind === WorkflowKind.Accounts &&
                                                <>
                                                    <SelectItem value="add_company_type">
                                                        Add Company Type
                                                    </SelectItem>
                                                    <SelectItem value="add_stage">
                                                        Add Stage
                                                    </SelectItem>
                                                    <SelectItem value="add_account_tag">
                                                        Add Tag
                                                    </SelectItem>
                                                    <SelectItem value="add_tier">
                                                        Add Tier
                                                    </SelectItem>
                                                </>
                                            }
                                        </SelectContent>
                                    </Select>
                                    <p className="text-xs font-medium flex-shrink-0">
                                        :
                                    </p>
                                </div>
                            </CardTitle>
                        </div>
                        {actionType !== "" &&
                            !NON_FILTER_DROPDOWN_ACTIONS.includes(actionType) && (
                                <div className="flex flex-col gap-3">
                                    {actionType === "send_template_new_message" && (
                                        <Alert className="ml-8 w-[380px]">
                                            <Terminal className="h-4 w-4" />
                                            <AlertTitle className="text-xs">
                                                Where checkins with be sent!
                                            </AlertTitle>
                                            <AlertDescription className="text-xs">
                                                <div className="flex flex-col items-start flex-wrap gap-1">
                                                    <div className="flex items-center gap-1 text-[11px]">
                                                        <Badge
                                                            color="blue"
                                                            size="1"
                                                            radius="medium"
                                                            variant="soft"
                                                            className="items-center text-[11px]"
                                                        >
                                                            Company
                                                        </Badge>
                                                        <span>to company's preferred communication method</span>
                                                    </div>
                                                    <div className="flex items-center gap-1 text-[11px]">
                                                        <Badge
                                                            color="orange"
                                                            size="1"
                                                            radius="medium"
                                                            variant="soft"
                                                            className="items-center text-[11px]"
                                                        >
                                                            Customer
                                                        </Badge>
                                                        <span>to customer's email</span>
                                                    </div>
                                                </div>
                                            </AlertDescription>
                                        </Alert>
                                    )}
                                    <div className="flex items-center gap-1.5">
                                        <DropdownMenu>
                                            <DropdownMenuTrigger
                                                asChild
                                                type="button"
                                                className="ml-8 text-xs"
                                            >
                                                {WorkflowNodesDropdownTrigger(
                                                    getTypeFromActionType(actionType),
                                                    filters,
                                                    usersQuery.data ?? [],
                                                    [],
                                                    [],
                                                    [],
                                                    categoriesQuery.data ?? [],
                                                    getTopicColors(
                                                        topicsQuery.data ?? [],
                                                    ),
                                                    accountsLabelsQuery.data ??
                                                    [],
                                                )}
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent
                                                align="start"
                                                className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                            >
                                                <FilterDropdownElement
                                                    type={getTypeFromActionType(
                                                        actionType,
                                                    )}
                                                    interactionTypes={[]}
                                                    categories={
                                                        categoriesQuery.data ?? []
                                                    }
                                                    filters={filters}
                                                    handleItemSelect={handleItemSelect}
                                                    topics={getTopicColors(
                                                        topicsQuery.data ?? [],
                                                    )}
                                                    users={usersQuery.data ?? []}
                                                    customerGroups={
                                                        customerGroupsQuery.data ?? []
                                                    }
                                                    teams={teamsQuery.data ?? []}
                                                    isSavedViewFilter={false}
                                                    channels={new Map()}
                                                    templates={
                                                        templatesQuery.data ?? []
                                                    }
                                                    accountLabels={
                                                        accountsLabelsQuery.data ??
                                                        []
                                                    }
                                                />
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </div>
                            )}
                        {actionType === "send_message" && (
                            <Textarea
                                className="text-xs ml-8 w-[360px]"
                                placeholder="Message..."
                                rows={2}
                                value={messageVal}
                                onChange={handleMessageChange}
                            />
                        )}
                        {actionType === "send_internal_message" && (
                            <Textarea
                                className="text-xs ml-8 w-[360px]"
                                placeholder="Internal Message..."
                                rows={2}
                                value={messageVal}
                                onChange={handleMessageChange}
                            />
                        )}
                        {actionType === "send_ai_response" && (
                            <Alert>
                                <Terminal className="h-4 w-4" />
                                <AlertTitle className="text-xs">
                                    Heads up!
                                </AlertTitle>
                                <AlertDescription className="text-xs">
                                    <div className="flex flex-col items-center flex-wrap gap-1">
                                        <span>This action will send an AI response to the customer, but it's currently only supported for the following integrations. Please let us know if you'd like additional integrations supported!</span>
                                        <div className="flex items-center gap-1">
                                            <Card className="rounded-lg shadow-none w-fit inline-flex items-center px-2 py-0.5 h-6 border border-gray-200 ">
                                                <span className="inline-flex items-center">
                                                    <SlackSvg className="h-4 w-4 mr-1" />
                                                    <span>Slack</span>
                                                </span>
                                            </Card>
                                            <span>and</span>
                                            <Card className="rounded-lg shadow-none w-fit inline-flex items-center px-2 py-0.5 h-6 border border-gray-200">
                                                <span className="inline-flex items-center">
                                                    <DiscordSvg className="h-4 w-4 mr-1" />
                                                    <span>Discord</span>
                                                </span>
                                            </Card>
                                        </div>
                                    </div>
                                </AlertDescription>
                            </Alert>
                        )}
                        {actionType === "send_new_message" && (
                            <div className="flex flex-col gap-3 ml-8 mr-3">
                                <div className="flex items-center gap-6">
                                    <Alert>
                                        <Terminal className="h-4 w-4" />
                                        <AlertTitle className="text-xs">
                                            Where checkins with be sent!
                                        </AlertTitle>
                                        <AlertDescription className="text-xs">
                                            <div className="flex flex-col items-start flex-wrap gap-1">
                                                <div className="flex items-center gap-1 text-[11px]">
                                                    <Badge
                                                        color="blue"
                                                        size="1"
                                                        radius="medium"
                                                        variant="soft"
                                                        className="items-center text-[11px]"
                                                    >
                                                        Company
                                                    </Badge>
                                                    <span>to company's preferred communication method</span>
                                                </div>
                                                <div className="flex items-center gap-1 text-[11px]">
                                                    <Badge
                                                        color="orange"
                                                        size="1"
                                                        radius="medium"
                                                        variant="soft"
                                                        className="items-center text-[11px]"
                                                    >
                                                        Customer
                                                    </Badge>
                                                    <span>to customer's email</span>
                                                </div>
                                            </div>
                                        </AlertDescription>
                                    </Alert>
                                </div>
                                <Textarea
                                    className="text-xs"
                                    placeholder="New Message..."
                                    rows={2}
                                    value={messageVal}
                                    onChange={handleMessageChange}
                                />
                            </div>
                        )}
                    </CardHeader>

                    <CardContent className="flex flex-col gap-2">
                        <Separator />
                        <Input
                            className="text-xs border-none px-0"
                            placeholder="Name..."
                            value={nameVal}
                            onChange={handleNameChange}
                        />
                    </CardContent>

                    <Handle
                        type="source"
                        position={Position.Top}
                        id="a"
                        isConnectable={isConnectable}
                        className={`${handleStyle} top-[18px]`}
                    />
                    <Handle
                        type="source"
                        position={Position.Bottom}
                        id="b"
                        isConnectable={isConnectable}
                        className={handleStyle}
                    />
                </Card>
            </div>
        );
    };

export default ActionNode;
