import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { CalendarIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import type React from "react";
import { useEffect, useState } from "react";

import * as ToggleGroup from "@radix-ui/react-toggle-group";

import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { Button } from "@/component/shadcn/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { ContractType, type CustomerCompany } from "@/interfaces/serverData";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSearch } from "../../SearchContext";

import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { Calendar } from "@/component/shadcn/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { useToast } from "@/component/shadcn/ui/use-toast";
import {
    type Account,
    type AccountUpdatePayload,
    type AccountsLabel,
    type GetUserResponse,
    type IndividualCreatePayload,
    LabelsType,
    type Teams,
} from "@/interfaces/serverData";
import { CompanyDropdown } from "@/reusable_components/dropdowns/CompanyDropdown";
import { ContactDropdown } from "@/reusable_components/dropdowns/ContactDropdown";
import type { UseQueryResult } from "@tanstack/react-query";
import { format } from "date-fns";
import { XIcon } from "lucide-react";
import type { AccountsListType } from "./Accounts";
import { AssigneeDropdown } from "./AssigneeDropdown";
import { LabelsDropdown } from "./LabelsDropdown";

const formSchemaCompany = z.object({
    companyName: z
        .string()
        .min(1, "Company Name is required")
        .max(50, "Company Name must be at most 50 characters"),
    companyDomain: z
        .string()
        .min(3, "Domain must be at least 3 characters")
        .max(50, "Domain must be at most 50 characters")
        .regex(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid domain format")
        .optional(),
    metadata: z.string().optional(),
    contract_value: z
        .number()
        .min(0, "Contract value must be greater than or equal to 0")
        .optional(),
    contract_type: z.string().optional(),
    renewal_date: z.date().optional(),
    image_url: z.string().optional(),
    trial_expiration_date: z.date().optional(),
    id_in_tool: z.string().optional(),
    number_of_seats: z.number().optional()
});

const formSchemaCustomer = z.object({
    companyName: z
        .string()
        .min(1, "Account Name is required")
        .max(50, "Account Name must be at most 50 characters"),
    companyDomain: z
        .string()
        .min(3, "Domain must be at least 3 characters")
        .max(50, "Domain must be at most 50 characters")
        .email("This is not a valid email.")
        .optional(),
    metadata: z.string().optional(),
    contract_value: z
        .number()
        .min(0, "Contract value must be greater than or equal to 0")
        .optional(),
    contract_type: z.string().optional(),
    renewal_date: z.date().optional(),
    image_url: z.string().optional(),
    trial_expiration_date: z.date().optional(),
    id_in_tool: z.string().optional(),
    role: z.string().optional()
});

interface AccountPopupProps {
    saveCompany?: () => void;
    icon?: React.ElementType;
    triggerElement: React.ReactNode;
    editing: boolean;
    type: "Company" | "Customer";
    teamsQuery: UseQueryResult<Teams[], Error>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    userID: string;
    orgID: string;
    listType: AccountsListType;
    company?: Account | CustomerCompany;
    contacts?: Account[];
    teams?: Teams[];
    labels?: Map<LabelsType, AccountsLabel | undefined>;
    tags?: AccountsLabel[];
    editingObject?: Account;
    updateData?: (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => Promise<void>;
    notEditableFields?: string[];
}

const AccountPopup: React.FC<AccountPopupProps> = ({
    saveCompany = () => { },
    icon,
    triggerElement,
    editing,
    type,
    teamsQuery,
    usersQuery,
    userID,
    orgID,
    listType,
    company: propsCompany,
    contacts: propsContacts,
    teams: propsTeams,
    labels: propsLabels,
    tags: propsTags,
    editingObject,
    updateData,
    notEditableFields,
}) => {
    let teamID = undefined;
    if (window.location.pathname.split("/")[1] === "teams") {
        teamID = window.location.pathname.split("/")[2] || "";
    }

    const [contacts, setContacts] = useState<Account[]>(propsContacts ?? []);
    const [assigneeID, setAssigneeID] = useState<string>(
        editingObject?.assignee_user_id ?? "",
    );
    const [selectedTeams, setSelectedTeams] = useState<Teams[]>(
        editingObject?.teams ?? propsTeams ?? [],
    );
    const [selectedLabels, setSelectedLabels] = useState<
        Map<LabelsType, AccountsLabel | undefined>
    >(
        propsLabels ??
        new Map([
            [LabelsType.Tier, undefined],
            [LabelsType.Stage, undefined],
            [LabelsType.CompanyType, undefined],
        ]),
    );
    const [selectedTags, setSelectedTags] = useState<AccountsLabel[]>(
        propsTags ?? [],
    );
    const [numberOfSeats, setNumberOfSeats] = useState<number | undefined>(
        editingObject?.number_of_seats
    );
    const [role, setRole] = useState<string | undefined>(
        editingObject?.role
    );

    const { toast } = useToast();

    const formSchema =
        type === "Customer" ? formSchemaCustomer : formSchemaCompany;

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            companyName: editingObject?.name ?? "",
            companyDomain: editingObject?.domain ?? editingObject?.email ?? "",
            image_url: editingObject?.image_url ?? "",
            contract_value: editingObject?.contract_value ?? 0,
            contract_type: editingObject?.contract_type ?? "",
            renewal_date: editingObject?.renewal_date
                ? new Date(`${editingObject.renewal_date}T00:00:00Z`)
                : undefined,
            trial_expiration_date: editingObject?.trial_expiration_date
                ? new Date(`${editingObject.trial_expiration_date}T00:00:00Z`)
                : undefined,
            id_in_tool: editingObject?.id_in_tool ?? "",
        },
    });

    useEffect(() => {
        if (editingObject?.renewal_date) {
            setRenewalDateVal(
                new Date(`${editingObject.renewal_date}T00:00:00`),
            );
        }
        if (editingObject?.trial_expiration_date) {
            setTrialExpirationDateVal(
                new Date(`${editingObject.trial_expiration_date}T00:00:00`),
            );
        }
    }, [editingObject]);

    const { reset, handleSubmit, getValues } = form;

    const toggleContact = (
        contact: Account,
        existingContacts: Account[],
        setSelectedContacts: React.Dispatch<React.SetStateAction<Account[]>>,
    ) => {
        let newContacts = Array.from(existingContacts);
        // Remove if apart of the current contacts
        if (newContacts.map((contact) => contact.id).includes(contact.id)) {
            newContacts = newContacts.filter((t) => t.id !== contact.id);
        } else {
            // Add if it's a new customer that we're adding
            newContacts.push(contact);
        }
        setContacts(newContacts);
        setSelectedContacts(newContacts);
    };

    const toggleLabel = (
        label: AccountsLabel | undefined,
        selectedLabels: Map<LabelsType, AccountsLabel | undefined>,
        labelType: LabelsType,
    ) => {
        if (!label) {
            const newSelectedLabels = new Map(selectedLabels);
            newSelectedLabels.set(labelType, undefined);
            setSelectedLabels(newSelectedLabels);
        } else {
            if (label.type === LabelsType.AccountTag) {
                let newTags = [];
                if (selectedTags?.map((t) => t.id).includes(label.id)) {
                    newTags = selectedTags.filter((l) => l.id !== label.id);
                } else {
                    newTags = [...(selectedTags ?? []), label];
                }
                setSelectedTags(newTags);
            } else {
                const toggle = (labelType: LabelsType) => {
                    const newSelectedLabels = new Map(selectedLabels);
                    const currentLabel = newSelectedLabels.get(labelType);
                    newSelectedLabels.set(
                        labelType,
                        currentLabel?.id === label.id ? undefined : label,
                    );
                    setSelectedLabels(newSelectedLabels);
                };

                switch (label.type) {
                    case LabelsType.Tier:
                    case LabelsType.Stage:
                    case LabelsType.CompanyType:
                        toggle(label.type);
                        break;
                }
            }
        }
    };

    const toggleAssignee = (
        userInfo: GetUserResponse,
        accountAssignee: string,
    ) => {
        setAssigneeID(userInfo.id);
    };

    const toggleTeam = (team: Teams, accountTeams: Teams[]) => {
        const isExistingTeam = accountTeams.find(
            (t) => t.id === team.id && t.team_name === team.team_name,
        );
        let new_teams: Teams[] = [];
        // Handle General Team
        if (team.id === orgID) {
            // Can't untoggle general team, just needs to click another team to untoggle General
            if (!isExistingTeam) {
                // Remove all existing teams
                new_teams = [];
            }
        } else {
            if (isExistingTeam) {
                new_teams = accountTeams.filter((t) => t.id !== team.id);
            } else {
                new_teams = [team, ...accountTeams];
            }
        }
        setSelectedTeams(new_teams);
    };

    const api = useApi();

    function onSubmit() {
        const values = getValues();
        const requestData: IndividualCreatePayload = {
            name: values.companyName,
            domain: values.companyDomain ?? "",
            image_url: values.image_url,
            contract_value: values.contract_value,
            contract_type: values.contract_type
                ? (values.contract_type as ContractType)
                : ContractType.Month,
            renewal_date: renewalDateVal // renewal date val is in user's timezone instead of utc
                ? format(renewalDateVal, "yyyy-MM-dd")
                : "",
            tier: selectedLabels.get(LabelsType.Tier)?.id,
            stage: selectedLabels.get(LabelsType.Stage)?.id,
            assignee_user_id: assigneeID,
            teams: selectedTeams ?? [],
            tags: selectedTags,
            trial_expiration_date: trialExpirationDateVal // renewal date val is in user's timezone instead of utc
                ? format(trialExpirationDateVal, "yyyy-MM-dd")
                : "",
            id_in_tool: values.id_in_tool
        };

        if (type === "Customer") {
            requestData.company_id = companyID;
            requestData.role = role;
            api.put(
                `${URLS.serverUrl}${ContactsAPI.createCustomer.url}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Created Customer!",
                            description: "Customer created successfully!",
                        });
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    if (updateData) {
                        updateData(true, true, false);
                    }
                    setOpen(false);
                });
        } else {
            requestData.contacts = contacts;
            requestData.company_type = selectedLabels.get(
                LabelsType.CompanyType,
            )?.id;
            requestData.number_of_seats = numberOfSeats
            api.put(
                `${URLS.serverUrl}${ContactsAPI.createCompany.url}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Created Company!",
                            description: "Company created successfully!",
                        });
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    if (updateData) {
                        updateData(true, true, false);
                    }
                    setOpen(false);
                });
        }
    }

    function onEdit() {
        const values = getValues();
        const requestData: AccountUpdatePayload = {
            id: editingObject?.id ?? "",
            user: userID,
            name: values.companyName,
            domain: values.companyDomain ?? "",
            image_url: values.image_url ?? "",
            contract_value: values.contract_value ?? 0,
            contract_type: values.contract_type
                ? (values.contract_type as ContractType)
                : ContractType.Month,
            renewal_date: renewalDateVal // renewal date val is in user's timezone instead of utc
                ? format(renewalDateVal, "yyyy-MM-dd")
                : "",
            tier: selectedLabels.get(LabelsType.Tier)?.id ?? "",
            stage: selectedLabels.get(LabelsType.Stage)?.id ?? "",
            assignee_user_id: assigneeID,
            teams: selectedTeams ?? [],
            tags: selectedTags,
            trial_expiration_date: trialExpirationDateVal // renewal date val is in user's timezone instead of utc
                ? format(trialExpirationDateVal, "yyyy-MM-dd")
                : "",
            id_in_tool: values.id_in_tool
        };

        if (type === "Customer") {
            if (companyID && companyID !== "") {
                requestData.company_id = companyID;
            }
            requestData.role = role;

            api.patch(
                `${URLS.serverUrl}${ContactsAPI.updateCustomer.url}/${editingObject?.id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Customer!",
                            description: "Customer updated successfully!",
                        });
                        if (updateData) {
                            updateData(true, true, false);
                        }
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    setOpen(false);
                });
        } else {
            requestData.contacts = contacts;
            requestData.company_type = selectedLabels.get(
                LabelsType.CompanyType,
            )?.id;
            requestData.number_of_seats = numberOfSeats
            api.patch(
                `${URLS.serverUrl}${ContactsAPI.editCompany.url}/${editingObject?.id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Company!",
                            description: "Company updated successfully!",
                        });
                        if (updateData) {
                            updateData(true, true, false);
                        }
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    setOpen(false);
                });
        }
    }

    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const [open, setOpen] = useState<boolean>(false);
    const [filteredCompanies, setFilteredCompanies] = useState([
        {
            label: "Slack",
            value: "Slack",
            color: "",
        },
        {
            label: "Discord",
            value: "Discord",
            color: "",
        },
        {
            label: "Google",
            value: "Google",
            color: "",
        },
        {
            label: "GitHubTicket",
            value: "GitHubTicket",
            color: "",
        },
    ]);

    useEffect(() => {
        if (loading) {
            const updatedCompanies = filteredCompanies
                .filter((broadcast) => searchBarFilters.has(broadcast.value))
                .map((broadcast) => {
                    if (broadcast.value === "Google") {
                        return {
                            ...broadcast,
                            label: "Gmail",
                            value: "Gmail",
                        };
                    }
                    return broadcast;
                });
            setFilteredCompanies(updatedCompanies);
        }
    }, [searchBarFilters, loading]);

    const [accountTitle, setAccountTitle] = useState<string>(
        type === "Company" ? "Add New Company" : "Add New Customer",
    );
    const [accountDesc, setAccountDesc] = useState<string>(
        type === "Company"
            ? "Add a new company account to track."
            : "Add a new customer account to track.",
    );

    const [errorMsg, setErrorMsg] = useState<string>("");
    const [validationErr, setValidationErr] = useState<boolean>(false);

    useEffect(() => {
        if (editing) {
            setAccountTitle(
                type === "Company" ? "Edit Company" : "Edit Customer",
            );
            setAccountDesc(
                type === "Company"
                    ? "Edit an existing company account."
                    : "Edit an existing customer account.",
            );
        }
    }, [editing, setAccountTitle]);

    const formItemClassName = "flex flex-col";
    const formItemClassNameDiv = "flex flex-row items-center";
    const formLabelCN = "w-[180px] pt-1";
    const formLabelError = "text-xs";

    const [companyEditing, setCompanyEditing] = useState<boolean>(false);
    const [contactEditing, setContactEditing] = useState<boolean>(false);

    const [companyID, setCompanyID] = useState<string>(propsCompany?.id ?? "");
    const toggleGroupItemClasses =
        "text-xs flex outline outline-1 outline-gray-300 h-[35px] w-[60px] items-center justify-center text-base leading-4 first:rounded-l last:rounded-r focus:z-10 focus:bg-iris3";
    const [renewalDateVal, setRenewalDateVal] = useState<Date | undefined>();
    const [trialExpirationDateVal, setTrialExpirationDateVal] = useState<
        Date | undefined
    >();

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerElement}</DialogTrigger>
            <DialogContent className="pt-10 max-w-[850px] w-full">
                <div className="ml-10 mr-10">
                    <DialogHeader className="justify-left text-left items-left pb-5">
                        <DialogTitle>{accountTitle}</DialogTitle>
                        <DialogDescription>{accountDesc}</DialogDescription>
                    </DialogHeader>

                    <Form {...form}>
                        <form
                            onSubmit={
                                editing
                                    ? handleSubmit(onEdit)
                                    : handleSubmit(onSubmit)
                            }
                            className="space-y-2 flex flex-col gap-3"
                        >
                            <FormField
                                control={form.control}
                                name="companyName"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                {type === "Company"
                                                    ? "Company Name"
                                                    : "Customer Name"}
                                            </FormLabel>

                                            <FormControl className="max-w-lg">
                                                <Input
                                                    placeholder={
                                                        type === "Company"
                                                            ? "Assembly"
                                                            : "Customer Name"
                                                    }
                                                    {...field}
                                                />
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="companyDomain"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                {type === "Company"
                                                    ? "Email Domain"
                                                    : "Email"}
                                            </FormLabel>

                                            <FormControl className="max-w-lg">
                                                <Input
                                                    placeholder={
                                                        type === "Company"
                                                            ? "askassembly.app"
                                                            : "account@example.com"
                                                    }
                                                    {...field}
                                                />
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="image_url"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                Image URL
                                            </FormLabel>

                                            <FormControl className="max-w-lg">
                                                <Input
                                                    placeholder="Image URL"
                                                    {...field}
                                                />
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />

                            <div className="flex flex-row gap-3 items-center">
                                <FormField
                                    control={form.control}
                                    name="contract_value"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormLabel
                                                    className={formLabelCN}
                                                >
                                                    Contract
                                                </FormLabel>

                                                <FormControl className="w-50">
                                                    <Input
                                                        type="number"
                                                        placeholder="$0"
                                                        {...field}
                                                        value={
                                                            field.value || ""
                                                        }
                                                        onChange={(e) => {
                                                            // If the value is empty, set it as undefined, otherwise parse as a number
                                                            const value =
                                                                e.target.value;
                                                            const numericValue =
                                                                value === ""
                                                                    ? undefined
                                                                    : Number(
                                                                        value,
                                                                    );
                                                            field.onChange(
                                                                numericValue,
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="contract_type"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormControl>
                                                    <div className="flex items-center">
                                                        <ToggleGroup.Root
                                                            className="inline-flex bg-mauve6 rounded space-x-px"
                                                            type="single"
                                                            defaultValue={
                                                                field.value ||
                                                                "Month"
                                                            }
                                                            aria-label="Contract Type"
                                                            onValueChange={(
                                                                value,
                                                            ) => {
                                                                field.onChange(
                                                                    value,
                                                                );
                                                            }}
                                                        >
                                                            <ToggleGroup.Item
                                                                className={`${toggleGroupItemClasses} ${field.value ===
                                                                    "Month" &&
                                                                    "bg-iris3"
                                                                    }`}
                                                                value="Month"
                                                                aria-label="Month"
                                                            >
                                                                Month
                                                            </ToggleGroup.Item>
                                                            <ToggleGroup.Item
                                                                className={`${toggleGroupItemClasses} ${field.value ===
                                                                    "Year" &&
                                                                    "bg-iris3"
                                                                    }`}
                                                                value="Year"
                                                                aria-label="Year"
                                                            >
                                                                Year
                                                            </ToggleGroup.Item>
                                                        </ToggleGroup.Root>
                                                    </div>
                                                </FormControl>
                                            </div>
                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <FormField
                                control={form.control}
                                name="renewal_date"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                Renewal Date
                                            </FormLabel>

                                            <FormControl className="w-50">
                                                <div className="flex items-center gap-1">
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button
                                                                variant="outline"
                                                                className="flex items-center gap-2 text-sm px-1"
                                                            >
                                                                <CalendarIcon className="h-4 w-4" />
                                                                {renewalDateVal ? (
                                                                    format(
                                                                        renewalDateVal,
                                                                        "MMMM dd, yyyy",
                                                                    )
                                                                ) : (
                                                                    <span>
                                                                        Select
                                                                        Date
                                                                    </span>
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0">
                                                            <Calendar
                                                                mode="single"
                                                                selected={
                                                                    renewalDateVal
                                                                }
                                                                onSelect={(
                                                                    date:
                                                                        | Date
                                                                        | undefined,
                                                                ) => {
                                                                    if (date) {
                                                                        setRenewalDateVal(
                                                                            date,
                                                                        );
                                                                        field.onChange(
                                                                            date,
                                                                        );
                                                                    } else {
                                                                        setRenewalDateVal(
                                                                            undefined,
                                                                        );
                                                                        field.onChange(
                                                                            undefined,
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                    {renewalDateVal && (
                                                        <Button
                                                            variant="outline"
                                                            size="sm"
                                                            type="button"
                                                            onClick={(
                                                                e: React.MouseEvent<
                                                                    HTMLButtonElement,
                                                                    MouseEvent
                                                                >,
                                                            ) => {
                                                                setRenewalDateVal(
                                                                    undefined,
                                                                );
                                                                field.onChange(
                                                                    undefined,
                                                                );
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <XIcon className="w-3 h-3 flex-shrink-0" />
                                                        </Button>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="trial_expiration_date"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                Trial Expiration Date
                                            </FormLabel>

                                            <FormControl className="w-50">
                                                <div className="flex items-center gap-1">
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button
                                                                variant="outline"
                                                                className="flex items-center gap-2 text-sm px-1"
                                                            >
                                                                <CalendarIcon className="h-4 w-4" />
                                                                {trialExpirationDateVal ? (
                                                                    format(
                                                                        trialExpirationDateVal,
                                                                        "MMMM dd, yyyy",
                                                                    )
                                                                ) : (
                                                                    <span>
                                                                        Select
                                                                        Date
                                                                    </span>
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0">
                                                            <Calendar
                                                                mode="single"
                                                                selected={
                                                                    trialExpirationDateVal
                                                                }
                                                                onSelect={(
                                                                    date:
                                                                        | Date
                                                                        | undefined,
                                                                ) => {
                                                                    if (date) {
                                                                        setTrialExpirationDateVal(
                                                                            date,
                                                                        );
                                                                        field.onChange(
                                                                            date,
                                                                        );
                                                                    } else {
                                                                        setTrialExpirationDateVal(
                                                                            undefined,
                                                                        );
                                                                        field.onChange(
                                                                            undefined,
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                    {trialExpirationDateVal && (
                                                        <Button
                                                            variant="outline"
                                                            size="sm"
                                                            type="button"
                                                            onClick={(
                                                                e: React.MouseEvent<
                                                                    HTMLButtonElement,
                                                                    MouseEvent
                                                                >,
                                                            ) => {
                                                                setTrialExpirationDateVal(
                                                                    undefined,
                                                                );
                                                                field.onChange(
                                                                    undefined,
                                                                );
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <XIcon className="w-3 h-3 flex-shrink-0" />
                                                        </Button>
                                                    )}
                                                </div>
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />

                            <FormItem className={formItemClassName}>
                                <div className={formItemClassNameDiv}>
                                    <FormLabel className={formLabelCN}>
                                        Labels
                                    </FormLabel>
                                    <div className="col-span-3">
                                        <FormControl>
                                            <LabelsDropdown
                                                selectedLabels={selectedLabels}
                                                selectedTags={selectedTags}
                                                toggleLabel={toggleLabel}
                                                accountType={type}
                                            />
                                        </FormControl>
                                    </div>
                                </div>

                                <FormMessage className={formLabelError} />
                            </FormItem>

                            <FormField
                                control={form.control}
                                name="id_in_tool"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                ID in Tool
                                            </FormLabel>

                                            <FormControl className="max-w-lg">
                                                <Input
                                                    placeholder={"ID in Tool"}
                                                    {...field}
                                                />
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />
                            {type === "Customer" ? (
                                <FormField
                                    control={form.control}
                                    name="role"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div className={formItemClassNameDiv}>
                                                <FormLabel className={formLabelCN}>
                                                    Role
                                                </FormLabel>

                                                <FormControl className="max-w-lg">
                                                    <Input
                                                        placeholder={"Role"}
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />
                            ) : (
                                <FormField
                                    control={form.control}
                                    name="number_of_seats"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div className={formItemClassNameDiv}>
                                                <FormLabel className={formLabelCN}>
                                                    Number of Seats
                                                </FormLabel>

                                                <FormControl>
                                                    <div className="flex items-center gap-1">
                                                        <Input
                                                            type="number"
                                                            value={numberOfSeats !== undefined ? numberOfSeats : ""}
                                                            onChange={(e) => setNumberOfSeats(Number(e.target.value))}
                                                            className="w-[80px]"
                                                        />
                                                        {numberOfSeats && (
                                                            <Button
                                                                variant="outline"
                                                                size="sm"
                                                                type="button"
                                                                onClick={(
                                                                    e: React.MouseEvent<
                                                                        HTMLButtonElement,
                                                                        MouseEvent
                                                                    >,
                                                                ) => {
                                                                    setNumberOfSeats(
                                                                        undefined,
                                                                    );
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <XIcon className="w-3 h-3 flex-shrink-0" />
                                                            </Button>
                                                        )}
                                                    </div>
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />
                            )}
                            {type === "Customer" ? (
                                <FormItem className={formItemClassName}>
                                    <div className={formItemClassNameDiv}>
                                        <FormLabel className={formLabelCN}>
                                            Company
                                        </FormLabel>
                                        <div className="col-span-2">
                                            <FormControl>
                                                <CompanyDropdown
                                                    teamID={teamID}
                                                    companyID={companyID}
                                                    setCompany={(
                                                        c:
                                                            | CustomerCompany
                                                            | undefined,
                                                        index?: number,
                                                    ) => {
                                                        setCompanyID(
                                                            c?.id ?? "",
                                                        );
                                                    }}
                                                    editing={companyEditing}
                                                    setEditing={
                                                        setCompanyEditing
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </FormItem>
                            ) : (
                                <FormItem className={formItemClassName}>
                                    <div className={formItemClassNameDiv}>
                                        <FormLabel className={formLabelCN}>
                                            Contacts
                                        </FormLabel>
                                        <div className="col-span-3">
                                            <FormControl>
                                                <ContactDropdown
                                                    selectedContactIDs={contacts.map(
                                                        (contact) => contact.id,
                                                    )}
                                                    selectedContacts={contacts}
                                                    teamID={teamID}
                                                    setContact={toggleContact}
                                                    editing={contactEditing}
                                                    setEditing={
                                                        setContactEditing
                                                    }
                                                    condensed={false}
                                                    condensedBadges={true}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </FormItem>
                            )}

                            <FormItem className={formItemClassName}>
                                <div className={formItemClassNameDiv}>
                                    <FormLabel className={formLabelCN}>
                                        Assignee
                                    </FormLabel>
                                    <div className="col-span-3 ml-0.5">
                                        <FormControl>
                                            <AssigneeDropdown
                                                selectedUser={assigneeID}
                                                users={usersQuery.data ?? []}
                                                toggleAssignee={toggleAssignee}
                                                userID={userID}
                                                notEditable={notEditableFields?.includes(
                                                    "Assignee",
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </FormItem>

                            <FormItem className={formItemClassName}>
                                <div className={formItemClassNameDiv}>
                                    <FormLabel className={formLabelCN}>
                                        Teams
                                    </FormLabel>
                                    <div className="col-span-3 ml-0">
                                        <FormControl>
                                            <TeamsDropdown
                                                selectedTeams={selectedTeams}
                                                teams={teamsQuery.data ?? []}
                                                toggleTeam={toggleTeam}
                                                defaultIsWorkspace={false}
                                                orgID={orgID}
                                                notEditable={notEditableFields?.includes(
                                                    "Teams",
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </FormItem>

                            <div className="pt-4 justify-end text-end items-end">
                                <Button className="bg-iris9" type="submit">
                                    Save
                                </Button>
                            </div>
                            <DialogFooter className="flex flex-col">
                                {validationErr && (
                                    <Alert
                                        variant="destructive"
                                        className="mt-4"
                                    >
                                        <div className="flex flex-row gap-3 items-center">
                                            <ExclamationTriangleIcon className="h-4 w-4" />
                                            <div>
                                                <AlertTitle>Error</AlertTitle>
                                                <AlertDescription>
                                                    {errorMsg}
                                                </AlertDescription>
                                            </div>
                                        </div>
                                    </Alert>
                                )}
                            </DialogFooter>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AccountPopup;
