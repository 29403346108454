import { API } from "@/constant";
import { URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Label } from "@/interfaces/serverData";
import { useQuery } from "@tanstack/react-query";

const useInteractionTypes = () => {
    const api = useApi()
    // TODO: make this depend on the team
    const interactionTypesQuery = useQuery<Label[]>({
        queryKey: ["interactionType"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getLabels}/InteractionType`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });
    return interactionTypesQuery;
};

export default useInteractionTypes;
