import { GradientPicker } from "@/design/GradientPicker";
import type {
    AccountsLabel,
    AccountsTag,
    EditTopicPayload,
    GetTopicsResponse,
    GetUserResponse,
    Teams,
    View,
} from "@/interfaces/serverData";
import { getBadgeForTeam } from "@/utilities/methods";
import { AvatarIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import {
    useMutation,
    type QueryObserverResult,
    type RefetchOptions,
} from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import {
    type MembersRowState,
    type TagsRowState,
    type ViewsRowState,
    queryToLabel,
} from "./constants";
import { DataTableColumnHeader } from "./data-table-column-header";
import {
    DataTableRowActionsAccountsTags,
    DataTableRowActionsMembers,
    DataTableRowActionsTopics,
    DataTableRowActionsViews,
} from "./data-table-row-actions";
import { toast } from "@/component/shadcn/ui/use-toast";
import { ContactsAPI, URLS, API } from "@/constant";
import { useApi } from "@/interfaces/api";
import { AxiosResponse } from "axios";
export type BadgeColor = (typeof badgePropDefs.color.values)[number];

export function generateTagsColumns(
    rowState: Map<string, TagsRowState>,
    saveTag: (id: string, newState: Partial<TagsRowState>) => void,
    teams: Teams[],
    refetchTopics: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<GetTopicsResponse[], Error>>,
    setLoadingState: React.Dispatch<React.SetStateAction<number>>,
    currentTeam?: Teams,
    teamID?: string,
): ColumnDef<GetTopicsResponse>[] {
    const columns: ColumnDef<GetTopicsResponse>[] = [
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title="ID"
                    className="hidden w-0"
                />
            ),
            cell: ({ row }) => {
                return <div className="hidden w-0">{row.original.id}</div>;
            },
            size: 0,
        },
        {
            accessorKey: "tag",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.tag}
                />
            ),
            cell: ({ row }) => {
                let color = "#9B9EF0";

                if (
                    rowState.get(row.id) !== undefined &&
                    rowState.get(row.id)?.colorSelected !== ""
                ) {
                    // biome-ignore lint/style/noNonNullAssertion: <explanation>
                    color = rowState.get(row.id)!.colorSelected;
                }

                return (
                    <div className="text-xs flex flex-row items-center gap-3 mr-3">
                        <GradientPicker
                            id={row.original.id}
                            background={color}
                            setBackground={saveTag}
                        />
                        <div className="flex flex-row items-center">
                            <p className="pl-0.3">{row.original.topic_name}</p>
                        </div>
                    </div>
                );
            },

            filterFn: (row, id, value) => {
                // Return true to include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const query = row.original.topic_name;
                return query.toLowerCase().includes(value.toLowerCase());
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.topic_name.localeCompare(
                    rowB.original.topic_name,
                );
            },
            enableSorting: true,
            size: 300,
        },
        {
            accessorKey: "description",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.description}
                />
            ),
            cell: ({ row }) => {
                const description: string = row.original.description ?? "";

                return <span className="mr-1 ml-3 text-xs">{description}</span>;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.description);
            },
            enableSorting: true,
            enableResizing: true,
            size: 600,
        },
        {
            accessorKey: "teams",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.team}
                />
            ),
            cell: ({ row }) => {
                const teams: Teams[] = row.original.teams ?? [];
                return (
                    <div className="pl-2 flex flex-wrap gap-1">
                        {teams.length === 0 ?
                            getBadgeForTeam("Workspace") : teams.map((team) => (getBadgeForTeam(team.team_name === "General" ? "Workspace" : team.team_name)))}
                    </div>
                );
            },
            enableSorting: true,
            enableResizing: true,
            size: 300,
        },
        {
            accessorKey: "count",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.count}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex px-3">
                        <Badge
                            color="gray"
                            size="1"
                            radius="full"
                            variant="soft"
                            className="m-0.5 text-[11px]"
                        >
                            <div className="flex flex-row items-center gap-0.5">
                                <CaretUpIcon />
                                {row.original.count}
                            </div>
                        </Badge>
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                return (
                    Number.parseInt(rowA.original.count) -
                    Number.parseInt(rowB.original.count)
                );
            },
            enableSorting: true,
            size: 150,
        },
        {
            id: "actions",
            cell: ({ row }) => (
                <DataTableRowActionsTopics
                    id={row.id}
                    rowState={rowState.get(row.id)}
                    saveTag={saveTag}
                    teams={teams}
                    teamID={teamID}
                    refetchTopics={refetchTopics}
                    setLoadingState={setLoadingState}
                    currentTeam={currentTeam}
                />
            ),
        },
    ];
    return columns;
}

export function generateAccountsTagsColumns(
    rowState: Map<string, AccountsLabel>,
    teams: Teams[],
    saveTagColor: (id: string, rowStateInfo: Partial<AccountsLabel>) => void,
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<AccountsLabel[], Error>>,
    currentTeam?: Teams,
    teamID?: string,
): ColumnDef<AccountsLabel>[] {
    const columns: ColumnDef<AccountsLabel>[] = [
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title="ID"
                    className="hidden w-0"
                />
            ),
            cell: ({ row }) => {
                return <div className="hidden w-0">{row.original.id}</div>;
            },
            size: 0,
        },
        {
            accessorKey: "tag",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.tag}
                />
            ),
            cell: ({ row }) => {
                let color = "#9B9EF0";

                if (
                    rowState.get(row.id) !== undefined &&
                    rowState.get(row.id)?.color !== ""
                ) {
                    // biome-ignore lint/style/noNonNullAssertion: <explanation>
                    color = rowState.get(row.id)!.color;
                }

                return (
                    <div className="text-xs flex flex-row items-center gap-3 mr-3">
                        <GradientPicker
                            id={row.original.id}
                            background={color}
                            setBackground={saveTagColor}
                        />
                        <div className="flex flex-row items-center">
                            <p className="pl-0.3">{row.original.name}</p>
                        </div>
                    </div>
                );
            },

            filterFn: (row, id, value) => {
                // Return true to include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const query = row.original.name;
                return query.toLowerCase().includes(value.toLowerCase());
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.name.localeCompare(
                    rowB.original.name,
                );
            },
            enableSorting: true,
            size: 300,
        },
        {
            accessorKey: "description",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.description}
                />
            ),
            cell: ({ row }) => {
                const description: string = row.original.description ?? "";

                return <span className="mr-1 ml-3 text-xs">{description}</span>;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.description);
            },
            enableSorting: true,
            enableResizing: true,
            size: 600,
        },
        {
            accessorKey: "teams",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.team}
                />
            ),
            cell: ({ row }) => {
                const teams: Teams[] = row.original.teams ?? [];
                return (
                    <div className="pl-2 flex flex-wrap gap-1">
                        {teams.length === 0 ?
                            getBadgeForTeam("Workspace") : teams.map((team) => (getBadgeForTeam(team.team_name === "General" ? "Workspace" : team.team_name)))}
                    </div>
                );
            },
            enableSorting: true,
            enableResizing: true,
            size: 300,
        },
        {
            accessorKey: "count",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.count}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex px-3">
                        <Badge
                            color="gray"
                            size="1"
                            radius="full"
                            variant="soft"
                            className="m-0.5 text-[11px]"
                        >
                            <div className="flex flex-row items-center gap-0.5">
                                <CaretUpIcon />
                                {row.original.count}
                            </div>
                        </Badge>
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.count - rowB.original.count;
            },
            enableSorting: true,
            size: 150,
        },
        {
            id: "actions",
            cell: ({ row }) => (
                <DataTableRowActionsAccountsTags
                    id={row.id}
                    tag={rowState.get(row.id)}
                    teams={teams}
                    teamID={teamID}
                    refetch={refetch}
                    currentTeam={currentTeam}
                />
            ),
        },
    ];
    return columns;
}

export function generateMembersColumns(
    rowState: Map<string, MembersRowState>,
    updateRowState: (id: string, newState: Partial<MembersRowState>) => void,
    deleteRowState?: (id: string) => void,
    isDelete?: boolean,
): ColumnDef<GetUserResponse>[] {
    const columns: ColumnDef<GetUserResponse>[] = [
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title="ID"
                    className="hidden w-0"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="hidden w-0">{rowState.get(row.id)?.id}</div>
                );
            },
            size: 0,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.name}
                    className="text-left"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="px-3 py-2 text-sm flex items-center gap-3">
                        <div className={"lb-avatar w-6 h-6 rounded-md"}>
                            {rowState.get(row.id)?.picture_url ? (
                                <img
                                    className="lb-avatar-image"
                                    src={rowState.get(row.id)?.picture_url}
                                    alt={rowState.get(row.id)?.name}
                                />
                            ) : (
                                <AvatarIcon className="w-6 h-6" />
                            )}
                        </div>
                        <div className="flex flex-col">
                            {rowState.get(row.id)?.name}
                            <div className="text-muted-foreground text-xs">
                                {rowState.get(row.id)?.email}
                            </div>
                        </div>
                    </div>
                );
            },
            filterFn: (row, id, value) => {
                // No filter applied, include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const nameMatches =
                    rowState
                        .get(row.id)
                        ?.name.toLowerCase()
                        .includes(value.toLowerCase()) ?? false;
                const emailMatches =
                    rowState
                        .get(row.id)
                        ?.email.toLowerCase()
                        .includes(value.toLowerCase()) ?? false;
                return nameMatches || emailMatches;
            },
            enableSorting: true,
            enableResizing: true,
            size: 700,
        },
        {
            accessorKey: "role",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.role}
                />
            ),
            cell: ({ row }) => {
                return (
                    <span className="px-3 text-sm">
                        {rowState.get(row.id)?.role}
                    </span>
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(rowState.get(row.id)?.role);
            },
            enableSorting: true,
            enableResizing: true,
            size: 300,
        },
        {
            id: "actions",
            cell: ({ row }) => (
                <DataTableRowActionsMembers
                    id={row.id}
                    rowState={rowState.get(row.id)}
                    updateRowState={updateRowState}
                    isDelete={isDelete}
                    deleteRowState={deleteRowState}
                />
            ),
        },
    ];
    return columns;
}

export function generateViewsColumns(
    rowState: Map<string, ViewsRowState>,
    updateRowState: (id: string, newState: Partial<ViewsRowState>) => void,
    deleteRowState?: (id: string) => void,
): ColumnDef<View>[] {
    const columns: ColumnDef<View>[] = [
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title="ID"
                    className="hidden w-0"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="hidden w-0">{rowState.get(row.id)?.id}</div>
                );
            },
            size: 0,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.name}
                    className="text-left"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="px-3 py-2 text-xs flex items-center gap-3">
                        {rowState.get(row.id)?.name}
                    </div>
                );
            },
            enableSorting: true,
            enableResizing: true,
            size: 200,
        },
        {
            accessorKey: "description",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.description}
                />
            ),
            cell: ({ row }) => {
                return (
                    <span className="px-3 text-xs">
                        {rowState.get(row.id)?.description}
                    </span>
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(rowState.get(row.id)?.description);
            },
            enableSorting: true,
            enableResizing: true,
            size: 700,
        },
        {
            id: "actions",
            cell: ({ row }) => (
                <DataTableRowActionsViews
                    id={row.id}
                    rowState={rowState.get(row.id)}
                    updateRowState={updateRowState}
                    deleteRowState={deleteRowState}
                />
            ),
        },
    ];
    return columns;
}
