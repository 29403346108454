import { loadingTypes } from "@/constant";
import type { ScopeResponse } from "@/interfaces/serverData";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
    $createTextNode,
    $getRoot,
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_HIGH,
    KEY_ARROW_DOWN_COMMAND,
    KEY_ARROW_UP_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_ENTER_COMMAND,
    KEY_ESCAPE_COMMAND,
} from "lexical";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Card } from "../shadcn/ui/card";
import { Command, CommandGroup, CommandItem } from "../shadcn/ui/command";
import { MentionNode } from "./nodes/MentionNode";

export function MentionsPlugin({ options, optionsLoaded, source }: { options: ScopeResponse[], optionsLoaded?: loadingTypes, source: string }) {
    const [editor] = useLexicalComposerContext();
    const [showDropdown, setShowDropdown] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [filteredOptions, setFilteredOptions] = useState<ScopeResponse[]>(options)
    const selectedItemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setFilteredOptions(options.filter((option) =>
            option.name.toLowerCase().includes(filterText.trim().toLowerCase()),
        ))
    }, [filterText, options])
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        editor.registerTextContentListener((text) => {
            const match = text.match(/@([\w\s]*)$/)
            if (match) {
                editor.getEditorState().read(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        const domSelection = window.getSelection();
                        if (domSelection && domSelection.rangeCount > 0) {
                            const range = domSelection.getRangeAt(0);
                            const atRange = document.createRange();
                            const textNode = range.startContainer;
                            const text = textNode.textContent || "";
                            const atIndex = text.lastIndexOf("@");

                            if (atIndex >= 0 && atIndex < text.length) {
                                const atRange = document.createRange();
                                atRange.setStart(textNode, atIndex);
                                atRange.setEnd(textNode, Math.min(atIndex + 1, text.length));

                                const atRect = atRange.getBoundingClientRect();
                                const editorRect = editor
                                    .getRootElement()
                                    ?.getBoundingClientRect();

                                if (editorRect) {
                                    // Calculate position relative to editor
                                    setPosition({
                                        x: atRect.left + window.scrollX,
                                        y: atRect.bottom + window.scrollY,
                                    });
                                }

                                setShowDropdown(true);
                                setFilterText(match[1] || "");
                                setSelectedIndex(0);
                            }
                        }
                    }
                });

                const typedText = match[1];

                // Check for exact match with any option
                const exactMatch = options.find(
                    (option) =>
                        option.name.toLowerCase() === typedText.trim().toLowerCase(),
                );

                if (exactMatch) {
                    // Convert to mention node if exact match found
                    handleOptionSelect(exactMatch)
                    setShowDropdown(false);
                } else {
                    setShowDropdown(true);
                    setFilterText(typedText);
                }
            } else {
                setShowDropdown(false);
            }
        });
    }, [editor, options]);

    // Add this useEffect to reset selectedIndex when filteredOptions changes
    useEffect(() => {
        if (selectedIndex >= filteredOptions.length) {
            setSelectedIndex(0);
        }
    }, [filteredOptions, selectedIndex]);

    useEffect(() => {
        const enterCommand = editor.registerCommand(
            KEY_ENTER_COMMAND,
            (event) => {
                if (!showDropdown || !event) return false;
                // Allow cmd/ctrl + enter to pass through for sending message
                if (event.metaKey || event.ctrlKey) return false;

                event.preventDefault();
                event.stopPropagation();
                if (filteredOptions[selectedIndex]) {
                    handleOptionSelect(filteredOptions[selectedIndex]);
                }
                return true;
            },
            COMMAND_PRIORITY_HIGH,
        );

        const arrowDownCommand = editor.registerCommand(
            KEY_ARROW_DOWN_COMMAND,
            (event) => {
                if (!showDropdown || !event) return false;

                event.preventDefault();
                setSelectedIndex((prev) =>
                    prev < filteredOptions.length - 1 ? prev + 1 : prev,
                );
                return true;
            },
            COMMAND_PRIORITY_HIGH,
        );

        const arrowUpCommand = editor.registerCommand(
            KEY_ARROW_UP_COMMAND,
            (event) => {
                if (!showDropdown || !event) return false;

                event.preventDefault();
                setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
                return true;
            },
            COMMAND_PRIORITY_HIGH,
        );

        const escapeCommand = editor.registerCommand(
            KEY_ESCAPE_COMMAND,
            (event) => {
                if (!showDropdown || !event) return false;

                event.preventDefault();
                setShowDropdown(false);
                return true;
            },
            COMMAND_PRIORITY_HIGH,
        );

        const keyDownCommand = editor.registerCommand(
            KEY_BACKSPACE_COMMAND,
            (event) => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    if (!selection.isCollapsed()) {
                        return false; // Let Lexical handle the deletion
                    }

                    const node = selection.anchor.getNode();
                    if (node instanceof MentionNode) {
                        editor.update(() => {
                            const writableNode = node.getWritable();
                            writableNode.__isEditing = true;
                            writableNode.select();
                        });
                        return true;
                    }
                }
                return false;
            },
            COMMAND_PRIORITY_HIGH,
        );

        return () => {
            enterCommand();
            arrowDownCommand();
            arrowUpCommand();
            escapeCommand();
            keyDownCommand();
        };
    }, [showDropdown, filteredOptions, selectedIndex]);

    function handleOptionSelect(option: ScopeResponse) {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const textContent = selection.anchor.getNode().getTextContent();
                const atIndex = textContent.lastIndexOf("@");
                if (atIndex !== -1) {
                    // Create new selection range from @ to current position
                    const currentOffset = selection.focus.offset;
                    selection.anchor.offset = atIndex;
                    selection.focus.offset = currentOffset;

                    // Delete the selected text and insert mention node
                    selection.removeText();
                    const mentionNode = new MentionNode(option);
                    selection.insertNodes([mentionNode]);

                    // Insert a text node after the variable node
                    const textNode = $createTextNode(" ");
                    const parentNode = selection.getNodes()[0]?.getParent();
                    if (parentNode) {
                        parentNode.append(textNode);
                    }
                    const root = $getRoot();
                    root.selectEnd();
                }
            }
        });
        setShowDropdown(false);
    }

    // Add a new useEffect to handle scrolling
    useEffect(() => {
        if (selectedItemRef.current) {
            selectedItemRef.current.scrollIntoView({
                block: 'nearest',
                behavior: 'smooth'
            });
        }
    }, [selectedIndex]);

    return (showDropdown &&
        optionsLoaded !== loadingTypes.error &&
        !(optionsLoaded === loadingTypes.loaded && options.length === 0)
    ) ? createPortal(
        <Card
            className="absolute bg-white z-[100]"
            style={{ left: position.x, top: position.y }}
        >
            <Command>
                {(optionsLoaded === loadingTypes.loading && options.length === 0) ? (
                    <div className="p-2 text-center text-sm text-muted-foreground">
                        Loading...
                    </div>
                ) : filteredOptions.length > 0 ? (
                    <CommandGroup className="max-h-[150px] overflow-y-auto">
                        {filteredOptions.map((option, index) => (
                            <CommandItem
                                key={option.key}
                                onSelect={() => handleOptionSelect(option)}
                                className={`text-[13px] ${index === selectedIndex ? "bg-accent" : ""}`}
                                ref={index === selectedIndex ? selectedItemRef : null}
                            >
                                {option.name}
                            </CommandItem>
                        ))}
                    </CommandGroup>
                ) : null}
            </Command>
        </Card>,
        document.body,
    ) : null;


}
