import { IssueContextMenu } from "@/IssuesTable/IssueContextMenu";
import { ticketStatuses } from "@/IssuesTable/constants";
import { Avatar } from "@/Ticket/Avatar";
import { MyUser, MyUserInfo } from "@/Ticket/User";
import AttributesBadge from "@/component/AttributesBadge";
import Clock from "@/component/Timer";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    GetUserResponse,
    IconEntry,
    QueriesWithPaginationResponse,
    Query,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import type { InteractionCountsResponse } from "@/interfaces/serverData";
import { cleanText, formatEmojis, getExternalIssueIcon, getHtmlStringFromReactContent } from "@/utilities/methods";
import { getStatusFullIcon } from "@/utilities/methods";
import { CheckIcon } from "@radix-ui/react-icons";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";
import { Badge } from "@radix-ui/themes";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type { InfiniteData, QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import parse from "html-react-parser";
import { MessageCircleReply } from "lucide-react";
import type React from "react";
import { type Dispatch, type SetStateAction, Suspense, memo, useCallback, useEffect, useRef } from "react"
import { useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { VariableSizeList } from "react-window";
import rehypeRaw from "rehype-raw";
import remarkEmoji from "remark-emoji";
import remarkGfm from "remark-gfm";
import { toHTML } from "slack-markdown";
import { IssueListType } from "./AdminQueriesPage";
import { integrationBackEndDataMappingToSvg } from "./Integrations/constant";

interface QuestionCardProps {
    issue: Query;
    users: GetUserResponse[];
    openContextMenuForSingleIssue: (issue: Query) => void;
    singleSelectedIssue: Query | undefined;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >;
    listType: IssueListType;
    teamId: string;
    viewId?: string;
    teams: Teams[];
    categories: Category[];
    topics: Topic[];
}

export type BadgeColor = (typeof badgePropDefs.color.values)[number];

const timestampCutoff = "2024-09-30T23:50:00.000000Z";
const parsedTimestampCutoff = new Date(timestampCutoff);


const QuestionCard = memo((props: QuestionCardProps) => {
    const api = useApi()
    const [truncatedText, setTruncatedText] = useState<string>(
        cleanText(formatEmojis(props.issue.query ?? "")),
    );
    const navigate = useNavigate();

    const commentParsedTimestamp = new Date(props.issue.created_at);
    const ticketUpdatedTimestamp = new Date(props.issue.ticket_updated_at);
    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () =>
            integrationBackEndDataMappingToSvg.get(
                props.issue.source ?? "Unknown",
            ),
        [props.issue.source],
    );

    const foundUser: GetUserResponse | undefined = useMemo(
        () =>
            props.users.find(
                (user) => user.id === props.issue.assignee_user_id,
            ),
        [props.issue.assignee_user_id, props.users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const externalIssuesIcons = new Set<IconEntry>();
    // biome-ignore lint/complexity/noForEach: <explanation>
    props.issue.external_issues?.forEach((url) => {
        externalIssuesIcons.add({
            Component: getExternalIssueIcon(url),
            props: {
                width: 18,
                height: 18,
                style: { marginLeft: "-1", marginRight: "-1" },
            },
        });
    });

    const handleRowClick = useCallback(
        (id: string) => {
            let from: string;
            if (props.listType === IssueListType.Team && props.teamId) {
                from = "team";
                navigate(`/teams/${props.teamId}/issue/${id}`, { state: { from } });
            } else if (props.listType === IssueListType.View && props.viewId) {
                from = "view";
                navigate(`/views/${props.viewId}/issue/${id}`, { state: { from } });
            } else {
                from = props.listType === IssueListType.Inbox ? "inbox" : "issues";
                navigate(`/issue/${id}`, { state: { from } });
            }
        },
        [props.listType, props.teamId, props.viewId, navigate],
    );

    const ticketUpdatedDate: string = useMemo(() => {
        let updatedDate = new Date(props.issue.ticket_updated_at);
        if (
            Number.isNaN(updatedDate.getTime()) ||
            !props.issue.ticket_updated_at
        ) {
            updatedDate = new Date();
        }
        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [props.issue.ticket_updated_at]);

    return (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div className="w-full h-full"
            onClick={() =>
                handleRowClick(
                    `${props.issue.ticket_identifier}-${props.issue.ticket_number}`,
                )
            }
            onContextMenu={() => props.openContextMenuForSingleIssue(props.issue)}
        >
            <div className="w-full bg-white border border-[#F2EFFA] rounded-[12px] h-[144px] p-3 ring-[0.5px] ring-[#1B1B21] ring-opacity-[0.08]">
                <div
                    className="min-h-[65px] h-full transition-transform duration-300 transform scale-100 w-full"
                >
                    <Flex
                        direction={"column"}
                        justify={"between"}
                        height={"100%"}
                        gap={"12px"}
                    >
                        <div className="flex flex-row justify-between gap-3 items-center">
                            <div className="flex flex-row gap-2 items-center">
                                <div className="lb-root">
                                    <div className="lb-comment-details">
                                        <Suspense
                                            fallback={
                                                <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                                            }
                                        >
                                            <Avatar
                                                user={props.issue.company_info ?? props.issue.customer_info}
                                                className="w-8 h-8"
                                            />
                                        </Suspense>
                                    </div>
                                </div>
                                <div className="w-[110px]">
                                        {props.issue.customer_info?.id &&
                                         props.issue.customer_info.id !== "" &&
                                         props.issue.customer_info.id !== "00000000-0000-0000-0000-000000000000" &&
                                         props.issue.company_info?.id &&
                                         props.issue.company_info.id !== "" &&
                                         props.issue.company_info.id !== "00000000-0000-0000-0000-000000000000" ? (
                                            <div>
                                                <MyUser
                                                    user={props.issue.customer_info}
                                                    className="lb-comment-author text-[11px] max-w-[100px] truncate text-[#424562]"
                                                />
                                                <MyUser
                                                    user={props.issue.company_info}
                                                    className="lb-comment-author text-xs max-w-[100px] truncate text-[#737595]"
                                                />
                                            </div>
                                        ) : props.issue.company_info?.id &&
                                           props.issue.company_info.id !== "" &&
                                           props.issue.company_info.id !== "00000000-0000-0000-0000-000000000000" ? (
                                            <MyUser
                                                user={props.issue.company_info}
                                                className="lb-comment-author text-xs max-w-[100px] truncate text-[#737595]"
                                            />
                                        ) : props.issue.customer_info?.id &&
                                          props.issue.customer_info.id !== "" &&
                                          props.issue.customer_info.id !== "00000000-0000-0000-0000-000000000000" ? (
                                            <MyUserInfo
                                                user={props.issue.customer_info}
                                                className="lb-comment-author text-xs max-w-[100px] truncate text-[#424562]"
                                            />
                                        ) : props.issue.user_info?.id !== "" ? (
                                            <MyUser
                                                user={props.issue.user_info}
                                                className="lb-comment-author text-xs max-w-[100px] truncate text-[#424562]"
                                            />
                                        ) : null}
                                        </div>
                            </div>
                            <div className="flex flex-row items-center">
                                {props.issue.assignee_user_id &&
                                    props.issue.assignee_user_id !==
                                    "noAssignee" ? (
                                    <div className="lb-avatar rounded-full w-6 h-6">
                                        {pictureURL && (
                                            <img
                                                className="lb-avatar-image"
                                                src={pictureURL}
                                                alt={userName}
                                            />
                                        )}
                                        <span>{userName ?? ""}</span>
                                    </div>
                                ) : (
                                    null
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 flex-1 text-[#424562] font-medium">
                            {(props.issue.source === "Slack" ||
                                props.issue.source === "CommunitySlack") &&
                                commentParsedTimestamp <
                                parsedTimestampCutoff ? (
                                <div className="text-[14px] max-h-10 overflow-hidden">
                                    <ReactMarkdown
                                        remarkPlugins={[
                                            remarkGfm,
                                            remarkEmoji,
                                        ]}
                                        rehypePlugins={[rehypeRaw]}
                                        className="markdown-content"
                                    >
                                        {getHtmlStringFromReactContent(
                                            parse(
                                                toHTML(
                                                    cleanText(
                                                        props.issue.query ??
                                                        "",
                                                    ),
                                                ),
                                            ),
                                        )}
                                    </ReactMarkdown>
                                </div>
                            ) : (
                                <div
                                    className="text-[14px] w-full flex-grow max-h-10 overflow-hidden text-[#424562] font-medium"
                                >
                                    <ReactMarkdown
                                        remarkPlugins={[
                                            remarkGfm,
                                            remarkEmoji,
                                        ]}
                                        rehypePlugins={[rehypeRaw]}
                                        className="w-full"
                                        components={{
                                            a: ({ node, ...props }) => (
                                                <a
                                                    target="_blank"
                                                    {...props}
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                        color: "#5B5BD6",
                                                    }}
                                                >
                                                    {props.children}
                                                </a>
                                            ),
                                        }}
                                    >
                                        {truncatedText}
                                    </ReactMarkdown>
                                </div>
                            )}
                        </div>
                        <Flex
                            gap="3"
                            align="center"
                            direction={"row"}
                            justify={"between"}
                        >
                            <div className="flex flex-row items-center gap-2">
                                {props.issue.breaching && (
                                    <Clock
                                        targetTime={props.issue.breaching}
                                        status={props.issue.ticket_status}
                                        businessHoursID={props.issue.business_hours_id}
                                        api={api}
                                        paused={props.issue.paused}
                                        whenRestart={props.issue.when_restart}
                                        timeLeftSeconds={props.issue.time_left_seconds}
                                    />
                                )}
                                {props.issue.ticket_status === "Open" && props.issue.owner_replies > 0 && (
                                    <div className="flex items-center bg-[#545A92] bg-opacity-5 h-6 px-2 rounded-sm text-xs text-[#006971]">
                                        <CheckIcon className="w-4 h-4" />
                                        <span>Replied</span>
                                    </div>
                                )}
                                {props.issue.owner_replies +
                                    props.issue.customer_replies !==
                                    0 && (
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div>
                                                        <AttributesBadge className="py-0.5">
                                                            <div className="flex items-center gap-1 text-[11px] text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                                {props.issue.owner_replies +
                                                                    props.issue.customer_replies}
                                                                <MessageCircleReply className="w-3.5 h-3.5" />
                                                            </div>
                                                        </AttributesBadge>
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="text-[11px] max-w-[150px] flex flex-col">
                                                    <div className="flex items-center gap-1">
                                                        {`Customer: ${props.issue.customer_replies}`}
                                                        <MessageCircleReply className="w-3.5 h-3.5" />
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        {`Team: ${props.issue.owner_replies} `}
                                                        <MessageCircleReply className="w-3.5 h-3.5" />
                                                    </div>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    )}
                            </div>
                            {/* <p>{props.issue.created_at}</p> */}
                            <div className="flex flex-row items-center gap-2">
                                <Text className="text-[#737595] text-[11px] font-medium">
                                    {ticketUpdatedDate}
                                </Text>
                                {SourceSvgImage && (
                                    <SourceSvgImage className="w-4 h-4" />
                                )}
                            </div>
                        </Flex>
                    </Flex>
                </div>
            </div>
        </div>
    );
});

export interface metadata {
    scopes: string[];
    indexedOn: string;
    slack_team_id?: string;
}

interface AdminKanbanViewPageProps {
    issues: Query[];
    users: GetUserResponse[];
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >;
    listType: IssueListType;
    teamId: string;
    viewId?: string;
    teams: Teams[];
    categories: Category[];
    topics: Topic[];
    userID: string;
    searchQuery: string;
    interactionCounts?: InteractionCountsResponse;
}

const QuestionList = memo(({ questionList, users, refetch, listType, teamId, viewId, teams, categories, topics, userID, setQuestions }: {
    questionList: Query[];
    users: GetUserResponse[];
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >;
    listType: IssueListType;
    teamId: string;
    viewId?: string;
    teams: Teams[];
    categories: Category[];
    topics: Topic[];
    userID: string;
    setQuestions: Dispatch<SetStateAction<Map<string, Query[]>>>
}) => {
    const listRef = useRef<VariableSizeList>(null);
    const [selectedIssues] = useState<Query[]>([]);
    const [singleSelectedIssue, setSingleSelectedIssue] = useState<Query>();
    const getItemSize = useCallback((index: number) => {
        // Height of each card + gap
        return 156;
    }, []);

    const openContextMenuForSingleIssue = useCallback(
        (issue: Query) => {
            // Only open the menu for the issue if no issue are bulk selected
            if (selectedIssues.length === 0) {
                setSingleSelectedIssue(issue);
            }
        },
        [selectedIssues],
    );

    const updateIssueState = useCallback((updatedProp: Partial<Query>) => {
        setQuestions((prevQuestions) => {
            const newQuestions = new Map(prevQuestions);

            // Find the category and issue to update
            for (const [category, issues] of newQuestions.entries()) {
                const issueIndex = issues.findIndex(issue => issue.id === singleSelectedIssue?.id);
                if (issueIndex !== -1) {
                    // Create new array with updated issue
                    const updatedIssues = [...issues];
                    updatedIssues[issueIndex] = {
                        ...updatedIssues[issueIndex],
                        ...updatedProp
                    };


                    // If ticket_status changed, move to different category
                    if (updatedProp.ticket_status && updatedProp.ticket_status !== issues[issueIndex].ticket_status) {
                        // Remove from current category
                        updatedIssues.splice(issueIndex, 1);
                        newQuestions.set(category, updatedIssues);

                        // Add to new category
                        const newCategory = updatedProp.ticket_status === "NeedsResponse" ? "Needs Response" : updatedProp.ticket_status;
                        const targetCategoryIssues = newQuestions.get(newCategory) ?? [];
                        targetCategoryIssues.push({
                            ...updatedIssues[issueIndex],
                            ...updatedProp
                        });
                        newQuestions.set(newCategory, targetCategoryIssues);
                    } else {
                        // Update in current category
                        newQuestions.set(category, updatedIssues);
                    }
                    break;
                }
            }

            return newQuestions;
        });
    }, [setQuestions, singleSelectedIssue?.id]);

    const ItemRenderer = useCallback(({ index, style }: { index: number; style: React.CSSProperties }) => {
        const question = questionList[index];
        return (
            <div style={style}>
                <IssueContextMenu
                    topics={topics}
                    users={users}
                    teams={teams}
                    categories={categories}
                    updateIssueState={updateIssueState}
                    userID={userID}
                    refetch={refetch}
                    singleSelectedIssue={singleSelectedIssue}
                    selectedIssues={[]}
                    listType={listType}
                    teamID={teamId}
                >
                    <QuestionCard
                        openContextMenuForSingleIssue={openContextMenuForSingleIssue}
                        issue={question}
                        users={users}
                        key={question.id}
                        refetch={refetch}
                        listType={listType}
                        teamId={teamId}
                        viewId={viewId}
                        teams={teams}
                        categories={categories}
                        topics={topics}
                        singleSelectedIssue={singleSelectedIssue}
                    />
                </IssueContextMenu>
            </div>
        );
    }, [questionList, topics, users, teams, categories, updateIssueState, userID, refetch, singleSelectedIssue, listType, teamId, openContextMenuForSingleIssue, viewId]);

    return (
        <VariableSizeList
            ref={listRef}
            height={Math.min(questionList.length * 180, window.innerHeight - 200)} // Limit max height
            width="100%"
            itemCount={questionList.length}
            itemSize={getItemSize}
            overscanCount={5}
        >
            {ItemRenderer}
        </VariableSizeList>
    );
});

const AdminKanbanViewPage: React.FC<AdminKanbanViewPageProps> = ({
    issues,
    users,
    refetch,
    listType,
    teamId,
    viewId,
    teams,
    categories,
    topics,
    userID,
    searchQuery,
    interactionCounts,
}) => {
    const [questions, setQuestions] = useState<Map<string, Query[]>>(new Map());
    const [initializedIssues, setInitializedIssues] = useState<boolean>(false);

    useEffect(() => {
        const categorizedIssues = new Map<string, Query[]>([
            ["Breaching", []],
            ["NeedsResponse", []],
            ["Escalated", []],
            ["Open", []],
            ["Closed", []]
        ]);

        // biome-ignore lint/complexity/noForEach: <explanation>
        issues.forEach(issue => {
            switch (issue.ticket_status) {
                case "Breaching":
                    categorizedIssues.get("Breaching")?.push(issue);
                    break;
                case "NeedsResponse":
                    categorizedIssues.get("NeedsResponse")?.push(issue);
                    break;
                case "Escalated":
                    categorizedIssues.get("Escalated")?.push(issue);
                    break;
                case "Open":
                    categorizedIssues.get("Open")?.push(issue);
                    break;
                case "Closed":
                    categorizedIssues.get("Closed")?.push(issue);
                    break;
            }
        });

        setQuestions(categorizedIssues);
        setInitializedIssues(true);

    }, [issues, initializedIssues]);

    return (
        <div className="h-full">
            <Flex direction="column" align="center" justify="center" height="full">
                <div className="w-full overflow-x-auto px-8 overflow-hidden h-full">
                    <Flex
                        gap="3"
                        style={{
                            minWidth: "fit-content",
                            height: "100%",
                        }}
                    >
                        {Array.from(questions.entries()).map(

                            ([category, questionList]) => (
                                <div
                                    key={category}
                                    className="flex flex-col items-start gap-2 w-[320px] bg-[#545A92] bg-opacity-5 rounded-[12px] p-3 h-[calc(100vh-50px)] pb-32"
                                >
                                    <div className="flex flex-row items-center gap-2">
                                        <div >
                                            {(() => {
                                                const Icon = getStatusFullIcon(category);
                                                return Icon ? (
                                                    <Icon className="ml-0.5 w-3.5 h-3.5" />
                                                ) : (
                                                    <QuestionMarkCircledIcon className="ml-0.5 w-3.5 h-3.5" />
                                                );
                                            })()}
                                        </div>
                                        <span className="text-xs font-medium text-[#191A2C]">{ticketStatuses.find((status) => status.value === category)
                                            ?.label ?? category}</span>
                                        {searchQuery.trim() === ""
                                            ? (
                                                interactionCounts?.header_counts?.get(category) !== undefined ? (
                                                    <Badge
                                                        variant="outline"
                                                        color="gray"
                                                        className="text-[9px] p-1 py-0"
                                                    >
                                                        {interactionCounts?.header_counts?.get(category)}
                                                    </Badge>
                                                ) : (
                                                    <Badge
                                                        variant="outline"
                                                        color="gray"
                                                        className="text-[9px] p-1 py-2 w-4 h-3"
                                                    >
                                                        <div className="w-2 h-2 border-2 border-t-1 border-gray-600 border-dashed rounded-full animate-spin" />
                                                    </Badge>
                                                )
                                            )
                                            : (
                                                <Badge
                                                    variant="outline"
                                                    color="gray"
                                                    className="text-[9px] p-1 py-0"
                                                >
                                                    {questionList.length}
                                                </Badge>
                                            )}
                                    </div>
                                    <div className="flex flex-col items-start gap-2 w-full overflow-y-auto flex-1">
                                        <QuestionList
                                            questionList={questionList}
                                            users={users}
                                            refetch={refetch}
                                            listType={listType}
                                            teamId={teamId}
                                            viewId={viewId}
                                            teams={teams}
                                            categories={categories}
                                            topics={topics}
                                            userID={userID}
                                            setQuestions={setQuestions}
                                        />
                                    </div>
                                </div>
                            ),
                        )}
                    </Flex>
                </div>
            </Flex>
        </div>
    );
};

export default memo(AdminKanbanViewPage);