import type { SharedInfo, UserResponse } from "@/interfaces/serverData";
import clsx from "clsx";
import type { ComponentProps } from "react";
import { getMetadataGmailHeaders } from "./utils";

interface UserProps extends ComponentProps<"span"> {
    user: UserResponse;
    gmailHeadersMetadata?: string;
    source?: string;
    truncate?: boolean;
}

export function MyUser({
    user,
    className,
    gmailHeadersMetadata,
    source,
    truncate = false,
    ...props
}: UserProps) {
    function getUserName(user: UserResponse) {
        return user.name ?? user.username ?? user.email ?? "Anonymous";
    }

    const metadata =
        source === "Gmail"
            ? getMetadataGmailHeaders(gmailHeadersMetadata)
            : null;

    return (
        <span className={clsx(className, "block")} {...props}>
            {metadata ? 
                <span className={truncate ? "max-w-[100px] truncate overflow-hidden text-ellipsis whitespace-nowrap" : ""}>{metadata}</span> 
                : 
                <span className={truncate ? "max-w-[100px] truncate overflow-hidden text-ellipsis whitespace-nowrap" : ""}>{getUserName(user)}</span>
            }
        </span>
    );
}


interface UserInfoProps extends ComponentProps<"span"> {
    user: SharedInfo;
    gmailHeadersMetadata?: string;
    source?: string;
}

export function MyUserInfo({
    user,
    className,
    gmailHeadersMetadata,
    source,
    ...props
}: UserInfoProps) {
    function getUserName(user: SharedInfo) {
        return user.name ?? user.domain ?? "Anonymous";
    }

    const metadata =
        source === "Gmail"
            ? getMetadataGmailHeaders(gmailHeadersMetadata)
            : null;

    return (
        <span className={clsx(className, "block")} {...props}>
            {metadata ? 
                <span className="block max-w-[100px] truncate overflow-hidden text-ellipsis whitespace-nowrap">{metadata}</span> 
                : 
                <span className="block max-w-[100px] truncate overflow-hidden text-ellipsis whitespace-nowrap">{getUserName(user)}</span>
            }
        </span>
    );
}