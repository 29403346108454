import { InsightListType } from "@/Insights/useInsightsData";
import {
    Tabs as ShadcnTabs,
    TabsList as ShadcnTabsList,
    TabsTrigger as ShadcnTabsTrigger,
} from "@/component/shadcn/ui/tabs";
import { getAccountsLabelIcon, getBadgeForTeam } from "@/utilities/methods";
import { Box, Flex, Heading, Skeleton } from "@radix-ui/themes";
import { Text } from "@radix-ui/themes";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import {
    ActivityIcon,
    DollarSign,
    LayoutGrid,
    MailIcon,
    PercentIcon,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { memo } from "react";
import { API, ContactsAPI, TeamsAPI, URLS } from "../../../../constant";
import { useApi } from "../../../../interfaces/api";
import {
    type AccountsLabel,
    type InteractionsAnalyticsPaginationResponse,
    LabelsType,
    type Teams,
} from "../../../../interfaces/serverData";

import { TeamBadges } from "../../../WorkspacePreferences/TeamBadges";

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import type { DateRange } from "react-day-picker";
import { AnalyticsInteractionsChart } from "./AnalyticsInteractionsChart";
type BadgeColor = "gray" | "green" | "blue" | "red";

export interface ChartData {
    date: string;
    duration: number;
}

const areEqual = (
    prevProps: AdminAnalyticsProps,
    nextProps: AdminAnalyticsProps,
) => {
    return (
        prevProps.tempTeamID === nextProps.tempTeamID &&
        prevProps.listType === nextProps.listType
    );
};

interface AdminAnalyticsProps {
    listType: InsightListType;
    tempTeamID?: string;
}

export interface GroupedDataItem {
    date: string;
    count: number;
}

export const convertTimestampToDate = (timestamp: string): string => {
    return timestamp.split("T")[0];
};

const AnalyticsSuccess: React.FC<AdminAnalyticsProps> = ({
    listType,
    tempTeamID,
}) => {
    const api = useApi();

    const [activeTab, setActiveTab] = useState<string>("all");

    const [teamID, setTeamID] = useState<string>(tempTeamID ?? "");

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams", tempTeamID ?? teamID],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listTeams;
            const res = await api.get(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const teams: Teams[] = res.data.data;
            return teams;
        },
    });

    const fetchAccountsLabels = async (): Promise<AccountsLabel[]> => {
        const { url } = ContactsAPI.getAccountsLabels;
        const response: AxiosResponse<{ data: AccountsLabel[] }> =
            await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch accounts labels");
    };

    const {
        data: accountsLabels = [],
        isLoading: accountsLabelsIsLoading,
        isError: accountsLabelsIsError,
        refetch: refetchAccountsLabels,
    } = useQuery<AccountsLabel[]>({
        queryKey: ["accountsLabels"],
        queryFn: fetchAccountsLabels,
        refetchOnWindowFocus: true,
    });

    const [companyTypes, setCompanyTypes] = useState<AccountsLabel[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);

    const mapCompanyType = (companyType: string) => {
        switch (companyType) {
            case "all":
                return "https://lookerstudio.google.com/embed/reporting/ab2f9fa6-aefa-4b7c-b15a-4e46dbd09b7d/page/d3jEF";
            case "Startup":
                return "https://lookerstudio.google.com/embed/reporting/4294f550-4b62-42ee-a95a-7d8271568571/page/d3jEF"; // this is correct
            case "Mid-Market":
                return "https://lookerstudio.google.com/embed/reporting/8ac98eff-f873-4c94-8001-604b590d9b59/page/d3jEF"; // this is correct
            case "Enterprise":
                return "https://lookerstudio.google.com/embed/u/0/reporting/74f40fc6-7782-48c7-b897-fe2497ac08f6/page/d3jEF";
            default:
                return "https://lookerstudio.google.com/embed/reporting/d84ea4db-0887-4dd8-957d-25a6d7f08057/page/d3jEF";
        }
    };

    const mapCompanyTypeDetails = (companyType: string) => {
        switch (companyType) {
            case "all":
                return {
                    count: 1000,
                    totalARR: "138M",
                    netRevenueRetention: "89%",
                    averageHealthScore: "91%",
                    outboundMessages: "988",
                    inboundMessages: "566",
                    totalMessages: "1554",
                    accountsInPoorHealth: "18",
                };
            case "Startup":
                return {
                    count: 600,
                    totalARR: "24M",
                    netRevenueRetention: "78%",
                    averageHealthScore: "81%",
                    outboundMessages: "486",
                    inboundMessages: "302",
                    totalMessages: "788",
                    accountsInPoorHealth: "10",
                };
            case "Mid-Market":
                return {
                    count: 300,
                    totalARR: "34M",
                    netRevenueRetention: "96%",
                    averageHealthScore: "97%",
                    outboundMessages: "243",
                    inboundMessages: "182",
                    totalMessages: "425",
                    accountsInPoorHealth: "1",
                };
            case "Enterprise":
                return {
                    count: 100,
                    totalARR: "80M",
                    netRevenueRetention: "93%",
                    averageHealthScore: "95%",
                    outboundMessages: "259",
                    inboundMessages: "82",
                    totalMessages: "341",
                    accountsInPoorHealth: "1",
                };
            default:
                return {
                    count: 1000,
                    totalARR: "138M",
                    netRevenueRetention: "89%",
                    averageHealthScore: "91%",
                    outboundMessages: "68",
                    inboundMessages: "12",
                    totalMessages: "80",
                    accountsInPoorHealth: "18",
                };
        }
    };
    const fetchInteractionsAnalytics = async ({
        pageParam = 0,
    }: {
        pageParam?: number;
    }): Promise<InteractionsAnalyticsPaginationResponse> => {
        try {
            const response = await api.get(
                `${URLS.serverUrl}${API.getInteractionsAnalytics}`,
                {
                    // todo: figure out how filters work for unique name
                    params: {
                        source: "",
                        teamID: tempTeamID ?? teamID,
                        start: range?.from?.toISOString(),
                        end: range?.to?.toISOString(),
                        limit: 1000,
                        offset: pageParam,
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );

            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const [range, setRange] = useState<DateRange | undefined>({
        from: new Date(new Date().setDate(new Date().getDate() - 7)),
        to: new Date(),
    });

    const {
        data: interactionsData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        status: interactionsStatus,
    } = useInfiniteQuery({
        queryKey: [
            [`interactions_analytics_${tempTeamID ?? teamID}`],
            tempTeamID ?? teamID,
            [],
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: fetchInteractionsAnalytics,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchOnWindowFocus: true,
    });

    const interactionsCombinedData = useMemo(() => {
        return interactionsData && Array.isArray(interactionsData.pages)
            ? interactionsData.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];
    }, [interactionsData]);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const parsedData = interactionsCombinedData?.map((item) => {
        const utcDate = parseISO(item.original_timestamp);
        // Convert to user's timezone
        const zonedDate = toZonedTime(utcDate, userTimeZone);
        return {
            date: format(zonedDate, "yyyy-MM-dd"),
        };
    });

    const groupedData = parsedData.reduce(
        (acc: { [key: string]: GroupedDataItem }, { date }) => {
            acc[date] = acc[date] || { date, count: 0 };
            acc[date].count += 1;
            return acc;
        },
        {},
    );

    const orderedGroupedData = Object.values(groupedData).sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    useEffect(() => {
        // This effect causes a recursive error because:
        // 1. It depends on accountsLabels in the dependency array
        // 2. It calls setTotalCount which likely updates a state variable
        // 3. This state update probably triggers a re-render
        // 4. When the component re-renders, it might be recalculating accountsLabels
        // 5. This causes the effect to run again, creating an infinite loop

        // To fix this, we need to ensure accountsLabels is stable or add proper conditions
        const companyTypesSample: AccountsLabel[] = [];
        let totalCountSample = 0;

        // Only process if accountsLabels exists and has changed
        if (accountsLabels && accountsLabels.length > 0) {
            for (const label of accountsLabels) {
                if (label.type === LabelsType.CompanyType) {
                    companyTypesSample.push(label);
                    totalCountSample += label.count;
                }
            }
            setCompanyTypes(companyTypesSample);
            setTotalCount(totalCountSample);
        }
    }, [accountsLabels]);

    return (
        <div>
            <Box ml={"2%"} mr={"2%"}>
                <Flex direction="column" justify="center" mb="4">
                    <Box mt="4" height="100%" width="98%">
                        <Flex
                            align="start"
                            direction="column"
                            justify={"start"}
                        >
                            <Heading
                                size="5"
                                align="left"
                                className="flex items-center gap-2 mb-2"
                            >
                                Account Health Dashboard
                                {
                                    // For Team view
                                    (listType === InsightListType.Team &&
                                        teamID && (
                                            <TeamBadges
                                                teams={(
                                                    teamsQuery.data ?? []
                                                ).filter(
                                                    (team) =>
                                                        team.id === teamID,
                                                )}
                                                defaultIsWorkspace={false}
                                            />
                                        )) ||
                                        // For regular view
                                        (listType === InsightListType.General &&
                                            getBadgeForTeam("General"))
                                }
                            </Heading>
                            <ShadcnTabs
                                defaultValue="all"
                                value={activeTab}
                                onValueChange={setActiveTab}
                            >
                                <ShadcnTabsList className="bg-[#545A92] bg-opacity-5 h-8 items-center rounded-[10px]">
                                    <ShadcnTabsTrigger
                                        value="all"
                                        className={`text-xs h-5 py-1.5 px-2 rounded-[8px] font-normal ${activeTab === "all" ? "bg-white text-[#2C2E4A] border border-solid border-[#F2EFFA]" : "bg-transparent text-[#191A2C] border border-transparent"}`}
                                    >
                                        <LayoutGrid className="h-4 w-4 mr-2" />
                                        All Customers
                                        <span className="ml-1 rounded-[4px] bg-[#545A92] bg-opacity-5 px-2 py-0.5 text-xs font-normal h-6 items-center justify-center flex">
                                            {totalCount +
                                                mapCompanyTypeDetails("all")
                                                    .count}
                                        </span>
                                    </ShadcnTabsTrigger>
                                    {companyTypes.map((companyType) => {
                                        return (
                                            <ShadcnTabsTrigger
                                                key={companyType.name}
                                                value={companyType.name}
                                                className={`text-xs h-5 py-1.5 px-2 font-normal rounded-[8px] ${activeTab === companyType.name ? "bg-white text-[#2C2E4A] border border-solid border-[#F2EFFA]" : "bg-transparent text-[#191A2C] border border-transparent"}`}
                                            >
                                                <div className="flex items-center gap-1">
                                                    {getAccountsLabelIcon(
                                                        LabelsType.CompanyType,
                                                        companyType.color,
                                                        4,
                                                    )}
                                                    {companyType.name}
                                                    <span className="ml-1 rounded-[4px] bg-[#545A92] bg-opacity-5 px-2 py-0.5 text-xs font-normal h-6 items-center justify-center flex">
                                                        {companyType.count +
                                                            mapCompanyTypeDetails(
                                                                companyType.name,
                                                            ).count}
                                                    </span>
                                                </div>
                                            </ShadcnTabsTrigger>
                                        );
                                    })}
                                </ShadcnTabsList>
                            </ShadcnTabs>
                        </Flex>
                    </Box>
                </Flex>

                <Flex
                    direction="row"
                    gap="3"
                    className="h-[40vh] max-h-[40vh] overflow-hidden mb-5"
                >
                    <div className="w-[70%] h-full">
                        <AnalyticsInteractionsChart className="w-full h-full" />
                    </div>

                    <div className="flex flex-col gap-8 justify-between w-[30%]">
                        <AnalyticsCard
                            icon={<DollarSign className="w-4 h-4" />}
                            label="Total ARR (USD)"
                            value={String(
                                mapCompanyTypeDetails(activeTab).totalARR,
                            )}
                            isLoading={false}
                            className="w-full"
                        />
                        <AnalyticsCard
                            icon={<PercentIcon className="w-4 h-4" />}
                            label="Net Revenue Retention"
                            value={String(
                                mapCompanyTypeDetails(activeTab)
                                    .netRevenueRetention,
                            )}
                            isLoading={false}
                        />
                        <AnalyticsCard
                            icon={<ActivityIcon className="w-4 h-4" />}
                            label="Average Health Score"
                            value={String(
                                mapCompanyTypeDetails(activeTab)
                                    .averageHealthScore,
                            )}
                            isLoading={false}
                        />
                    </div>
                </Flex>
                <Flex
                    direction="row"
                    gap="5"
                    className="h-[40vh] max-h-[40vh] overflow-hidden"
                >
                    <div className="flex flex-col gap-3 w-1/3 justify-between">
                        <AnalyticsCard
                            icon={<MailIcon className="w-4 h-4" />}
                            label="Total Interactions (MoM)"
                            value={String(
                                mapCompanyTypeDetails(activeTab).totalMessages,
                            )}
                            isLoading={false}
                        />
                        <AnalyticsCard
                            icon={
                                <MailIcon
                                    color={"#ca73f5"}
                                    className="w-4 h-4"
                                />
                            }
                            label="Outbound Messages"
                            value={String(
                                mapCompanyTypeDetails(activeTab)
                                    .outboundMessages,
                            )}
                            isLoading={false}
                        />
                        <AnalyticsCard
                            icon={
                                <MailIcon
                                    color={"#302ea6"}
                                    className="w-4 h-4"
                                />
                            }
                            label="Inbound Messages"
                            value={String(
                                mapCompanyTypeDetails(activeTab)
                                    .inboundMessages,
                            )}
                            isLoading={false}
                        />
                    </div>
                    {/* <HealthScoreChart2 /> */}
                    <Card className="rounded-lg shadow-none flex-1 w-[70%]">
                        <CardContent className="h-full p-0 w-full">
                            <iframe
                                title="Assembly Analytics"
                                width="98%"
                                height="98%"
                                src={mapCompanyType(activeTab)}
                                frameBorder="0"
                                style={{ border: "0" }}
                                allowFullScreen
                                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                            />
                        </CardContent>
                    </Card>
                </Flex>
            </Box>
        </div>
    );
};

interface StatCardProps {
    icon: React.ReactNode; // JSX.Element for the icon
    label: string; // Label for the stat
    value?: string; // Optional value for the stat
    isLoading: boolean; // Loading state for skeleton
    className?: string;
}

const AnalyticsCard = ({
    icon,
    label,
    value,
    isLoading,
    className,
}: StatCardProps) => (
    <Card
        style={{ minWidth: "100px", width: "100%" }}
        className="shadow-none rounded-lg px-4 py-4 w-full h-full items-center justify-center"
    >
        <Flex
            direction="row"
            align="center"
            gap="2"
            justify="between"
            style={{ width: "100%", height: "100%" }}
        >
            <div style={{ width: "24px", height: "24px" }}>{icon}</div>
            <Flex direction="column" gap="1" align="end">
                {isLoading ? (
                    <Skeleton>
                        <Text size="5" color="gray" weight="bold">
                            000
                        </Text>
                    </Skeleton>
                ) : (
                    <Text size="3" color="gray" weight="bold">
                        {value ?? 0}
                    </Text>
                )}
                <Text
                    size={label && label.length > 25 ? "2" : "3"}
                    color="gray"
                >
                    {label}
                </Text>
            </Flex>
        </Flex>
    </Card>
);

const AnalyticsCard2 = ({
    title,
    value,
    className,
}: { title: string; value: string; className?: string }) => {
    return (
        <Card className={`shadow-none rounded-lg ${className}`}>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
                <CardTitle className="text-2xl w-full text-center">
                    {value}
                </CardTitle>
            </CardHeader>
        </Card>
    );
};
export default memo(AnalyticsSuccess, areEqual);
