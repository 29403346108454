import { API, URLS } from "@/constant";
import type {
    GetTimeLeftWithinBusinessHoursPayload,
    GetTimeLeftWithinBusinessHoursResponse,
} from "@/interfaces/serverData";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type { AxiosInstance } from "axios";
import { PauseIcon } from "lucide-react";
import { Component } from "react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "./shadcn/ui/tooltip";

export type BadgeType = (typeof badgePropDefs.variant.values)[number];

interface ClockProps {
    targetTime: string;
    api: AxiosInstance;
    status: string;
    businessHoursID?: string;
    paused?: boolean;
    whenRestart?: string;
    timeLeftSeconds?: number;
}

interface ClockState {
    fixDate: Date;
    diff: number;
    paused: boolean;
    whenRestart: Date;
    loading: boolean;
}

class Clock extends Component<ClockProps, ClockState> {
    intervalId: NodeJS.Timeout | null = null;

    constructor(props: ClockProps) {
        super(props);

        const { targetTime } = this.props;
        const fixDate = new Date(targetTime);
        const currDate = new Date();
        const diff = fixDate.getTime() - currDate.getTime();

        this.state = {
            fixDate,
            diff: this.props.timeLeftSeconds ? this.props.timeLeftSeconds * 1000 : diff,
            whenRestart: this.props.whenRestart ? new Date(this.props.whenRestart) : currDate,
            paused: this.props.paused ?? false,
            loading: true
        };

        if (diff > 0) {
            this.timeLeftWithinBusinessHours();
        } else {
            this.setState({ loading: false })
        }
    }

    componentDidMount() {
        this.intervalId = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    tick() {
        const { diff } = this.state;
        const now = new Date();
        if (this.state.paused && now < this.state.whenRestart) {
            // Don't need to refetch if business hours are paused until we get to the restart date
            return;
        }
        if (diff > 0) {
            this.timeLeftWithinBusinessHours();
        } else {
            this.setState((prevState) => ({
                diff: prevState.fixDate.getTime() - new Date().getTime(),
            }));
        }
    }

    async timeLeftWithinBusinessHours(): Promise<void> {
        if (this.props.status === "Breaching" || this.props.status === "Open") {
            return;
        }
        if (this.props.paused === true && this.props.whenRestart && new Date() < new Date(this.props.whenRestart)) {
            return;
        }
        const requestData: GetTimeLeftWithinBusinessHoursPayload = {
            target_time: this.props.targetTime,
            business_hours_id: this.props.businessHoursID ?? "",
        };

        try {
            const response = await this.props.api.post(
                URLS.serverUrl + API.getTimeLeftWithinBusinessHours,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (response.status === 200 && response.data.data) {

                const res: GetTimeLeftWithinBusinessHoursResponse =
                    response.data.data;
                console.log("handling 200 response and res is", res)
                const restartDate = res.when_restart
                    ? new Date(res.when_restart)
                    : new Date();
                this.setState({
                    diff: res.time_left_seconds * 1000,
                    paused: res.paused,
                    whenRestart: restartDate,
                    loading: false
                }, () => {
                    if (!this.intervalId) {
                        this.intervalId = setInterval(() => this.tick(), 1000);
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching business hours:", error);
        }
    }

    getDisplayTime() {
        if (this.props.status === "Breaching") return "00:00";
        const { diff } = this.state;
        if (diff === 0) {
            return "now";
        } else if (diff < -100) {
            return "00:00";
        }

        const hours = Math.floor(diff / (60 * 60 * 1000));
        const mins = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
        const secs = Math.floor((diff % (60 * 1000)) / 1000);

        if (hours > 0) return `${hours}h ${mins}m`;
        if (mins > 0) {
            if (mins < 10 && secs > 0) {
                if (secs >= 10) {
                    return `${mins}:${secs}`
                }
                return `${mins}:0${secs}`
            }
            return `${mins}m`
        };
        return "00:00";
    }

    getBackgroundColor() {
        if (this.state.diff <= 0 || this.state.diff <= 300000) {
            return 'rgba(168, 7, 16, 1)';
        } else {
            return 'rgba(110, 106, 222, 0.2)';
        }
    }

    getWidth() {
        if (this.state.diff <= 0) {
            return 0
        } else if (this.state.diff <= 300000) { // 5 minutes
            return 7
        } else if (this.state.diff <= 1800000) { // 30 minutes
            return 20
        } else if (this.state.diff <= 3600000) { // 1 hour
            return 35
        } else if (this.state.diff <= 7200000) { // 2 hours
            return 45
        } else if (this.state.diff <= 21600000) { // 6 hours
            return 55
        } else if (this.state.diff <= 43200000) { // 12 hours
            return 70
        } else if (this.state.diff <= 86400000) { // 24 hours
            return 85
        } else {
            return 100
        }
    }

    render() {
        const { diff } = this.state;
        const displayTime = this.getDisplayTime();

        const timeOptions: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        const dateOptions: Intl.DateTimeFormatOptions = {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
        };

        return (
            <div className="flex items-center gap-2">
                <div className={`bg-[#545A92] bg-opacity-5 rounded-sm flex items-center h-6 w-16 relative ${diff < 300000 ? 'text-[#A80710] ' : 'text-[#424562]'}`}>
                    <div
                        className={`absolute top-0 left-0 h-full w-full ${this.getWidth() === 100 ? "rounded-sm" : "rounded-tl-sm rounded-bl-sm"} `}
                        style={{
                            width: `${this.getWidth()}%`,
                            backgroundColor: `${this.getBackgroundColor()}`
                        }}
                    />
                    <div className="flex items-center gap-1 justify-center w-full h-full">
                        {this.state.paused && diff > 0 && (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div>
                                            <PauseIcon className="w-3 h-3" />
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent side="top" align="start" className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col text-[10px] w-[200px] z-50">
                                        <div className="text-xs font-semibold">
                                            Business Hours are Paused
                                        </div>
                                        <p className="leading-normal">{`Hours will open at ${this.state.whenRestart.toLocaleTimeString(undefined, timeOptions)} on ${this.state.whenRestart.toLocaleDateString(undefined, dateOptions)}`}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}

                        <span className="text-[11px]">{displayTime}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Clock;


