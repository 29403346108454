import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { Input } from "@/component/shadcn/ui/input";
import { toast } from "@/component/shadcn/ui/use-toast";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import { BusinessHoursType } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { Pencil2Icon } from "@radix-ui/react-icons";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import { LinkIcon } from "lucide-react";
import { useEffect, useState } from "react";
import BusinessHoursComponent from "./BusinessHoursComponent";
import SignatureComponent from "./SignatureComponent";

const Profile = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );

    const { loading, isLoggedIn, user } = useAuthInfo();
    const [email, setEmail] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    const [username, setUserName] = useState<string>("");
    const [userID, setUserID] = useState<string>("");
    const [pictureURL, setPictureURL] = useState<string>("");
    const [previewURL, setPreviewURL] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!loading) {
            if (isLoggedIn) {
                setLoadingState(loadingTypes.loaded);
                setEmail(user.email);
                setFullName(`${user.firstName} ${user.lastName}`);
                setUserName(`${user.username}`);
                setUserID(user.userId);
                setPictureURL(user.pictureUrl ?? "");
            } else {
                setLoadingState(loadingTypes.error);
            }
        }
    }, [loading, isLoggedIn]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPreviewURL(e.target.value); // Store the URL in state
    };

    const api = useApi();
    const handleSave = async () => {
        const requestData = {
            user_id: userID,
            new_image_url: previewURL,
        };
        api.post(URLS.serverUrl + API.changeUserProperties, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setOpenDialog(false);
                    setPictureURL(previewURL)
                    setPreviewURL("")
                    toast({
                        title: "Updated User!",
                        description: "User properties updated successfully!",
                    });
                }
            })
            .catch((res) => {
                console.log("failed to change user property");
                toast({
                    title: "Failed to update user",
                    description: "Please try again later",
                    variant: "destructive",
                });
            });
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">Profile</h2>
                <p className="pb-10 text-sm text-gray11">
                    Manage your Assembly profile
                </p>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && (
                    <div className="flex flex-col gap-6">
                        <Card className="shadow-none rounded-lg py-6 px-6">
                            <CardContent>
                                <div className="flex flex-col gap-6">
                                    <div className="flex flex-row justify-between">
                                        <h4 className="text-sm font-medium">
                                            Email
                                        </h4>
                                        <h4 className="text-sm">{email}</h4>
                                    </div>

                                    <Separator size="4" />

                                    <div className="flex flex-row justify-between">
                                        <h4 className="text-sm font-medium">
                                            Full Name
                                        </h4>
                                        <h4 className="text-sm">{fullName}</h4>
                                    </div>

                                    <Separator size="4" />
                                    <div className="flex flex-row justify-between">
                                        <h4 className="text-sm font-medium">
                                            Username
                                        </h4>
                                        <h4 className="text-sm">{username}</h4>
                                    </div>

                                    <Separator size="4" />
                                    <div className="flex flex-row justify-between">
                                        <h4 className="flex items-center gap-1 text-sm font-medium">
                                            Profile Photo
                                            <Dialog
                                                open={openDialog}
                                                onOpenChange={setOpenDialog}
                                            >
                                                <DialogTrigger asChild>
                                                    <Button
                                                        variant="outline"
                                                        className="px-1 h-5"
                                                    >
                                                        <Pencil2Icon className="w-3 h-3" />
                                                    </Button>
                                                </DialogTrigger>
                                                <DialogContent>
                                                    <DialogHeader>
                                                        <h3 className="text-lg font-semibold flex items-center gap-2 py-1">
                                                            Update Profile
                                                            Picture
                                                            <LinkIcon className="w-4 h-4" />
                                                        </h3>
                                                        We currently only support link uploads. You can copy your profile picture url from other platforms like Slack or Github!
                                                    </DialogHeader>
                                                    <div className="flex flex-col items-center gap-5">
                                                        {/* Only Link Upload allowed */}
                                                        <Input
                                                            id="text"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                        {previewURL && (
                                                            <img
                                                                src={previewURL}
                                                                alt="Profile"
                                                                className="w-[150px] h-[150px] rounded-full object-cover"
                                                            />
                                                        )}
                                                    </div>
                                                    <DialogFooter>
                                                        <Button
                                                            variant="secondary"
                                                            onClick={() =>
                                                                setPreviewURL(
                                                                    "",
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            onClick={handleSave}
                                                        >
                                                            Save
                                                        </Button>
                                                    </DialogFooter>
                                                </DialogContent>
                                            </Dialog>
                                        </h4>
                                        <img
                                            src={pictureURL}
                                            alt="Profile"
                                            className="w-12 h-12 rounded-full object-cover"
                                        />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <SignatureComponent userID={userID} />
                        <BusinessHoursComponent
                            type={BusinessHoursType.User}
                            id={userID}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
};

export default Profile;
