import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { type Teams, type Template, teamSchema } from "@/interfaces/serverData";
import { toggleTeamSelection } from "@/utilities/methods";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { workspace } from "../DataTable/constants";
import { TeamBadges } from "../TeamBadges";
import { UUID } from "crypto";
import { TemplateOnboardingPage } from "@/pages/Admin/CRM/Onboarding/TemplateOnboardingPage";

interface ImplementationTemplatesDialogProps {
    type: string;
    triggerElement: React.ReactNode;
    teams: Teams[];
    refetchTemplates: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Template[], Error>>;
    editingObject?: Template;
    currentTeam?: Teams;
    noDialog?: boolean;
    userID: string;
}

export const ImplementationTemplatesDialog: React.FC<ImplementationTemplatesDialogProps> = ({
    type,
    triggerElement,
    teams,
    refetchTemplates,
    editingObject,
    currentTeam,
    noDialog,
    userID,
}) => {
    const { id } = useParams(); // team ID
    const api = useApi();
    const queryClient = useQueryClient();
    const { toast } = useToast();

    const [templateIsOpen, setTemplateIsOpen] = useState(false);

    const templateFormSchema = z.object({
        templateName: z
            .string()
            .min(1, {
                message: "Template name must be at least 1 character.",
            })
            .max(70, {
                message: "Template name must be at most 70 characters.",
            }),
        templateTeams: z.array(teamSchema).optional(),
        templateContents: z.string().optional(),
    });
    const templateForm = useForm<z.infer<typeof templateFormSchema>>({
        resolver: zodResolver(templateFormSchema),
        defaultValues: {
            templateName: editingObject?.name ?? "",
            templateTeams:
                editingObject?.teams ??
                (id && currentTeam ? [currentTeam] : [workspace]) ??
                [],
        },
    });
    const { control, setValue, handleSubmit } = templateForm;

    const itToggleTeam = (team: Teams, templateTeams: Teams[]) => {
        setValue("templateTeams", toggleTeamSelection(team, templateTeams));
    };

    const createTemplateMutation = useMutation({
        mutationFn: async (newTemplate: {
            id: UUID;
            name: string;
            team_ids: string[];
            personal: boolean;
            metadata: Map<string, string>;
            template_type: string;
        }) => {
            const response: AxiosResponse = await api.put(
                `${URLS.serverUrl}${API.addTemplate}`,
                newTemplate,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );

            setNewID(crypto.randomUUID());
            return response;
        },
        onSuccess: () => {
            refetchTemplates();
            setTemplateIsOpen(false);
            templateForm.reset();
            toast({
                title: "Created Template!",
                description: "The template has been created successfully.",
            });
        },
        onError: () => {
            setTemplateIsOpen(false);
            toast({
                title: "Failed to create template",
                description:
                    "Oops! Something's wrong. Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const updateTemplateMutation = useMutation({
        mutationFn: async (updatedTemplate: Template) => {
            const response: AxiosResponse = await api.patch(
                `${URLS.serverUrl}${API.editTemplate}/${updatedTemplate.id}`,
                updatedTemplate,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            return response;
        },
        onSuccess: () => {
            refetchTemplates();
            setTemplateIsOpen(false);
            templateForm.reset();
            toast({
                title: "Updated Template!",
                description: "The template has been updated successfully.",
            });
        },
        onError: () => {
            setTemplateIsOpen(false);
            toast({
                title: "Failed to update template",
                description:
                    "Oops! Something's wrong. Please try again at a later time.",
                variant: "destructive",
            });
        },
    });


    const [newID, setNewID] = useState<string>(editingObject?.id ?? crypto.randomUUID());

    const onSubmit = (values: z.infer<typeof templateFormSchema>) => {
        if (editingObject) {
            updateTemplateMutation.mutate({
                ...editingObject,
                name: values.templateName,
                teams:
                    values.templateTeams?.filter(
                        (team) => team.id !== "workspace",
                    ) ?? [],
            });
        } else {
            createTemplateMutation.mutate({
                id: newID as UUID,
                name: values.templateName,
                team_ids: id
                    ? [id]
                    : (values.templateTeams
                          ?.map((team) => team.id)
                          .filter((teamID) => teamID !== "workspace") ?? []),
                personal: false,
                metadata: new Map(),
                template_type: "Implementation",
            });
        }
    };

    const deleteTemplateMutation = useMutation({
        mutationFn: async (id: string) => {
            const response: AxiosResponse = await api.delete(
                `${URLS.serverUrl}${API.deleteTemplate}/${id}`,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            refetchTemplates();
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["templates"] });
            toast({
                title: "Deleted Template!",
                description: "The template has been deleted successfully.",
            });
        },
        onError: () => {
            toast({
                title: "Failed to delete template",
                description:
                    "Oops! Something's wrong. Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    // Delete template handler
    const handleDelete = (templateId: string) => {
        deleteTemplateMutation.mutate(templateId);
        setTemplateIsOpen(false);
    };

    // Remove from team handler
    const handleRemoveTeam = (template: Template) => {
        let newCategoryTeams: Teams[];
        if (template.teams.length === 0) {
            const validTeams = [
                ...teams.filter((team) => team.id !== "workspace"),
            ];
            newCategoryTeams = validTeams.filter((team) => team.id !== id);
        } else {
            newCategoryTeams = template.teams.filter((team) => team.id !== id);
        }
        template.teams = newCategoryTeams;
        updateTemplateMutation.mutate(template);
    };

    return (
        <div>
            {noDialog ? (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <div
                    onClick={() => {
                        if (editingObject) {
                            handleRemoveTeam(editingObject);
                        }
                    }}
                >
                    {triggerElement}
                </div>
            ) : (
                <Dialog open={templateIsOpen} onOpenChange={setTemplateIsOpen}>
                    <DialogTrigger asChild>{triggerElement}</DialogTrigger>
                    <DialogContent className="max-w-[1350px] w-full">
                        <div className="mx-8 my-3">
                            {(() => {
                                switch (type) {
                                    case "Edit":
                                    case "Create":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>
                                                        {editingObject
                                                            ? "Edit Template"
                                                            : "Add Template"}
                                                    </DialogTitle>
                                                    <DialogDescription>
                                                        {editingObject
                                                            ? "Edit an existing implementation template."
                                                            : "Add a new implementation template"}
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <Form {...templateForm}>
                                                    <form
                                                        onSubmit={handleSubmit(
                                                            onSubmit,
                                                        )}
                                                        className="space-y-8"
                                                    >
                                                        <FormField
                                                            control={
                                                                templateForm.control
                                                            }
                                                            name="templateName"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-5 items-center gap-4">
                                                                        <FormLabel htmlFor="templateName">
                                                                            Name
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                id="templateName"
                                                                                className="col-span-4"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        <FormMessage className="text-xs" />
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        
                                                        <FormField
                                                            control={control}
                                                            name="templateTeams"
                                                            render={({
                                                                field,
                                                            }) => {
                                                                return (
                                                                    <FormItem>
                                                                        <div className="grid grid-cols-5 items-center gap-4">
                                                                            <FormLabel>
                                                                                Teams
                                                                            </FormLabel>
                                                                            {id ? (
                                                                                <div className="flex flex-col gap-2 col-span-4">
                                                                                    <Button
                                                                                        variant="ghost"
                                                                                        className="flex items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted overflow-hidden"
                                                                                        type="button"
                                                                                        disabled={
                                                                                            true
                                                                                        }
                                                                                    >
                                                                                        <TeamBadges
                                                                                            teams={
                                                                                                editingObject?.teams ??
                                                                                                (id &&
                                                                                                currentTeam
                                                                                                    ? [
                                                                                                          currentTeam,
                                                                                                      ]
                                                                                                    : [
                                                                                                          workspace,
                                                                                                      ]) ??
                                                                                                []
                                                                                            }
                                                                                            defaultIsWorkspace={
                                                                                                true
                                                                                            }
                                                                                        />
                                                                                    </Button>
                                                                                </div>
                                                                            ) : (
                                                                                <FormControl>
                                                                                    <TeamsDropdown
                                                                                        selectedTeams={
                                                                                            field?.value ??
                                                                                            []
                                                                                        }
                                                                                        teams={
                                                                                            teams
                                                                                        }
                                                                                        toggleTeam={
                                                                                            itToggleTeam
                                                                                        }
                                                                                        defaultIsWorkspace={
                                                                                            true
                                                                                        }
                                                                                    />
                                                                                </FormControl>
                                                                            )}
                                                                        </div>
                                                                        <FormMessage />
                                                                    </FormItem>
                                                                );
                                                            }}
                                                        />
                                                        <FormField
                                                            control={
                                                                templateForm.control
                                                            }
                                                            name="templateContents"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="flex flex-col items-start gap-4">
                                                                        <div>
                                                                        <FormLabel htmlFor="templateContents">
                                                                            Template
                                                                        </FormLabel>
                                                                        </div>
                                                                        <FormControl> 
                                                                            <TemplateOnboardingPage
                                                                                userID={userID}
                                                                                accountProp={editingObject ?? {
                                                                                    id: newID,
                                                                                    name: "",
                                                                                    template: "",
                                                                                    teams: [],
                                                                                    created_at: "",
                                                                                    updated_at: "",
                                                                                    metadata: new Map(),
                                                                                    personal: false,
                                                                                    user_id: "",
                                                                                }}
                                                                                saveTemplate={onSubmit}
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </form>
                                                </Form>
                                            </>
                                        );
                                    case "Delete":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>
                                                        Are you absolutely sure?
                                                    </DialogTitle>
                                                    <DialogDescription>
                                                        {`This action cannot be undone. This will permanently delete the Template "${editingObject?.name}" from the Workspace.`}
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <DialogFooter className="justify-end text-end items-end gap-3">
                                                    <Button
                                                        className="bg-iris9 py-0.5"
                                                        type="submit"
                                                        onClick={() =>
                                                            setTemplateIsOpen(
                                                                false,
                                                            )
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="bg-red-500 py-0.5"
                                                        type="submit"
                                                        onClick={() => {
                                                            if (editingObject) {
                                                                handleDelete(
                                                                    editingObject.id,
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </DialogFooter>
                                            </>
                                        );
                                    default:
                                        return null; // You could return a default view or nothing
                                }
                            })()}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};
