"use client";

import { TrendingUp } from "lucide-react";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import {
    type ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/component/shadcn/ui/chart";
const chartData = [
    {
        month: "October",
        midMarket: 1000000000,
        startup: 800000000,
        enterprise: 1400000000,
    },
    {
        month: "November",
        midMarket: 1000000000,
        startup: 900000000,
        enterprise: 2000000000,
    },
    {
        month: "December",
        midMarket: 1400000000,
        startup: 900000000,
        enterprise: 2200000000,
    },
    {
        month: "January",
        midMarket: 1900000000,
        startup: 1000000000,
        enterprise: 2400000000,
    },
    {
        month: "February",
        midMarket: 1900000000,
        startup: 900000000,
        enterprise: 2800000000,
    },
    {
        month: "March",
        midMarket: 2000000000,
        startup: 950000000,
        enterprise: 3000000000,
    },
];

const chartConfig = {
    enterprise: {
        label: "Enterprise",
        color: "hsl(var(--chart-1))",
    },
    startup: {
        label: "Startup",
        color: "hsl(var(--chart-2))",
    },
    midMarket: {
        label: "Mid-Market",
        color: "hsl(var(--chart-3))",
    },
} satisfies ChartConfig;

export function AnalyticsInteractionsChart({
    className,
}: { className: string }) {
    return (
        <Card className={`shadow-none rounded-lg ${className}`}>
            <CardHeader>
                <CardTitle className="flex flex-row items-center gap-2">
                    Balance by Company Size <TrendingUp className="h-4 w-4" />
                </CardTitle>
                <CardDescription>October 2024 - March 2025</CardDescription>
            </CardHeader>
            <CardContent className="h-[calc(100%-80px)] flex flex-col">
                <ChartContainer config={chartConfig} className="h-full">
                    <LineChart
                        accessibilityLayer
                        data={chartData}
                        margin={{
                            left: 12,
                            right: 12,
                            top: 12,
                            bottom: 12,
                        }}
                        height={300}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="month"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            tickFormatter={(value) => value.slice(0, 3)}
                        />
                        <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent />}
                        />
                        <Line
                            dataKey="enterprise"
                            type="monotone"
                            stroke="#846fb8"
                            strokeWidth={2}
                            dot={false}
                        />
                        <Line
                            dataKey="startup"
                            type="monotone"
                            stroke="#1a941b"
                            strokeWidth={2}
                            dot={false}
                        />
                        <Line
                            dataKey="midMarket"
                            type="monotone"
                            stroke="#57d1e1"
                            strokeWidth={2}
                            dot={false}
                        />
                    </LineChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
}
