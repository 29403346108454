import { Card } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import type { GetUserResponse } from "@/interfaces/serverData";
import { AvatarIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { memo, useCallback, useEffect, useState } from "react";

interface OnboardingAssigneeDropdownProps {
    assigneeUserID?: string;
    userID: string;
    saveAssignee: (assignee: GetUserResponse) => void;
    users: GetUserResponse[];
    className?: string;
}

export const OnboardingAssigneeDropdown = memo(
    function OnboardingAssigneeDropdown({
        assigneeUserID = "",
        userID,
        saveAssignee,
        users,
        className,
    }: OnboardingAssigneeDropdownProps) {
        const assignText = "Unassigned...";

        // Initialize state based on props
        const initialUserData = assigneeUserID
            ? users.find((user) => user.id === assigneeUserID)
            : null;
        const [user, setUser] = useState<string>(
            initialUserData
                ? `${initialUserData.first_name} ${initialUserData.last_name}`
                : assignText,
        );
        const [enabled, setEnabled] = useState<boolean>(!!initialUserData);
        const [userInfo, setUserInfo] = useState<GetUserResponse | undefined>(
            initialUserData || undefined,
        );

        // Don't use state for pictureURL, derive it from userInfo instead
        const pictureURL = userInfo?.picture_url;

        // Update only when assigneeUserID or users change
        useEffect(() => {
            if (assigneeUserID) {
                const userData = users.find(
                    (user) => user.id === assigneeUserID,
                );
                if (userData?.first_name && userData?.last_name) {
                    setUser(`${userData.first_name} ${userData.last_name}`);
                    setUserInfo(userData);
                    setEnabled(true);
                } else {
                    setEnabled(false);
                    setUserInfo(undefined);
                }
            } else {
                setUser(assignText);
                setUserInfo(undefined);
                setEnabled(false);
            }
        }, [assigneeUserID, users]);

        const [myUser, setMyUser] = useState<GetUserResponse>({
            id: "",
            email: "",
            username: "",
            first_name: "",
            last_name: "",
            picture_url: "",
            user_role: "",
        });

        useEffect(() => {
            if (userID) {
                const userData = users.find((user) => user.id === userID);
                if (userData?.first_name && userData?.last_name) {
                    setMyUser(userData);
                }
            }
        }, [userID, users]);

        const [open, setOpen] = useState<boolean>(false);

        const handleAssigneeSelect = useCallback(
            (selectedUser: GetUserResponse) => {
                saveAssignee(selectedUser);
                setUser(`${selectedUser.first_name} ${selectedUser.last_name}`);
                setUserInfo(selectedUser);
                setEnabled(true);
                setOpen(false);
            },
            [saveAssignee],
        );

        const handlePopoverClick = useCallback((e: React.MouseEvent) => {
            e.stopPropagation();
        }, []);

        return (
            <div className="text-xs flex items-center w-full h-full">
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <Badge
                            color={"gray"}
                            size="2"
                            radius="medium"
                            variant="outline"
                            className={`ring-0 w-full flex items-center ${className}`}
                            onClick={handlePopoverClick}
                        >
                            <div className="lb-root flex flex-row items-center justify-between w-full rounded-lg lb-comment-header mb-0 lb-comment-details">
                                <div className="flex flex-row items-center gap-1">
                                    {enabled ? (
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div
                                                        className={
                                                            "lb-avatar rounded-full w-5 h-5"
                                                        }
                                                    >
                                                        {pictureURL && (
                                                            <img
                                                                className="lb-avatar-image"
                                                                src={pictureURL}
                                                                alt={user[0]}
                                                            />
                                                        )}
                                                        <span>
                                                            {user[0] ?? ""}
                                                        </span>
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent
                                                    sideOffset={5}
                                                    className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                                                    asChild
                                                >
                                                    <Card className="bg-white text-gray11 p-3 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                                        <p className="font-bold ">
                                                            {userInfo?.email ??
                                                                ""}
                                                        </p>
                                                    </Card>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    ) : (
                                        <div
                                            className={
                                                "lb-avatar rounded-full w-5 h-5"
                                            }
                                        >
                                            {pictureURL && (
                                                <img
                                                    className="lb-avatar-image"
                                                    src={pictureURL}
                                                    alt={user[0]}
                                                />
                                            )}
                                            <span>{user[0] ?? ""}</span>
                                        </div>
                                    )}
                                    <div className="text-xs font-medium">
                                        {user}
                                    </div>
                                </div>
                                <TriangleDownIcon className="h-3 w-3" />
                            </div>
                        </Badge>
                    </PopoverTrigger>
                    <PopoverContent
                        className="w-[200px] p-0"
                        onClick={handlePopoverClick}
                    >
                        <Command>
                            <CommandInput
                                placeholder="Search assignee..."
                                className="h-9 text-xs"
                            />
                            <CommandList>
                                <CommandEmpty>No assignee found.</CommandEmpty>
                                <CommandGroup>
                                    <CommandItem
                                        className="text-xs"
                                        onSelect={() =>
                                            handleAssigneeSelect(myUser)
                                        }
                                        onClick={handlePopoverClick}
                                    >
                                        <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                            <AvatarIcon className="w-5 h-5 mx-0.3" />

                                            <span className="lb-comment-author text-xs font-normal">
                                                Assign to Me
                                            </span>
                                        </div>
                                    </CommandItem>
                                    {users.map((user) => (
                                        <CommandItem
                                            key={user.id}
                                            value={`${user.first_name} ${user.last_name}`}
                                            onSelect={() =>
                                                handleAssigneeSelect(user)
                                            }
                                            onClick={handlePopoverClick}
                                        >
                                            <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                                <div
                                                    className={
                                                        "lb-avatar w-5 h-5"
                                                    }
                                                >
                                                    {user.picture_url && (
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={
                                                                user.picture_url
                                                            }
                                                            alt={`${user.first_name} ${user.last_name}`}
                                                        />
                                                    )}
                                                    <span>{`${user.first_name} ${user.last_name}`}</span>
                                                </div>
                                                <span className="lb-comment-author text-xs font-normal">
                                                    {`${user.first_name} ${user.last_name}`}
                                                </span>
                                            </div>
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        );
    },
);
