import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import {
    type FilterOption,
    menuThenMenuSources,
    sourcesFilterOptions,
} from "@/IssuesTable/constants";
import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { toast } from "@/component/shadcn/ui/use-toast";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    type Account,
    type AccountUpdatePayload,
    ContractType,
    LabelsType,
    type ScopeResponse,
} from "@/interfaces/serverData";
import { CaretDownIcon, QuestionMarkIcon } from "@radix-ui/react-icons";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ReactComponent as GmailSvg } from "../../../images/integrations/gmail.svg";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";

export interface PrefCommDropdownProps {
    company: Account | undefined;
    contactsCombinedData: Account[];
    channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
    userID: string;
    updateData?: ((company: boolean, contacts: boolean, tickets: boolean) => Promise<void>) | undefined;
}

export const PrefCommDropdown = ({
    company,
    contactsCombinedData,
    channelsQuery,
    userID,
    updateData,
}: PrefCommDropdownProps) => {
    const api = useApi();
    const SUPPORTED_SOURCES = ["Slack", "CommunitySlack", "Discord", "Gmail"];

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeMenu, setActiveMenu] = useState<string>("Source");
    const filterOptions: FilterOption[] = sourcesFilterOptions.filter((fo) =>
        SUPPORTED_SOURCES.includes(fo.type),
    );
    const [filters, setFilters] = useState<
        Map<
            string,
            Set<{ label: string; value: string; key: string; color: string }>
        >
    >(new Map());

    let Icon: React.ElementType = QuestionMarkIcon;
    let formattedLabel = "Select channel...";
    const sourceFilter = filters.get("Source");
    if (sourceFilter) {
        const currOption = Array.from(sourceFilter)[0];
        const [source, channelName] = currOption.value.split(" -- ");
        formattedLabel = channelName ? channelName.trim() : currOption.label;
        const formattedSource = source ? source.trim().replace(/\s+/g, "") : "";
        Icon =
            integrationBackEndDataMappingToSvg.get(formattedSource) ??
            QuestionMarkIcon;
    }

    const handleItemSelect =
        (
            type: string,
            option: {
                label: string;
                value: string;
                key: string;
                color: string;
            },
        ) =>
            () => {
                // Open up another context menu for these sources
                if (SUPPORTED_SOURCES.includes(option.value)) {
                    if (menuThenMenuSources.includes(option.value)) {
                        setActiveMenu(option.value);
                    } else {
                        setActiveMenu("Source");
                    }
                }

                const newFilters = new Map(filters);
                const preferred_communication = new Map<string, ScopeResponse>();
                let updatePC = false;
                if (SUPPORTED_SOURCES.includes(type)) {
                    newFilters.set("Source", new Set([option]));
                    setFilters(newFilters);

                    // There should only be one source entry in filter
                    if (newFilters.size === 1) {
                        const [_, filter] = Array.from(newFilters.entries())[0];

                        // Split the label to get the source and channel name
                        const option = Array.from(filter)[0];
                        const [source, channelNameWithHash] =
                            option.label.split(" -- ");
                        const channelName = channelNameWithHash
                            .replace("#", "")
                            .trim(); // Remove the '#' if it exists
                        const scope: ScopeResponse = {
                            key: option.key,
                            name: channelName,
                        };
                        preferred_communication.set(source, scope);
                    }
                    updatePC = true;
                } else if (option.value === "None") {
                    updatePC = true;
                }

                if (updatePC) {
                    const requestData: AccountUpdatePayload = {
                        id: company?.id ?? "",
                        user: userID,
                        name: company?.name ?? "",
                        domain: company?.domain ?? "",
                        image_url: company?.image_url ?? "",
                        contract_value: company?.contract_value ?? 0,
                        contract_type: company?.contract_type
                            ? (company.contract_type as ContractType)
                            : ContractType.Month,
                        contacts: contactsCombinedData,
                        renewal_date: company?.renewal_date,
                        tier: company?.labels.find(label => label.type == LabelsType.Tier)?.id ?? undefined,
                        stage: company?.labels.find(label => label.type == LabelsType.Stage)?.id ?? undefined,
                        company_type: company?.labels.find(label => label.type == LabelsType.CompanyType)?.id ?? undefined,
                        assignee_user_id: company?.assignee_user_id,
                        teams: company?.teams ?? [],
                        tags: company?.tags,
                        preferred_communication: Object.fromEntries(
                            preferred_communication,
                        ),
                    };

                    // Save the update
                    api.patch(
                        `${URLS.serverUrl}${ContactsAPI.editCompany.url}/${company?.id}`,
                        requestData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status !== 200) {
                                toast({
                                    title: "Oops! Something's wrong.",
                                    description:
                                        "Please try again at a later time.",
                                    variant: "destructive",
                                });
                            } else {
                                toast({
                                    title: "Updated Preferred Communication",
                                    description:
                                        "Company's preferred communication method has been updated successfully!",
                                });
                                if (updateData) {
                                    updateData(true, true, false);
                                }
                            }
                        })
                        .catch((res) => {
                            toast({
                                title: "Oops! Something's wrong.",
                                description: "Please try again at a later time.",
                                variant: "destructive",
                            });
                        })
                        .finally(() => setIsOpen(false));
                }
                return;
            };

    useEffect(() => {
        if (
            company?.preferred_communication &&
            company?.preferred_communication.size !== 0
        ) {
            const newFilters = new Map();
            const source = Object.keys(company.preferred_communication)[0];
            if (source) {
                const scope = Object.values(company.preferred_communication)[0];
                const option = {
                    label: `${source} -- #${scope?.name}`,
                    key: `${scope?.key}`,
                    value: `${source} -- ${scope?.name} -- ${scope?.key}`,
                    color: "",
                };
                newFilters.set("Source", new Set([option]));
                setFilters(newFilters);
            } else {
                setFilters(new Map());
            }
        } else {
            setFilters(new Map());
        }
    }, [company?.preferred_communication]);

    return (
        <DropdownMenu
            open={isOpen}
            onOpenChange={() => {
                setIsOpen(!isOpen);
                setActiveMenu("Source");
            }}
        >
            <DropdownMenuTrigger asChild type="button">
                <Button
                    variant="outline"
                    className="mr-2 justify-between h-8 text-xs px-3 overflow-hidden text-ellipsis break-word w-[215px] flex items-center justify-between outline outline-1 outline-gray-300"
                >
                    <div className="flex items-center gap-1.5">
                        {formattedLabel !== "Select channel..." && (
                            <Icon className="w-4 h-4" />
                        )}
                        <div className="w-full truncate">{formattedLabel}</div>
                    </div>
                    <CaretDownIcon />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                align="start"
                className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
            >
                {activeMenu === "Gmail" ? (
                    <Command
                        className="rounded-md shadow-md text-xs pb-1"
                        aria-disabled
                    >
                        <CommandInput
                            placeholder={`Filter contacts' emails...`}
                            className="px-1 text-[13px]"
                        />
                        <CommandList className="space-y-1">
                            <CommandEmpty className="text-xs px-4 py-2">
                                No emails found
                            </CommandEmpty>
                            {contactsCombinedData.map((contact) => (
                                <CommandItem
                                    key={contact.id}
                                    className="px-1 py-0.5"
                                >
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        onClick={handleItemSelect("Gmail", {
                                            label: `Gmail -- ${contact.domain}`,
                                            value: `Gmail -- ${contact.domain}`,
                                            key: `${contact.domain}`,
                                            color: "",
                                        })}
                                        className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-0"
                                    >
                                        <div className="flex items-center gap-2">
                                            <div className="flex items-center gap-2">
                                                <GmailSvg className="w-4 h-4" />
                                                {contact.domain}
                                            </div>
                                        </div>
                                    </Button>
                                </CommandItem>
                            ))}
                        </CommandList>
                    </Command>
                ) : (
                    <FilterDropdownElement
                        type={filterOptions
                            .map((opt) => opt.type)
                            .includes(activeMenu)
                            ? activeMenu
                            : "Source"}
                        categories={[]}
                        filters={filters}
                        handleItemSelect={handleItemSelect}
                        topics={[]}
                        users={[]}
                        companies={[]}
                        customers={[]}
                        customerGroups={[]}
                        teams={[]}
                        isSavedViewFilter={false}
                        channels={channelsQuery?.data ?? new Map()}
                        organizationSources={SUPPORTED_SOURCES}
                        extraOptions={activeMenu === "Source"
                            ? [
                                {
                                    color: "",
                                    label: "None",
                                    value: "None",
                                    key: "None",
                                },
                            ]
                            : []}
                        interactionTypes={[]} />
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
