import { InsightListType } from "@/Insights/useInsightsData";
import { getBadgeForTeam } from "@/utilities/methods";
import {
    ChatBubbleIcon,
    CheckCircledIcon,
    LapTimerIcon,
    ResetIcon,
} from "@radix-ui/react-icons";
import { Box, Card, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { Tabs } from "@radix-ui/themes";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { LoaderIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { memo } from "react";
import type { DateRange } from "react-day-picker";
import { API, TeamsAPI, URLS } from "../../constant";
import { useApi } from "../../interfaces/api";
import type {
    AnalyticsResponse,
    InteractionsAnalyticsPaginationResponse,
    ResolutionTime,
    ResolutionTimeResponse,
    ResponseTime,
    ResponseTimeResponse,
    SLAResponse,
    Teams,
    TimerAnalyticsResponse,
} from "../../interfaces/serverData";
import { TeamBadges } from "../WorkspacePreferences/TeamBadges";
import AnalyticsFilters from "./Analytics/AnalyticsFilters";
import FirstResponseAnalyticsComponent from "./Analytics/FirstResponseAnalyticsPage";
import InteractionsComponent from "./Analytics/InteractionsPage";
import ResolutionAnalyticsComponent from "./Analytics/ResolutionAnalyticsPage";
import ResponseAnalyticsComponent from "./Analytics/ResponseAnalyticsPage";
import SLAAnalyticsComponent from "./Analytics/SLAAnalyticsPage";
import SLABreachCountComponent, {
    type SLABreachCount,
    type SLABreachCountData,
} from "./Analytics/SLACountPage";
import { TickerTimerPage } from "./Analytics/TicketTimerPage";

type BadgeColor = "gray" | "green" | "blue" | "red";

export interface ChartData {
    date: string;
    duration: number;
}

const areEqual = (
    prevProps: AdminAnalyticsProps,
    nextProps: AdminAnalyticsProps,
) => {
    return (
        prevProps.tempTeamID === nextProps.tempTeamID &&
        prevProps.listType === nextProps.listType
    );
};

interface AdminAnalyticsProps {
    listType: InsightListType;
    tempTeamID?: string;
}

export interface GroupedDataItem {
    date: string;
    count: number;
}

export const convertTimestampToDate = (timestamp: string): string => {
    return timestamp.split("T")[0];
};

const AdminAnalyticsPage: React.FC<AdminAnalyticsProps> = ({
    listType,
    tempTeamID,
}) => {
    const api = useApi();
    const [incomingData, setIncomingData] = useState<AnalyticsResponse | null>(
        null,
    );
    const [activeTab, setActiveTab] = useState<string>("chat");
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error

    const [responseChartData, setResponseChartData] = useState<ChartData[]>([]); // For the rolling average chart
    const [teamID, setTeamID] = useState<string>(tempTeamID ?? "");

    const iconStyle = (color: BadgeColor) => ({
        color,
    });

    useEffect(() => {
        let url = `${URLS.serverUrl}${API.analytics}`;

        if (listType === InsightListType.Team) {
            url += `/${tempTeamID ?? teamID}`;
        }

        api.get(url, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setIncomingData(res.data.data);
                    setLoadingState(1);
                } else {
                    setLoadingState(2);
                }
            })
            .catch(() => setLoadingState(2));
    }, [api, tempTeamID, listType, teamID]);

    const [range, setRange] = useState<DateRange | undefined>({
        from: new Date(new Date().setDate(new Date().getDate() - 7)),
    });
    const [playgroundFilters, setPlaygroundFilters] = useState<
        Map<string, Set<string>>
    >(new Map());

    const fetchInteractionsAnalytics = async ({
        pageParam = 0,
    }: {
        pageParam?: number;
    }): Promise<InteractionsAnalyticsPaginationResponse> => {
        try {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            // Convert Set to comma-separated string
            const sourceString = sourceFilter.join("&");
            const response = await api.get(
                `${URLS.serverUrl}${API.getInteractionsAnalytics}`,
                {
                    // todo: figure out how filters work for unique name
                    params: {
                        source: sourceString,
                        teamID: tempTeamID ?? teamID,
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                        limit: 1000,
                        offset: pageParam,
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );

            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: interactionsData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        status: interactionsStatus,
    } = useInfiniteQuery({
        queryKey: [
            [`interactions_analytics_${tempTeamID ?? teamID}`],
            tempTeamID ?? teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: fetchInteractionsAnalytics,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 100000,
        refetchOnWindowFocus: true,
        enabled: activeTab === "chat",
    });

    const interactionsCombinedData = useMemo(() => {
        return interactionsData && Array.isArray(interactionsData.pages)
            ? interactionsData.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];
    }, [interactionsData]);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const parsedData = interactionsCombinedData?.map((item) => {
        const utcDate = parseISO(item.original_timestamp);
        // Convert to user's timezone
        const zonedDate = toZonedTime(utcDate, userTimeZone);
        return {
            date: format(zonedDate, "yyyy-MM-dd"),
        };
    });

    // Rest of the grouping logic remains the same
    const groupedData = parsedData.reduce(
        (acc: { [key: string]: GroupedDataItem }, { date }) => {
            acc[date] = acc[date] || { date, count: 0 };
            acc[date].count += 1;
            return acc;
        },
        {},
    );

    // Fill in missing dates from range.from to range.to
    if (range?.from && range?.to) {
        const startDate = range.from;
        const endDate = range.to;
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dateStr = format(currentDate, "yyyy-MM-dd");
            if (!groupedData[dateStr]) {
                groupedData[dateStr] = { date: dateStr, count: 0 };
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    const orderedGroupedData = Object.values(groupedData).sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    const dataQuery = useQuery<TimerAnalyticsResponse>({
        queryKey: [
            "ticketTimer",
            teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );
            const sourceString = sourceFilter.join("&");
            const res = await api.get(URLS.serverUrl + API.timerAnalytics, {
                params: {
                    source: sourceString,
                    teamID: teamID,
                    start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                    end: range?.to?.toJSON() ?? new Date().toJSON(),
                },
                headers: { "Content-Type": "application/json" },
            });
            return res.data.data;
        },
        enabled: activeTab === "timer",
    });

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const responseTimeQuery = useQuery<ResponseTimeResponse>({
        queryKey: [
            [`response_time_${tempTeamID ?? teamID}`],
            tempTeamID ?? teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            // Convert Set to comma-separated string
            const sourceString = sourceFilter.join("&");
            const resp = await api.get(
                `${URLS.serverUrl}${API.getResponseTime}`,
                {
                    params: {
                        source: sourceString,
                        teamID: tempTeamID ?? teamID,
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );
            return resp.status === 200 ? resp.data.data : null;
        },
        refetchOnWindowFocus: false, // Avoid unnecessary refetch
        refetchInterval: false,
    });

    const mapResponseTimeToChartData = (data: ResponseTime[]): ChartData[] => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // Iterate over response time data
        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            // Extract the date from the `start` field
            const date = item.start.split("T")[0]; // Group by day (YYYY-MM-DD)

            // Initialize if the date doesn't exist yet
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }

            // Sum the durations and count occurrences for the given day
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        // Calculate the average duration for each day and map to chart data
        return Object.entries(groupedData).map(
            ([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count, // Calculate the average response time
            }),
        );
    };

    const [responseTimePerDay, setResponseTimePerDay] = useState<ChartData[]>(
        [],
    );

    const calculateResponseTimePerDay = useCallback((data: ResponseTime[]) => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration = item.duration;
            groupedData[date].count = 1;
        });

        return Object.entries(groupedData)
            .map(([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }))
            .sort((a, b) => a.date.localeCompare(b.date));
    }, []);

    useEffect(() => {
        if (responseTimeQuery.isSuccess && responseTimeQuery.data?.results) {
            const perDayData = calculateResponseTimePerDay(
                responseTimeQuery.data.results,
            );
            setResponseTimePerDay(perDayData);
        }
    }, [
        responseTimeQuery.data,
        responseTimeQuery.isSuccess,
        calculateResponseTimePerDay,
    ]);

    useEffect(() => {
        if (responseTimeQuery.isSuccess) {
            const chartData = mapResponseTimeToChartData(
                responseTimeQuery.data?.results || [],
            );
            setResponseChartData(chartData); // Grouped data by day
        }
    }, [responseTimeQuery.data, responseTimeQuery.isSuccess]);

    const [firstResponseChartData, setFirstResponseChartData] = useState<
        ChartData[]
    >([]); // For the rolling average chart

    const firstResponseTimeQuery = useQuery<ResponseTimeResponse>({
        queryKey: [
            [`first_response_time_${tempTeamID ?? teamID}`],
            tempTeamID ?? teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            // Convert Set to comma-separated string
            const sourceString = sourceFilter.join("&");
            const resp = await api.get(
                `${URLS.serverUrl}${API.getFirstResponseTime}`,
                {
                    params: {
                        source: sourceString,
                        teamID: tempTeamID ?? teamID,
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );
            return resp.status === 200 ? resp.data.data : null;
        },
        refetchOnWindowFocus: false, // Avoid unnecessary refetch
        refetchInterval: false,
        enabled: activeTab === "firstResponse",
    });

    const mapFirstResponseTimeToChartData = (
        data: ResponseTime[],
    ): ChartData[] => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // Iterate over response time data
        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            // Extract the date from the `start` field
            const date = item.start.split("T")[0]; // Group by day (YYYY-MM-DD)

            // Initialize if the date doesn't exist yet
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }

            // Sum the durations and count occurrences for the given day
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        // Calculate the average duration for each day and map to chart data
        return Object.entries(groupedData).map(
            ([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count, // Calculate the average response time
            }),
        );
    };

    const [firstResponseTimePerDay, setFirstResponseTimePerDay] = useState<
        ChartData[]
    >([]);

    useEffect(() => {
        if (
            firstResponseTimeQuery.isSuccess &&
            firstResponseTimeQuery.data?.results
        ) {
            const perDayData = calculateResponseTimePerDay(
                firstResponseTimeQuery.data.results,
            );
            setFirstResponseTimePerDay(perDayData);
        }
    }, [
        firstResponseTimeQuery.data,
        firstResponseTimeQuery.isSuccess,
        calculateResponseTimePerDay,
    ]);

    const { data: breachCountData } = useQuery<SLABreachCount[]>({
        queryKey: [
            "slaBreachCount",
            range?.from?.toISOString(),
            range?.to?.toISOString(),
            Array.from(playgroundFilters.get("Source") ?? []),
            teamID,
        ],
        queryFn: async () => {
            const sourceFilters = Array.from(
                playgroundFilters.get("Source") ?? [],
            );
            const sourceString = sourceFilters.join("&");
            const res = await api.get(
                `${URLS.serverUrl}${API.getSLABreachCount}`,
                {
                    params: {
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                        source: sourceString,
                        teamID: teamID,
                    },
                },
            );
            if (res.status !== 200) {
                throw new Error("Failed to fetch SLA breach count");
            }
            return res.data.data.results;
        },
        enabled: activeTab === "slaCount",
    });

    const mapSLABreachCountToChartData = (
        data: SLABreachCount[],
    ): SLABreachCountData[] => {
        const groupedData: {
            [key: string]: { count: number };
        } = {};

        // Iterate over SLA breach count data
        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            // Extract the date from the `start` field
            const date = new Date(item.date).toISOString().split("T")[0];

            // Initialize if the date doesn't exist yet
            if (!groupedData[date]) {
                groupedData[date] = { count: 0 };
            }

            // Sum the durations and count occurrences for the given day
            groupedData[date].count += 1;
        });

        const result = Object.entries(groupedData).map(([date, { count }]) => ({
            date,
            count,
        }));
        return result.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        );
    };

    useEffect(() => {
        if (firstResponseTimeQuery.isSuccess) {
            const chartData = mapFirstResponseTimeToChartData(
                firstResponseTimeQuery.data?.results || [],
            );
            setFirstResponseChartData(chartData); // Grouped data by day
        }
    }, [firstResponseTimeQuery.data, firstResponseTimeQuery.isSuccess]);

    const handleFilterSelect = useCallback(
        (
            type: string,
            option: {
                label: string;
                value: string;
                key: string;
                color: string;
            },
        ) =>
            () => {
                const newFilters = new Map(playgroundFilters);
                if (newFilters.has(type)) {
                    const currValues = new Set(newFilters.get(type));
                    if (currValues?.has(option.value)) {
                        currValues.delete(option.value);
                        if (currValues.size === 0) {
                            newFilters.delete(type);
                        } else {
                            newFilters.set(type, currValues);
                        }
                    } else {
                        currValues.add(option.value);
                        newFilters.set(type, currValues);
                    }
                } else {
                    newFilters.set(type, new Set([option.value]));
                }
                setPlaygroundFilters(newFilters);
            },
        [playgroundFilters],
    );

    // Add new state for resolution time chart data and per day data
    const [resolutionChartData, setResolutionChartData] = useState<ChartData[]>(
        [],
    );
    const [resolutionTimePerDay, setResolutionTimePerDay] = useState<
        ChartData[]
    >([]);

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams", tempTeamID ?? teamID],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listTeams;
            const res = await api.get(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const teams: Teams[] = res.data.data;
            return teams;
        },
    });

    const resolutionTimeQuery = useQuery<ResolutionTimeResponse>({
        queryKey: [
            "resolution_time",
            tempTeamID ?? teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );
            const sourceString = sourceFilter.join("&");
            const resp = await api.get(
                `${URLS.serverUrl}${API.getResolutionTime}`,
                {
                    params: {
                        source: sourceString,
                        teamID: tempTeamID ?? teamID,
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );
            return resp.status === 200 ? resp.data.data : null;
        },
        refetchOnWindowFocus: false,
        refetchInterval: false,
    });

    // Function to map resolution time data to chart data
    const mapResolutionTimeToChartData = (
        data: ResolutionTime[],
    ): ChartData[] => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        return Object.entries(groupedData).map(
            ([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }),
        );
    };

    // Function to calculate resolution time per day
    const calculateResolutionTimePerDay = useCallback(
        (data: ResolutionTime[]) => {
            const groupedData: {
                [key: string]: { totalDuration: number; count: number };
            } = {};

            // biome-ignore lint/complexity/noForEach: <explanation>
            data.forEach((item) => {
                const date = item.start.split("T")[0];
                if (!groupedData[date]) {
                    groupedData[date] = { totalDuration: 0, count: 0 };
                }
                groupedData[date].totalDuration += item.duration;
                groupedData[date].count += 1;
            });

            return Object.entries(groupedData)
                .map(([date, { totalDuration, count }]) => ({
                    date,
                    duration: totalDuration / count,
                }))
                .sort((a, b) => a.date.localeCompare(b.date));
        },
        [],
    );

    // Set resolution time per day and chart data
    useEffect(() => {
        if (
            resolutionTimeQuery.isSuccess &&
            resolutionTimeQuery.data?.results
        ) {
            const perDayData = calculateResolutionTimePerDay(
                resolutionTimeQuery.data.results,
            );
            setResolutionTimePerDay(perDayData);
        }
    }, [
        resolutionTimeQuery.data,
        resolutionTimeQuery.isSuccess,
        calculateResolutionTimePerDay,
    ]);

    const timerEnabledQuery = useQuery({
        queryKey: ["timerEnabled"],
        queryFn: async () => {
            const res = await api.get(URLS.serverUrl + API.getTimerEnabled);
            return res.data.data;
        },
    });

    useEffect(() => {
        if (resolutionTimeQuery.isSuccess) {
            const chartData = mapResolutionTimeToChartData(
                resolutionTimeQuery.data?.results || [],
            );
            setResolutionChartData(chartData);
        }
    }, [resolutionTimeQuery.data, resolutionTimeQuery.isSuccess]);

    const slaTimeQuery = useQuery<SLAResponse>({
        queryKey: [
            "sla",
            tempTeamID ?? teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            // Convert Set to comma-separated string
            const sourceString = sourceFilter.join("&");
            const resp = await api.get(`${URLS.serverUrl}${API.getSLATime}`, {
                params: {
                    source: sourceString,
                    teamID: tempTeamID ?? teamID,
                    start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                    end: range?.to?.toJSON() ?? new Date().toJSON(),
                },
                headers: { "Content-Type": "application/json" },
            });
            return resp.status === 200 ? resp.data.data : null;
        },
        refetchOnWindowFocus: false, // Avoid unnecessary refetch
        refetchInterval: false,
    });

    // Add new state for SLA analytics data and per day data
    const [slaChartData, setSlaChartData] = useState<ChartData[]>([]);
    const [slaTimePerDay, setSlaTimePerDay] = useState<ChartData[]>([]);

    // Function to map SLA time data to chart data
    const mapSlaTimeToChartData = (data: ResolutionTime[]): ChartData[] => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        return Object.entries(groupedData).map(
            ([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }),
        );
    };

    // Function to calculate SLA time per day
    const calculateSlaTimePerDay = useCallback((data: ResolutionTime[]) => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        return Object.entries(groupedData)
            .map(([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }))
            .sort((a, b) => a.date.localeCompare(b.date));
    }, []);

    // Set SLA time per day and chart data
    useEffect(() => {
        if (slaTimeQuery.isSuccess && slaTimeQuery.data?.results) {
            const perDayData = calculateSlaTimePerDay(
                slaTimeQuery.data.results,
            );
            setSlaTimePerDay(perDayData);
        }
    }, [slaTimeQuery.data, slaTimeQuery.isSuccess, calculateSlaTimePerDay]);

    useEffect(() => {
        if (slaTimeQuery.isSuccess) {
            const chartData = mapSlaTimeToChartData(
                slaTimeQuery.data?.results || [],
            );
            setSlaChartData(chartData);
        }
    }, [slaTimeQuery.data, slaTimeQuery.isSuccess]);

    const formatTime = (seconds: number) => {
        if (seconds < 60) {
            return `${seconds.toFixed(2)} secs`;
        } else if (seconds < 3600) {
            return `${(seconds / 60).toFixed(2)} mins`;
        } else if (seconds < 86400) {
            return `${(seconds / 3600).toFixed(2)} hours`;
        } else {
            return `${(seconds / 86400).toFixed(2)} days`;
        }
    };

    return (
        <div>
            <Box ml={"2%"} mr={"2%"}>
                <Flex direction="column" justify="center" mb="4">
                    <Box mt="7" height="100%" width="98%">
                        <Flex
                            align="start"
                            direction="column"
                            justify={"start"}
                        >
                            <Heading
                                size="5"
                                align="left"
                                className="flex items-center gap-2"
                            >
                                Analytics
                                {
                                    // For Team view
                                    (listType === InsightListType.Team &&
                                        teamID && (
                                            <TeamBadges
                                                teams={(
                                                    teamsQuery.data ?? []
                                                ).filter(
                                                    (team) =>
                                                        team.id === teamID,
                                                )}
                                                defaultIsWorkspace={false}
                                            />
                                        )) ||
                                        // For regular view
                                        (listType === InsightListType.General &&
                                            getBadgeForTeam("General"))
                                }
                            </Heading>
                            <Text mb="12px" size="2">
                                View and export analytics about your
                                interactions, average response times, slas, and
                                more.
                            </Text>
                        </Flex>
                    </Box>
                </Flex>

                <Flex direction="column" gap="5">
                    <Card style={{ backgroundColor: "#EFF0F3", width: "100%" }}>
                        <Flex direction="column" gap="6">
                            <div className="flex flex-row gap-4">
                                {incomingData && (
                                    <>
                                        <StatCard
                                            icon={
                                                <ChatBubbleIcon
                                                    style={iconStyle("gray")}
                                                    className="w-[21px] h-[21px]"
                                                />
                                            }
                                            label="Interactions"
                                            value={String(incomingData?.count)}
                                            isLoading={loadingState === 0}
                                        />

                                        <StatCard
                                            icon={
                                                <ResetIcon
                                                    className="w-[21px] h-[21px]"
                                                    color="#868E8B"
                                                />
                                            }
                                            label="Response Time"
                                            value={formatTime(
                                                responseTimeQuery.data
                                                    ?.average ?? 0,
                                            )}
                                            isLoading={loadingState === 0}
                                        />
                                        <StatCard
                                            icon={
                                                <CheckCircledIcon
                                                    style={iconStyle("green")}
                                                    className="w-[21px] h-[21px]"
                                                />
                                            }
                                            label="Resolution Time"
                                            value={formatTime(
                                                resolutionTimeQuery.data
                                                    ?.average ?? 0,
                                            )}
                                            isLoading={loadingState === 0}
                                        />
                                        <StatCard
                                            icon={
                                                <LapTimerIcon
                                                    style={{ color: "#f57695" }}
                                                    className="w-[21px] h-[21px]"
                                                />
                                            }
                                            label="SLA Breach Duration"
                                            value={formatTime(
                                                slaTimeQuery.data?.average ?? 0,
                                            )}
                                            isLoading={loadingState === 0}
                                        />
                                    </>
                                )}
                                {!incomingData && (
                                    <>
                                        <StatCard
                                            key={0}
                                            icon={<LoaderIcon />}
                                            label=""
                                            value={String(0)}
                                            isLoading={true}
                                        />
                                        {/* <StatCard
                                            key={1}
                                            icon={<LoaderIcon />}
                                            label=""
                                            value={0}
                                            isLoading={true}
                                        />
                                        <StatCard
                                            key={2}
                                            icon={<LoaderIcon />}
                                            label=""
                                            value={0}
                                            isLoading={true}
                                        /> */}
                                    </>
                                )}
                                {/* {incomingData?.categories &&
                                    Object.entries(incomingData.categories).map(
                                        ([key, val]) => (
                                            <StatCard
                                                key={key}
                                                icon={getIcon(key)}
                                                label={key}
                                                value={val}
                                                isLoading={loadingState === 0}
                                            />
                                        ),
                                    )} */}
                            </div>
                        </Flex>
                    </Card>

                    <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
                        <Tabs.List>
                            <Tabs.Trigger
                                value="chat"
                                onClick={() => setActiveTab("chat")}
                            >
                                Interactions
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                value="response"
                                onClick={() => setActiveTab("response")}
                            >
                                Response Times
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                value="resolution"
                                onClick={() => setActiveTab("resolution")}
                            >
                                Resolution Times
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                value="sla"
                                onClick={() => setActiveTab("sla")}
                            >
                                SLA Breach Duration
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                value="firstResponse"
                                onClick={() => setActiveTab("firstResponse")}
                            >
                                Time to First Response
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                value="slaCount"
                                onClick={() => setActiveTab("slaCount")}
                            >
                                SLA Breach Count
                            </Tabs.Trigger>
                            {timerEnabledQuery?.data === true && (
                                <Tabs.Trigger
                                    value="timer"
                                    onClick={() => setActiveTab("timer")}
                                >
                                    Timer
                                </Tabs.Trigger>
                            )}
                        </Tabs.List>
                        <div className="flex flex-col gap-2">
                            <AnalyticsFilters
                                activeTab={activeTab}
                                range={range}
                                setRange={setRange}
                                playgroundFilters={playgroundFilters}
                                setPlaygroundFilters={setPlaygroundFilters}
                                listType={listType}
                                teamID={teamID}
                                setTeamID={setTeamID}
                                teamsQueryData={teamsQuery.data}
                                handleFilterSelect={handleFilterSelect}
                                interactionsChartData={orderedGroupedData}
                                responseChartData={responseChartData}
                                responseTimeQueryData={
                                    responseTimeQuery.data?.results ?? []
                                }
                                resolutionTimePerDay={resolutionTimePerDay}
                                resolutionTimeQueryData={
                                    resolutionTimeQuery.data?.results ?? []
                                }
                                slaTimePerDay={slaTimePerDay}
                                slaTimeQueryData={
                                    slaTimeQuery.data?.results ?? []
                                }
                                firstResponseTimePerDay={
                                    firstResponseTimePerDay
                                }
                                firstResponseTimeQueryData={
                                    firstResponseTimeQuery.data?.results ?? []
                                }
                                slaBreachCountData={breachCountData ?? []}
                                ticketTimerData={dataQuery.data?.data ?? []}
                            />
                            <Tabs.Content value="chat">
                                <InteractionsComponent
                                    interactionsChartData={orderedGroupedData}
                                    interactionsQueryStatus={interactionsStatus}
                                />
                            </Tabs.Content>
                            <Tabs.Content value="response">
                                <ResponseAnalyticsComponent
                                    responseTimePerDay={responseTimePerDay}
                                    responseChartData={responseChartData}
                                    responseTimeQueryStatus={
                                        responseTimeQuery.status
                                    }
                                    responseTimeQueryIsError={
                                        responseTimeQuery.isError
                                    }
                                    responseTimeQueryData={
                                        responseTimeQuery.data
                                    }
                                />
                            </Tabs.Content>
                            <Tabs.Content value="resolution">
                                <ResolutionAnalyticsComponent
                                    resolutionTimePerDay={resolutionTimePerDay}
                                    resolutionChartData={resolutionChartData}
                                    resolutionTimeQueryStatus={
                                        resolutionTimeQuery.status
                                    }
                                    resolutionTimeQueryIsError={
                                        resolutionTimeQuery.isError
                                    }
                                    resolutionTimeQueryData={
                                        resolutionTimeQuery.data
                                    }
                                />
                            </Tabs.Content>
                            <Tabs.Content value="sla">
                                <SLAAnalyticsComponent
                                    slaTimePerDay={slaTimePerDay}
                                    slaChartData={slaChartData}
                                    slaTimeQueryStatus={slaTimeQuery.status}
                                    slaTimeQueryIsError={slaTimeQuery.isError}
                                    slaTimeQueryData={slaTimeQuery.data}
                                />
                            </Tabs.Content>
                            <Tabs.Content value="firstResponse">
                                <FirstResponseAnalyticsComponent
                                    firstResponseTimePerDay={
                                        firstResponseTimePerDay
                                    }
                                    firstResponseChartData={
                                        firstResponseChartData
                                    }
                                    firstResponseTimeQueryStatus={
                                        firstResponseTimeQuery.status
                                    }
                                    firstResponseTimeQueryIsError={
                                        firstResponseTimeQuery.isError
                                    }
                                    firstResponseTimeQueryData={
                                        firstResponseTimeQuery.data
                                    }
                                />
                            </Tabs.Content>
                            <Tabs.Content value="timer">
                                <TickerTimerPage dataQuery={dataQuery} />
                            </Tabs.Content>
                            <Tabs.Content value="slaCount">
                                <SLABreachCountComponent
                                    listType={listType}
                                    teamID={teamID}
                                    teamsQueryData={teamsQuery.data}
                                    setTeamID={setTeamID}
                                    breachCountData={mapSLABreachCountToChartData(
                                        breachCountData ?? [],
                                    )}
                                />
                            </Tabs.Content>
                        </div>
                    </Tabs.Root>
                    <div className="mb-8" />
                </Flex>
            </Box>
        </div>
    );
};

// Helper Component: StatCard
interface StatCardProps {
    icon: React.ReactNode; // JSX.Element for the icon
    label: string; // Label for the stat
    value?: string; // Optional value for the stat
    isLoading: boolean; // Loading state for skeleton
}

const StatCard = ({ icon, label, value, isLoading }: StatCardProps) => (
    <Card style={{ minWidth: "100px", width: "100%" }}>
        <Flex direction="row" align="start" gap="2" justify="between">
            <div style={{ width: "24px", height: "24px" }}>{icon}</div>
            <Flex direction="column" gap="1" align="end">
                {isLoading ? (
                    <Skeleton>
                        <Text size="5" color="gray" weight="bold">
                            000
                        </Text>
                    </Skeleton>
                ) : (
                    <Text size={"3"} color="gray" weight="bold">
                        {value ?? 0}
                    </Text>
                )}
                <Text size="3" color="gray">
                    {label}
                </Text>
            </Flex>
        </Flex>
    </Card>
);

export default memo(AdminAnalyticsPage, areEqual);
