import { ContactsAPI, URLS } from "@/constant";
import type {
    GetOnboardingResponse,
    OnboardingChecklist,
    OnboardingChecklistPayload,
} from "@/interfaces/onboardingServerData";
import {
    OnboardingChecklistStatus,
    OnboardingChecklistType,
} from "@/interfaces/onboardingServerData";
import type { Template } from "@/interfaces/serverData";
import type { Account } from "@/interfaces/serverData";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import type { AxiosInstance } from "axios";

export function saveAssignee(
    assigneeID: string,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    parent_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
): Promise<any> {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
        parent_id: parent_id,
    };
    return api
        .patch(
            `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        .then((res) => {
            if (res.status === 200) {
                if (currentItem) {
                    updateIssueState?.(
                        { assignee_user_id: assigneeID },
                        currentItem,
                    );
                }
                console.log(`Updated assignee to ${assigneeID} successfully`);
                return res;
            } else {
                console.log("Call to update assignee failed");
                return res;
            }
        })
        .catch((err) => {
            return err;
        });
}

export function saveStatus(
    task_status: OnboardingChecklistStatus,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    onboarding_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
): Promise<any> {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        task_status: task_status,
        onboarding_id: onboarding_id,
    };
    return api
        .patch(
            `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        .then((res) => {
            if (res.status === 200) {
                if (currentItem) {
                    updateIssueState?.(
                        { task_status: task_status },
                        currentItem,
                    );
                }
                console.log(`Updated status to ${task_status} successfully`);
                return res;
            } else {
                console.log("Call to update status failed");
                return res;
            }
        })
        .catch((err) => {
            return err;
        });
}

export function saveGoogleSheetEnabled(
    google_sheet_enabled: boolean,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    onboarding_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
): Promise<any> {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        google_sheet_enabled: google_sheet_enabled,
        onboarding_id: onboarding_id,
    };
    return api
        .patch(
            `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklistGoogleSheetStatus.url}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        .then((res) => {
            if (res.status === 200) {
                if (currentItem) {
                    updateIssueState?.(
                        { google_sheet_enabled: google_sheet_enabled },
                        currentItem,
                    );
                }
                console.log(
                    `Updated google_sheet_enabled to ${google_sheet_enabled} successfully`,
                );
                return res;
            } else {
                console.log("Call to update google_sheet_enabled failed");
                return res;
            }
        })
        .catch((err) => {
            return err;
        });
}

export function saveEnabled(
    enabled: boolean,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    parent_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
): Promise<any> {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        enabled: enabled,
        parent_id: parent_id,
    };
    return api
        .patch(
            `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        .then((res) => {
            if (res.status === 200) {
                if (currentItem) {
                    updateIssueState?.({ enabled: enabled }, currentItem);
                }
                console.log(`Updated enabled to ${enabled} successfully`);
                return res;
            } else {
                console.log("Call to update enabled failed");
                return res;
            }
        })
        .catch((err) => {
            return err;
        });
}

export function deleteItem(
    api: AxiosInstance,
    ids: string[],
    deleteIssue: (ids: string[]) => void,
    userID: string,
    parent_id: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        parent_id: parent_id,
    };
    api.post(
        `${URLS.serverUrl}${ContactsAPI.deleteOnboardingChecklistItem.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            if (deleteIssue) {
                deleteIssue(ids);
            }
            console.log("Deleted item successfully");
        } else {
            console.log("Call to delete item failed");
        }
    });
}

export function saveAll(
    payload: Partial<OnboardingChecklistPayload>,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    onboarding_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
): Promise<any> {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        parent_id: payload.parent_id,
        onboarding_id: onboarding_id,
        ...payload,
    };
    return api
        .patch(
            `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        .then((res) => {
            if (res.status === 200) {
                if (currentItem) {
                    updateIssueState?.(payload, currentItem);
                }
                console.log("Updated all fields successfully");
                return res;
            } else {
                console.log("Call to update completed failed");
                return res;
            }
        })
        .catch((err) => {
            return err;
        });
}

export function setOnboardingStateHelper(
    newState: Partial<OnboardingChecklist>,
    updatedMap: Map<string, OnboardingChecklist>,
    val: OnboardingChecklist,
) {
    if (!val.id) {
        return updatedMap;
    }
    const currentIssue = updatedMap?.get(val.id);

    if (!currentIssue) {
        return updatedMap;
    }

    const updatedIssue = {
        ...currentIssue,
        ...newState,
        assignee_user_id:
            newState.assignee_user_id || currentIssue.assignee_user_id,
    };

    updatedMap?.set(val.id, updatedIssue);
    return updatedMap;
}

export function setOnboardingState(
    newState: Partial<OnboardingChecklist>,
    oldItem: OnboardingChecklist,
    selectedItems: OnboardingChecklist[],
    setOnboardingItems: (
        updater: (
            prev: Map<string, OnboardingChecklist>,
        ) => Map<string, OnboardingChecklist>,
    ) => void,
    setSelectedItems: (items: OnboardingChecklist[]) => void,
) {
    setOnboardingItems((prev) => {
        const updatedMap = new Map(prev);
        if (selectedItems.length === 0) {
            // single issue
            setOnboardingStateHelper(newState, updatedMap, oldItem);
        } else {
            // selected issues with selector
            for (const val of selectedItems) {
                setOnboardingStateHelper(newState, updatedMap, val);
            }
        }
        return updatedMap;
    });
    setSelectedItems([]);
}

export function deleteOnboardingItem(
    ids: string[],
    selectedItems: OnboardingChecklist[],
    setOnboardingItems: (
        updater: (
            prev: Map<string, OnboardingChecklist>,
        ) => Map<string, OnboardingChecklist>,
    ) => void,
    setSelectedItems: (items: OnboardingChecklist[]) => void,
) {
    setOnboardingItems((prev) => {
        const updatedMap = new Map(prev);
        if (selectedItems.length === 0) {
            // single issue
            updatedMap.delete(ids[0]);
        } else {
            // selected issues with selector
            for (const val of selectedItems) {
                updatedMap.delete(val.id);
            }
        }
        return updatedMap;
    });
    setSelectedItems([]);
}

export function handleDeleteIssue(
    userID: string,
    issueIds: string[],
    selectedItems: OnboardingChecklist[],
    api: AxiosInstance,
    onboardingMainItemId: string,
    deleteOnUI?: (ids: string[]) => void,
) {
    let selectedIssueIds: string[];
    if (selectedItems.length === 0) {
        selectedIssueIds = issueIds;
    } else {
        selectedIssueIds = selectedItems.map((issue) => issue.id);
    }

    deleteItem(
        api,
        selectedIssueIds,
        (ids) => {
            if (deleteOnUI) {
                deleteOnUI(ids);
            }
        },
        userID,
        onboardingMainItemId,
    );
}

export function saveIssue(
    type: string,
    payload: Partial<OnboardingChecklistPayload>,
    userID: string,
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<GetOnboardingResponse, Error>>,
    issueId: string,
    item: OnboardingChecklist,
    onboardingMainItemId: string,
    milestoneId: string,
    selectedItems: OnboardingChecklist[],
    api: AxiosInstance,
): Promise<any> {
    let selectedIssueIds: string[];
    if (selectedItems.length === 0) {
        selectedIssueIds = [issueId];
    } else {
        selectedIssueIds = selectedItems.map((issue) => issue.id);
    }

    switch (type) {
        case "task_status":
            return saveStatus(
                payload.task_status || OnboardingChecklistStatus.NotStarted,
                api,
                selectedIssueIds,
                userID,
                onboardingMainItemId,
                item,
                updateIssueState,
            );
        case "Assignee":
            return saveAssignee(
                payload.assignee_user_id || "",
                api,
                selectedIssueIds,
                userID,
                onboardingMainItemId,
                item,
                updateIssueState,
            );
        case "enabled":
            return saveEnabled(
                payload.enabled ?? true,
                api,
                selectedIssueIds,
                userID,
                onboardingMainItemId,
                item,
                updateIssueState,
            );
        case "ALL":
            return saveAll(
                payload,
                api,
                selectedIssueIds,
                userID,
                onboardingMainItemId,
                item,
                updateIssueState,
            );
        case "google_sheet_enabled":
            return saveGoogleSheetEnabled(
                payload.google_sheet_enabled ?? false,
                api,
                selectedIssueIds,
                userID,
                onboardingMainItemId,
                item,
                updateIssueState,
            );
        default:
            return Promise.resolve(null);
    }
}

export function saveOnboardingItem(
    issue: Partial<OnboardingChecklist>,
    column: string,
    value: string,
    onboardingMainItemId: string,
    selectedItems: OnboardingChecklist[],
    api: AxiosInstance,
    userID: string,
) {
    switch (column) {
        default:
            return saveAll(
                issue,
                api,
                [issue.id ?? ""],
                userID,
                onboardingMainItemId,
            );
    }
}

export async function addOnboardingItem(
    onboardingItem: OnboardingChecklistPayload,
    parentId: string,
    accountType: string,
    accountProp: Account | Template,
    api: AxiosInstance,
): Promise<string> {
    onboardingItem.onboarding_type = OnboardingChecklistType.Task;
    onboardingItem.parent_id = parentId;
    if (accountType === "Company") {
        onboardingItem.company_id = accountProp.id;
    } else if (accountType === "Customer") {
        onboardingItem.customer_id = accountProp.id;
    } else if (accountType === "Template") {
        onboardingItem.template_id = accountProp.id;
    }

    try {
        const res = await api.post(
            `${URLS.serverUrl}${ContactsAPI.addOnboardingChecklistItem.url}`,
            onboardingItem,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        if (res.status !== 200) {
            return Promise.reject("Failed to add onboarding item");
        }
        return res.data.data?.[0]?.id;
    } catch (err) {
        return Promise.reject("Failed to add onboarding item");
    }
}
