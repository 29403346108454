import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import { getStatusFullIcon } from "@/utilities/methods";
import {
    CaretDownIcon,
    CaretUpIcon,
    QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import { HeaderType } from "./constants";

import { ticketStatuses } from "./constants";
import { Badge } from "@radix-ui/themes";

interface IssuesListHeaderCardProps {
    title: string;
    value: string;
    header_type: HeaderType;
    isExpanded: Map<string, boolean>;
    updateExpanded: (type: string, value: boolean) => void;
    numIssues?: number;
    doNotShowNumIssues?: boolean;
}

export function IssuesListHeaderCard({
    title: label,
    value,
    header_type,
    isExpanded,
    updateExpanded,
    numIssues,
    doNotShowNumIssues
}: IssuesListHeaderCardProps) {
    // TODO: make this based on the header_type, not just the status
    const Icon = getStatusFullIcon(value);

    return (
        <Card className="h-10 flex items-center justify-start px-5 py-2 shadow-none border-none bg-[#545A92] bg-opacity-[0.08] mt-1">
            <CardContent className="flex items-center gap-2 text-[13px] p-0">
                <div
                    className={`${header_type === HeaderType.Status ? "" : "hidden"}`}
                >
                    {Icon ? (
                        <Icon className="ml-0.5 w-3.5 h-3.5" />
                    ) : (
                        <QuestionMarkCircledIcon className="ml-0.5 w-3.5 h-3.5" />
                    )}
                </div>
                {ticketStatuses.find((status) => status.value === label)
                    ?.label ?? label}
                {!doNotShowNumIssues && (numIssues !== undefined ? (
                    <Badge
                        variant="outline"
                        color="gray"
                        className="text-[9px] p-1 py-0"
                    >
                        {numIssues}
                    </Badge>
                ) : (
                    <Badge
                        variant="outline"
                        color="gray"
                        className="text-[9px] p-1 py-2 w-4 h-3"
                    >
                        <div className="w-2 h-2 border-2 border-t-1 border-gray-600 border-dashed rounded-full animate-spin" />
                    </Badge>
                ))}
                <Button
                    type="submit"
                    variant="ghost"
                    onClick={() =>
                        updateExpanded(value, !isExpanded.get(value))
                    }
                    className="p-1 m-0 hover:bg-transparent hover:text-current"
                >
                    {isExpanded.get(value) ? (
                        <CaretDownIcon className="w-3 h-3 -mx-1.5" />
                    ) : (
                        <CaretUpIcon className="w-3 h-3 -mx-1.5" />
                    )}
                </Button>
            </CardContent>
        </Card>
    );
}
