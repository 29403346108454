type URLType = {
    serverUrl: string;
};

export const URLS: URLType = {
    serverUrl:
        process.env.REACT_APP_SERVER_URL ?? "https://dashboard.askassembly.app",
};

export const SERVERS = {
    github: "https://api.github.com/",
};

export const version_zero = "/api/v0/";
export const version_one = "/api/v1/";
export const authenticated_requests = `${version_one}auth/`;
export const API = {
    getAccessCode: `${authenticated_requests}get_access_code`,
    auth: `${authenticated_requests}auth`,
    index: `${authenticated_requests}index`,
    getItemsByOrgID: `${authenticated_requests}get_items_by_orgid`,
    update_cookie: `${authenticated_requests}update_cookie`,
    indexStatus: `${authenticated_requests}index_status`,
    getIntegrations: `${authenticated_requests}get_integrations`,
    getUniqueIntegrations: `${authenticated_requests}get_unique_integrations`,

    getForwardingEmailAddresses: `${authenticated_requests}get_forwarding_email_addresses`,
    saveForwardingEmailAddresses: `${authenticated_requests}save_forwarding_email_addresses`,

    authenticateIntegration: `${authenticated_requests}authenticate_integration`,
    indexIntegration: `${authenticated_requests}index_integration`,
    deleteIntegration: `${authenticated_requests}delete_integration`,

    getScopes: `${authenticated_requests}get_scopes`,
    getScopesPaginated: `${authenticated_requests}get_scopes_paginated`,
    getIssueTypes: `${authenticated_requests}get_issue_types`,
    queriesWithPagination: `${authenticated_requests}queries_paginated`,
    queriesByTeam: `${authenticated_requests}queries/team`,
    queriesByAccount: `${authenticated_requests}queries/account`,
    interactionsWithPagination: `${authenticated_requests}interactions`,
    interactionCounts: `${authenticated_requests}interaction_counts`,
    getLabels: `${authenticated_requests}labels`,

    analytics: `${authenticated_requests}analytics`,
    getInteractionsAnalytics: `${authenticated_requests}analytics/interactions`,
    getResponseTime: `${authenticated_requests}analytics/response`,
    getFirstResponseTime: `${authenticated_requests}analytics/first_response`,
    getResolutionTime: `${authenticated_requests}analytics/resolution`,
    getSLATime: `${authenticated_requests}analytics/sla`,
    getSLABreachCount: `${authenticated_requests}analytics/sla_breach_count`,

    saveBotSettingsV2: `${authenticated_requests}bot_settings`,
    getBotSettingsV2: `${authenticated_requests}bot_settings`,
    initializeBot: `${authenticated_requests}bot_settings`,
    getAllSourceUsers: `${authenticated_requests}get_all_source_users`, // source specific users

    saveSLA: `${authenticated_requests}sla`,
    getSLAs: `${authenticated_requests}sla`,

    saveBusinessHours: `${authenticated_requests}business_hours/save`,
    getBusinessHours: `${authenticated_requests}business_hours/get`,
    getTimeLeftWithinBusinessHours: `${authenticated_requests}business_hours/time_left`,

    getWorkflows: `${authenticated_requests}workflows`,
    getWorkflow: `${authenticated_requests}workflows`,
    saveWorkflow: `${authenticated_requests}workflows`,
    updateWorkflow: `${authenticated_requests}workflows`,
    deleteWorkflow: `${authenticated_requests}workflows`,

    getWorkflowsNew: `${authenticated_requests}workflows/new`,
    saveWorkflowNew: `${authenticated_requests}workflows/new`,
    updateWorkflowNew: `${authenticated_requests}workflows/new`,
    deleteWorkflowNew: `${authenticated_requests}workflows/new`,
    workflowsForIssues: `${authenticated_requests}workflows/new/issue`,

    saveNotificationOptions: `${authenticated_requests}notifications_options`,
    getNotificationOptions: `${authenticated_requests}notifications_options`,

    saveNotificationSettings: `${authenticated_requests}notifications`,
    getNotifications: `${authenticated_requests}notifications`,
    initializeNotification: `${authenticated_requests}notifications`,

    getSystemNotifications: `${authenticated_requests}system_notifications`,
    createOrUpdateSystemNotifications: `${authenticated_requests}system_notifications`,

    saveOmnichannelSettings: `${authenticated_requests}omnichannel`,
    getOmnichannels: `${authenticated_requests}omnichannel`,

    saveBotSettings: `${authenticated_requests}save_bot_settings`,
    getBotSettings: `${authenticated_requests}get_bot_settings`,
    whoAmI: `${authenticated_requests}whoami`,
    search: `${authenticated_requests}search_natural`,
    describe: `${authenticated_requests}describe`,
    updateConnection: `${authenticated_requests}update_connection`,
    getConnections: `${authenticated_requests}get_connections`,
    deleteConnection: `${authenticated_requests}delete_connection`,

    getCategories: `${authenticated_requests}category`,
    addCategory: `${authenticated_requests}category`,
    editCategory: `${authenticated_requests}category`,
    deleteCategory: `${authenticated_requests}category`,
    getCategory: `${authenticated_requests}category`, // Not used

    getTopics: `${authenticated_requests}get_topics`,
    addTopic: `${authenticated_requests}add_topic`,
    editTopic: `${authenticated_requests}edit_topic`,
    deleteTopic: `${authenticated_requests}delete_topic`,
    getTopicsPref: `${authenticated_requests}topics_preferences`,
    saveTopicsPref: `${authenticated_requests}save_topics_preferences`,
    saveTicket: `${authenticated_requests}ticket`,
    getTicketInfo: `${authenticated_requests}ticket`,

    determineTopTopics: `${authenticated_requests}insights/top_topics`,
    determineTopInsights: `${authenticated_requests}insights/top_insights`,
    saveInsight: `${authenticated_requests}insights/save`,
    getInsightsWithPagination: `${authenticated_requests}insights`,
    getInsightsByTeam: `${authenticated_requests}insights/team`,
    getInsightInfo: `${authenticated_requests}insights`,
    similarIssuesForInsight: `${authenticated_requests}insights/similar_issues`,
    genTitleDescForInsight: `${authenticated_requests}insights/gen_title_desc`,

    getApiKeys: `${authenticated_requests}get_api_keys`,
    createTicket: `${authenticated_requests}ticket/create`,
    createTicketFromUI: `${authenticated_requests}ticket/create_ui`,
    createEscalation: `${authenticated_requests}ticket/escalate`,
    similarIssuesForTicket: `${authenticated_requests}ticket/similar_issues`,
    createHistory: `${authenticated_requests}ticket/history`,
    getHistoryRecords: `${authenticated_requests}ticket/get_history_records`,
    publishMessage: `${authenticated_requests}ticket/publish_message`,
    publishExternalIssue: `${authenticated_requests}ticket/publish`,
    getFile: `${authenticated_requests}ticket/get_file`,
    getOrg: `${authenticated_requests}get_org`,
    getUser: `${authenticated_requests}get_user`,
    getAllUsers: `${authenticated_requests}get_all_users`,
    changeUserRole: `${authenticated_requests}user_role`,
    changeUserProperties: `${authenticated_requests}user_properties`,
    announcement: `${authenticated_requests}announcement`,
    sendAnnouncement: `${authenticated_requests}send_announcement`,
    sendAnnouncementWithFiles: `${authenticated_requests}send_announcement_with_files`,
    getAnnouncements: `${authenticated_requests}get_announcements`,
    createHistoricalTickets: `${authenticated_requests}historical_tickets`,
    getAIResponse: `${authenticated_requests}generate_ai_response`,

    saveCustomerGroup: `${authenticated_requests}customer_group`,
    getCustomerGroups: `${authenticated_requests}get_customer_groups`,
    getCustomerGroup: `${authenticated_requests}get_customer_group`,

    getMostRecentTicket: `${authenticated_requests}get_next_ticket`,

    exportTickets: `${authenticated_requests}ticket/export`,
    getTimerEnabled: `${authenticated_requests}timer`,
    saveTimerEnabled: `${authenticated_requests}timer`,

    getTicketTimer: `${authenticated_requests}timer/status`,
    addTicketTimerEvent: `${authenticated_requests}timer/activity`,

    timerAnalytics: `${authenticated_requests}timer/analytics`,
    timerAnalyticsExport: `${authenticated_requests}timer/analytics/export`,

    createAPIKey: `${authenticated_requests}keys`,
    listAPIKey: `${authenticated_requests}keys`,
    deleteAPIKey: `${authenticated_requests}keys`,

    getTemplates: `${authenticated_requests}templates`,
    getPersonalTemplates: `${authenticated_requests}templates/personal`,
    addTemplate: `${authenticated_requests}templates`,
    editTemplate: `${authenticated_requests}templates`,
    deleteTemplate: `${authenticated_requests}templates`,

    createNangoSessionToken: `${authenticated_requests}nango/session_token`,
    getNangoSessionToken: `${authenticated_requests}nango/get_session_token`,
    createNangoIntegration: `${authenticated_requests}nango/create_integration`,
};

export const APIWithMethod = {
    listViews: [`${authenticated_requests}views`, "GET"],
    getView: [`${authenticated_requests}views`, "GET"],
    createView: [`${authenticated_requests}views`, "PUT"],
    deleteView: [`${authenticated_requests}views`, "DELETE"],
};

export const TeamsAPI = {
    createTeam: [`${authenticated_requests}teams`, "PUT"],
    updateTeam: [`${authenticated_requests}teams`, "PATCH"],
    deleteTeam: [`${authenticated_requests}teams`, "DELETE"],
    listTeams: [`${authenticated_requests}teams`, "GET"],
    listMemberTeams: [`${authenticated_requests}teams/all`, "GET"],
    getTeam: [`${authenticated_requests}teams`, "GET"],
    addTeamSource: [`${authenticated_requests}teams/add_source`, "PUT"],
    editTeamSource: [`${authenticated_requests}teams/edit_source`, "PATCH"],
    deleteTeamSource: [
        `${authenticated_requests}teams/delete_source`,
        "DELETE",
    ],
    getTeamSources: [`${authenticated_requests}teams/get_sources`, "GET"],
};

export interface APIWithMethodPair {
    url: string;
    method: string;
}

export const ContactsAPI: Record<string, APIWithMethodPair> = {
    listCustomers: {
        url: `${authenticated_requests}customers`,
        method: "GET",
    },
    getCustomer: { url: `${authenticated_requests}customers`, method: "GET" },
    createCustomer: {
        url: `${authenticated_requests}customers`,
        method: "PUT",
    },
    updateCustomer: {
        url: `${authenticated_requests}customers`,
        method: "PATCH",
    },
    deleteCustomer: {
        url: `${authenticated_requests}customers`,
        method: "DELETE",
    },
    mergeCustomers: {
        url: `${authenticated_requests}customers/merge`,
        method: "POST",
    },

    importCompanies: {
        url: `${authenticated_requests}companies/import`,
        method: "POST",
    },
    listCompanies: { url: `${authenticated_requests}companies`, method: "GET" },
    getCompany: { url: `${authenticated_requests}companies`, method: "GET" },
    createCompany: { url: `${authenticated_requests}companies`, method: "PUT" },
    editCompany: { url: `${authenticated_requests}companies`, method: "PATCH" },
    editCompanyNotes: {
        url: `${authenticated_requests}companies/edit_notes`,
        method: "POST",
    },
    deleteCompany: {
        url: `${authenticated_requests}companies`,
        method: "DELETE",
    },

    listAccountsCompanies: {
        url: `${authenticated_requests}accounts/companies`,
        method: "GET",
    },
    listAccountsContacts: {
        url: `${authenticated_requests}accounts/contacts`,
        method: "GET",
    },
    getAccountsLabels: {
        url: `${authenticated_requests}accounts/labels`,
        method: "GET",
    },
    createAccountsLabel: {
        url: `${authenticated_requests}accounts/labels`,
        method: "PUT",
    },
    updateAccountsLabel: {
        url: `${authenticated_requests}accounts/labels`,
        method: "PATCH",
    },
    deleteAccountsLabel: {
        url: `${authenticated_requests}accounts/labels`,
        method: "DELETE",
    },

    getProperties: {
        url: `${authenticated_requests}crm/properties`,
        method: "GET",
    },
    getSchemas: {
        url: `${authenticated_requests}crm/schemas`,
        method: "GET",
    },
    getSavedSchema: {
        url: `${authenticated_requests}crm/get_saved_schema`,
        method: "GET",
    },
    getOnboardingChecklist: {
        url: `${authenticated_requests}onboarding`,
        method: "GET",
    },
    getOnboardingChecklistHistory: {
        url: `${authenticated_requests}onboarding/history`,
        method: "GET",
    },
    addMilestone: {
        url: `${authenticated_requests}onboarding/milestone`,
        method: "POST",
    },
    addMilestoneFromTemplate: {
        url: `${authenticated_requests}onboarding/milestone_from_template`,
        method: "POST",
    },
    addOnboardingChecklistItem: {
        url: `${authenticated_requests}onboarding/create`,
        method: "POST",
    },
    deleteOnboardingChecklistItem: {
        url: `${authenticated_requests}onboarding/delete`,
        method: "POST",
    },
    saveOnboardingChecklist: {
        url: `${authenticated_requests}onboarding`,
        method: "PATCH",
    },
    saveOnboardingChecklistGoogleSheetStatus: {
        url: `${authenticated_requests}onboarding/google_status`,
        method: "PATCH",
    },
    updateOnboardingGoogleLink: {
        url: `${authenticated_requests}onboarding/google_link`,
        method: "POST",
    },
    listOnboardingChecklist: {
        url: `${authenticated_requests}onboarding/list`,
        method: "POST",
    },
    listOnboardingChecklistCounts: {
        url: `${authenticated_requests}onboarding/list_counts`,
        method: "POST",
    },
    getCTAs: {
        url: `${authenticated_requests}companies/ctas`,
        method: "GET",
    },
    getNews: {
        url: `${authenticated_requests}companies/news`,
        method: "GET",
    },
    getIFrames: {
        url: `${authenticated_requests}companies/iframes`,
        method: "GET",
    },
    addIFrames: {
        url: `${authenticated_requests}companies/iframes`,
        method: "PUT",
    },
};

export const EXTERNAL_LINKS = {
    privacy_policy: "https://www.askassembly.app/privacy-policy",
    contact: "https://www.askassembly.app/contact",
};

// cookie management

type CookieKeys = {
    [key: string]: string;
};

export const ASSEMBLY_COOKIES: CookieKeys = {
    logged_in: "logged_in",
    indexed: "indexed",
    login_client: "client",
    admin: "admin",
    jira_url: "jira_url",
    jira_reindex: "jira_reindex",
    slack_bot_enabled: "slack_bot_enabled",
    linear_reauth: "linear_reauth",
};

// page states
export const pages = {
    login: "login",
    index: "index",
    search: "search",
};

export const login_clients = {
    GITHUB_CLIENT: "GitHub",
    GITLAB_CLIENT: "GitLab",
};

export enum loadingTypes {
    loading = 0,
    loaded = 1,
    error = 2,
    none = 3,
}
