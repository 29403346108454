import { useToast } from "@/component/shadcn/ui/use-toast";
import { ContactsAPI } from "@/constant";
import { URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { AccountsLabel, Teams } from "@/interfaces/serverData";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { AccountsTagsDataTable } from "./DataTable/accounts-tags-data-table";
import { generateAccountsTagsColumns } from "./DataTable/columns";

interface AccountsTagsProps {
    data: AccountsLabel[];
    teams: Teams[];
    rowState: Map<string, AccountsLabel>;
    updateRowState: (id: string, newState: Partial<AccountsLabel>) => void;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<AccountsLabel[], Error>>;
    isLoading: boolean;
    isError: boolean;
    currentTeam?: Teams;
}

export const AccountsTags: React.FC<AccountsTagsProps> = ({
    data,
    teams,
    rowState,
    updateRowState,
    refetch,
    isLoading,
    isError,
    currentTeam,
}) => {
    console.log("row state is", rowState);
    const { id } = useParams(); // team ID
    const api = useApi();

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";
    const { toast } = useToast();

    const saveTagColor = (id: string, rowStateInfo: Partial<AccountsLabel>) => {
        const { url } = ContactsAPI.updateAccountsLabel;
        api.patch(
            `${URLS.serverUrl}${url}/${id}`,
            { color: rowStateInfo.color },
            {
                headers: { "Content-Type": "application/json" },
            },
        )
            .then((res) => {
                if (res.status === 200) {
                    refetch();
                    toast({
                        title: "Updated Account Label!",
                        description:
                            "Your account label has been updated successfully.",
                    });
                }
            })
            .catch((res) => {
                console.log("failed to edit tag");
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    };

    return (
        <div>
            <Box className="flex flex-col gap-4">
                {isLoading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {isError && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {!isLoading && !isError && (
                    <div className="flex flex-col items-end gap-2">
                        <AccountsTagsDataTable
                            data={data}
                            columns={generateAccountsTagsColumns(
                                rowState,
                                teams,
                                saveTagColor,
                                refetch,
                                currentTeam,
                                id,
                            )}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
};
