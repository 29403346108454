import { Avatar } from "@/Ticket/Avatar";
import type { Query } from "@/interfaces/serverData";
import { Suspense } from "react";

interface CardAvatarProps {
    issueState: Query
}

export function CardAvatar({
    issueState
}: CardAvatarProps) {
    const hasCompanyAvatar = issueState.company_info?.avatar && issueState.company_info?.avatar !== ""
    const hasCustomerAvatar = issueState.customer_info?.avatar &&
        issueState.customer_info?.avatar !== ""
    const hasBothAvatars = hasCompanyAvatar && hasCustomerAvatar
    const containerClasses = "lb-root pr-2 w-12 h-12 flex items-center justify-center";

    if (hasBothAvatars) { // Show company and customer photo
        return (
            <div className={containerClasses}>
                <div className="lb-comment-details relative w-9 h-9">
                    <Suspense
                        fallback={
                            <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                        }
                    >
                        {issueState.company_info && <Avatar
                            user={issueState.company_info}
                            className="w-8 h-8 object-cover"
                        />}
                    </Suspense>
                    <Suspense
                        fallback={
                            <div className="relative aspect-square w-6 flex-none animate-pulse rounded-full bg-gray-100" />
                        }
                    >
                        <Avatar
                            user={issueState.customer_info}
                            className="absolute bottom-0 right-0 w-5 h-5 object-cover"
                            style={{ transform: "translate(25%, 25%)" }}
                        />
                    </Suspense>
                </div>
            </div>
        );
    } else if (hasCompanyAvatar && !hasCustomerAvatar) { // show company photo
        return (
            <div className={containerClasses}>
                <div className="lb-comment-details relative w-9 h-9">
                    <Suspense
                        fallback={
                            <div className="relative aspect-square w-10 flex-none animate-pulse rounded-full bg-gray-100" />
                        }
                    >
                        <Avatar user={issueState.company_info} className="w-8 h-8 object-cover" />
                    </Suspense>
                </div>
            </div>
        );
    } else if (!hasCompanyAvatar && hasCustomerAvatar) { // show customer photo
        return (
            <div className={containerClasses}>
                <div className="lb-comment-details relative w-9 h-9">
                    <Suspense
                        fallback={
                            <div className="relative aspect-square w-10 flex-none animate-pulse rounded-full bg-gray-100" />
                        }
                    >
                        <Avatar user={issueState.customer_info} className="w-8 h-8 object-cover" />
                    </Suspense>
                </div>
            </div>
        );
    } else {
        return (
            <div className={containerClasses}>
                <div className="lb-comment-details relative w-9 h-9">
                    <Suspense
                        fallback={
                            <div className="relative aspect-square w-10 flex-none animate-pulse rounded-full bg-gray-100" />
                        }
                    >
                        <Avatar user={issueState.user_info} className="w-8 h-8 object-cover" />
                    </Suspense>
                </div>
            </div>
        );
    }

};
