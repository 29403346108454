import { Callout, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { loadingTypes } from "../../../constant";
import { useApi } from "../../../interfaces/api";

import "../toast_styles.css";
import { FancyMultiSelect } from "@/component/MultiSelect";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from "@/component/shadcn/ui/separator";
import { Skeleton } from "@/component/shadcn/ui/skeleton";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { API, URLS } from "../../../constant";
import {
    type GetConnectionsResponse,
    type IssueType,
    type ObjectType,
    type ScopeResponse,
    integrationLabelMap,
} from "../../../interfaces/serverData";

interface ConnectorProps {
    triggerElement: React.ReactNode;
    saveSettings: (
        connectionInput: string,
        connectionMetadata: ScopeResponse[],
        connectionPriority: string | undefined,
        connectionIssueType: IssueType | undefined,
        connectionObjectType?: ObjectType,
        existingConnectionID?: string,
        intercomSyncOnly?: boolean,
        selectedChannels?: ScopeResponse[],
    ) => void;
    enabledIntegrations: string[];
    botType: string;
    description: string;
    existingConnection?: GetConnectionsResponse;
    channels?: ScopeResponse[];
    selectedChannels?: ScopeResponse[];
    setSelectedChannels?: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    title?: string;
}

const Shortcuts = (props: ConnectorProps) => {
    const api = useApi();

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    ); // 0: loading, 1: loaded, 2: error

    const [loadingScopesDropdown, setLoadingScopesDropdown] = useState<number>(
        loadingTypes.loading,
    );
    const [loadingIssueTypesDropdown, setLoadingIssueTypesDropdown] =
        useState<number>(loadingTypes.loading);

    const [integrations, setIntegrations] = useState<string[]>([]);
    const [selectedIntegration, setSelectedIntegration] = useState<string>(
        props.existingConnection?.connected_integrations?.connection ??
            integrations[0],
    );

    const [intercomSyncOnly, setIntercomSyncOnly] = useState<boolean>(
        props.existingConnection?.connected_integrations?.intercom_sync_only ??
            false,
    );

    const [modalOpen, setModalOpen] = useState(false);

    // TODO: don't allow saving duplicate of the same reaction shortcut
    function save(existingConnectionID?: string) {
        setModalOpen(false);
        if (props.enabledIntegrations.includes(selectedIntegration)) {
            switch (selectedIntegration) {
                case "Intercom":
                    props.saveSettings(
                        selectedIntegration,
                        [selectedSourceMetadata],
                        selectedPriority,
                        selectedIssueType,
                        selectedObjectType,
                        existingConnectionID,
                        intercomSyncOnly,
                        props.selectedChannels,
                    );
                    break;
                case "Jira":
                    props.saveSettings(
                        selectedIntegration,
                        [selectedSourceMetadata],
                        "",
                        selectedIssueType,
                        undefined,
                        existingConnectionID,
                        false,
                    );
                    break;
                default:
                    props.saveSettings(
                        selectedIntegration,
                        [selectedSourceMetadata],
                        "",
                        undefined,
                        undefined,
                        existingConnectionID,
                        false,
                    );
                    break; // Add break for the default case as well
            }
        } else {
            console.log("errored");
        }
    }

    const [selectedSourceMetadata, setSelectedSourceMetadata] =
        useState<ScopeResponse>(
            props.existingConnection?.connected_integrations?.scopes?.[0] ?? {
                key: "",
                name: "",
            },
        );
    function updateSelectedSourceMetadata(value: string) {
        const selectedItem: ScopeResponse = JSON.parse(value);
        setSelectedSourceMetadata(selectedItem);
    }
    const [selectedPriority, setSelectedPriority] = useState<string>(
        props.existingConnection?.connected_integrations?.priority ?? "None",
    );
    const [selectedIssueType, setSelectedIssueType] = useState<IssueType>(
        props.existingConnection?.connected_integrations?.issue_types?.[0] ?? {
            id: "",
            name: "",
        },
    );

    function updateSelectedIssueType(value: string) {
        const selectedItem: IssueType = JSON.parse(value);
        setSelectedIssueType(selectedItem);
    }

    const [selectedObjectType, setSelectedObjectType] = useState<ObjectType>(
        props.existingConnection?.connected_integrations?.object_type ??
            "Conversation",
    );
    function updateSelectedObjectType(value: ObjectType) {
        const selectedItem: ObjectType = value;
        setSelectedObjectType(selectedItem);
    }

    const loremIpsum = "Lorem ipsum dolor sit amet, consectetur";

    const [sources, setSources] = useState<ScopeResponse[]>([]);
    const [issueTypes, setIssueTypes] = useState<IssueType[]>([]);
    const [priorities, setPriorities] = useState<string[]>([
        "None", // purple
        "Low", // green
        "Normal", // yellow
        "High", // orange
        "Urgent", // red
    ]);

    useEffect(() => {
        if (!modalOpen) return;
        setIntegrations(props.enabledIntegrations);
        if (
            (!selectedIntegration || selectedIntegration === "") &&
            props.enabledIntegrations.length > 0
        ) {
            setSelectedIntegration(props.enabledIntegrations[0]);
        }

        setLoadingState(loadingTypes.loaded);
    }, [props.enabledIntegrations, modalOpen]);

    useEffect(() => {
        if (
            [
                "Jira",
                "Linear",
                "GitHubTicket",
                "GithubDiscussion",
                "Intercom",
            ].includes(selectedIntegration)
        ) {
            setLoadingScopesDropdown(loadingTypes.loading);
            if (["Jira", "Intercom"].includes(selectedIntegration)) {
                setLoadingIssueTypesDropdown(loadingTypes.loading);
            }
            const requestData = {
                type: selectedIntegration,
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setSources(dataItems);
                    if (
                        dataItems.length > 0 &&
                        props.existingConnection?.connected_integrations
                            ?.connection !== selectedIntegration
                    ) {
                        setSelectedSourceMetadata(dataItems[0]);
                    }
                    setLoadingScopesDropdown(loadingTypes.loaded);
                })
                .catch((res) => {
                    console.error("Error in grabbing scopes");
                    setLoadingScopesDropdown(loadingTypes.error);
                });
        }
    }, [selectedIntegration, api]);

    useEffect(() => {
        if (
            ["Jira", "Intercom"].includes(selectedIntegration) &&
            selectedSourceMetadata.name !== "" &&
            loadingScopesDropdown === loadingTypes.loaded
        ) {
            setLoadingIssueTypesDropdown(loadingTypes.loading);
            const requestData = {
                type: selectedIntegration,
                project_key: selectedSourceMetadata.key,
            };
            api.post(URLS.serverUrl + API.getIssueTypes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: IssueType[] = res.data.data;
                    setIssueTypes(dataItems);
                    if (
                        dataItems.length > 0 &&
                        props.existingConnection?.connected_integrations
                            ?.connection !== selectedIntegration
                    ) {
                        setSelectedIssueType(dataItems[0]);
                    }
                    setLoadingIssueTypesDropdown(loadingTypes.loaded);
                })
                .catch((res) => {
                    console.error("Error in grabbing issue types");
                    setLoadingIssueTypesDropdown(loadingTypes.error);
                });
        }
    }, [selectedSourceMetadata, api, loadingScopesDropdown]);
    return (
        <div>
            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger>{props.triggerElement}</DialogTrigger>
                <DialogContent
                    style={{
                        width: "fit-content",
                        maxWidth: "450px",
                        paddingBottom: "30px",
                        paddingRight: "35px",
                        paddingLeft: "35px",
                    }}
                >
                    <div className="flex flex-col gap-1">
                        <div className="flex flex-col">
                            <div className="flex justify-between items-center">
                                <DialogTitle className="text-[16px] font-medium text-[#333333]">
                                    {props.title
                                        ? props.title
                                        : props.existingConnection
                                          ? `Update ${integrationLabelMap.get(props.existingConnection?.connected_integrations?.connection) ?? props.existingConnection?.connected_integrations?.connection} Shortcut`
                                          : `Create Shortcuts from ${props.botType}`}
                                </DialogTitle>
                            </div>
                            <Separator className="w-full my-4" />
                        </div>

                        {loadingState === loadingTypes.loading && (
                            <Card style={{ flex: 1, padding: "20px" }}>
                                <Skeleton>
                                    <Text>{loremIpsum}</Text>
                                </Skeleton>
                            </Card>
                        )}
                        {loadingState === loadingTypes.error && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                        {loadingState === loadingTypes.loaded && (
                            <div className="flex flex-col gap-4 items-start">
                                <span className="mb-2 text-[14px] text-[#333333] font-normal">
                                    {props.description}
                                </span>

                                <div className="flex items-center gap-2">
                                    <Text className="text-[14px] font-medium text-[#333333] min-w-[120px]">
                                        Integration:
                                    </Text>

                                    {props.enabledIntegrations.length > 0 ? (
                                        <Select
                                            onValueChange={
                                                setSelectedIntegration
                                            }
                                            defaultValue={selectedIntegration}
                                        >
                                            <SelectTrigger className="box-border border py-4 px-2.5">
                                                <SelectValue placeholder="Select an integration" />
                                            </SelectTrigger>
                                            <SelectContent className="min-w-[180px]">
                                                {integrations.map(
                                                    (integration) => {
                                                        return (
                                                            <SelectItem
                                                                key={
                                                                    integration
                                                                }
                                                                value={
                                                                    integration
                                                                }
                                                            >
                                                                {integrationLabelMap.get(
                                                                    integration,
                                                                ) ??
                                                                    integration}
                                                            </SelectItem>
                                                        );
                                                    },
                                                )}
                                            </SelectContent>
                                        </Select>
                                    ) : (
                                        <Text color="red" size="1">
                                            Oops! Looks like you'll need to set
                                            up your GitHub, Intercom, Jira, or
                                            Linear integration first to use the
                                            bot.
                                        </Text>
                                    )}
                                </div>

                                <div className="flex items-center gap-2">
                                    <Text className="text-[14px] font-medium text-[#333333] min-w-[120px]">
                                        Destination:
                                    </Text>
                                    {loadingScopesDropdown ===
                                        loadingTypes.loading && (
                                        <Skeleton className="bg-white">
                                            <Text className="text-gray-300 bg-gray-300">
                                                {loremIpsum}
                                            </Text>
                                        </Skeleton>
                                    )}
                                    {loadingScopesDropdown ===
                                        loadingTypes.loaded && (
                                        <Select
                                            onValueChange={
                                                updateSelectedSourceMetadata
                                            }
                                            defaultValue={JSON.stringify(
                                                selectedSourceMetadata,
                                            )}
                                        >
                                            <SelectTrigger className="box-border py-4 px-2.5">
                                                <SelectValue placeholder="Select a source" />
                                            </SelectTrigger>
                                            <SelectContent className="min-w-[180px]">
                                                {sources.map((channel) => {
                                                    const valueItem =
                                                        channel.name;
                                                    return (
                                                        <SelectItem
                                                            key={valueItem}
                                                            value={JSON.stringify(
                                                                channel,
                                                            )}
                                                        >
                                                            {valueItem}
                                                        </SelectItem>
                                                    );
                                                })}
                                            </SelectContent>
                                        </Select>
                                    )}
                                    {loadingScopesDropdown ===
                                        loadingTypes.error && (
                                        <Skeleton className="bg-white">
                                            <Select />
                                        </Skeleton>
                                    )}
                                </div>
                                {selectedIntegration === "Intercom" && (
                                    <div className="flex items-center gap-2">
                                        <Text className="text-[14px] font-medium text-[#333333] min-w-[120px]">
                                            Object Type:
                                        </Text>
                                        <Select
                                            onValueChange={
                                                updateSelectedObjectType
                                            }
                                            defaultValue={selectedObjectType}
                                        >
                                            <SelectTrigger className="box-border py-4 px-2.5">
                                                <SelectValue placeholder="Select a source" />
                                            </SelectTrigger>
                                            <SelectContent className="min-w-[180px]">
                                                <SelectItem value="Ticket">
                                                    Ticket
                                                </SelectItem>
                                                <SelectItem value="Conversation">
                                                    Conversation
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}
                                {(selectedIntegration === "Jira" ||
                                    (selectedIntegration === "Intercom" &&
                                        selectedObjectType === "Ticket")) && (
                                    <div className="flex items-center gap-2">
                                        <Text className="text-[14px] font-medium text-[#333333] min-w-[120px]">
                                            Ticket Type:
                                        </Text>
                                        {loadingIssueTypesDropdown ===
                                            loadingTypes.loading && (
                                            <Skeleton className="bg-white">
                                                <Text className="text-gray-300 bg-gray-300">
                                                    {loremIpsum}
                                                </Text>
                                            </Skeleton>
                                        )}
                                        {loadingIssueTypesDropdown ===
                                            loadingTypes.loaded && (
                                            <Select
                                                onValueChange={
                                                    updateSelectedIssueType
                                                }
                                                defaultValue={JSON.stringify(
                                                    selectedIssueType,
                                                )}
                                            >
                                                <SelectTrigger className="box-border py-4 px-2.5">
                                                    <SelectValue placeholder="Select a source" />
                                                </SelectTrigger>
                                                <SelectContent className="min-w-[180px]">
                                                    {issueTypes.map(
                                                        (issueType) => {
                                                            const valueItem =
                                                                issueType.name;
                                                            return (
                                                                <SelectItem
                                                                    key={
                                                                        valueItem
                                                                    }
                                                                    value={JSON.stringify(
                                                                        issueType,
                                                                    )}
                                                                >
                                                                    {valueItem}
                                                                </SelectItem>
                                                            );
                                                        },
                                                    )}
                                                </SelectContent>
                                            </Select>
                                        )}
                                        {loadingIssueTypesDropdown ===
                                            loadingTypes.error && (
                                            <Skeleton className="bg-white">
                                                <Select />
                                            </Skeleton>
                                        )}
                                    </div>
                                )}
                                {selectedIntegration === "Intercom" && (
                                    <div className="flex items-center gap-2">
                                        <div className="flex items-center gap-1 min-w-[120px]">
                                            <Text className="text-[14px] font-medium text-[#333333]">
                                                Priority:
                                            </Text>
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0 text-destructive hover:bg-sidebar" />
                                                    </TooltipTrigger>
                                                    <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col max-w-[250px]">
                                                        Ticket Type must have a
                                                        valid Priority attribute
                                                        if not selecting None.
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                        <Select
                                            onValueChange={setSelectedPriority}
                                            defaultValue={selectedPriority}
                                        >
                                            <SelectTrigger className="box-border py-4 px-2.5">
                                                <SelectValue placeholder="Select a source" />
                                            </SelectTrigger>
                                            <SelectContent className="min-w-[180px]">
                                                {priorities.map((priority) => {
                                                    return (
                                                        <SelectItem
                                                            key={priority}
                                                            value={priority}
                                                        >
                                                            {priority}
                                                        </SelectItem>
                                                    );
                                                })}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}

                                {props.channels &&
                                    props.selectedChannels &&
                                    props.setSelectedChannels && (
                                        <div className="flex items-start justify-between pt-4">
                                            <div className="min-w-[400px]">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex items-center gap-1">
                                                        <span>
                                                            Which channel should
                                                            we sync
                                                            conversations from?
                                                        </span>
                                                        <Tooltip>
                                                            <TooltipTrigger>
                                                                <InfoCircledIcon className="w-4 h-4" />
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                This list is
                                                                sourced from the
                                                                Customer
                                                                Channels
                                                                Selected
                                                                section. Add it
                                                                there first!
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </div>
                                                    <FancyMultiSelect
                                                        selectedChannels={
                                                            props.selectedChannels
                                                        }
                                                        setSelectedChannels={
                                                            props.setSelectedChannels
                                                        }
                                                        options={props.channels}
                                                        placeholder="Select Channels..."
                                                        selectAllOption={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <Button
                                    style={{
                                        width: "auto",
                                        alignSelf: "end",
                                    }}
                                    onClick={() =>
                                        save(props.existingConnection?.id)
                                    }
                                    disabled={
                                        selectedIntegration?.includes(
                                            "Intercom",
                                        )
                                            ? loadingIssueTypesDropdown ===
                                              loadingTypes.error
                                            : loadingScopesDropdown ===
                                              loadingTypes.error
                                    }
                                >
                                    {props.enabledIntegrations.length > 0
                                        ? "Add"
                                        : "Go to Manage Integrations"}
                                </Button>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Shortcuts;
