import { Checkbox } from "@/component/shadcn/ui/checkbox";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import type { Account, GetUserResponse } from "@/interfaces/serverData";
import {
    assigneeBadge,
    getBadgeForAccountsLabel,
    getBadgeForAccountsTag,
    healthScoreBadge,
    lastInteractionBadge,
} from "@/utilities/methods";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import { ImageIcon } from "lucide-react";
import { DataTableColumnHeader } from "../../../WorkspacePreferences/DataTable/data-table-column-header";
import { queryToLabel } from "./constants";

export function generateCustomerColumms(
    rowState: Map<string, Account>,
    users: GetUserResponse[],
): ColumnDef<Account>[] {
    const columns: ColumnDef<Account>[] = [
        {
            accessorKey: "select",
            header: ({ table }) => {
                const isAllSelected = table.getIsAllRowsSelected();
                const isSomeSelected = table.getIsSomeRowsSelected(); // Some rows selected, not all

                return (
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div className="flex items-center justify-center">
                                    <Checkbox
                                        checked={
                                            isAllSelected || isSomeSelected
                                        }
                                        onCheckedChange={(checked) => {
                                            // Handle toggling all rows selection based on the checkbox state
                                            if (checked) {
                                                table.toggleAllRowsSelected(
                                                    true,
                                                ); // Select all rows
                                            } else {
                                                table.toggleAllRowsSelected(
                                                    false,
                                                ); // Deselect all rows
                                            }
                                        }}
                                        className={`${isAllSelected || isSomeSelected
                                            ? "opacity-100"
                                            : "opacity-0"
                                            } hover:opacity-100 hover:shadow-[0_0_10px_4px] hover:shadow-iris5 lb-comment-details data-[state=checked]:bg-[#5e6ad2] data-[state=checked]:text-[#eceefb] outline-1 outline hover:outline-iris10 outline-[#eceefb] cursor-pointer`} // You can customize the appearance as needed
                                    />
                                </div>
                            </TooltipTrigger>
                            <TooltipContent className="bg-[#5B5BD6]">
                                {isAllSelected || isSomeSelected
                                    ? "Deselect All"
                                    : "Select All"}
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                );
            },
            cell: ({ row, column }) => {
                // return <div className="hidden" />
                return (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={row.getToggleSelectedHandler()}
                        onClick={(event) => {
                            event.stopPropagation(); // Stop click event from bubbling up to the row
                        }}
                    />
                );
            },
            enableSorting: false,
            size: 0,
            enableHiding: false,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.name}
                    className="mr-2"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex items-center gap-2 p-0.5 mr-1">
                        {row.original.image_url !== "" &&
                            row.original.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-5 h-5 flex-shrink-0">
                                <img
                                    className="lb-avatar-image"
                                    src={row.original.image_url}
                                    alt={row.original.name}
                                />

                                <span>
                                    {row.original.name ?? row.original.domain}
                                </span>
                            </div>
                        ) : (
                            <div className="lb-avatar rounded w-6 h-6 flex-shrink-0">
                                <ImageIcon className="w-5 h-5 mx-1" />
                            </div>
                        )}
                        {row.original.name !== undefined &&
                            row.original.name.trim() !== "" &&
                            row.original.name.trim() !== "-" &&
                            row.original.name.trim() !== "(no name)" ? (
                            <p className="text-[13px] pl-1 font-semibold">
                                {row.original.name}
                            </p>
                        ) : (
                            <p className="text-[13px] pl-1 font-semibold">
                                {row.original.domain}
                            </p>
                        )}
                        {row.original.health_score !== undefined &&
                            row.original.health_score !== null &&
                            healthScoreBadge(row.original.health_score, false)}
                    </div>
                );
            },

            filterFn: (row, id, value) => {
                // Return true to include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const query = row.original.name;
                return query.toLowerCase().includes(value.toLowerCase());
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.name.localeCompare(rowB.original.name);
            },
            enableSorting: true,
            size: 250,
        },
        {
            accessorKey: "accountType",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.accountType}
                    className="text-xs mx-2"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row ml-2">
                        <Badge
                            color={
                                row.original.account_type === "Company"
                                    ? "blue"
                                    : "orange"
                            }
                            size="2"
                            radius="medium"
                            variant="soft"
                            className="text-xs items-center"
                        >
                            {row.original.account_type}
                        </Badge>
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.account_type.localeCompare(
                    rowB.original.account_type,
                );
            },
            enableSorting: false,
            size: 100,
        },
        {
            accessorKey: "labels",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.label}
                    className="ml-1 mr-2"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex items-center gap-1 ml-2 my-0.5 flex-wrap">
                        {row.original.labels?.map((label) =>
                            getBadgeForAccountsLabel(label, false),
                        ) || null}
                        {row.original.tags?.map((label) =>
                            getBadgeForAccountsTag(label),
                        ) || null}
                    </div>
                );
            },
            filterFn: (row, id, value) => {
                return (
                    row.original.labels?.some(
                        (label) => label.name === value,
                    ) || false
                );
            },
            enableResizing: true,
            size: 230,
        },
        {
            accessorKey: "contractValue",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.contractValue}
                    className="ml-1 mr-2"
                />
            ),
            cell: ({ row }) => {
                return (
                    row.original.contract_value !== 0 &&
                    row.original.contract_value !== undefined && (
                        <Badge
                            color="mint"
                            size="2"
                            radius="medium"
                            variant="soft"
                            className="text-xs items-center ml-2"
                        >
                            <p>
                                {row.original.contract_type} | $
                                {row.original.contract_value}{" "}
                            </p>
                        </Badge>
                    )
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.contract_value);
            },
            sortingFn: (rowA, rowB, columnId) => {
                return (
                    rowA.original.contract_value - rowB.original.contract_value
                );
            },
            enableSorting: true,
            enableResizing: true,
            size: 10,
        },
        {
            accessorKey: "renewalDate",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.renewalDate}
                    className="ml-1 mr-2"
                />
            ),
            cell: ({ row }) => {
                const renewalDate = row.original.renewal_date
                    ? new Date(row.original.renewal_date)
                    : new Date();
                const formattedRenewalDate = renewalDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    timeZone: "UTC"
                });
                return (
                    <div className="ml-2 whitespace-nowrap">
                        {row.original.renewal_date &&
                            <div className="flex items-center gap-1 text-xs">
                                <CalendarIcon />
                                {formattedRenewalDate}
                            </div>
                        }
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                const dateA = rowA.original.renewal_date
                    ? new Date(rowA.original.renewal_date).getTime()
                    : 0;
                const dateB = rowB.original.renewal_date
                    ? new Date(rowB.original.renewal_date).getTime()
                    : 0;
                return dateA - dateB;
            },
            enableSorting: true,
            enableResizing: true,
            size: 10,
        },
        {
            accessorKey: "lastInteraction",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.lastInteraction}
                    className="ml-1 mr-2"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="ml-2">
                        {row.original.last_ticket &&
                            lastInteractionBadge(row.original.last_ticket)}
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                const dateA = rowA.original.last_ticket?.created_at
                    ? new Date(rowA.original.last_ticket.created_at).getTime()
                    : 0;
                const dateB = rowB.original.last_ticket?.created_at
                    ? new Date(rowB.original.last_ticket.created_at).getTime()
                    : 0;
                return dateA - dateB;
            },
            enableSorting: true,
            enableResizing: true,
            size: 10,
        },
        // {
        //     accessorKey: "sources",
        //     header: ({ column }) => (
        //         <DataTableColumnHeader
        //             column={column}
        //             title={queryToLabel.sources}
        //             className="text-xs"
        //         />
        //     ),
        //     cell: ({ row }) => {
        //         const metadata = row.original.metadata ?? {};
        //         const keys = Object.keys(metadata);

        //         const serviceIcons = new Set<IconEntry>();
        //         // biome-ignore lint/complexity/noForEach: <explanation>
        //         keys.forEach((serviceName) => {
        //             const IconComponent =
        //                 integrationBackEndDataMappingToSvg.get(serviceName);
        //             if (IconComponent) {
        //                 serviceIcons.add({
        //                     Component: IconComponent,
        //                     props: {
        //                         width: 20,
        //                         height: 20,
        //                         style: {
        //                             marginLeft: "-2px",
        //                             marginRight: "-2px",
        //                         },
        //                     },
        //                 });
        //             }
        //         });
        //         return (
        //             <div className="flex flex-row">
        //                 {Array.from(serviceIcons).map((icon) =>
        //                     React.createElement(icon.Component, icon.props),
        //                 )}
        //             </div>
        //         );
        //     },
        //     enableSorting: false,
        //     size: 12,
        // },
        {
            accessorKey: "assignee",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.assignee}
                    className="text-left text-xs mx-2"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center pl-2">
                        {assigneeBadge(row.original.assignee_user_id, users)}
                    </div>
                );
            },
            enableSorting: false,
            size: 20,
        },
    ];
    return columns;
}
