import { Card } from "@/component/shadcn/ui/card";
import type { Teams } from "@/interfaces/serverData";
import { AccountsListType } from "@/pages/Admin/CRM/Accounts";
import { TeamBadges } from "@/pages/WorkspacePreferences/TeamBadges";
import type { UseQueryResult } from "@tanstack/react-query";

interface NoAccountsPopupProps {
    userID: string;
    listType: AccountsListType;
    teamsQuery: UseQueryResult<Teams[], Error>;
    viewName?: string;
    teamID?: string;
}

export function NoAccountsPopup({
    userID,
    listType,
    teamsQuery,
    viewName,
    teamID,
}: NoAccountsPopupProps) {
    return (
        <div className="flex items-center justify-center h-full">
            <Card className="w-[400px] rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none py-7 px-10 flex flex-col gap-1">
                <div className="font-semibold">
                    No Accounts for this View
                </div>
                {listType === AccountsListType.Accounts && (
                    <div className="text-sm">
                        Hook up a CRM under Workspace Preferences, then new accounts will automatically populate here, or add accounts manually from the top right corner!
                    </div>
                )}
                {listType === AccountsListType.Inbox && (
                    <div className="text-sm">
                        Assign accounts to yourself from the general account list. Then those accounts will then populate here!
                    </div>
                )}
                {listType === AccountsListType.Team && (
                    <div className="text-sm">
                        Assign
                        <TeamBadges
                            teams={(
                                teamsQuery.data ?? []
                            ).filter(
                                (team) =>
                                    team.id === teamID,
                            )}
                            defaultIsWorkspace={false}
                            className="mx-1.5"
                        />
                        as a Team Owner on accounts. Then those accounts will populate here!
                    </div>
                )}
                {/* todo: support views and watch for accounts */}
                {/* {listType === AccountsListType.TeamViewer && (
                    <div className="text-sm">
                        Add
                        <TeamBadges
                            teams={(
                                teamsQuery.data ?? []
                            ).filter(
                                (team) =>
                                    team.id === teamID,
                            )}
                            defaultIsWorkspace={false}
                            className="mx-1.5"
                        />
                        as a Viewer on accounts. Then those accounts will then populate here!
                    </div>
                )}
                {listType === AccountsListType.View && (
                    <div className="text-sm">
                        No current accounts fit
                        {viewName ? SavedViewBadge(viewName, "mx-1.5") : "this view's"}
                        filters. Once an account does, it will then populate here!
                    </div>
                )} */}
            </Card>
        </div>
    );
}
