import type { ScopeResponse } from "@/interfaces/serverData";
import {
    $createTextNode,
    type EditorConfig,
    type RangeSelection,
    type SerializedTextNode,
    TextNode,
} from "lexical";

export class MentionNode extends TextNode {
    __scope: ScopeResponse;
    __isEditing: boolean;

    constructor(scope: ScopeResponse) {
        super(`@${scope.name}`);
        this.__scope = scope;
        this.__isEditing = false;
    }

    static getType() {
        return "mention";
    }

    static clone(node: MentionNode) {
        return new MentionNode(node.__scope);
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    createDOM(config: any) {
        const span = document.createElement("span");

        // Rectangular outline styles with added margins
        span.style.backgroundColor = "transparent";
        span.style.color = "#5B5BD6";
        span.setAttribute("data-type", "mention-node");
        span.textContent = this.getTextContent();
        return span;
    }

    updateDOM(
        prevNode: TextNode,
        dom: HTMLElement,
        config: EditorConfig,
    ): boolean {
        // Type guard to ensure prevNode is MentionNode
        if (!(prevNode instanceof MentionNode)) {
            return false;
        }

        const textContent = this.getTextContent();
        if (textContent !== prevNode.getTextContent()) {
            dom.textContent = textContent;
            this.__scope = {
                ...this.__scope,
                // Remove @ when updating scope
                name: textContent.slice(1),
            };
            return true;
        }
        return false;
    }

    getTextContent(): string {
        return super.getTextContent();
    }

    // Add this method to reset editing state
    clearEdit(): void {
        this.__isEditing = false;
    }

    onKeyDown(event: KeyboardEvent): boolean {
        if (event.key === "Backspace") {
            const writableNode = this.getWritable();
            writableNode.__isEditing = true;

            // Select the end of the mention node immediately
            const length = this.getTextContent().length + 1;
            this.select(length, length);

            return true;
        }
        return false;
    }

    select(_anchorOffset?: number, _focusOffset?: number): RangeSelection {
        const selection = super.select(_anchorOffset, _focusOffset);

        if (this.__isEditing) {
            const textContent = this.getTextContent();
            const writableNode = this.getWritable();
            const textNode = $createTextNode(textContent); // Don't add @ here
            writableNode.replace(textNode);

            // Position cursor at the end of the text
            const offset = _anchorOffset ?? textContent.length;
            textNode.select(offset, offset);
        }

        return selection;
    }

    static importJSON(serializedNode: SerializedMentionNode): MentionNode {
        const node = $createMentionNode({
            name: serializedNode.text,
            key: serializedNode.key,
        });
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }

    exportJSON(): SerializedMentionNode {
        return {
            ...super.exportJSON(),
            type: "mention",
            version: 1,
            key: this.__scope.key,
        };
    }
}

type SerializedMentionNode = Omit<SerializedTextNode, "type"> & {
    type: "mention";
    key: string;
};

export function $createMentionNode({
    name,
    key,
}: { name: string; key: string }) {
    return new MentionNode({
        name,
        key,
    }).setMode("token");
}
