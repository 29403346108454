import { Button } from "@/component/shadcn/ui/button";
import type { Template, Teams } from "@/interfaces/serverData";
import { PlusIcon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { OnboardingChecklistType } from "@/interfaces/onboardingServerData";
import { MilestoneCard, NewMilestoneCard, getMilestoneProgressValue } from "./MilestoneCard";
import { useMilestoneLogic } from "./hooks/useMilestoneLogic";

export interface TemplateOnboardingProps {
    userID: string;
    accountProp: Template;
    saveTemplate: (values: { 
        templateName: string; 
        templateTeams?: Teams[]; 
        templateContents?: string;
    }) => void;
}

export const TemplateOnboardingPage = ({
    userID,
    accountProp,
    saveTemplate,
}: TemplateOnboardingProps) => {
    const {
        loadingOnboardingData,
        onboardingItems,
        tasksData,
        loadingTasksData,
        onboardingMainItem,
        addOnboardingItem,
        addNewMilestone,
        saveIssue,
        handleDeleteIssue,
        setOnboardingState,
        saveOnboardingItem,
        refetchOnboardingData,
        showAddItem,
        setShowAddItem,
        users,
    } = useMilestoneLogic({
        userID,
        accountProp,
        accountType: "Template",
    });

    

    return (
        <Flex direction="column" align="center" justify="center" gap="2">
            
            {loadingOnboardingData || loadingTasksData ? (
                <div>Loading...</div>
            ) : (
                <>
                        <NewMilestoneCard
                            removeCard={() => setShowAddItem(false)}
                            handleSave={async (milestone, data) => {
                                addNewMilestone(milestone, data, (tasksData?.onboarding_checklist?.length ?? 0) > 0);
                            }}
                            users={users ?? []}
                            userID={userID}
                            isTemplate={true}
                            editingData={tasksData?.onboarding_checklist ?? []}
                            milestonePayload={Array.from(onboardingItems.values())
                                .filter(
                                    (item) =>
                                        item.onboarding_type ===
                                        OnboardingChecklistType.Milestone,
                                )[0]}
                        />
                </>
            )}
        </Flex>
    );
}; 