import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { Switch } from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export default function Timer() {
    const api = useApi();
    const { toast } = useToast();
    const timerQuery = useQuery({
        queryKey: ["timerEnabled"],
        queryFn: async () => {
            const resp = await api.get(URLS.serverUrl + API.getTimerEnabled, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            if (resp.status === 200) {
                return resp.data.data;
            }
            return false;
        },
    });

    useEffect(() => {
        setTimerEnabled(timerQuery.data ?? false);
    }, [timerQuery.data]);

    const updateTimerMutation = useMutation({
        mutationFn: async (data: boolean) => {
            const resp = await api.patch(
                URLS.serverUrl + API.saveTimerEnabled,
                {
                    enabled: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (resp.status === 200) {
                toast({
                    title: "Updated Settings!",
                    description: "The settings have been updated successfully.",
                });
            } else {
                toast({
                    title: "Failed to update settings",
                    description:
                        "Oops! Something's wrong. Please try again at a later time.",
                    variant: "destructive",
                });
            }
        },
    });

    const [timerEnabled, setTimerEnabled] = useState<boolean>(
        timerQuery.data ?? false,
    );

    return (
        <div>
            <Card className="shadow-none rounded-md">
                <CardHeader className="flex flex-row justify-between items-center">
                    <div>
                        <CardTitle>Timer</CardTitle>
                        <CardDescription>
                            Enable the timer to time how long it takes to
                            resolve a ticket.
                        </CardDescription>
                    </div>
                    <Switch
                        checked={timerEnabled}
                        onCheckedChange={(checked) => {
                            setTimerEnabled(checked);
                            updateTimerMutation.mutate(checked);
                        }}
                        className="rounded-full"
                    />
                </CardHeader>
            </Card>
        </div>
    );
}
