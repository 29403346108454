export const queryToLabel: Record<string, string> = {
    id: "Id",
    name: "Name",
    company: "Company",
    description: "Description",
    domain: "Domain",
    primarySource: "Primary Source",
    sources: "Sources",
    label: "Labels",
    firstSeen: "First Seen",
    ticketStatus: "Status",
    accountType: "Account Type",
    edit: "Edit",
    select: "Select",
    contractValue: "Contract Value",
    contacts: "Contacts",
    lastInteraction: "Last Interaction",
    assignee: "Assignee",
    renewalDate: "Renewal Date"
};

export const sources = [
    {
        label: "Slack",
        value: "Slack",
    },
    {
        label: "Discord",
        value: "Discord",
    },
    {
        label: "Chat Widget",
        value: "ChatWidget",
    },
    {
        label: "Gmail",
        value: "Gmail",
    },
    {
        label: "Web",
        value: "Web",
    },
    {
        label: "GitHubTicket",
        value: "GitHubTicket",
    },
];

export const CustomerState = {
    delete: "Delete",
    edit: "Edit",
};
