import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { AccountsLabel, AccountsTag, GetTopicsResponse, Teams } from "@/interfaces/serverData";
import {
    DotsHorizontalIcon,
    LinkBreak1Icon,
    Pencil2Icon,
    TrashIcon,
} from "@radix-ui/react-icons";
import type { MembersRowState, TagsRowState } from "./constants";
import { TopicsDialog } from "../TopicsDialog";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { AccountsLabelsDialog } from "../AccountsLabelsDialog";

interface DataTableRowActionsMembersProps<GetUserResponse> {
    id: string;
    rowState: MembersRowState | undefined;
    updateRowState: (id: string, newState: Partial<MembersRowState>) => void;
    deleteRowState?: (id: string) => void;
    isDelete?: boolean;
}

export function DataTableRowActionsMembers<TData>({
    id,
    rowState,
    updateRowState,
    deleteRowState = () => { },
    isDelete = false,
}: DataTableRowActionsMembersProps<TData>) {
    const api = useApi();
    const roles = ["Member", "Admin", "Owner"];

    function changeUserRole(role: string) {
        const requestData = {
            user_id: rowState?.id,
            new_role: role,
        };
        api.post(URLS.serverUrl + API.changeUserRole, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    updateRowState(id, {
                        role: role,
                    });
                }
            })
            .catch((res) => {
                console.log("failed to change user role");
            });
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                {roles
                    .filter((role) => role !== rowState?.role)
                    .map((role) => (
                        <DropdownMenuItem
                            onClick={() => changeUserRole(role)}
                            className="cursor-pointer hover:bg-muted"
                            key={role}
                        >
                            Make {role}
                        </DropdownMenuItem>
                    ))}
                {isDelete && (
                    <DropdownMenuItem
                        onClick={() => deleteRowState(id)}
                        className="cursor-pointer hover:bg-muted"
                    >
                        Delete
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

interface DataTableRowActionsTopicsProps<GetUserResponse> {
    id: string;
    rowState: TagsRowState | undefined;
    saveTag: (id: string, newState: Partial<TagsRowState>) => void;
    teams: Teams[];
    refetchTopics: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<GetTopicsResponse[], Error>>;
    setLoadingState: React.Dispatch<React.SetStateAction<number>>;
    currentTeam?: Teams;
    teamID?: string;
}

export function DataTableRowActionsTopics<TData>({
    id,
    rowState,
    saveTag,
    teams,
    refetchTopics,
    setLoadingState,
    currentTeam,
    teamID,
}: DataTableRowActionsTopicsProps<TData>) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[200px]">
                <TopicsDialog
                    type={"Edit"}
                    triggerElement={
                        <DropdownMenuItem
                            className="cursor-pointer hover:bg-muted text-xs flex items-center gap-2"
                            onSelect={(e) => e.preventDefault()}
                        >
                            <Pencil2Icon className="w-3.5 h-3.5" />
                            Edit
                        </DropdownMenuItem>
                    }
                    teams={teams}
                    refetchTopics={refetchTopics}
                    setLoadingState={setLoadingState}
                    saveTag={saveTag}
                    currentTeam={currentTeam}
                    editingObject={rowState}
                />
                {teamID ? (
                    rowState?.teams.length === 1 &&
                        rowState.teams[0].id === teamID ? (
                        <TopicsDialog
                            type={"Delete"}
                            triggerElement={
                                <DropdownMenuItem
                                    className="cursor-pointer hover:bg-muted text-xs flex items-center gap-2"
                                    onSelect={(e) => e.preventDefault()}
                                >
                                    <TrashIcon className="w-4 h-4" />
                                    Delete
                                </DropdownMenuItem>
                            }
                            teams={teams}
                            refetchTopics={refetchTopics}
                            setLoadingState={setLoadingState}
                            saveTag={saveTag}
                            currentTeam={currentTeam}
                            editingObject={rowState}
                        />
                    ) : (
                        <TopicsDialog
                            type={"RemoveFromTeam"}
                            triggerElement={
                                <DropdownMenuItem
                                    className="cursor-pointer hover:bg-muted text-xs flex items-center gap-2"
                                    onSelect={(e) => e.preventDefault()}
                                >
                                    <LinkBreak1Icon className="w-3.5 h-3.5" />
                                    Remove From Team
                                </DropdownMenuItem>
                            }
                            teams={teams}
                            refetchTopics={refetchTopics}
                            setLoadingState={setLoadingState}
                            saveTag={saveTag}
                            currentTeam={currentTeam}
                            editingObject={rowState}
                            noDialog={true}
                        />
                    )
                ) : (
                    <TopicsDialog
                        type={"Delete"}
                        triggerElement={
                            <DropdownMenuItem
                                className="cursor-pointer hover:bg-muted text-xs flex items-center gap-2"
                                onSelect={(e) => e.preventDefault()}
                            >
                                <TrashIcon className="w-4 h-4" />
                                Delete
                            </DropdownMenuItem>
                        }
                        teams={teams}
                        refetchTopics={refetchTopics}
                        setLoadingState={setLoadingState}
                        saveTag={saveTag}
                        currentTeam={currentTeam}
                        editingObject={rowState}
                    />
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

interface DataTableRowActionsAccountsTagsProps<TData> {
    id: string;
    tag: AccountsLabel | undefined;
    teams: Teams[];
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<AccountsLabel[], Error>>;
    currentTeam?: Teams;
    teamID?: string;
}

export function DataTableRowActionsAccountsTags<TData>({
    id,
    tag,
    teams,
    refetch,
    currentTeam,
    teamID,
}: DataTableRowActionsAccountsTagsProps<TData>) {
    console.log("tag is", tag)
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            {tag &&
                <DropdownMenuContent align="end" className="w-[200px]">
                    <AccountsLabelsDialog
                        type={"Edit"}
                        triggerElement={
                            <DropdownMenuItem
                                className="cursor-pointer hover:bg-muted text-xs flex items-center gap-2"
                                onSelect={(e) => e.preventDefault()}
                            >
                                <Pencil2Icon className="w-3.5 h-3.5" />
                                Edit
                            </DropdownMenuItem>
                        }
                        teams={teams}
                        refetch={refetch}
                        currentTeam={
                            currentTeam
                        }
                        editingObject={
                            tag
                        }
                    />
                    <AccountsLabelsDialog
                        type={"Delete"}
                        triggerElement={
                            <DropdownMenuItem
                                className="cursor-pointer hover:bg-muted text-xs flex items-center gap-2"
                                onSelect={(e) => e.preventDefault()}
                            >
                                <TrashIcon className="w-4 h-4" />
                                Delete
                            </DropdownMenuItem>
                        }
                        teams={teams}
                        refetch={refetch}
                        currentTeam={
                            currentTeam
                        }
                        editingObject={
                            tag
                        }
                    />
                </DropdownMenuContent>
            }
        </DropdownMenu>
    );
}

interface DataTableRowActionsViewsProps<ViewsRowState> {
    id: string;
    rowState: ViewsRowState | undefined;
    updateRowState: (id: string, newState: Partial<ViewsRowState>) => void;
    deleteRowState?: (id: string) => void;
}

export function DataTableRowActionsViews<TData>({
    id,
    rowState,
    updateRowState,
    deleteRowState = () => { },
}: DataTableRowActionsViewsProps<TData>) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuItem
                    onClick={() => deleteRowState(id)}
                    className="cursor-pointer hover:bg-muted"
                >
                    Delete
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
