import { CheckIcon } from "@radix-ui/react-icons";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import { Template } from "@/interfaces/serverData";
import useTemplates from "@/hooks/use-templates";
import { Button } from "@/component/shadcn/ui/button";

interface TemplateDropdownProps {
    onSelect: (templateID: string) => void;
    selectedTemplateId?: string;
    buttonText?: string;
    className?: string;
    templateType: "Implementation" | "Interactions";
}

export function TemplateDropdown({
    onSelect,
    selectedTemplateId,
    buttonText = "{ }",
    className,
    templateType,
}: TemplateDropdownProps) {
    const { data: templates } = useTemplates(templateType);
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <p>{buttonText}</p>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" side="left" className="overflow-hidden p-0">
                <Command className="rounded-md shadow-md text-xs pb-1">
                    <CommandInput
                        placeholder="Filter templates..."
                        className="px-1 text-xs"
                    />
                    <CommandList className="flex-1 max-h-[140px] overflow-y-auto">
                        <CommandEmpty className="text-xs px-4 py-2">
                            No results found.
                        </CommandEmpty>
                        {templates?.map((template) => (
                            <CommandItem
                                key={template.id}
                                onSelect={() => onSelect(template.id)}
                                className="text-xs flex items-center gap-2 px-4"
                            >
                                {template.name}
                                {selectedTemplateId === template.id && (
                                    <CheckIcon className="h-4 w-4" />
                                )}
                            </CommandItem>
                        ))}
                    </CommandList>
                </Command>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
