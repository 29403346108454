export enum FilterType {
    DirectSelect = "DirectSelect", // Directly adds a filter, sets the value to true (i.e. assembly bot)
    MenuThenSelect = "MenuThenSelect", // Resets the active menu and presents different options (i.e. assignee, tag, etc)
    MenuThenMenu = "MenuThenMenu", // Opens another active menu with more options (i.e. slack channels, specific gmails)
}

export type FilterOption = {
    type: string;
    filterType: FilterType;
    showTrigger: boolean;
    extraOptions?: {
        color: string;
        label: string;
        value: string;
        key: string;
    }[];
};

export const sources = [
    {
        color: "",
        label: "Slack",
        value: "Slack",
        key: "Slack",
    },
    {
        color: "",
        label: "Community Slack",
        value: "CommunitySlack",
        key: "CommunitySlack",
    },
    {
        color: "",
        label: "Discord",
        value: "Discord",
        key: "Discord",
    },
    {
        color: "",
        label: "Chat Widget",
        value: "ChatWidget",
        key: "ChatWidget",
    },
    {
        color: "",
        label: "GitHub Issues",
        value: "GitHubTicket",
        key: "GitHubTicket",
    },
    {
        color: "",
        label: "Github Discussion",
        value: "GithubDiscussion",
        key: "GithubDiscussion",
    },
    {
        color: "",
        label: "Gmail",
        value: "Gmail",
        key: "Gmail",
    },
    {
        color: "",
        label: "Web",
        value: "Web",
        key: "Web",
    },
    {
        color: "",
        label: "Intercom",
        value: "Intercom",
        key: "Intercom",
    },
    {
        color: "",
        label: "API",
        value: "API",
        key: "API",
    },
];

export const sourcesFilterOptions: FilterOption[] = [
    {
        type: "Slack",
        filterType: FilterType.MenuThenSelect,
        showTrigger: false,
        extraOptions: [
            {
                label: "Slack -- All Channels",
                value: "Slack -- All Channels",
                color: "",
                key: "Slack -- All Channels",
            },
        ],
    },
    {
        type: "CommunitySlack",
        filterType: FilterType.MenuThenSelect,
        showTrigger: false,
        extraOptions: [
            {
                label: "CommunitySlack -- All Channels",
                value: "CommunitySlack -- All Channels",
                color: "",
                key: "CommunitySlack -- All Channels",
            },
        ],
    },
    {
        type: "Discord",
        filterType: FilterType.MenuThenSelect,
        showTrigger: false,
        extraOptions: [
            {
                label: "Discord -- All Channels",
                value: "Discord -- All Channels",
                color: "",
                key: "Discord -- All Channels",
            },
        ],
    },
    {
        type: "Gmail",
        filterType: FilterType.MenuThenSelect,
        showTrigger: false,
        extraOptions: [
            {
                label: "Gmail -- All Emails",
                value: "Gmail -- All Emails",
                color: "",
                key: "Gmail -- All Emails",
            },
        ],
    },
    {
        type: "API",
        filterType: FilterType.MenuThenSelect,
        showTrigger: false,
        extraOptions: [
            {
                label: "API -- All API Keys",
                value: "API -- All API Keys",
                color: "",
                key: "API -- All API Keys",
            },
        ],
    },
];

export const menuThenMenuSources: string[] = [
    "Slack",
    "CommunitySlack",
    "Discord",
    "Gmail",
    "API",
];

export const broadcasts = [
    {
        label: "Slack",
        value: "Slack",
    },
    {
        label: "Discord",
        value: "Discord",
    },
];

export const ticketStatuses = [
    {
        color: "",
        label: "Breaching",
        value: "Breaching",
        key: "Breaching",
    },
    {
        color: "",
        label: "Needs Response",
        value: "NeedsResponse",
        key: "NeedsResponse",
    },
    {
        color: "",
        label: "Escalated",
        value: "Escalated",
        key: "Escalated"
    },
    {
        color: "",
        label: "Open",
        value: "Open",
        key: "Open",
    },
    {
        color: "",
        label: "Closed",
        value: "Closed",
        key: "Closed",
    },
];

export const insightStatuses = [
    {
        color: "",
        label: "New",
        value: "New",
        key: "New",
    },
    {
        color: "",
        label: "Open",
        value: "Open",
        key: "Open",
    },
    {
        color: "",
        label: "Closed",
        value: "Closed",
        key: "Closed",
    },
];

export const ticketStatusPriorityOrder = [
    "Breaching",
    "NeedsResponse",
    "Escalated",
    "Open",
    "Closed",
    "ClosedNoWorkflows",
];

export const insightStatusPriorityOrder = ["New", "Open", "Closed"];

export enum ticketStatus {
    Breaching = "Breaching",
    NeedsResponse = "NeedsResponse",
    Open = "Open",
    Closed = "Closed",
}

export type RowState = {
    tagSelected: string;
    topicsSelected: string[];
    statusSelected: string;
};

export enum HeaderType {
    Status = "Status",
    Other = "Other",
}

export const replyTypes = [
    {
        color: "",
        label: "Customer",
        value: "Customer",
        key: "Customer",
    },
    {
        color: "",
        label: "Team",
        value: "Team",
        key: "Team",
    },
    {
        color: "",
        label: "Internal",
        value: "Internal",
        key: "Internal",
    }
];

export const accountTypes = [
    {
        color: "",
        label: "Customer",
        value: "Customer",
        key: "Customer",
    },
    {
        color: "",
        label: "Company",
        value: "Company",
        key: "Company",
    }
];