import { Button } from "@/component/shadcn/ui/button";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { URLS } from "@/constant";
import { ContactsAPI } from "@/constant";
import useUser from "@/hooks/use-users";
import { useApi } from "@/interfaces/api";
import {
    type GetOnboardingResponse,
    type Onboarding,
    type OnboardingChecklist,
    type OnboardingChecklistPayload,
    OnboardingChecklistType,
} from "@/interfaces/onboardingServerData";
import type { Account } from "@/interfaces/serverData";
import { PlusIcon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { type RefetchOptions, useQuery } from "@tanstack/react-query";
// import { GoogleSpreadsheetConnection } from "./GoogleSpreadsheetConnection";
import type { QueryObserverResult } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { TemplateDropdown } from "@/reusable_components/dropdowns/TemplateDropdown";
import type { OrgMemberInfo } from "@propelauth/react";
import { GoogleSpreadsheetConnection } from "./GoogleSpreadsheetConnection";
import {
    MilestoneCard,
    NewMilestoneCard,
    getMilestoneProgressValue,
} from "./MilestoneCard";
import {
    addOnboardingItem,
    handleDeleteIssue,
    saveIssue,
    saveOnboardingItem,
    setOnboardingState,
} from "./methods";
// import { GoogleSpreadsheetConnection } from "./GoogleSpreadsheetConnection";

export interface OnboardingProps {
    org: OrgMemberInfo;
    userID: string;
    accountProp: Account;
    accountType: "Company" | "Customer" | "Template";
}

export const OnboardingPage = ({
    org,
    userID,
    accountProp,
    accountType,
}: OnboardingProps) => {
    const api = useApi();
    const { toast } = useToast();

    const fetchMilestones = async (): Promise<GetOnboardingResponse> => {
        try {
            const { url } = ContactsAPI.getOnboardingChecklist;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    [accountType === "Company" ? "company_id" : "customer_id"]:
                        accountProp.id,
                    onboarding_type: OnboardingChecklistType.Milestone,
                },
            });
            if (response.data === null) {
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            }
            if (response.status === 200) {
                return response.data.data;
            }
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        }
    };

    const {
        data: onboardingData,
        isLoading: loadingOnboardingData,
        isError: errorOnboardingData,
        refetch: refetchOnboardingData,
    } = useQuery({
        queryKey: ["milestones", accountProp.id],
        queryFn: fetchMilestones,
    });

    const [onboardingItems, setOnboardingItems] = useState<
        Map<string, OnboardingChecklist>
    >(
        new Map(
            onboardingData?.onboarding_checklist?.map((item) => [
                item.id,
                item,
            ]),
        ),
    );

    useEffect(() => {
        setOnboardingItems(
            new Map(
                onboardingData?.onboarding_checklist?.map((item) => [
                    item.id,
                    item,
                ]),
            ),
        );
    }, [onboardingData]);

    const [onboardingMainItem, setOnboardingMainItem] = useState<
        Onboarding | undefined
    >(onboardingData?.onboarding);

    useEffect(() => {
        if (!loadingOnboardingData && onboardingData) {
            setOnboardingMainItem(onboardingData?.onboarding);
        }
    }, [loadingOnboardingData, onboardingData]);

    const addOnboardingItemWrapper = useCallback(
        async (
            onboardingItem: OnboardingChecklistPayload,
            parentId: string,
        ): Promise<string> => {
            try {
                const added = await addOnboardingItem(
                    onboardingItem,
                    parentId,
                    accountType,
                    accountProp,
                    api,
                );
                return added;
            } catch (err) {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
                return "";
            }
        },
        [accountType, accountProp.id, api, toast],
    );

    const addNewMilestone = useCallback(
        (
            milestone: OnboardingChecklistPayload,
            onboardingItems: OnboardingChecklist[],
        ) => {
            if (accountType === "Company") {
                milestone.company_id = accountProp.id;
                for (const item of onboardingItems) {
                    item.company_id = accountProp.id;
                    item.onboarding_type = OnboardingChecklistType.Task;
                }
            } else if (accountType === "Customer") {
                milestone.customer_id = accountProp.id;
                for (const item of onboardingItems) {
                    item.customer_id = accountProp.id;
                    item.onboarding_type = OnboardingChecklistType.Task;
                }
            } else if (accountType === "Template") {
                milestone.template_id = accountProp.id;
                for (const item of onboardingItems) {
                    item.template_id = accountProp.id;
                    item.onboarding_type = OnboardingChecklistType.Task;
                }
            }
            milestone.onboarding_type = OnboardingChecklistType.Milestone;
            const request = {
                milestone: milestone,
                tasks: onboardingItems,
            };
            api.post(
                `${URLS.serverUrl}${ContactsAPI.addMilestone.url}`,
                request,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status === 500) {
                        const errorDetails =
                            res?.data?.details ??
                            "Please try again at a later time.";

                        toast({
                            title: "Oops! Something's wrong.",
                            description: errorDetails,
                            variant: "destructive",
                        });
                    } else if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Added milestone!",
                            description:
                                "Your milestone has been added successfully.",
                        });
                        if (refetchOnboardingData) {
                            refetchOnboardingData();
                        }
                        setShowAddItem(false);
                    }
                })
                .catch((err) => {
                    if (err.status === 500) {
                        const errorDetails =
                            err?.response?.data?.details ??
                            "Please try again at a later time.";

                        toast({
                            title: "Oops! Something's wrong.",
                            description: errorDetails,
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    }
                });
        },
        [accountType, accountProp.id, api, refetchOnboardingData],
    );

    const addNewMilestoneFromTemplate = useCallback(
        (templateID: string) => {
            let companyID = "";
            let customerID = "";
            if (accountType === "Company") {
                companyID = accountProp.id;
            } else if (accountType === "Customer") {
                customerID = accountProp.id;
            }
            const request = {
                template_id: templateID,
                company_id: companyID,
                customer_id: customerID,
            };
            api.post(
                `${URLS.serverUrl}${ContactsAPI.addMilestoneFromTemplate.url}`,
                request,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Added milestone!",
                            description:
                                "Your milestone has been added successfully.",
                        });
                        if (refetchOnboardingData) {
                            refetchOnboardingData();
                        }
                        setShowAddItem(false);
                    }
                })
                .catch((err) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                });
        },
        [accountType, accountProp.id, api, refetchOnboardingData],
    );

    const [selectedItems, setSelectedItems] = useState<OnboardingChecklist[]>(
        [],
    );

    const wrappedSetOnboardingState = useCallback(
        (
            newState: Partial<OnboardingChecklist>,
            oldItem: OnboardingChecklist,
        ) => {
            setOnboardingState(
                newState,
                oldItem,
                selectedItems,
                setOnboardingItems,
                setSelectedItems,
            );
        },
        [selectedItems],
    );

    const wrappedHandleDeleteIssue = useCallback(
        (
            userID: string,
            issueIds: string[],
            onboardingMainItemId: string,
            deleteOnUI?: (ids: string[]) => void,
        ) => {
            handleDeleteIssue(
                userID,
                issueIds,
                selectedItems,
                api,
                onboardingMainItemId,
                deleteOnUI,
            );
        },
        [selectedItems, api, onboardingMainItem?.id],
    );

    const wrappedSaveIssue = useCallback(
        async (
            type: string,
            payload: Partial<OnboardingChecklistPayload>,
            userID: string,
            updateIssueState: (
                newState: Partial<OnboardingChecklist>,
                oldItem: OnboardingChecklist,
            ) => void,
            refetch: (
                options?: RefetchOptions,
            ) => Promise<QueryObserverResult<GetOnboardingResponse, Error>>,
            issueId: string,
            item: OnboardingChecklist,
            onboardingMainItemId: string,
            milestoneId: string,
        ) => {
            const res = await saveIssue(
                type,
                payload,
                userID,
                updateIssueState,
                refetch,
                issueId,
                item,
                onboardingMainItemId,
                milestoneId,
                selectedItems,
                api,
            );
            if (res.status !== 200) {
                let errorDetails = "Please try again at a later time.";
                if (res.status === 500) {
                    errorDetails =
                        res?.response?.data?.details ??
                        "Please try again at a later time.";
                }
                toast({
                    title: "Oops! Something's wrong.",
                    description: errorDetails,
                    variant: "destructive",
                });
            }
        },
        [selectedItems, api],
    );

    const wrappedSaveOnboardingItem = useCallback(
        (
            issue: Partial<OnboardingChecklist>,
            column: string,
            value: string,
            onboardingMainItemId: string,
        ) => {
            saveOnboardingItem(
                issue,
                column,
                value,
                onboardingMainItemId,
                selectedItems,
                api,
                userID,
            );
        },
        [selectedItems, api, userID],
    );

    const updateGoogleLink = useCallback(
        (onboarding_id: string, google_id: string) => {
            api.post(
                `${URLS.serverUrl}${ContactsAPI.updateOnboardingGoogleLink.url}`,
                { onboarding_id, google_link: google_id },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Google Sheet!",
                            description:
                                "Your Google Sheet has been updated successfully.",
                        });

                        if (refetchOnboardingData) {
                            refetchOnboardingData();
                        }
                    }
                })
                .catch((err) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                });
        },
        [api, refetchOnboardingData, toast],
    );

    const handleSuccess = (fileIds: string[]) => {
        console.log("fileIds", fileIds);
        updateGoogleLink(onboardingMainItem?.id ?? "", fileIds[0]);
    };

    const [showAddItem, setShowAddItem] = useState<boolean>(false);

    const { data: users } = useUser();

    return (
        <Flex direction="column" align="center" justify="center" gap="2">
            <div className="flex flex-row gap-2 justify-end items-end w-full">
                <div className="ml-auto flex gap-2 mb-2">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                            >
                                Add Milestone
                                <PlusIcon />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                            align="start"
                            side="left"
                            className="overflow-hidden text-xs"
                        >
                            <DropdownMenuItem
                                className="text-xs"
                                onSelect={() => setShowAddItem(true)}
                            >
                                Add New Milestone
                            </DropdownMenuItem>
                            <DropdownMenuItem className="text-xs">
                                <TemplateDropdown
                                    onSelect={addNewMilestoneFromTemplate}
                                    selectedTemplateId={undefined}
                                    buttonText={"Add From Template"}
                                    templateType={"Implementation"}
                                />
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>

                    {(org.orgId === "d878861f-76e3-4941-993e-dabba64e5987" ||
                        org.orgId === "fc12e902-f8ee-4176-947b-7f716469d1d6" ||
                        org.orgId === "01HQSDHJ9ZR783V0VKT59M110V") && (
                        <GoogleSpreadsheetConnection
                            onCancel={() => {}}
                            onSuccess={handleSuccess}
                            spreadsheetID={onboardingMainItem?.google_link}
                        />
                    )}
                </div>
            </div>
            {loadingOnboardingData ? (
                <div>Loading...</div>
            ) : (
                <>
                    {showAddItem && (
                        <NewMilestoneCard
                            removeCard={() => setShowAddItem(false)}
                            handleSave={addNewMilestone}
                            users={users ?? []}
                            userID={userID}
                        />
                    )}
                    {Array.from(onboardingItems.values())
                        .filter(
                            (item) =>
                                item.onboarding_type ===
                                OnboardingChecklistType.Milestone,
                        )
                        .map((item) => {
                            const progress = getMilestoneProgressValue(
                                item.total_tasks ?? 0,
                                item.completed_count ?? 0,
                            );
                            const incompleteCount = Array.from(
                                onboardingItems.values(),
                            )
                                .filter(
                                    (i) =>
                                        i.onboarding_type ===
                                            OnboardingChecklistType.Milestone &&
                                        getMilestoneProgressValue(
                                            i.total_tasks ?? 0,
                                            i.completed_count ?? 0,
                                        ) !== 1,
                                )
                                .indexOf(item);

                            return (
                                <MilestoneCard
                                    key={item.id}
                                    accountType={accountType}
                                    accountProp={accountProp}
                                    item={item}
                                    org={org}
                                    users={users ?? []}
                                    saveIssue={wrappedSaveIssue}
                                    userID={userID}
                                    isSelected={false}
                                    onSelect={() => {}}
                                    refetch={refetchOnboardingData}
                                    updateIssueState={wrappedSetOnboardingState}
                                    deleteIssues={wrappedHandleDeleteIssue}
                                    setSheetOpen={() => {}}
                                    addIssue={addOnboardingItemWrapper}
                                    setTopLevelEditing={() => {}}
                                    isOpen={
                                        progress !== 1 && incompleteCount < 3
                                    }
                                    saveIssueNew={wrappedSaveOnboardingItem}
                                    accountOnboardingParentId={
                                        onboardingMainItem?.id ?? ""
                                    }
                                    spreadsheetID={
                                        onboardingMainItem?.google_link
                                    }
                                    entityName={accountProp.name}
                                    imageUrl={accountProp.image_url}
                                    showEntityName={false}
                                />
                            );
                        })}
                </>
            )}
        </Flex>
    );
};
