import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent, CardHeader } from "@/component/shadcn/ui/card";
import { Input } from "@/component/shadcn/ui/input";
import { Skeleton } from "@/component/shadcn/ui/skeleton";
import { toast } from "@/component/shadcn/ui/use-toast";
import { API, URLS, loadingTypes } from "@/constant";
import { ErrorChip } from "@/design/Chip";
import { useApi } from "@/interfaces/api";
import {Link} from "@radix-ui/themes";
import type {
    BotMetadata,
    FilterMsgs,
    OrgInfoResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import { Box, Flex, Switch, Text } from "@radix-ui/themes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Check, ClipboardIcon, Mail, Plus, X, XIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as GmailSvg } from "../../../images/integrations/gmail.svg";
import { readableIntegrationMapping } from "../constant";
import IntegrationHeader from "./IntegrationHeader";
import { Pencil1Icon } from "@radix-ui/react-icons";

interface GoogleIntegrationState {
    accounts: Map<string, IntegrationMetadataResponse>;
}

interface IntegrationMetadataResponse {
    unique_name: string;
    scopes: ScopeResponse[];
    date: string;
    slack_team_id?: string;
    token_scopes?: string[];
    metadata?: {
        forwarding_email_addresses?: string;
    };
}

export default function GoogleIntegration() {
    const api = useApi();
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [includeOrgMsgs, setIncludeOrgMsgs] = useState<boolean>(true);
    const [accountMsgsOnly, setAccountMsgsOnly] = useState<boolean>(false);
    const [blacklistedScopes, setBlacklistedScopes] =
        useState<ScopeResponse[]>();
    const [blacklistedDomains, setBlacklistedDomains] =
        useState<string[]>();
    const [botMetadata, setBotMetadata] = useState<BotMetadata>();
    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const [newScope, setNewScope] = useState<string>("");
    const [isAddingScope, setIsAddingScope] = useState<boolean>(false);
    const [newDomain, setNewDomain] = useState<string>("");
    const [isAddingDomain, setIsAddingDomain] = useState<boolean>(false);

    const { data, isLoading, isError, refetch } =
        useQuery<GoogleIntegrationState>({
            queryKey: ["integrations", "google"],
            queryFn: async () => {
                const requestData = {
                    types: ["Google"],
                };
                const response = await api.post(
                    URLS.serverUrl + API.getItemsByOrgID,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );
                const data: OrgInfoResponse = response.data.data;
                const accounts: Map<string, IntegrationMetadataResponse> =
                    new Map();
                if (data.Google) {
                    for (const account of data.Google) {
                        accounts.set(account.unique_name, account);
                    }
                }
                return {
                    accounts: accounts,
                };
            },
        });

    const { data: forwardingEmailAddressesData, isLoading: forwardingEmailAddressesLoading, isError: forwardingEmailAddressesError, refetch: forwardingEmailAddressesRefetch } =
        useQuery<GoogleIntegrationState>({
            queryKey: ["integrations", "google", "forwardingEmailAddresses"],
            queryFn: async () => {
                const requestData = {
                    types: ["Google"],
                };
                const response = await api.post(
                    URLS.serverUrl + API.getForwardingEmailAddresses,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );
                const data: OrgInfoResponse = response.data.data;
                const accounts: Map<string, IntegrationMetadataResponse> =
                    new Map();
                if (data.Google) {
                    for (const account of data.Google) {
                        accounts.set(account.unique_name, account);
                    }
                }
                return {
                    accounts: accounts,
                };
            },
        });

    const queryClient = useQueryClient();
    const { mutate: deleteMutation } = useMutation({
        mutationFn: async (account: string) => {
            const res = await api.delete(
                URLS.serverUrl + API.deleteIntegration,
                {
                    data: {
                        integration_name: "Google",
                        unique_name: account,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (res.status === 200) {
                console.log("Successfully deleted connection");
            } else {
                console.log("Failed to delete connection");
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["integrations", "google"],
            });
        },
        onError: (err) => {
            throw err;
        },
    });

    const googleContinue = () => {
        const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/google`;
        const google_url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.settings.basic&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${redirect_url}&client_id=${client_id}&approval_prompt=force`;
        window.open(google_url, "_self");
    };
    // not ready to use this just yet.
    // const googleContinue = () => {
    //     const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    //     const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/manage_integrations/google`;
    //     const google_url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${redirect_url}&client_id=${client_id}&approval_prompt=force`;

    //     const width = 500;
    //     const height = 600;
    //     const left = (window.innerWidth - width) / 2;
    //     const top = (window.innerHeight - height) / 2;

    //     const popup = window.open(
    //         google_url,
    //         "googleAuthPopup",
    //         `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    //     );

    //     if (popup) {
    //         popup.focus();
    //     }
    // };

    // Memoize the accounts array to prevent unnecessary re-renders
    const connectedAccounts = useMemo(() => 
        Array.from(data?.accounts.values() || []), 
        [data?.accounts]
    );

    const forwardingEmailAddresses = useMemo(() => 
        Array.from(forwardingEmailAddressesData?.accounts.values() || []), 
        [forwardingEmailAddressesData?.accounts]
    );
   
    useEffect(() => {
        // Only fetch settings if we haven't already loaded them
        if (connectedAccounts.length > 0 && loadingState !== loadingTypes.loaded) {
            const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
            api.get(`${serverUrl}${API.getBotSettingsV2}/Gmail`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        const settingsData: BotMetadata = res.data.data;
                        setBotMetadata(settingsData);
                        setAccountMsgsOnly(
                            settingsData.filter_msgs?.account_msgs_only ??
                            false,
                        );
                        setIncludeOrgMsgs(
                            settingsData.filter_msgs?.include_org_msgs ?? true,
                        );
                        setBlacklistedScopes(
                            settingsData.filter_msgs?.blacklisted_scopes,
                        );
                        setBlacklistedDomains(
                            settingsData.filter_msgs?.blacklisted_domains,
                        );
                        setLoadingState(loadingTypes.loaded);
                    } else {
                        console.error(
                            "could not get gmail bot preferences, res status was",
                            res.status,
                        );
                    }
                })
                .catch((res) => {
                    console.error(
                        "could not get gmail bot preferences, res was",
                        res,
                    );
                });
        }
    }, [connectedAccounts.length, api, loadingState]); // Only depend on the length changing

    function saveSettings(
        accountMsgsOnlyInput: boolean,
        includeOrgMsgsInput: boolean,
        blacklistedScopesInput: ScopeResponse[],
        blacklistedDomainsInput: string[],
    ) {
        setLoadingState(loadingTypes.loaded)
        const filter_msgs: FilterMsgs = {
            include_org_msgs: includeOrgMsgsInput,
            account_msgs_only: accountMsgsOnlyInput,
            blacklisted_scopes: blacklistedScopesInput,
            blacklisted_domains: blacklistedDomainsInput
        };
        let requestData: BotMetadata;
        if (botMetadata) {
            requestData = botMetadata;
            requestData.bot_type = "Gmail";
        } else {
            requestData = {
                bot_type: "Gmail",
            };
        }
        requestData.filter_msgs = filter_msgs;
        api.post(
            `${URLS.serverUrl}${API.saveBotSettingsV2}/gmail`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                } else {
                    toast({
                        title: "Updated Settings!",
                        description:
                            "Your settings have been updated successfully.",
                    });
                    refetch();
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    }

    const handleAddScope = (email: string) => {
        const trimmedEmail = email.trim()
        if (!trimmedEmail) return;
        const newScope: ScopeResponse = {
            key: trimmedEmail,
            name: trimmedEmail,
        };
        const updatedScopes = [...(blacklistedScopes ?? []), newScope];
        setIsAddingScope(false);
        setNewScope("");
        saveSettings(accountMsgsOnly, includeOrgMsgs, updatedScopes, blacklistedDomains ?? []);
    };

    const handleRemoveScope = (email: string) => {
        const updatedScopes = [...(blacklistedScopes ?? [])].filter((scope) => scope.key !== email.trim());
        saveSettings(accountMsgsOnly, includeOrgMsgs, updatedScopes, blacklistedDomains ?? []);
    };

    const handleAddDomain = (domain: string) => {
        const trimmedDomain = domain.trim()
        if (!trimmedDomain) return;
        const updatedDomains = [...(blacklistedDomains ?? []), trimmedDomain];
        setIsAddingDomain(false);
        setNewDomain("");
        saveSettings(accountMsgsOnly, includeOrgMsgs, blacklistedScopes ?? [], updatedDomains);
    };

    const handleRemoveDomain = (domain: string) => {
        const updatedDomains = [...(blacklistedDomains ?? [])].filter((d) => d !== domain.trim());
        saveSettings(accountMsgsOnly, includeOrgMsgs, blacklistedScopes ?? [], updatedDomains);
    };

    const handleCopyToClipboard = (address: string) => {
        if (address) {
            navigator.clipboard.writeText(address);
            toast({
                title: "Copied to clipboard!",
                description: "The forwarding email address has been copied to your clipboard.",
            });
        }
    };


    const defaultForwardingEmailAddress = forwardingEmailAddresses?.[0]?.metadata?.forwarding_email_addresses ?? ""
    const [isEditing, setIsEditing] = useState(false);
    // todo: if we want to support multiple forwarding email addresses, we need to change this
    const [editValue, setEditValue] = useState(defaultForwardingEmailAddress);
    
    useEffect(() => {
        setEditValue(forwardingEmailAddresses?.[0]?.metadata?.forwarding_email_addresses ?? "");
    }, [forwardingEmailAddresses]);

    const handleSaveEdit = () => {
        const requestData = {
            email_addresses: editValue,
        }
        api.post(`${URLS.serverUrl}${API.saveForwardingEmailAddresses}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    toast({
                        title: "Forwarding email address updated",
                        description: "The forwarding email address has been updated successfully.",
                    });
                    setIsEditing(false);
                    setEditValue(res.data.data);
                    forwardingEmailAddressesRefetch();
                } else {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex
                    direction={"column"}
                    align={"start"}
                    gap="6"
                    className="w-full mb-10"
                >
                    <IntegrationHeader
                        integrationType={
                            readableIntegrationMapping.get("google") ?? "Google"
                        }
                        description="Sync Gmail requests into Assembly. You can add multiple Gmail accounts."
                        SvgIcon={GmailSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        <DialogTriggerButton onClick={googleContinue}>
                            Add Account
                            <Plus className="h-4 w-4" />
                        </DialogTriggerButton>
                    </Flex>
                    {isLoading && <Skeleton className="h-24 w-full" />}
                    {isError && (
                        <div className="w-full flex justify-center">
                            <ErrorChip />
                        </div>
                    )}
                    {!isLoading &&
                        !isError &&
                        connectedAccounts.length === 0 && (
                            <Card className="w-full shadow-none rounded-md">
                                <CardContent className="p-6 shadow-none">
                                    <p className="text-sm">
                                        No Google accounts connected. Click the
                                        "Connect Account" button to get started.
                                    </p>
                                </CardContent>
                            </Card>
                        )}
                    {!isLoading && !isError && connectedAccounts.length > 0 && (
                        <div className="grid gap-4 w-full">
                            {connectedAccounts.map((account) => (
                                <Card
                                    key={account.unique_name}
                                    className="shadow-none rounded-md"
                                >
                                    <CardContent className="flex flex-col items-start justify-between p-4">
                                        <div className="flex flex-row items-center justify-between w-full">
                                            <div className="flex items-center gap-4">
                                                <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-iris3 border border-iris4">
                                                    <Mail className="h-4 w-4 text-iris9" />
                                                </div>
                                                <div>
                                                    <h3 className="text-sm">
                                                        {account.unique_name}
                                                    </h3>
                                                    <p className="text-xs text-muted-foreground">
                                                        Enabled
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-1 text-sm">
                                                <span className="h-2 w-2 rounded-full bg-green-500" />
                                                Connected
                                            </div>
                                        </div>
                                        {/* <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button variant="ghost">
                                                        <ChevronDown className="h-4 w-4" />
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="end">
                                                    <DropdownMenuItem
                                                        className="text-destructive"
                                                        onClick={() =>
                                                            deleteMutation(
                                                                account.unique_name,
                                                            )
                                                        }
                                                    >
                                                        Disconnect
                                                    </DropdownMenuItem>
                                                </DropdownMenuContent>
                                            </DropdownMenu> */}
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    )}
                    {loadingState === loadingTypes.loading &&
                        connectedAccounts.length > 0 && (
                            <Skeleton className="bg-white">
                                <Text className="bg-gray-300 text-gray-300 text-xs">
                                    {[...Array(2)].map((_, index) => (
                                        <Text key={null}>{loremIpsum}</Text>
                                    ))}
                                </Text>
                            </Skeleton>
                        )}
                    {loadingState === loadingTypes.loaded &&
                        connectedAccounts.length > 0 && (
                            <Card className="shadow-none rounded-md w-full">
                                <CardContent className="flex flex-col items-start gap-3 py-5 px-6">
                                    <div className="text-md">Email Preferences</div>
                                    <div className="flex items-center justify-between text-xs text-muted-foreground gap-2 w-full">
                                        <div className="flex flex-col items-start">
                                            <div className="text-sm text-black">
                                                Organization Emails
                                            </div>
                                            Create interactions for emails from
                                            your company's domain.
                                        </div>
                                        <Switch
                                            checked={includeOrgMsgs}
                                            onCheckedChange={(checked) => {
                                                setIncludeOrgMsgs(checked);
                                                saveSettings(
                                                    accountMsgsOnly,
                                                    checked,
                                                    blacklistedScopes ?? [],
                                                    blacklistedDomains ?? []
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between text-xs text-muted-foreground gap-2 w-full">
                                        <div className="flex flex-col items-start">
                                            <div className="text-sm text-black">
                                                Account Emails
                                            </div>
                                            Only create interactions for emails
                                            from companies and customers who are
                                            included in your Assembly Accounts
                                            Table.
                                        </div>
                                        <Switch
                                            checked={accountMsgsOnly}
                                            onCheckedChange={(checked) => {
                                                setAccountMsgsOnly(checked);
                                                saveSettings(
                                                    checked,
                                                    includeOrgMsgs,
                                                    blacklistedScopes ?? [],
                                                    blacklistedDomains ?? []
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between text-xs text-muted-foreground gap-2 w-full">
                                        <div className="flex flex-col items-start">
                                            <div className="text-sm text-black">
                                                Spam Filter
                                            </div>
                                            Specific email addresses that you
                                            don't want to create interactions
                                            for.
                                            <div className="flex-1 flex flex-wrap gap-2 items-center mt-2">
                                                {(blacklistedScopes ?? []).map(
                                                    (scope: ScopeResponse) => (
                                                        <Card
                                                            key={scope.key}
                                                            className="rounded-lg shadow-none flex flex-row gap-1 px-2 py-1 items-center text-xs bg-muted text-gray-700"
                                                        >
                                                            {scope.name &&
                                                                `${scope.name} `}
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                className="h-3 w-3 p-0 ml-1.5 hover:bg-transparent"
                                                                onClick={() =>
                                                                    handleRemoveScope(
                                                                        scope.key,
                                                                    )
                                                                }
                                                            >
                                                                <X className="h-2.5 w-2.5" />
                                                            </Button>
                                                        </Card>
                                                    ),
                                                )}

                                                {isAddingScope ? (
                                                    <div className="flex items-center">
                                                        <Input
                                                            className="h-6 w-[180px] text-xs mr-1 py-0.5"
                                                            value={newScope}
                                                            onChange={(e) =>
                                                                setNewScope(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            onKeyDown={(e) =>
                                                                e.key ===
                                                                "Enter" &&
                                                                handleAddScope(
                                                                    newScope,
                                                                )
                                                            }
                                                            placeholder="Enter email address"
                                                            autoFocus
                                                        />
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className="h-6 px-1.5 rounded-l-none border-l-0"
                                                            onClick={() => {
                                                                setIsAddingScope(
                                                                    false,
                                                                );
                                                                setNewScope("");
                                                            }}
                                                        >
                                                            <XIcon
                                                                className="h-3 w-3"
                                                                color="red"
                                                            />
                                                        </Button>
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className="h-6 px-1.5 rounded-l-none border-l-0"
                                                            onClick={() =>
                                                                handleAddScope(
                                                                    newScope,
                                                                )
                                                            }
                                                        >
                                                            <Check
                                                                className="h-3 w-3"
                                                                color="green"
                                                            />
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        className="h-6 px-2"
                                                        onClick={() =>
                                                            setIsAddingScope(true)
                                                        }
                                                    >
                                                        {(blacklistedScopes?.length ??
                                                            0) > 0 ? (
                                                            <Plus className="h-3 w-3" />
                                                        ) : (
                                                            <div className="flex items-center gap-1">
                                                                <Plus className="h-3 w-3" />
                                                                Add Email
                                                            </div>
                                                        )}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between text-xs text-muted-foreground gap-2 w-full mt-2">
                                        <div className="flex flex-col items-start">
                                            <div className="text-sm text-black">
                                                Blocked Domains
                                            </div>
                                            Email domains that you
                                            don't want to create interactions
                                            for.
                                            <div className="flex-1 flex flex-wrap gap-2 items-center mt-2">
                                                {(blacklistedDomains ?? []).map(
                                                    (domain: string) => (
                                                        <Card
                                                            key={domain}
                                                            className="rounded-lg shadow-none flex flex-row gap-1 px-2 py-1 items-center text-xs bg-muted text-primary"
                                                        >
                                                            {domain}
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                className="h-3 w-3 p-0 ml-1.5 hover:bg-transparent"
                                                                onClick={() =>
                                                                    handleRemoveDomain(
                                                                        domain,
                                                                    )
                                                                }
                                                            >
                                                                <X className="h-2.5 w-2.5" />
                                                            </Button>
                                                        </Card>
                                                    ),
                                                )}

                                                {isAddingDomain ? (
                                                    <div className="flex items-center">
                                                        <Input
                                                            className="h-6 w-[180px] text-xs mr-1 py-0.5"
                                                            value={newDomain}
                                                            onChange={(e) =>
                                                                setNewDomain(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            onKeyDown={(e) =>
                                                                e.key ===
                                                                "Enter" &&
                                                                handleAddDomain(
                                                                    newDomain,
                                                                )
                                                            }
                                                            placeholder="Enter domain"
                                                            autoFocus
                                                        />
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className="h-6 px-1.5 rounded-l-none border-l-0"
                                                            onClick={() => {
                                                                setIsAddingDomain(
                                                                    false,
                                                                );
                                                                setNewDomain("");
                                                            }}
                                                        >
                                                            <XIcon
                                                                className="h-3 w-3"
                                                                color="red"
                                                            />
                                                        </Button>
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className="h-6 px-1.5 rounded-l-none border-l-0"
                                                            onClick={() =>
                                                                handleAddDomain(
                                                                    newDomain,
                                                                )
                                                            }
                                                        >
                                                            <Check
                                                                className="h-3 w-3"
                                                                color="green"
                                                            />
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        className="h-6 px-2"
                                                        onClick={() =>
                                                            setIsAddingDomain(true)
                                                        }
                                                    >
                                                        {(blacklistedDomains?.length ??
                                                            0) > 0 ? (
                                                            <Plus className="h-3 w-3" />
                                                        ) : (
                                                            <div className="flex items-center gap-1">
                                                                <Plus className="h-3 w-3" />
                                                                Add Domain
                                                            </div>
                                                        )}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        )}

                    {loadingState === loadingTypes.loaded && forwardingEmailAddresses.length === 1 && (
                        <Card className="shadow-none rounded-md w-full">
                        <CardContent className="flex flex-col items-start gap-3 py-5 px-6">
                            <div className="text-md">Google Group Preferences</div>
                            <div className="flex flex-col items-start">
                                <div className="text-sm text-black pb-3">
                                    Support Email:
                                </div>
                                <div className="flex flex-col gap-2">
                                    {forwardingEmailAddresses.map((address) => (
                                        <div key={address.unique_name} className="flex flex-col gap-4">
                                            <div className="flex items-center gap-1">
                                                <Input
                                                    placeholder="Add forwarding email"
                                                    className="w-[450px] h-8"
                                                    readOnly={!isEditing}
                                                    value={editValue}
                                                    onChange={(e) => setEditValue(e.target.value)}
                                                />
                                                {isEditing ? (
                                                    <div className="flex gap-1">
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className="h-8 px-2"
                                                            onClick={() => {
                                                                setIsEditing(false);
                                                                setEditValue(defaultForwardingEmailAddress);
                                                            }}
                                                        >
                                                            <X className="h-3 w-3" color="red" />
                                                        </Button>
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className="h-8 px-2"
                                                            onClick={() => {
                                                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                                if (!emailRegex.test(editValue)) {
                                                                    toast({
                                                                        title: "Invalid email format",
                                                                        description: "Please enter a valid email address",
                                                                        variant: "destructive",
                                                                    });
                                                                    return;
                                                                }
                                                                handleSaveEdit();
                                                            }}
                                                        >
                                                            <Check className="h-3 w-3" color="green" />
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        className="h-8 px-2"
                                                        onClick={() => setIsEditing(true)}
                                                    >
                                                        <Pencil1Icon className="h-3 w-3" />
                                                    </Button>
                                                )}
                                            </div>
                                            <div>
                                            <div className="text-sm text-black">
                                                Forwarding Email Instructions:
                                            </div>
                                            <div className="text-xs text-muted-foreground">
                                                Add the email below to your gmail group by navigating to <Link href="https://admin.google.com" target="_blank">admin.google.com</Link>, clicking on Apps &gt; Google Workspace &gt; Gmail &gt; Routing &gt; Email forwarding using recipient address map &gt; Add Another Rule <br/> <br/> Add a rule to forward emails from your support group to the assembly email address provided above. Messages to affect should be "All incoming messages". Make sure to check "Also route to original destination" and click "Save".

                                            </div>
                                            </div>
                                            <div className="flex items-center gap-1">
                                                <Input 
                                                    value={address.unique_name}
                                                    readOnly
                                                    className="w-[450px] h-8"
                                                />
                                                <Button
                                                    onClick={() => handleCopyToClipboard(address.unique_name)}
                                                    variant="outline"
                                                    size="sm"
                                                    className="h-8 px-3 flex items-center gap-1"
                                                >
                                                    <ClipboardIcon className="h-3 w-3" />
                                                    Copy
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    )}
                </Flex>
            </Box>
        </div>
    );
}
