import { Card } from "@/component/shadcn/ui/card";
import type { Teams } from "@/interfaces/serverData";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { TeamBadges } from "@/pages/WorkspacePreferences/TeamBadges";
import { SavedViewBadge } from "@/utilities/methods";
import type { UseQueryResult } from "@tanstack/react-query";

interface NoInteractionsPromptProps {
    userID: string;
    listType: IssueListType;
    teamsQuery: UseQueryResult<Teams[], Error>;
    viewName?: string;
    teamID?: string;
}

export function NoInteractionsPrompt({
    userID,
    listType,
    teamsQuery,
    viewName,
    teamID,
}: NoInteractionsPromptProps) {
    return (
        <div className="flex items-center justify-center h-full">
            <Card className="w-[400px] rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none py-7 px-10 flex flex-col gap-1">
                <div className="font-semibold">
                    No Interactions Found
                </div>
                {listType === IssueListType.Issues && (
                    <div className="text-sm">
                        Remove filters or hook up integrations under Workspace Preferences, then new messages will automatically create interactions and populate them here!
                    </div>
                )}
                {listType === IssueListType.Inbox && (
                    <div className="text-sm">
                        Remove filters or assign interactions to yourself. Then those interactions will then populate here!
                    </div>
                )}
                {listType === IssueListType.Team && (
                    <div className="text-sm">
                        Remove filters or assign
                        <TeamBadges
                            teams={(
                                teamsQuery.data ?? []
                            ).filter(
                                (team) =>
                                    team.id === teamID,
                            )}
                            defaultIsWorkspace={false}
                            className="mx-1.5"
                        />
                        as a Team Owner on interactions. Then those interactions will populate here!
                    </div>
                )}
                {listType === IssueListType.TeamViewer && (
                    <div className="text-sm">
                        Remove filters or add
                        <TeamBadges
                            teams={(
                                teamsQuery.data ?? []
                            ).filter(
                                (team) =>
                                    team.id === teamID,
                            )}
                            defaultIsWorkspace={false}
                            className="mx-1.5"
                        />
                        as a Viewer on interactions. Then those interactions will then populate here!
                    </div>
                )}
                {listType === IssueListType.View && (
                    <div className="text-sm">
                        No current interactions fit
                        {viewName ? SavedViewBadge(viewName, "mx-1.5") : "this view's"}
                        filters. Once an interaction does, it will then populate here!
                    </div>
                )}
            </Card>
        </div>
    );
}
