import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import type React from "react";
import { useEffect, useState } from "react";

import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { ContractType, LabelsType } from "@/interfaces/serverData";

import { Button } from "@/component/shadcn/ui/button";
import { useToast } from "@/component/shadcn/ui/use-toast";
import type {
    Account,
    AccountUpdatePayload,
    ListCustomersResponse,
} from "@/interfaces/serverData";
import { ContactDropdown } from "@/reusable_components/dropdowns/ContactDropdown";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AccountsListType } from "./Accounts";

interface LinkContactsPopupProps {
    triggerElement: React.ReactNode;
    listType: AccountsListType;
    userID: string;
    company?: Account;
    contacts?: Account[];
    updateData?: (company: boolean, contacts: boolean, tickets: boolean) => Promise<void>;
}

const LinkContactsPopup: React.FC<LinkContactsPopupProps> = ({
    triggerElement,
    listType,
    userID,
    company,
    contacts: propsContacts,
    updateData,
}) => {
    let teamID = undefined;
    if (window.location.pathname.split("/")[1] === "teams") {
        teamID = window.location.pathname.split("/")[2] || "";
    }
    const [contacts, setContacts] = useState<Account[]>(propsContacts ?? []);
    const [contactEditing, setContactEditing] = useState<boolean>(false);

    const { toast } = useToast();
    const fetchContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    limit: 100,
                    offset: pageParam,
                },
            });
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const fetchTeamContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(
                `${URLS.serverUrl}${url}/team/${teamID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: contactsData,
        fetchNextPage: fetchContactsNextPage,
        hasNextPage: contactsHasNextPage,
        isFetchingNextPage: isFetchingContactsNextPage,
        refetch: refetchContacts,
        isLoading: contactsIsLoading,
        isError: contactsIsError,
    } = useInfiniteQuery({
        queryKey:
            listType === AccountsListType.Team
                ? ["team_customers"]
                : ["all_customers"],
        queryFn:
            listType === AccountsListType.Team
                ? fetchTeamContacts
                : fetchContacts,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });

    // Fetch all the data
    useEffect(() => {
        if (contactsHasNextPage && !isFetchingContactsNextPage) {
            fetchContactsNextPage();
        }
    }, [
        contactsHasNextPage,
        isFetchingContactsNextPage,
        fetchContactsNextPage,
    ]);

    const contactsCombinedData =
        contactsData && Array.isArray(contactsData.pages)
            ? contactsData.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    const toggleContact = (
        customer: Account,
        existingContacts: Account[],
        setSelectedContacts: React.Dispatch<React.SetStateAction<Account[]>>,
    ) => {
        let newContacts = Array.from(existingContacts);
        // Remove if apart of the current contacts
        if (newContacts.map((t) => t.id).includes(customer.id)) {
            newContacts = newContacts.filter((t) => t.id !== customer.id);
        } else {
            // Add if it's a new customer that we're adding
            newContacts.push(customer);
        }
        setContacts(newContacts);
        setSelectedContacts(newContacts);
    };

    const api = useApi();

    function onEdit() {
        const requestData: AccountUpdatePayload = {
            id: company?.id ?? "",
            user: userID,
            name: company?.name ?? "",
            domain: company?.domain ?? "",
            image_url: company?.image_url ?? "",
            contract_value: company?.contract_value ?? 0,
            contract_type: company?.contract_type
                ? (company?.contract_type as ContractType)
                : ContractType.Month,
            tier:
                (company?.labels ?? []).find(
                    (label) => label.type === LabelsType.Tier,
                )?.id ?? "",
            stage:
                (company?.labels ?? []).find(
                    (label) => label.type === LabelsType.Stage,
                )?.id ?? "",
            company_type:
                (company?.labels ?? []).find(
                    (label) => label.type === LabelsType.CompanyType,
                )?.id ?? "",
            contacts: contacts,
        };

        console.log("request data is", requestData);

        api.patch(
            `${URLS.serverUrl}${ContactsAPI.editCompany.url}/${company?.id}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                } else {
                    toast({
                        title: "Updated Company!",
                        description:
                            "The company has been updated successfully.",
                    });
                    if (updateData) {
                        updateData(true, true, false);
                    }
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
        setOpen(false);
    }
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerElement}</DialogTrigger>
            <DialogContent className="pt-10 max-w-[850px] w-full">
                <div className="mx-10 my-5">
                    <DialogHeader className="justify-left text-left items-left pb-2">
                        <DialogTitle>Link Customer</DialogTitle>
                        <DialogDescription>{`Link or unlink existing customers as contacts of ${company ? company.name : "this company"}.`}</DialogDescription>
                    </DialogHeader>
                    <ContactDropdown
                        selectedContactIDs={contacts.map((c) => c.id)}
                        selectedContacts={contacts}
                        teamID={teamID}
                        setContact={toggleContact}
                        editing={contactEditing}
                        setEditing={setContactEditing}
                        condensed={false}
                        condensedBadges={true}
                    />
                    <div className="pt-4 justify-end text-end items-end">
                        <Button
                            className="bg-iris9"
                            type="submit"
                            onClick={() => onEdit()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LinkContactsPopup;
