import AttributesBadge from "@/component/AttributesBadge";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { useApi, useApiFormData } from "@/interfaces/api";
import { useAuthInfo } from "@propelauth/react";
import { PlusIcon } from "@radix-ui/react-icons";
import { BuildingIcon, MailboxIcon } from "lucide-react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export const WorkflowCreateButton: React.FC = () => {
    const LOREM_IPSUM = "Lorem ipsum dolor sit amet";
    const api = useApi();
    const apiFormData = useApiFormData();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    const navigate = useNavigate();

    const navigateToInteractionsWorklowEditor = async () => {
        navigate("/preferences/workflows/create/interactions");
    };

    const navigateToAccountsWorklowEditor = async () => {
        navigate("/preferences/workflows/create/accounts");
    };

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <AttributesBadge className="px-2">
                        <PlusIcon strokeWidth={1.5} className="h-3.5 w-3.5" />
                        <p className="text-sm text-[#60646c]">Create</p>
                    </AttributesBadge>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    className="w-51 text-[#60646c]"
                    align="end"
                    side="bottom"
                >
                    <DropdownMenuItem
                        className="flex items-center gap-2 text-[13px] px-2 py-1.5 hover:bg-muted"
                        onSelect={() => navigateToInteractionsWorklowEditor()}
                    >
                        <MailboxIcon
                            className="h-3.5 w-3.5 flex-shrink-0 text-[#5e6ad2]"
                        />
                        Interactions Workflow
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        className="flex items-center gap-2 text-[13px] px-2 py-1.5 hover:bg-muted"
                        onSelect={() => navigateToAccountsWorklowEditor()}
                    >
                        <BuildingIcon
                            className="h-3.5 w-3.5 flex-shrink-0 text-[#5e6ad2]"
                        />
                        Accounts Workflow
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
};

export default WorkflowCreateButton;
