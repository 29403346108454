import AttributesBadge from "@/component/AttributesBadge";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import { toast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    type ExternalIssues,
    type Insight,
    type IssueType,
    type OrgInfoResponse,
    type PublishTicketPayload,
    type PublishTicketResponse,
    PublishTicketType,
    type ScopeResponse,
    type Ticket,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    getExternalIssueIcon,
    getExternalIssueText,
} from "@/utilities/methods";
import {
    CaretDownIcon,
    InfoCircledIcon,
    ReaderIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { Skeleton, Text } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { PencilRulerIcon } from "lucide-react";
import { useEffect, useState } from "react";

function extractParts(url: string) {
    try {
        const urlObj = new URL(url);
        const pathParts = urlObj.pathname.split("/").filter((part) => part);
        if (url.toLowerCase().includes("linear")) {
            return pathParts.length > 2 ? pathParts[2] : null;
        } else if (url.toLowerCase().includes("github")) {
            return pathParts.length > 3
                ? `${pathParts[1]} ${pathParts[3]}`
                : null;
        } else if (url.toLowerCase().includes("atlassian")) {
            return pathParts.length > 1 ? pathParts[1] : null;
        }
    } catch (error) {
        console.error("Invalid URL or error parsing URL:", error);
        return null;
    }
}

// A ticket or an insight should be inputted
interface ExternalIssuesSectionProps {
    ticket?: Ticket;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    insight?: Insight;
    updateInsightState?: (newState: Partial<Insight>) => void;
    userID: string;
}

export const ExternalIssuesSection: React.FC<ExternalIssuesSectionProps> = ({
    ticket,
    refetchTicketData,
    insight,
    updateInsightState,
    userID,
}) => {
    const LOREM_IPSUM = "Lorem ipsum dolor sit amet";
    const api = useApi();
    const externalIssues: ExternalIssues[] =
        ticket?.external_issues ?? insight?.external_issues ?? [];
    const [creatingExternalIssue, setCreatingExternalIssue] =
        useState<boolean>(false);
    const [enabledIntegrations, setEnabledIntegrations] = useState<string[]>(
        [],
    );
    const [destinations, setDestinations] = useState<ScopeResponse[]>([]);
    const [isDestinationsLoading, setIsDestinationsLoading] = useState(false);
    const [issueTypes, setIssueTypes] = useState<IssueType[]>([]);
    const [isIssueTypesLoading, setIsIssueTypesLoading] = useState(false);

    const [selectedInt, setSelectedInt] =
        useState<string>("Select Integration");
    const [selectedDest, setSelectedDest] = useState<ScopeResponse>({
        key: "",
        name: "Select Destination",
    });
    const [issueType, setIssueType] = useState<IssueType>();
    const [priority, setPriority] = useState<string>("None");
    const DestIcon: React.ElementType =
        integrationBackEndDataMappingToSvg.get(selectedInt) ?? ReaderIcon;
    const integrationLabelMap = new Map<string, string>([
        ["GitHubTicket", "GitHub Issues"],
        ["GithubDiscussion", "Github Discussions"],
        ["Linear", "Linear"],
        ["Jira", "Jira"],
        ["Intercom", "Intercom"],
    ]);

    useEffect(() => {
        const requestData = {
            types: [
                "GithubDiscussion",
                "GitHubTicket",
                "Linear",
                "Jira",
                "Intercom",
            ],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        const enabledInts: string[] = [];
                        if (orgInfo.GitHubTicket) {
                            enabledInts.push("GitHubTicket");
                        }
                        if (orgInfo.GithubDiscussion) {
                            enabledInts.push("GithubDiscussion");
                        }
                        if (orgInfo.Linear) {
                            enabledInts.push("Linear");
                        }
                        if (orgInfo.Jira) {
                            enabledInts.push("Jira");
                        }
                        if (orgInfo.Intercom) {
                            enabledInts.push("Intercom");
                        }
                        setEnabledIntegrations(enabledInts);
                        if (enabledInts.length > 0) {
                            setSelectedInt(enabledInts[0]);
                        }
                    }
                } else {
                    console.log("failed to get integrations");
                }
            })
            .catch((res) => {
                console.log("failed to get integrations");
            });
    }, [api]);

    useEffect(() => {
        if (selectedInt !== "Select Integration") {
            setIsDestinationsLoading(true);
            setIsIssueTypesLoading(true);
            const requestData = {
                type: selectedInt,
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setDestinations(dataItems);
                    if (dataItems.length > 0) {
                        setSelectedDest(dataItems[0]);
                    }
                })
                .catch((res) => {
                    console.log("could not find sources");
                })
                .finally(() => {
                    setIsDestinationsLoading(false);
                });
        }
    }, [selectedInt, api]);

    useEffect(() => {
        if (
            (selectedInt === "Intercom" || selectedInt === "Jira") &&
            selectedDest.name !== "Select Destination"
        ) {
            setIsIssueTypesLoading(true);
            const requestData = {
                type: selectedInt,
                project_key: selectedDest.key,
            };
            api.post(URLS.serverUrl + API.getIssueTypes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: IssueType[] = res.data.data;
                    setIssueTypes(dataItems);
                    if (dataItems.length > 0) {
                        setIssueType(dataItems[0]);
                    }
                })
                .catch((res) => {
                    console.error("Error in grabbing issue types");
                })
                .finally(() => {
                    setIsIssueTypesLoading(false);
                });
        } else {
            setIssueTypes([]);
        }
    }, [selectedInt, selectedDest, api]);

    const createExternalIssue = async () => {
        // Submit the external issue
        if (creatingExternalIssue) {
            toast({
                title: "Creating external issue...",
                variant: "default",
                className: "loading",
            });
            if (selectedInt === "Select Integration") {
                toast({
                    title: "Please select an integration",
                    variant: "destructive",
                });
                return;
            }
            if (selectedDest.name === "Select Destination") {
                toast({
                    title: "Please select a destination",
                    variant: "destructive",
                });
                return;
            }
            if (!issueType && ["Intercom", "Jira"].includes(selectedInt)) {
                toast({
                    title: "Please select a ticket type",
                    variant: "destructive",
                });
                return;
            }
            if (!priority && ["Intercom"].includes(selectedInt)) {
                toast({
                    title: "Please select a priority, even if it's None",
                    variant: "destructive",
                });
                return;
            }
            try {
                let payload: PublishTicketPayload;
                let existingExtIssues: ExternalIssues[];
                if (ticket) {
                    payload = {
                        connection: selectedInt,
                        title: ticket.title,
                        source: "Web",
                        user: userID,
                        question: ticket.query,
                        url: ticket.url,
                        comments: [],
                        system_id: ticket.id,
                        system_type: PublishTicketType.Issue,
                        destination: selectedDest,
                        issue_type: issueType,
                    };
                    if (priority !== "None" && priority !== "") {
                        payload.priority = priority;
                    }
                    existingExtIssues = ticket.external_issues;
                } else if (insight) {
                    payload = {
                        connection: selectedInt,
                        title: insight.title,
                        source: "Web",
                        user: userID,
                        question: insight.description,
                        url: `https://www.dashboard.askassembly.app/insight/${insight.id}`,
                        comments: [],
                        system_id: insight.id,
                        system_type: PublishTicketType.Insight,
                        destination: selectedDest,
                        issue_type: issueType,
                    };
                    if (priority !== "None" && priority !== "") {
                        payload.priority = priority;
                    }
                    existingExtIssues = insight.external_issues;
                } else {
                    toast({
                        title: "Please provide an issue or insight",
                        variant: "destructive",
                    });
                    console.error(
                        "no ticket/issue nor insight was provided to create the external issue for",
                    );
                    return;
                }

                const response = await api.post(
                    URLS.serverUrl + API.publishExternalIssue,
                    payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                if (response.status === 200) {
                    const res: PublishTicketResponse = response.data.data;
                    // Insight Display stays updated based on the insightState
                    if (ticket && refetchTicketData) {
                        refetchTicketData();
                    } else if (insight && updateInsightState) {
                        const newExtIssue: ExternalIssues = {
                            url: res.urls[0],
                            id: "",
                            parent_id: "",
                        };
                        updateInsightState({
                            external_issues: [
                                ...existingExtIssues,
                                newExtIssue,
                            ],
                        });
                    }
                    setSelectedDest({
                        key: "",
                        name: "Select Destination",
                    })
                    setIssueType(undefined)
                    setPriority("None")
                    toast({
                        title: "External Issue Created",
                        description: "External issue created successfully",
                        variant: "default",
                    });
                } else {
                    console.log(
                        `Failed to create external issue: ${response.status}`,
                    );
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                }
            } catch (error) {
                console.error("Error creating external issue:", error);
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            }
            setCreatingExternalIssue(false);
        } else {
            setCreatingExternalIssue(true);
        }
    };

    return (
        <div className="pb-2">
            {externalIssues?.map((issue) => {
                const IssueSvgImage: React.ElementType = getExternalIssueIcon(
                    issue.url,
                );
                const issueName = extractParts(issue.url);
                return (
                    <Card className="p-2 my-1 rounded" key={issue.id}>
                        <div className="prose max-w-none text-xs flex items-center gap-1 px-2 py-0">
                            <IssueSvgImage
                                style={{
                                    width: "20px",
                                    height: "20px",
                                }}
                                justify="start"
                            />
                            <span className="px-1">
                                <a
                                    href={issue.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-iris9 hover:text-iris11 hover:underline"
                                >
                                    {issueName ??
                                        getExternalIssueText(issue.url)}
                                </a>
                            </span>
                        </div>
                    </Card>
                );
            })}
            {creatingExternalIssue ? (
                <Card className="py-2 px-7 my-1 relative">
                    <Button
                        variant="ghost"
                        className="text-xs p-1 absolute top-1 right-1"
                        onClick={() => setCreatingExternalIssue(false)}
                    >
                        <CaretDownIcon className="w-4 h-4" />
                    </Button>
                    <div className="prose max-w-none text-xs flex flex-col gap-4 p-2 mt-3">
                        <div className="flex items-center justify-between gap-2">
                            <div className="text-[14px] text-muted-foreground">
                                Integration
                            </div>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div>
                                        <AttributesBadge>
                                            <DestIcon
                                                style={{
                                                    width: "15px",
                                                    height: "15px",
                                                }}
                                                justify="start"
                                            />
                                            {integrationLabelMap.get(
                                                selectedInt,
                                            ) ?? selectedInt}
                                            <TriangleDownIcon className="h-3 w-3" />
                                        </AttributesBadge>
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    side="bottom"
                                    align="end"
                                    className="w-[200px] p-2"
                                >
                                    {enabledIntegrations.map((integration) => {
                                        const IssueSvgImage: React.ElementType =
                                            integrationBackEndDataMappingToSvg.get(
                                                integration,
                                            ) ?? ReaderIcon;
                                        return (
                                            <DropdownMenuItem
                                                key={integration}
                                                className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                onSelect={() => {
                                                    setSelectedInt(integration);
                                                    setPriority("None");
                                                }}
                                            >
                                                <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                    <IssueSvgImage
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                        }}
                                                        justify="start"
                                                    />
                                                    {integration}
                                                </div>
                                            </DropdownMenuItem>
                                        );
                                    })}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>

                        <div className="flex items-center justify-between gap-2">
                            <div className="text-[14px] text-muted-foreground">
                                Destination
                            </div>
                            {isDestinationsLoading ? (
                                <Skeleton>
                                    <Text>{LOREM_IPSUM}</Text>
                                </Skeleton>
                            ) : (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <div>
                                            <AttributesBadge>
                                                {selectedDest.name}
                                                <TriangleDownIcon className="h-3 w-3" />
                                            </AttributesBadge>
                                        </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                        side="bottom"
                                        align="end"
                                        className="w-[200px] h-[180px] p-2 overflow-y-auto"
                                    >
                                        {destinations.map((channel) => (
                                            <DropdownMenuItem
                                                key={channel.key}
                                                className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                onSelect={() =>
                                                    setSelectedDest(
                                                        channel,
                                                    )
                                                }
                                            >
                                                <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                    {channel.name}
                                                </div>
                                            </DropdownMenuItem>
                                        ))}
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            )}
                        </div>

                        {/* Only allow ticket type selection for Intercom and Jira */}
                        {["Intercom", "Jira"].includes(selectedInt) && (
                            <div className="flex items-center justify-between gap-2">
                                <div className="text-[14px] text-muted-foreground">
                                    Ticket Type
                                </div>
                                {isIssueTypesLoading ? (
                                    <Skeleton>
                                        <Text>{LOREM_IPSUM}</Text>
                                    </Skeleton>
                                ) : (
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <div>
                                                <AttributesBadge>
                                                    {issueType?.name ??
                                                        ""}
                                                    <TriangleDownIcon className="h-3 w-3" />
                                                </AttributesBadge>
                                            </div>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            side="bottom"
                                            align="end"
                                            className="w-[200px] p-2"
                                        >
                                            {issueTypes.map((it) => (
                                                <DropdownMenuItem
                                                    key={it.id}
                                                    className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                    onSelect={() =>
                                                        setIssueType(it)
                                                    }
                                                >
                                                    <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                        {it.name}
                                                    </div>
                                                </DropdownMenuItem>
                                            ))}
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                )}
                            </div>
                        )}

                        {/* Only allow priority selection for Intercom */}
                        {selectedInt === "Intercom" && (
                            <div className="flex items-center justify-between gap-2">
                                <div className="text-[14px] text-muted-foreground flex items-center gap-1">
                                    Priority
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0 text-destructive hover:bg-sidebar" />
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col max-w-[250px]">
                                                Ticket Type must
                                                have a valid Priority
                                                attribute if not
                                                selecting None.
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <div>
                                            <AttributesBadge>
                                                {priority}
                                                <TriangleDownIcon className="h-3 w-3" />
                                            </AttributesBadge>
                                        </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                        side="bottom"
                                        align="end"
                                        className="w-[200px] p-2"
                                    >
                                        {[
                                            "None",
                                            "Low",
                                            "Normal",
                                            "High",
                                            "Urgent",
                                        ].map((p) => (
                                            <DropdownMenuItem
                                                key={p}
                                                className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                onSelect={() =>
                                                    setPriority(p)
                                                }
                                            >
                                                <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                    {p}
                                                </div>
                                            </DropdownMenuItem>
                                        ))}
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                        )}

                        <div className="flex items-center justify-between gap-2">
                            <div />
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={createExternalIssue}
                                className="py-0 flex items-center gap-1.5"
                            >
                                Create External Issue
                                <PencilRulerIcon
                                    strokeWidth={1.5}
                                    className="h-4 w-4 text-[#5e6ad2] flex-shrink-0"
                                />
                            </Button>
                        </div>
                    </div>
                </Card>
            ) : (
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={createExternalIssue}
                    className="py-0 flex items-center gap-1.5 mt-3"
                >
                    Create External Issue
                    <PencilRulerIcon
                        strokeWidth={1.5}
                        className="h-4 w-4 text-[#5e6ad2] flex-shrink-0"
                    />
                </Button>
            )}
        </div>
    );
};
