export interface GetSystemNotificationResponse {
    enabled: boolean;
    metadata: {
        reminders_and_deadlines: boolean;
    };
}

export interface TimePreferences {
    date_type: string;
    date_value: number;
    date_unit: string;
}
export interface GetMilestonePreferencesResponse {
    enabled: boolean;
    metadata: {
        milestone_preferences: TimePreferences;
        task_preferences: TimePreferences;
    };
}

export enum FeatureType {
    MILESTONE = "MILESTONE",
    SYSTEM = "SYSTEM",
}
