import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    type Account,
    type AccountsLabel,
    type Category,
    type CustomerGroup,
    type FilterIncludeType,
    type GetUserResponse,
    type Label,
    LabelsType,
    type ScopeResponse,
    type Teams,
    type Template,
    type Topic,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    getBadgeForAccountsLabel,
    getBadgeForAccountsTag,
    getLowercase,
    getOptions,
    getPlural,
    getStatusIcon,
} from "@/utilities/methods";
import {
    AllSidesIcon,
    AvatarIcon,
    CheckIcon,
    ComponentBooleanIcon,
    CrossCircledIcon,
    DotFilledIcon,
    GroupIcon,
    ImageIcon,
    QuestionMarkIcon,
} from "@radix-ui/react-icons";
import { Badge, Button } from "@radix-ui/themes";
import {
    BoxIcon,
    HouseIcon,
    MailIcon,
    MessageCircleReply,
    NotepadText,
    UsersIcon,
} from "lucide-react";
import { useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import {
    insightStatusPriorityOrder,
    sources,
    ticketStatusPriorityOrder,
} from "./constants";

export const ElementDisplay = (
    type: string,
    label: string,
    value: string,
    color: string,
    filters: Map<
        string,
        Set<
            | string
            | { label: string; value: string; key: string; color: string }
        >
    >,
    categories: Category[],
    users: GetUserResponse[],
    customerGroups: CustomerGroup[],
    channels: Map<string, ScopeResponse[]>,
    customers: Account[],
    companies: Account[],
    interactionTypes: Label[],
    accountsLabels: AccountsLabel[],
) => {
    const typeFilters = filters.get(type);
    let isSelected = false;
    if (typeFilters) {
        isSelected =
            typeFilters.has(value) ||
            [...typeFilters].some((elem) => {
                if (typeof elem === "string") {
                    // If it's a string, check if it matches the value
                    return elem === value;
                } else if (typeof elem === "object" && elem !== null) {
                    // If it's an object, compare keys or values
                    const typedElem = elem as {
                        label: string;
                        value: string;
                        key: string;
                        color: string;
                    };
                    return typedElem.key === value || typedElem.value === value;
                }
                return false;
            });
    } else {
        // In case there are additional prefixes, check if the key starts with the prefix (i.e. 'Status_...')
        isSelected = [...filters.keys()].some((key) => {
            if (key.startsWith(`${type}_`)) {
                const correspondingFilters = filters.get(key);
                if (correspondingFilters) {
                    return (
                        correspondingFilters.has(value) ||
                        [...correspondingFilters].some((elem) => {
                            if (typeof elem === "string") {
                                return elem === value;
                            } else if (
                                typeof elem === "object" &&
                                elem !== null
                            ) {
                                const typedElem = elem as {
                                    label: string;
                                    value: string;
                                    key: string;
                                    color: string;
                                };
                                return (
                                    typedElem.key === value ||
                                    typedElem.value === value
                                );
                            }
                            return false;
                        })
                    );
                }
            }
            return false;
        });
    }
    if (sources.map((source) => source.value).includes(type)) {
        const sourceFilters = filters.get("Source");
        if (sourceFilters) {
            isSelected = filters.get("Source")?.has(value) ?? false;
        } else {
            isSelected = [...filters.keys()].some(
                (key) =>
                    key.startsWith("Source_") && filters.get(key)?.has(value),
            );
        }
    }
    switch (type) {
        case "Source": {
            const Icon =
                integrationBackEndDataMappingToSvg.get(value) ?? AllSidesIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Broadcast": {
            const Icon =
                integrationBackEndDataMappingToSvg.get(value) ??
                QuestionMarkIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Tag":
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0 rounded-xl py-1 px-2"
                    >
                        <div className="flex flex-row items-center">
                            {value === "AI_MATCH_TAG" ? (
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                            ) : value === "Any category" ? (
                                <BoxIcon className="w-3 h-3 mr-0.5" />
                            ) : (
                                <ComponentBooleanIcon
                                    color={
                                        categories.find(
                                            (category) =>
                                                category.name === value,
                                        )?.color ?? "gray"
                                    }
                                />
                            )}
                            <p className="pl-0.5">{label}</p>
                        </div>
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        case "InteractionType":
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color={"gray"}
                        size="2"
                        variant="outline"
                        className="m-0 py-1 px-2 max-w-full rounded-sm"
                    >
                        <div className="flex flex-row gap-0.5 items-center">
                            {value === "*ANY*" ||
                                value === "Any interaction type" ? (
                                <BoxIcon className="w-3 h-3 mr-0.5" />
                            ) : (
                                <MailIcon
                                    color={
                                        interactionTypes?.find(
                                            (interactionType) =>
                                                interactionType.name === value,
                                        )?.color ?? "gray"
                                    }
                                    className="w-2.5 h-2.5"
                                />
                            )}
                            <p className="pl-0.5">{label}</p>
                        </div>
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        case "Topic":
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0 rounded-xl py-1 px-2"
                    >
                        <div className="flex flex-row items-center">
                            {value === "AI_MATCH_TOPIC" ? (
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                            ) : value === "NONE" ? (
                                <CrossCircledIcon className="mr-1" />
                            ) : value === "Any tag" ? (
                                <BoxIcon className="w-3 h-3 mr-1" />
                            ) : (
                                <DotFilledIcon
                                    color={color !== "" ? color : "#9B9EF0"}
                                    style={{
                                        transform: "scale(1.8)",
                                    }}
                                />
                            )}
                            <p className="pl-0.3">{label}</p>
                        </div>
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        case "Status":
        case "Insight Status": {
            const Icon = getStatusIcon(value ?? "Unknown");
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Assignee": {
            const pictureURL = users.find(
                (user) => user.id === value,
            )?.picture_url;
            return (
                <div className="flex items-center gap-2">
                    <div className="lb-avatar rounded-lg w-5 h-5">
                        {pictureURL ? (
                            <img
                                className="lb-avatar-image"
                                src={pictureURL}
                                alt={label}
                            />
                        ) : (
                            <AvatarIcon className="w-6 h-6" />
                        )}
                    </div>
                    <span className="lb-comment-author text-xs font-normal font-destructive">
                        {label}
                    </span>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Company": {
            const fullCompany = companies.find((c) => c.id === value);
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        {fullCompany?.image_url !== "" &&
                            fullCompany?.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-4 h-4">
                                <img
                                    className="lb-avatar-image"
                                    src={fullCompany?.image_url}
                                    alt={fullCompany?.name}
                                />
                            </div>
                        ) : (
                            <ImageIcon className="w-4 h-4" />
                        )}
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Customer": {
            const fullCustomer = customers.find((c) => c.id === value);
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        {fullCustomer?.image_url !== "" &&
                            fullCustomer?.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-4 h-4">
                                <img
                                    className="lb-avatar-image"
                                    src={fullCustomer?.image_url}
                                    alt={fullCustomer?.name}
                                />
                            </div>
                        ) : (
                            <ImageIcon className="w-4 h-4" />
                        )}
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Customer Group": {
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <GroupIcon />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Team":
        case "TeamOwner": {
            return (
                <div className="flex items-center gap-2">
                    {label === "General" || label === "No Team Owner" ? (
                        <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                            <HouseIcon className="text-iris9 w-3 h-3 !size-3" />
                        </div>
                    ) : (
                        <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                            <UsersIcon
                                className="text-red9 w-3 h-3 !size-3"
                            />
                        </div>
                    )}
                    {label}
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Slack":
        case "Discord":
        case "CommunitySlack":
        case "Gmail":
        case "API": {
            const [source, channelName] = label.split(" -- ");
            const formattedLabel = channelName ? channelName.trim() : label;
            const formattedSource = source ? source.trim() : "";
            const Icon =
                integrationBackEndDataMappingToSvg.get(formattedSource) ??
                QuestionMarkIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {formattedLabel}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Template": {
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <NotepadText className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-3.5 w-3.5" />}
                </div>
            );
        }
        case "Reply": {
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <MessageCircleReply className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Tier":
        case "Stage":
        case "CompanyType":
        case "AccountTag": {
            let labelType = LabelsType.Tier
            if (type === "Stage") {
                labelType = LabelsType.Stage
            } else if (type === "CompanyType") {
                labelType = LabelsType.CompanyType
            } else if (type === "AccountTag") {
                labelType = LabelsType.AccountTag
            }
            const accountLabel = accountsLabels.find(
                (label) =>
                    label.id === value && label.type === labelType,
            )
            if (!accountLabel) {
                return (
                    <div className="flex items-center gap-2">
                        <ScnBadge
                            className="flex items-center gap-2 font-normal px-2 py-1"
                            variant="outline"
                        >
                            <BoxIcon className="w-3 h-3" />
                            {label}
                        </ScnBadge>
                        {isSelected && <CheckIcon className="h-3.5 w-3.5" />}
                    </div>
                )
            }
            return (
                <div className="flex items-center gap-2">
                    {labelType === LabelsType.AccountTag ? getBadgeForAccountsTag(accountLabel) : getBadgeForAccountsLabel(accountLabel)}
                    {isSelected && <CheckIcon className="h-3.5 w-3.5" />}
                </div>
            );
        }
        case "AccountType": {
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color={
                            value === "Company"
                                ? "blue"
                                : "orange"
                        }
                        size="2"
                        radius="medium"
                        variant="soft"
                        className="text-xs items-center px-2 py-1 rounded-sm"
                    >
                        {label}
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        default:
            return (
                <div className="flex items-center gap-2">
                    {label}
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
    }
};

interface FilterDropdownElementProps {
    type: string;
    filters: Map<
        string,
        Set<
            | string
            | { label: string; value: string; key: string; color: string }
        >
    >;
    handleItemSelect: (
        type: string,
        option: { label: string; value: string; key: string; color: string },
        index?: number,
        includeType?: FilterIncludeType
    ) => () => void;
    interactionTypes: Label[];
    categories: Category[];
    topics: Topic[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    teams: Teams[];
    isSavedViewFilter: boolean;
    extraOptions?: {
        color: string;
        label: string;
        value: string;
        key: string;
    }[];
    channels: Map<string, ScopeResponse[]>;
    key?: string;
    index?: number;
    templates?: Template[];
    customers?: Account[];
    companies?: Account[];
    accountLabels?: AccountsLabel[];
    organizationSources?: string[];
    includeType?: FilterIncludeType;
}

function FilterDropdownElement({
    type,
    filters,
    handleItemSelect,
    interactionTypes,
    categories,
    topics,
    users,
    customerGroups,
    teams,
    isSavedViewFilter,
    extraOptions,
    channels,
    key,
    index,
    templates,
    customers,
    companies,
    accountLabels,
    organizationSources,
    includeType
}: FilterDropdownElementProps) {
    const [topicsToShow, setTopicsToShow] = useState(10);
    const options: {
        label: string;
        value: string;
        key: string;
        color: string;
    }[] =
        getOptions(
            type,
            topics,
            users,
            customerGroups,
            categories,
            teams,
            channels,
            templates ?? [],
            customers ?? [],
            companies ?? [],
            interactionTypes ?? [],
            organizationSources,
            undefined,
            false,
            accountLabels,
        ) ?? [];

    const displayedOptions = type === "Topic"
        ? options.slice(0, topicsToShow)
        : options;

    const hasMoreTopics = type === "Topic" && topicsToShow < options.length;

    return (
        <Command className="rounded-md shadow-md text-xs pb-1 w-[250px]" aria-disabled>
            <CommandInput
                placeholder={`Filter ${getLowercase(type)}...`}
                className="px-1 text-[13px]"
            />
            <CommandList className="space-y-1">
                <CommandEmpty className="text-xs px-4 py-2">
                    {`No ${getPlural(type)} found`}
                </CommandEmpty>
                {extraOptions?.map((extraOption) => (
                    <CommandItem
                        key={extraOption.value}
                        className="px-1 py-0.5"
                    >
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={
                                index === undefined
                                    ? handleItemSelect(type, extraOption)
                                    : (includeType === undefined ? handleItemSelect(type, extraOption, index) : handleItemSelect(type, extraOption, index, includeType))
                            }
                            className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-1"
                        >
                            {ElementDisplay(
                                type,
                                extraOption.label,
                                extraOption.value,
                                extraOption.color,
                                filters,
                                categories,
                                users,
                                customerGroups,
                                channels,
                                customers ?? [],
                                companies ?? [],
                                interactionTypes,
                                accountLabels ?? [],
                            )}
                        </Button>
                    </CommandItem>
                ))}
                {displayedOptions
                    .sort((a, b) => {
                        if (type === "Status") {
                            return (
                                ticketStatusPriorityOrder.indexOf(a.value) -
                                ticketStatusPriorityOrder.indexOf(b.value)
                            );
                        }
                        if (type === "Insight Status") {
                            return (
                                insightStatusPriorityOrder.indexOf(a.value) -
                                insightStatusPriorityOrder.indexOf(b.value)
                            );
                        }
                        const prioritizeLabels = [
                            "No Assignee",
                            "No Team Owner",
                        ];
                        if (
                            prioritizeLabels.includes(a.label) &&
                            !prioritizeLabels.includes(b.label)
                        ) {
                            return -1;
                        }
                        if (
                            prioritizeLabels.includes(b.label) &&
                            !prioritizeLabels.includes(a.label)
                        ) {
                            return 1;
                        }
                        return a.label.localeCompare(b.label);
                    })
                    .map((option) => (
                        <CommandItem key={option.value} className="px-1 py-0.5">
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={
                                    isSavedViewFilter
                                        ? undefined
                                        : index === undefined
                                            ? handleItemSelect(type, option)
                                            : includeType === undefined
                                                ? handleItemSelect(
                                                    type,
                                                    option,
                                                    index,
                                                )
                                                : handleItemSelect(
                                                    type,
                                                    option,
                                                    index,
                                                    includeType
                                                )
                                }
                                className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-1"
                            >
                                {ElementDisplay(
                                    type,
                                    option.label,
                                    option.value,
                                    option.color,
                                    filters,
                                    categories,
                                    users,
                                    customerGroups,
                                    channels,
                                    customers ?? [],
                                    companies ?? [],
                                    interactionTypes,
                                    accountLabels ?? [],
                                )}
                            </Button>
                        </CommandItem>
                    ))}
                {hasMoreTopics && (
                    <CommandItem className="px-1 py-0.5">
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={() => setTopicsToShow(prev => prev + 10)}
                            className="flex items-center justify-center text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-1"
                        >
                            Show More
                        </Button>
                    </CommandItem>
                )}
            </CommandList>
        </Command>
    );
}

export default FilterDropdownElement;
