import { Button } from "@/component/shadcn/ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import { ContactsAPI } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    type Account,
    type CustomerCompany,
    type ListCustomersResponse,
    type SharedInfo,
    SharedInfoTypeCompany,
    SharedInfoTypeCustomer,
    type TicketCompany,
    type TicketCustomer,
} from "@/interfaces/serverData";
import { cn } from "@/lib/utils";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    AvatarIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CopyIcon,
    ImageIcon,
    OpenInNewWindowIcon,
    Pencil2Icon,
} from "@radix-ui/react-icons";
import * as Toggle from "@radix-ui/react-toggle";
import { DataList } from "@radix-ui/themes";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { X } from "lucide-react";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { CompanyDropdown } from "@/reusable_components/dropdowns/CompanyDropdown";
import { ContactDropdown } from "@/reusable_components/dropdowns/ContactDropdown";

interface CustomerProfileProps {
    ticketID: string;
    userInfo: SharedInfo | undefined;
    source: string;
    source_unique_name: string;
    account?: Account;
    userID: string;
    refetch: (success: boolean, message: string) => void;
    sidebarSize?: number;
}

function CustomerProfile({
    ticketID,
    userInfo,
    source,
    source_unique_name,
    account,
    userID,
    refetch,
    sidebarSize = 0,
}: CustomerProfileProps) {
    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () => integrationBackEndDataMappingToSvg.get(source ?? "Unknown"),
        [source],
    );

    const updateCompanyForTicket = (companyID: string) => {
        const requestData: TicketCompany = {
            ids: [ticketID],
            company_id: companyID,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetch(true, "Updated Company!");
            } else {
                refetch(false, "Failed to update company");
            }
        });
    };

    const updateCustomerForTicket = (customerID: string) => {
        const requestData: TicketCustomer = {
            ids: [ticketID],
            customer_id: customerID,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetch(true, "Updated Customer!");
            } else {
                refetch(false, "Failed to update customer");
            }
        });
    };

    const displayText =
        source_unique_name.length > 0 && source === "Gmail"
            ? `${source} (${source_unique_name})`
            : source;

    const copyEmail = useCallback((): void => {
        navigator.clipboard.writeText(userInfo?.domain ?? "");
    }, [userInfo?.domain]);

    const copyUsername = useCallback((): void => {
        navigator.clipboard.writeText(userInfo?.name ?? "");
    }, [userInfo?.name]);

    const handleOpenInNewTab = (id: string, type: string) => {
        if (type === "Customer") {
            window.open(`/accounts/individual/${id}`, "_blank");
        } else if (type === "Company") {
            window.open(`/accounts/${id}`, "_blank");
        }
    };

    const [editing, setEditing] = useState<boolean>(false);

    const [companyID, setCompanyID] = useState<string>(
        userInfo?.type === SharedInfoTypeCompany ? userInfo?.id ?? account?.company?.id ?? "" : account?.company?.id ?? "",
    );

    const [company, setCompany] = useState<CustomerCompany>();

    useEffect(() => {
        if (account?.company_id !== "" && account?.company_id !== undefined) {
            setCompanyID(account?.company_id ?? "");
            const { url } = ContactsAPI.getCompany;

            api.get(`${URLS.serverUrl}${url}/${account?.company_id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        const companyInfo: Account = res.data.data;
                        setCompany({
                            id: companyInfo.id,
                            name: companyInfo.name,
                            domain: companyInfo.domain,
                            image_url: companyInfo.image_url,
                            additional_properties:
                                companyInfo.additional_properties,
                            contract_value: companyInfo.contract_value,
                            contract_type: companyInfo.contract_type,
                        } as CustomerCompany);
                    }
                })
                .catch((res) => {
                    console.error(
                        "Error fetching full Assembly Customer:",
                        res,
                    );
                });
        } else if (
            account?.company?.id !== "" &&
            account?.company?.id !== undefined
        ) {
            setCompanyID(account?.company?.id ?? "");
            setCompany(account?.company);
        }
    }, [account]);

    const [contactEditing, setContactEditing] = useState<boolean>(false);


    const api = useApi();

    

    const capitalizeWords = (text: string) => {
        return text
            .toLowerCase()
            .split("_")
            .join(" ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    

    const [showMoreProperties, setShowMoreProperties] = useState(false);

    function shouldShowMoreProperties(
        account: Account | undefined,
        company: CustomerCompany | undefined,
    ): boolean {
        const hasAccountProperties =
            account?.additional_properties &&
            Object.keys(account.additional_properties).length > 0;

        const hasCompanyProperties =
            company?.additional_properties &&
            Object.keys(company.additional_properties).length > 0;

        const hasAccountContractValue =
            account?.contract_value !== undefined &&
            account.contract_value !== 0;

        const hasAccountContractType =
            account?.contract_type !== undefined &&
            account.contract_type !== "";

        const hasCompanyContractType = company?.contract_type !== undefined;

        const hasCompanyContractValue =
            company?.contract_value !== undefined &&
            company.contract_value !== 0;

        const hasCompanyDomain = company?.domain !== undefined;

        // Return true if any of the conditions are met
        return (
            hasAccountProperties ||
            hasCompanyProperties ||
            hasAccountContractValue ||
            hasAccountContractType ||
            hasCompanyContractType ||
            hasCompanyContractValue ||
            hasCompanyDomain
        );
    }

    const dataListItemStyle = "flex flex-col gap-0 [&>*]:m-0";

    const saveContact = (contactID: string) => {
        updateCustomerForTicket(contactID);
        setContactEditing(false);
    }

    const [contacts, setContacts] = useState<Account[]>(account ? [account] : []);

    const toggleContact = (
        customer: Account,
        existingContacts: Account[],
        setSelectedContacts: React.Dispatch<React.SetStateAction<Account[]>>,
    ) => {
        setContacts([customer]);
        setSelectedContacts([customer]);
    };

    return (
        <div>
            <div className="prose max-w-none text-xs flex flex-col gap-3">
                <div>
                    <div className="flex flex-row items-center gap-1">
                        {contactEditing ? (
                            <ContactDropdown
                                companyID={companyID}
                                editing={contactEditing}
                                setEditing={setContactEditing}
                                setContact={toggleContact}
                                selectedContactIDs={contacts.map((c) => c.id)}
                                selectedContacts={contacts}
                                saveOnCheck={saveContact}
                            />
                        ) : (
                            userInfo?.type !== SharedInfoTypeCustomer ?
                                <AvatarIcon className="w-6 h-6" /> :
                                <div className="flex flex-row items-center gap-1">
                                    {userInfo?.avatar ? (
                                        <div className="lb-avatar rounded-lg w-6 h-6">
                                            {userInfo?.avatar && (
                                                <img
                                                    className="lb-avatar-image"
                                                    src={userInfo?.avatar}
                                                    alt={userInfo?.name}
                                                />
                                            )}
                                            <span>
                                                {userInfo?.name}
                                            </span>
                                        </div>
                                    ) : (
                                        <AvatarIcon className="w-6 h-6" />
                                    )}

                                    <div className="font-medium mx-1 flex flex-col">
                                        <div className="text-sm flex items-center gap-1">
                                            <span
                                                className={cn(
                                                    "truncate",
                                                    sidebarSize < 20
                                                        ? "max-w-[120px]"
                                                        : "max-w-[300px]",
                                                )}
                                            >
                                                {account?.name ??
                                                    userInfo?.name}
                                            </span>
                                            {account && (
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Toggle.Root
                                                                aria-label="Toggle italic"
                                                                className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                                onClick={() =>
                                                                    handleOpenInNewTab(
                                                                        account.id ??
                                                                        "",
                                                                        "Customer",
                                                                    )
                                                                }
                                                            >
                                                                <OpenInNewWindowIcon className="h-3 w-3" />
                                                            </Toggle.Root>
                                                        </TooltipTrigger>
                                                        <TooltipContent className="bg-[#5B5BD6]">
                                                            <p>
                                                                Open Customer
                                                                Page
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            )}
                                        </div>
                                    </div>
                                </div>
                        )}

                        <div className="flex flex-row items-start gap-2 justify-between">
                            {!contactEditing && (
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-6 w-6 ml-auto hover:bg-iris4"
                                    aria-label="Edit customer"
                                    onClick={() => {
                                        setContactEditing(!contactEditing);
                                    }}
                                >
                                    <Pencil2Icon className="h-3 w-3" />
                                </Button>
                            )}
                        </div>
                    </div>
                </div>

                {userInfo?.type === SharedInfoTypeCustomer ?
                    <DataList.Root size="1" orientation="horizontal">
                        {userInfo?.domain && (
                            <DataList.Item>
                                <DataList.Label
                                    className="text-xs"
                                    minWidth="60px"
                                >
                                    Email
                                </DataList.Label>
                                <DataList.Value className="text-xs">
                                    <div className="flex items-center gap-1.5 min-w-0">
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div className="truncate">
                                                        {userInfo?.domain}
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>{userInfo?.domain}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Toggle.Root
                                                        aria-label="Toggle italic"
                                                        className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4 flex-shrink-0"
                                                        onClick={copyEmail}
                                                    >
                                                        <CopyIcon className="h-3 w-3" />
                                                    </Toggle.Root>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Copy Email</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                        )}
                        {userInfo?.name && (
                            <DataList.Item align="center">
                                <DataList.Label
                                    className="text-xs"
                                    minWidth="60px"
                                >
                                    Username
                                </DataList.Label>
                                <DataList.Value>
                                    <div className="flex items-center gap-1.5 min-w-0">
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div className="truncate">
                                                        {userInfo?.name}
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>{userInfo?.name}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Toggle.Root
                                                        aria-label="Toggle italic"
                                                        className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4 flex-shrink-0"
                                                        onClick={copyUsername}
                                                    >
                                                        <CopyIcon className="h-3 w-3" />
                                                    </Toggle.Root>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Copy Username</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                        )}

                        <DataList.Item align="center">
                            <DataList.Label className="text-xs mr-0" minWidth="60px">
                                Company
                            </DataList.Label>
                            <DataList.Value className="flex flex-row items-center p-0.5 overflow-x-auto">
                                {editing ? (
                                    <CompanyDropdown
                                        companyID={companyID}
                                        setCompany={(
                                            c: CustomerCompany | undefined,
                                            index?: number,
                                        ) => {
                                            setCompanyID(c?.id ?? "");
                                        }}
                                        onSave={updateCompanyForTicket}
                                        editing={editing}
                                        setEditing={setEditing}
                                        width={150}
                                    />
                                ) : (
                                    <div className="flex items-center gap-1">
                                        {company && (
                                            <>
                                                {company?.image_url !== "" &&
                                                    company?.image_url !==
                                                    undefined ? (
                                                    <div className="lb-avatar rounded w-5 h-5">
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={
                                                                company.image_url
                                                            }
                                                            alt={company.name}
                                                        />

                                                        <span>
                                                            {company.name ?? ""}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <ImageIcon className="w-5 h-5" />
                                                )}
                                                {company?.name}
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Toggle.Root
                                                                aria-label="Toggle italic"
                                                                className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                                onClick={() =>
                                                                    handleOpenInNewTab(
                                                                        company.id ??
                                                                        "",
                                                                        "Company",
                                                                    )
                                                                }
                                                            >
                                                                <OpenInNewWindowIcon className="h-3 w-3" />
                                                            </Toggle.Root>
                                                        </TooltipTrigger>
                                                        <TooltipContent className="bg-[#5B5BD6]">
                                                            <p>
                                                                Open Company
                                                                Page
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </>
                                        )}
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-5 w-5 hover:bg-iris4"
                                            aria-label="Edit customer"
                                            onClick={() => {
                                                setEditing(!editing);
                                            }}
                                        >
                                            <Pencil2Icon className="h-3 w-3" />
                                        </Button>
                                    </div>
                                )}
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item align="center">
                            <DataList.Label className="text-xs" minWidth="60px">
                                Source
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex items-center text-xs">
                                    {SourceSvgImage && (
                                        <SourceSvgImage className="w-4 h-4" />
                                    )}
                                    <span
                                        className={cn(
                                            "px-1 truncate",
                                            sidebarSize < 25
                                                ? "max-w-[90px]"
                                                : "max-w-[300px]",
                                        )}
                                    >
                                        {displayText}
                                    </span>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    </DataList.Root>
                    :
                    <DataList.Root size="1" orientation="horizontal">
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs" minWidth="60px">
                                Company
                            </DataList.Label>
                            <DataList.Value className="flex flex-row items-center p-0.5 overflow-x-auto">
                                {editing ? (
                                    <CompanyDropdown
                                        companyID={companyID}
                                        setCompany={(
                                            c: CustomerCompany | undefined,
                                            index?: number,
                                        ) => {
                                            setCompanyID(c?.id ?? "");
                                        }}
                                        onSave={updateCompanyForTicket}
                                        editing={editing}
                                        setEditing={setEditing}
                                        width={150}
                                    />
                                ) : (
                                    <div className="flex items-center gap-1.5">
                                        {company && (
                                            <>
                                                {company?.image_url !== "" &&
                                                    company?.image_url !==
                                                    undefined ? (
                                                    <div className="lb-avatar rounded w-5 h-5">
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={
                                                                company.image_url
                                                            }
                                                            alt={company.name}
                                                        />

                                                        <span>
                                                            {company.name ?? ""}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <ImageIcon className="w-5 h-5" />
                                                )}
                                                {company?.name}
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Toggle.Root
                                                                aria-label="Toggle italic"
                                                                className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                                onClick={() =>
                                                                    handleOpenInNewTab(
                                                                        company.id ??
                                                                        "",
                                                                        "Company",
                                                                    )
                                                                }
                                                            >
                                                                <OpenInNewWindowIcon className="h-3 w-3" />
                                                            </Toggle.Root>
                                                        </TooltipTrigger>
                                                        <TooltipContent className="bg-[#5B5BD6]">
                                                            <p>
                                                                Open Company
                                                                Page
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </>
                                        )}
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-6 w-6 hover:bg-iris4"
                                            aria-label="Edit customer"
                                            onClick={() => {
                                                setEditing(!editing);
                                            }}
                                        >
                                            <Pencil2Icon className="h-3 w-3" />
                                        </Button>
                                    </div>
                                )}
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item align="center">
                            <DataList.Label className="text-xs" minWidth="60px">
                                Source
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex items-center text-xs">
                                    {SourceSvgImage && (
                                        <SourceSvgImage className="w-4 h-4" />
                                    )}
                                    <span
                                        className={cn(
                                            "px-1 truncate",
                                            sidebarSize < 25
                                                ? "max-w-[90px]"
                                                : "max-w-[300px]",
                                        )}
                                    >
                                        {displayText}
                                    </span>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    </DataList.Root>
                }
                <DataList.Root
                    size="1"
                    orientation="vertical"
                    className="flex flex-col gap-3"
                >
                    {showMoreProperties && (
                        <>
                            {account?.additional_properties &&
                                Object.entries(
                                    account.additional_properties,
                                ).map(([key, value]) => (
                                    <DataList.Item
                                        key={key}
                                        className={dataListItemStyle}
                                    >
                                        <DataList.Label
                                            className="text-xs"
                                            minWidth="60px"
                                        >
                                            {capitalizeWords(key)}
                                        </DataList.Label>
                                        <DataList.Value>
                                            <div className="flex items-center text-xs">
                                                <span className="pl-2">
                                                    {String(value)}
                                                </span>
                                            </div>
                                        </DataList.Value>
                                    </DataList.Item>
                                ))}

                            {company?.additional_properties &&
                                Object.entries(
                                    company.additional_properties,
                                ).map(([key, value]) => (
                                    <DataList.Item
                                        key={key}
                                        className={dataListItemStyle}
                                    >
                                        <DataList.Label
                                            className="text-xs"
                                            minWidth="60px"
                                        >
                                            {capitalizeWords(key)}
                                        </DataList.Label>
                                        <DataList.Value>
                                            <div className="flex items-center text-xs">
                                                <span className="pl-2">
                                                    {String(value)}
                                                </span>
                                            </div>
                                        </DataList.Value>
                                    </DataList.Item>
                                ))}

                            {account?.contract_value !== undefined &&
                                account?.contract_value !== 0 && (
                                    <DataList.Item
                                        className={dataListItemStyle}
                                    >
                                        <DataList.Label
                                            className="text-xs"
                                            minWidth="60px"
                                        >
                                            Contract Value
                                        </DataList.Label>
                                        <DataList.Value>
                                            <div className="flex items-center text-xs">
                                                <span className="pl-2">
                                                    {account.contract_value}
                                                </span>
                                            </div>
                                        </DataList.Value>
                                    </DataList.Item>
                                )}

                            {account?.contract_type !== undefined &&
                                account?.contract_type !== "" && (
                                    <DataList.Item
                                        className={dataListItemStyle}
                                    >
                                        <DataList.Label
                                            className="text-xs"
                                            minWidth="60px"
                                        >
                                            Contract Type
                                        </DataList.Label>
                                        <DataList.Value>
                                            <div className="flex items-center text-xs">
                                                <span className="pl-2">
                                                    {account.contract_type}
                                                </span>
                                            </div>
                                        </DataList.Value>
                                    </DataList.Item>
                                )}

                            {company?.contract_type !== undefined && (
                                <DataList.Item
                                    className={dataListItemStyle}
                                >
                                    <DataList.Label
                                        className="text-xs"
                                        minWidth="60px"
                                    >
                                        Company Contract Type
                                    </DataList.Label>
                                    <DataList.Value>
                                        <div className="flex items-center text-xs">
                                            <span className="pl-2">
                                                {company.contract_type}
                                            </span>
                                        </div>
                                    </DataList.Value>
                                </DataList.Item>
                            )}

                            {company?.contract_value !== undefined &&
                                company?.contract_value !== 0 && (
                                    <DataList.Item
                                        className={dataListItemStyle}
                                    >
                                        <DataList.Label
                                            className="text-xs"
                                            minWidth="60px"
                                        >
                                            Company Contract Value
                                        </DataList.Label>
                                        <DataList.Value>
                                            <div className="flex items-center text-xs">
                                                <span className="pl-2">
                                                    {company.contract_value}
                                                </span>
                                            </div>
                                        </DataList.Value>
                                    </DataList.Item>
                                )}

                            {company?.domain !== undefined && (
                                <DataList.Item
                                    className={dataListItemStyle}
                                >
                                    <DataList.Label
                                        className="text-xs"
                                        minWidth="60px"
                                    >
                                        Company Domain
                                    </DataList.Label>
                                    <DataList.Value>
                                        <div className="flex items-center text-xs">
                                            <span className="pl-2">
                                                {company.domain}
                                            </span>
                                        </div>
                                    </DataList.Value>
                                </DataList.Item>
                            )}
                        </>
                    )}
                </DataList.Root>
                {shouldShowMoreProperties(account, company) && (
                    <Button
                        variant="outline"
                        className="text-xs ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex flex-row gap-2 items-center h-7"
                        onClick={() =>
                            setShowMoreProperties(!showMoreProperties)
                        }
                    >
                        <span>
                            {showMoreProperties ? "Hide" : ""} More
                            Properties
                        </span>
                        {showMoreProperties ? (
                            <ChevronUpIcon className="h-3 w-3" />
                        ) : (
                            <ChevronDownIcon className="h-3 w-3" />
                        )}
                    </Button>
                )}
            </div>
        </div>
    );
}

export default memo(CustomerProfile);
