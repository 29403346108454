import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuSeparator,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import type {
    Category,
    GetUserResponse,
    QueriesWithPaginationResponse,
    Query,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import {
    saveAssignee,
    saveStatus,
    saveTag,
    toggleTopicSelection,
} from "@/utilities/methods";
import {
    getIconForType,
    getLabelForType,
    getLowercase,
    getOptions,
    getPlural,
} from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import { useCallback, useEffect, useState } from "react";
import { ElementDisplay } from "./FilterDropdownElement";
import { ticketStatusPriorityOrder } from "./constants";
import { IssueGroupInfo } from "./IssuesList";
import { InfiniteData, QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useApi } from "@/interfaces/api";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { AxiosInstance } from "axios";

interface IssueContextMenuProps {
    children: React.ReactNode;
    topics: Topic[];
    users: GetUserResponse[];
    teams: Teams[];
    categories: Category[];
    bulk?: boolean;
    selectedIssues: IssueGroupInfo[];
    updateIssueState: (
        newState: Partial<Query>,
        issueGroup: IssueGroupInfo,
        newGroup?: string,
    ) => void;
    userID: string;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >;
    singleSelectedIssue: Query | undefined;
    listType: IssueListType;
    teamID: string;
}

const sortOptions = (
    type: string,
    a: { label: string; value: string },
    b: { label: string; value: string },
) => {
    if (type === "Status") {
        return (
            ticketStatusPriorityOrder.indexOf(a.value) -
            ticketStatusPriorityOrder.indexOf(b.value)
        );
    }

    const prioritizeLabel = "No Assignee";
    if (a.label === prioritizeLabel && b.label !== prioritizeLabel) {
        return -1;
    }
    if (b.label === prioritizeLabel && a.label !== prioritizeLabel) {
        return 1;
    }

    return a.label?.localeCompare(b.label);
};

export function IssueContextMenu({
    children,
    topics,
    users,
    teams,
    categories,
    bulk,
    updateIssueState,
    userID,
    refetch,
    singleSelectedIssue,
    selectedIssues,
    listType,
    teamID,
}: IssueContextMenuProps) {
    // For Assembly Dev & Latchel, show a Closed - No Workflows option
    const dropdownOptions = [
        "Status",
        "Assignee",
        "Tag",
        "Add Topic",
        "Remove Topic",
    ];
    const authInfo = useAuthInfo();
    const [orgId, setOrgId] = useState<string>("");
    const api = useApi();
    const saveIssue = useCallback(
        (
            type: string,
            value: string,
            selectedIssues: IssueGroupInfo[],
            updateIssueState: (
                newState: Partial<Query>,
                issueGroup: IssueGroupInfo,
                newGroup?: string,
            ) => void,
            userID: string,
            refetch: (
                options?: RefetchOptions,
            ) => Promise<
                QueryObserverResult<
                    InfiniteData<QueriesWithPaginationResponse, unknown>,
                    Error
                >
            >,
            issueState?: Query,
        ) => {
            let issueGroups: IssueGroupInfo[];
            if (selectedIssues.length === 0 && issueState) {
                issueGroups = [
                    {
                        issueId: issueState.id,
                        group: issueState.ticket_status,
                        issueTopics: issueState.topic,
                    },
                ];
            } else {
                issueGroups = selectedIssues;
            }
            switch (type) {
                case "Tag":
                    return saveTag(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                    );
                case "Add Topic":
                    return toggleTopicSelection(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                        true,
                    );
                case "Remove Topic":
                    return toggleTopicSelection(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                        false,
                    );
                case "Status":
                    return saveStatus(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                        refetch,
                    );
                case "Assignee":
                    return saveAssignee(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                    );
                default:
                    return null;
            }
        },
        [api],
    );
    const onSave = useCallback(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (type: string, value: any) => {
            saveIssue(
                type,
                value,
                selectedIssues,
                updateIssueState,
                userID,
                refetch,
                singleSelectedIssue,
            );
        },
        [saveIssue, selectedIssues, updateIssueState, userID, refetch, singleSelectedIssue],
    );

    const handleOpenInNewTab = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            const issueId = `${singleSelectedIssue?.ticket_identifier}-${singleSelectedIssue?.ticket_number}`;
            sessionStorage.setItem("from", listType);
            if ((listType === IssueListType.Team || listType === IssueListType.TeamViewer) && teamID) {
                window.open(`/teams/${teamID}/issue/${issueId}`);
            } else {
                window.open(`/issue/${issueId}`, "_blank");
            }
        },
        [
            listType,
            teamID,
            singleSelectedIssue?.ticket_identifier,
            singleSelectedIssue?.ticket_number,
        ],
    );

    useEffect(() => {
        const orgs = authInfo.orgHelper?.getOrgs();
        if (orgs === undefined || orgs.length !== 1) {
            return;
        }

        setOrgId(orgs[0].orgId);
    }, [authInfo.orgHelper]);

    return (
        <ContextMenu>
            <ContextMenuTrigger>{children}</ContextMenuTrigger>
            <ContextMenuContent className="w-60 text-gray-700 bg-white">
                {!bulk && (
                    <ContextMenuItem
                        inset
                        className="text-xs rounded-md hover:text-gray-900 hover:bg-gray-100 flex items-center gap-1.5"
                        onClick={handleOpenInNewTab}
                    >
                        <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                        Open in New Tab
                    </ContextMenuItem>
                )}
                {bulk && (
                    <ContextMenuLabel inset className="text-[13px] py-1">
                        Bulk Actions
                    </ContextMenuLabel>
                )}
                <ContextMenuSeparator />
                {dropdownOptions.map((type) => {
                    const options =
                        getOptions(
                            type,
                            topics,
                            users,
                            [],
                            categories,
                            teams,
                            new Map(),
                            [],
                            [],
                            [],
                            [],
                            undefined,
                            orgId,
                            true,
                        ) ?? [];
                    return (
                        <ContextMenuSub key={type}>
                            <ContextMenuSubTrigger
                                inset
                                className="text-xs rounded-md hover:text-gray-900 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                            >
                                {getIconForType(type)}
                                {getLabelForType(type)}
                            </ContextMenuSubTrigger>
                            <ContextMenuSubContent className="w-[250px] min-w-[250px] text-xs text-gray-700 bg-white overflow-visible">
                                <Command
                                    className="rounded-md shadow-md text-xs pb-1"
                                    aria-disabled
                                >
                                    <CommandInput
                                        placeholder={`Filter ${getLowercase(type)}...`}
                                        className="px-1 text-[13px]"
                                    />
                                    <ContextMenuItem className="m-0 p-0 hover:bg-transparent hover:text-inherit cursor-default data-[highlighted]:bg-transparent">
                                        <CommandList className="space-y-1 w-full max-h-[200px] overflow-y-auto">
                                            <CommandEmpty className="text-xs px-4 py-2">
                                                {`No ${getPlural(type)} found`}
                                            </CommandEmpty>
                                            {options
                                                .filter((option): option is { label: string; value: string; color: string; key: string } => option !== null)
                                                .sort((a, b) =>
                                                    sortOptions(type, a, b),
                                                )
                                                .map((option) => (
                                                    <CommandItem
                                                        key={option?.value ?? ""}
                                                        onSelect={(event) => {
                                                            onSave(
                                                                type,
                                                                option?.value ?? "",
                                                            );
                                                            // Find and close the context menu
                                                            const menu =
                                                                document.querySelector(
                                                                    '[role="menu"]',
                                                                );
                                                            if (menu) {
                                                                (
                                                                    menu as HTMLElement
                                                                ).click();
                                                            }
                                                        }}
                                                        className="flex items-center justify-between text-xs rounded-md hover:bg-gray-100 hover:text-gray-900 w-full px-3 py-1.5"
                                                    >
                                                        {ElementDisplay(
                                                            type.includes(
                                                                "Topic",
                                                            )
                                                                ? "Topic"
                                                                : type,
                                                            option?.label ?? "",
                                                            option?.value ?? "",
                                                            option?.color ?? "",
                                                            new Map(), // Not showing selected values bceause it can be a different for bulk actions
                                                            categories,
                                                            users,
                                                            [],
                                                            new Map(),
                                                            [],
                                                            [],
                                                            [],
                                                        )}
                                                    </CommandItem>
                                                ))}
                                        </CommandList>
                                    </ContextMenuItem>
                                </Command>
                            </ContextMenuSubContent>
                        </ContextMenuSub>
                    );
                })}
            </ContextMenuContent>
        </ContextMenu>
    );
}
