import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import { Box, Button, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TeamsSvg } from "../../../images/integrations/teams.svg";
import type { metadata } from "../IntegrationsManagementPage";
import IntegrationHeader from "./IntegrationHeader";

const TeamsIntegration = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["Teams"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.Teams) {
                            setIntegrationEnabled(true);
                        }

                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const navigate = useNavigate();

    const linearContinue = () => {
        const client_id = process.env.REACT_APP_LINEAR_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/linear`;
        const state = process.env.REACT_APP_LINEAR_STATE;
        const linear_url = `https://linear.app/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_url}&state=${state}&scope=read,write,issues:create`;
        window.open(linear_url, "_self");
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Teams"
                        description="Stay in sync with all Microsoft Teams conversations"
                        SvgIcon={TeamsSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={linearContinue}>Enable</Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button disabled={true}>Enabled</Button>
                                {/* <Button
                                    size="1"
                                    onClick={linearContinue}
                                    mb={"20px"}
                                >
                                    <ReloadIcon /> Refresh Scopes
                                </Button> */}
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
            </Box>
        </div>
    );
};

export default TeamsIntegration;
