import { API, URLS, loadingTypes } from '@/constant';
import { useApi } from '@/interfaces/api';
import type { GetSourceUsersResponse, ScopeResponse } from '@/interfaces/serverData';
import { useEffect, useState } from 'react';

interface UseSourceUsersProps {
    source: string;
    channelID?: string;
    includeDefaults?: boolean;
}

export function useSourceUsers({ source, channelID, includeDefaults }: UseSourceUsersProps) {
    const SLACK_AT_CHANNEL: ScopeResponse = {
        key: "channel",
        name: "channel"
    }
    const SLACK_AT_HERE: ScopeResponse = {
        key: "here",
        name: "here"
    }
    const DISCORD_AT_EVERYONE: ScopeResponse = {
        key: "everyone",
        name: "everyone"
    }

    const api = useApi();
    const [sourceUsers, setSourceUsers] = useState<ScopeResponse[]>([]);
    const [sourceUsersLoaded, setSourceUsersLoaded] = useState<loadingTypes>(loadingTypes.loading);
    const [nextCursor, setNextCursor] = useState<string>("0");
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [defaultsAdded, setDefaultsAdded] = useState(false);

    // Reset state when source or channel changes
    useEffect(() => {
        setSourceUsers([]);
        setNextCursor("0");
        setHasNextPage(true);
        setSourceUsersLoaded(loadingTypes.loading);
    }, [source, channelID]);

    const fetchSourceUsersIncrementally = async (source: string, channel: string) => {
        try {
            setSourceUsersLoaded(loadingTypes.loading);

            let url: string;
            if (channel !== "") {
                url = `${URLS.serverUrl}${API.getAllSourceUsers}/${source}/${channel}`
            } else {
                url = `${URLS.serverUrl}${API.getAllSourceUsers}/${source}`
            }
            const response = await api.get(
                url,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        limit: 10,
                        offset: nextCursor,
                    },
                },
            );

            if (response.status === 200) {
                const resp: GetSourceUsersResponse = response.data.data;
                if (!defaultsAdded && ["Slack", "CommunitySlack"].includes(source) && includeDefaults) {
                    setSourceUsers(prevUsers => [...prevUsers, ...resp.data, SLACK_AT_CHANNEL, SLACK_AT_HERE]);
                    setDefaultsAdded(true)
                } else if (!defaultsAdded && source === "Discord" && includeDefaults) {
                    setSourceUsers(prevUsers => [...prevUsers, ...resp.data, DISCORD_AT_EVERYONE]);
                    setDefaultsAdded(true)
                } else {
                    setSourceUsers(prevUsers => [...prevUsers, ...resp.data]);
                }
                setHasNextPage(resp.has_next_page);
                setNextCursor(resp.cursor);
                setSourceUsersLoaded(loadingTypes.loaded);
            } else {
                console.error("Error fetching source users:", response.status);
                setSourceUsersLoaded(loadingTypes.error);
            }
        } catch (error) {
            console.error("Error fetching source users:", error);
            setSourceUsersLoaded(loadingTypes.error);
        }
    };

    // Initial fetch when source changes
    useEffect(() => {
        if (["Discord", "GitHubTicket", "GitHubDiscussion"].includes(source) || (["Slack", "CommunitySlack"].includes(source) && channelID !== "")) {
            fetchSourceUsersIncrementally(source, channelID ?? "");
        } else {
            setSourceUsersLoaded(loadingTypes.loaded)
        }
    }, [source, channelID]);

    // Fetch more when needed
    useEffect(() => {
        if (sourceUsersLoaded === loadingTypes.loaded && hasNextPage && nextCursor !== "0") {
            fetchSourceUsersIncrementally(source ?? "", channelID ?? "");
        }
    }, [sourceUsersLoaded, nextCursor]);

    return {
        sourceUsers,
        sourceUsersLoaded,
        hasNextPage
    };
}
