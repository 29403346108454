import { Template } from "@/interfaces/serverData";
import { API } from "@/constant";
import { URLS } from "@/constant";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApi } from "@/interfaces/api";

const useTemplates = (templateType: "Implementation" | "Interactions") => {
    const api = useApi();
    const templatesQuery = useQuery<Template[]>({
        queryKey: ["templates"],
        queryFn: async () => {
            const response: AxiosResponse<{ data: Template[] }> = await api.get(
                `${URLS.serverUrl}${API.getTemplates}`,
                {
                    params: {
                        template_type: templateType,
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });
    return templatesQuery;
};

export default useTemplates;
