import { FancyMultiSelect } from "@/component/MultiSelect";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { TrashIcon } from "@radix-ui/react-icons";
import { ChevronDownIcon } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import { Badge } from "@radix-ui/themes";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import type { ScopeResponse } from "@/interfaces/serverData";

import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import type { BroadcastInfo } from "./AnnouncementsEditingPage";

interface AnnouncementsBroadcastSourcesProps {
    fields: FieldArrayWithId<
        {
            customerGroupMetadata: {
                customer_group: string;
            }[];
            sourceMetadata: {
                source: string;
            }[];
            broadcastName: string;
        },
        "sourceMetadata",
        "id"
    >[];
    fieldsState: BroadcastInfo[];
    form: UseFormReturn<
        {
            broadcastName: string;
            sourceMetadata: {
                source: string;
            }[];
            customerGroupMetadata: {
                customer_group: string;
            }[];
        },
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        any,
        undefined
    >;
    handleBroadcastChange: (index: number, newBroadcast: string) => void;
    filteredBroadcasts: {
        label: string;
        value: string;
        color: string;
        key: string;
    }[];

    removeSource: (index: number) => void;
    setFieldsState: (
        state: BroadcastInfo[] | ((prev: BroadcastInfo[]) => BroadcastInfo[]),
    ) => void;
}

export const AnnouncementsBroadcastSources: React.FC<
    AnnouncementsBroadcastSourcesProps
> = ({
    fields,
    fieldsState,
    form,
    handleBroadcastChange,
    filteredBroadcasts,
    removeSource,
    setFieldsState,
}) => {
        const getUniqueOptionsForBroadcast = (
            options: ScopeResponse[],
        ): ScopeResponse[] => {
            const uniqueMap = new Map<string, ScopeResponse>();
            for (const option of options) {
                if (!uniqueMap.has(option.key)) {
                    console.log(option.key);
                    uniqueMap.set(option.key, option);
                }
            }
            const vals = Array.from(uniqueMap.values());
            return vals;
        };

        return (
            <>
                <div>
                    {fields.length !== 0 && (
                        <FormLabel className="pb-0 mb-0 text-xs">
                            Accounts
                        </FormLabel>
                    )}
                    {fields.map((item, index) => {
                        const IconComponent = fieldsState[index]?.icon;

                        return (
                            <FormField
                                key={item.id}
                                control={form.control}
                                name={"sourceMetadata"}
                                render={({ field }) => (
                                    <>
                                        <FormItem className="flex flex-row justify-between items-start">
                                            <div className="flex flex-col gap-y-5">
                                                <FormControl>
                                                    <div className="flex flex-col items-start gap-3">
                                                        <DropdownMenu>
                                                            <DropdownMenuTrigger
                                                                asChild
                                                                type="button"
                                                            >
                                                                <Badge
                                                                    color={"gray"}
                                                                    size="2"
                                                                    radius="full"
                                                                    variant="outline"
                                                                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        <>
                                                                            {IconComponent !==
                                                                                undefined && (
                                                                                    <IconComponent className="w-4 h-4" />
                                                                                )}
                                                                            <p className="pl-1 pr-1">
                                                                                {
                                                                                    fieldsState[
                                                                                        index
                                                                                    ]
                                                                                        .selectedBroadcast
                                                                                }
                                                                            </p>
                                                                            <ChevronDownIcon />
                                                                        </>
                                                                    </div>
                                                                </Badge>
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent
                                                                align="start"
                                                                className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                                            >
                                                                <FilterDropdownElement
                                                                    type={
                                                                        "Broadcast"
                                                                    }
                                                                    filters={
                                                                        fieldsState[
                                                                            index
                                                                        ].filters
                                                                    }
                                                                    handleItemSelect={(
                                                                        type: string,
                                                                        option: {
                                                                            label: string;
                                                                            value: string;
                                                                            key: string;
                                                                            color: string;
                                                                        },
                                                                    ) => {
                                                                        return () => {
                                                                            if (
                                                                                type ===
                                                                                "Broadcast"
                                                                            ) {
                                                                                handleBroadcastChange(
                                                                                    index,
                                                                                    option.value,
                                                                                );
                                                                            }
                                                                        };
                                                                    }}
                                                                    topics={[]}
                                                                    users={[]}
                                                                    extraOptions={
                                                                        filteredBroadcasts
                                                                    }
                                                                    categories={[]}
                                                                    customerGroups={[]}
                                                                    teams={[]}
                                                                    isSavedViewFilter={
                                                                        false
                                                                    }
                                                                    channels={
                                                                        new Map()
                                                                    }
                                                                />
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                        <div className="pl-3">
                                                            <FancyMultiSelect
                                                                setSelectedChannels={(
                                                                    newChannels:
                                                                        | ScopeResponse[]
                                                                        | ((
                                                                            prev: ScopeResponse[],
                                                                        ) => ScopeResponse[]),
                                                                ) => {
                                                                    // Ensure newChannels is of type ScopeResponse[]
                                                                    setFieldsState(
                                                                        (
                                                                            prevState,
                                                                        ) => {
                                                                            const newState =
                                                                                [
                                                                                    ...prevState,
                                                                                ];
                                                                            newState[
                                                                                index
                                                                            ].selectedChannels =
                                                                                Array.isArray(
                                                                                    newChannels,
                                                                                )
                                                                                    ? newChannels
                                                                                    : newChannels(
                                                                                        prevState[
                                                                                            index
                                                                                        ]
                                                                                            .selectedChannels,
                                                                                    );
                                                                            return newState;
                                                                        },
                                                                    );
                                                                }}
                                                                selectedChannels={getUniqueOptionsForBroadcast(
                                                                    fieldsState[
                                                                        index
                                                                    ]
                                                                        ?.selectedChannels ||
                                                                    [],
                                                                )}
                                                                options={
                                                                    fieldsState[
                                                                        index
                                                                    ].options
                                                                }
                                                                placeholder="Select Accounts..."
                                                            />
                                                        </div>
                                                    </div>
                                                </FormControl>
                                                <FormMessage className="text-xs" />
                                            </div>
                                            <Button
                                                type="button"
                                                variant="outline"
                                                size="sm"
                                                className="mt-0"
                                                onClick={() => {
                                                    removeSource(index);
                                                }}
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </FormItem>
                                        <Separator size="4" className="mt-4 mb-4" />
                                    </>
                                )}
                            />
                        );
                    })}
                </div>
            </>
        );
    };
