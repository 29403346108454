import { Card, CardContent, CardHeader } from "@/component/shadcn/ui/card";
import { useApi } from "@/interfaces/api";
import {
    Box,
    Flex,
    Heading,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";

import { useEffect, useState } from "react";
import { useSearch } from "../SearchContext";
import { useToast } from "@/component/shadcn/ui/use-toast";
import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { API } from "@/constant";
import { URLS } from "@/constant";
import { useForm } from "react-hook-form";
import { FeatureType, GetSystemNotificationResponse } from "@/interfaces/notificationsServerData";

interface NotificationForm {
    enable_slack_notifs: boolean;
    reminders_and_deadlines: boolean;
}

const NotificationPreferences: React.FC<{userID: string}> = ({userID}) => {
    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [isSlackIntegrationEnabled, setIsSlackIntegrationEnabled] =
        useState<boolean>(false);

    const api = useApi();
    const { toast } = useToast();
    const { register, handleSubmit, watch, setValue } = useForm<NotificationForm>();

    const enable_slack_notifs = watch("enable_slack_notifs");

    useEffect(() => {
        if (loading) {
            if (searchBarFilters.get("Slack")) {
                setIsSlackIntegrationEnabled(true);
            } else {
                setIsSlackIntegrationEnabled(false);
            }
        }
    }, [searchBarFilters, loading]);

    useEffect(() => {
        api.get(URLS.serverUrl + API.getSystemNotifications, {
            params: {
                settings_type: "User",
                settings_type_id: userID,
                feature_type: FeatureType.SYSTEM
            },
        }).then((res) => {
            const response : GetSystemNotificationResponse = res.data.data;
            setValue("enable_slack_notifs", response.enabled);
            setValue("reminders_and_deadlines", response.metadata.reminders_and_deadlines);
        });
    }, [api, userID, setValue]);

    const onSubmit = async (data: NotificationForm) => {
        try {
            await api.post(URLS.serverUrl + API.createOrUpdateSystemNotifications, {
                enabled: data.enable_slack_notifs,
                metadata: {
                    reminders_and_deadlines: data.reminders_and_deadlines
                },
                settings_type: "User",
                settings_type_id: userID,
                feature_type: FeatureType.SYSTEM
            });
            toast({
                title: "Success",
                description: "Notification preferences updated",
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update notification preferences",
                variant: "destructive"
            });
        }
    };
    
    return (
        <div>
            <Flex direction="column" align="center" justify="center">
                <Box mt="7" width="54%">
                <Flex direction={"column"} align={"start"} className="w-full" mb="3">
                        <Heading size="5" align="left">
                            Notifications
                        </Heading>

                        <Text mb="15px" size="2">
                            Manage your notification settings
                        </Text>
                </Flex>
                    {!loading ? (
                        <Skeleton>
                            <Text>
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Text>
                        </Skeleton>
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex direction="column" gap="4">
                                <Card className="shadow-none pt-6 pb-0 px-2 rounded-lg">
                                    <CardContent>
                                        <div className="flex flex-row items-center justify-between">
                                            <div className="text-sm font-medium flex flex-row items-center gap-2">
                                                <SlackSvg className="w-4 h-4" />
                                                Enable Slack Notifications
                                            </div>
                                            {isSlackIntegrationEnabled ? (
                                                <Switch
                                                    {...register("enable_slack_notifs")}
                                                    checked={enable_slack_notifs}
                                                    onCheckedChange={(checked) => {
                                                        setValue("enable_slack_notifs", checked);
                                                        handleSubmit(onSubmit)();
                                                    }}
                                                />
                                            ) : (
                                                <DialogTriggerButton onClick={() => {
                                                    window.open("https://dashboard.askassembly.app/admin/manage_integrations/Slack", "_blank")
                                                }}>
                                                    Enable Slack
                                                </DialogTriggerButton>
                                            )}
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card className={`shadow-none rounded-lg ${!enable_slack_notifs ? 'opacity-50 pointer-events-none' : ''}`}>
                                    <CardHeader>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-2 text-sm font-medium">
                                               General Notifications
                                            </div>
                                        </div>
                                    </div>
                                    </CardHeader>
                                    <CardContent>
                                         <div className="flex flex-row items-center justify-between">
                                            <div className="flex flex-col">
                                                <div className="text-sm font-medium flex flex-row items-center gap-2">
                                                Reminders and deadlines 
                                                </div>
                                                <div className="text-xs text-gray11">
                                                Get reminders and deadlines for tickets, milestones, accounts and more
                                                </div>
                                            </div>
                                            <Switch 
                                                checked={watch("reminders_and_deadlines")}
                                                onCheckedChange={(checked) => {
                                                    setValue("reminders_and_deadlines", checked, { shouldDirty: true });
                                                    handleSubmit(onSubmit)();
                                                }}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Flex>
                        </form>
                    )}
                
                </Box>
            </Flex>
        </div>
    );
};

export default NotificationPreferences;
