import AttributesBadge from "@/component/AttributesBadge";
import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Dialog,
    DialogClose,
    DialogContent,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { toast } from "@/component/shadcn/ui/use-toast";
import { Editor } from "@/component/textEditor/Editor";
import {
    type EmailRecipient,
    ReplyFromPlugin,
} from "@/component/textEditor/ReplyFromPlugin";
import { API, TeamsAPI, URLS } from "@/constant";
import { useSourceUsers } from "@/hooks/use-sourceUsers";
import { useApi, useApiFormData } from "@/interfaces/api";
import {
    type CreateEscalation,
    EditorActionType,
    EditorType,
    type ExternalIssues,
    type GetTeamResponse,
    type GetUserResponse,
    type HistoryResponse,
    type Integration,
    type IssueType,
    type OrgInfoResponse,
    type PublishTicketPayload,
    type PublishTicketResponse,
    PublishTicketType,
    type ScopeResponse,
    type SendMessageResponse,
    type Teams,
    type Ticket,
    type UploadedFile,
    type UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { useAuthInfo } from "@propelauth/react";
import {
    AvatarIcon,
    CaretSortIcon,
    CheckIcon,
    InfoCircledIcon,
    PlusIcon,
    ReaderIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { Skeleton, Text } from "@radix-ui/themes";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import {
    HouseIcon,
    PencilRulerIcon,
    SquareArrowUpIcon,
    UsersIcon,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";

interface InteractionCreateButtonProps {
    ticket: Ticket;
    teams: Teams[];
    userID: string;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export const InteractionCreateButton: React.FC<
    InteractionCreateButtonProps
> = ({ ticket, teams, userID, refetchTicketData, refetchThreadData }) => {
    const LOREM_IPSUM = "Lorem ipsum dolor sit amet";
    const api = useApi();
    const apiFormData = useApiFormData();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    useEffect(() => {
        authInfoRef.current = authInfo;
    }, [authInfo]);
    const ESCALATE_TYPES = ["Web", "Gmail", "Slack"];

    const [dialogOpen, setDialogOpen] = useState(false);
    const [createType, setCreateType] = useState<string>();
    const [enabledEscalations, setEnabledEscalations] = useState<string[]>([
        "Web",
    ]);

    const [teamOwner, setTeamOwner] = useState<string>();
    const [owner, setOwner] = useState<GetUserResponse>();
    const [pictureURL, setPictureURL] = useState<string>("");
    const [myUser, setMyUser] = useState<GetUserResponse>();
    const [integrationID, setIntegrationID] = useState<string>();
    const [channel, setChannel] = useState<ScopeResponse>();
    const [users, setUsers] = useState<GetUserResponse[]>([]);

    const [isSendDisabled, setIsSendDisabled] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<
        (UploadedFile | UploadedFileWithMetadata)[]
    >([]);
    const [isToggled, setIsToggled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(
        `${ticket.ticket_identifier}-${ticket.number} Escalation`,
    );

    const [enabledIntegrations, setEnabledIntegrations] = useState<string[]>(
        [],
    );
    const [destinations, setDestinations] = useState<ScopeResponse[]>([]);
    const [isDestinationsLoading, setIsDestinationsLoading] = useState(false);
    const [issueTypes, setIssueTypes] = useState<IssueType[]>([]);
    const [isIssueTypesLoading, setIsIssueTypesLoading] = useState(false);

    const [selectedInt, setSelectedInt] =
        useState<string>("Select Integration");
    const [selectedDest, setSelectedDest] = useState<ScopeResponse>({
        key: "",
        name: "Select Destination",
    });
    const [issueType, setIssueType] = useState<IssueType>();
    const [priority, setPriority] = useState<string>("None");
    const DestIcon: React.ElementType =
        integrationBackEndDataMappingToSvg.get(selectedInt) ?? ReaderIcon;
    const integrationLabelMap = new Map<string, string>([
        ["GitHubTicket", "GitHub Issues"],
        ["GithubDiscussion", "Github Discussions"],
        ["Linear", "Linear"],
        ["Jira", "Jira"],
        ["Intercom", "Intercom"],
    ]);

    const handleSelectChange = (value: string) => {
        setCreateType(value);
    };

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();

            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: ["Slack", "Google"],
                }),
            });

            if (!res.ok) return theMap;

            const orgInfo: OrgInfoResponse = (await res.json()).data;

            // Create array of promises to wait for
            const promises: Promise<void>[] = [];

            if (orgInfo.Slack) {
                setEnabledEscalations((prev) => [...prev, "Slack"]);
                promises.push(
                    api
                        .get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`)
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                // Note: only including asm_ticket_channels, not trigger_based_ticket_channels so that we can correlate the escalation corretly
                                theMap.set("Slack", dataItems);
                            }
                        }),
                );
            }

            if (orgInfo.Google) {
                setEnabledEscalations((prev) => [...prev, "Gmail"]);
                promises.push(
                    api
                        .get(
                            `${URLS.serverUrl}${API.getUniqueIntegrations}/Google`,
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] =
                                    integrationsResponse.map((integration) => ({
                                        key: integration.id,
                                        name: integration.unique_name,
                                    }));
                                theMap.set("Gmail", dataItems);
                            }
                        }),
                );
            }

            // Wait for all promises to resolve
            await Promise.all(promises);

            return theMap;
        },
    });

    useEffect(() => {
        if (teamOwner && teamOwner !== "" && teamOwner !== "NoTeamOwner") {
            const [url] = TeamsAPI.getTeam;
            api.get(`${URLS.serverUrl}${url}/${teamOwner}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            }).then((res) => {
                if (res.status === 200) {
                    const teamInfo: GetTeamResponse = res.data.data;
                    setUsers(teamInfo.members);
                    if (owner && !teamInfo.members.find(user => user.id === owner.id)) {
                        setOwner(undefined)
                    }
                    const foundUser = teamInfo.members.find(user => user.id === userID)
                    if (foundUser) {
                        setMyUser(foundUser)
                    } else {
                        setMyUser(undefined)
                    }
                }
            });
        } else {
            api.post(URLS.serverUrl + API.getAllUsers, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                if (res.status === 200) {
                    const orgUsers: GetUserResponse[] = res.data.data
                    setUsers(orgUsers);
                    if (owner && !orgUsers.find(user => user.id === owner.id)) {
                        setOwner(undefined)
                    }
                    const foundUser = orgUsers.find(user => user.id === userID)
                    if (foundUser) {
                        setMyUser(foundUser)
                    } else {
                        setMyUser(undefined)
                    }
                } else {
                    console.log("Call to update tag failed");
                }
            });
        }
    }, [api, teamOwner]);

    useEffect(() => {
        if (owner) {
            const userData = users.find(
                (user) => user.id === owner.id,
            );
            if (
                userData &&
                userData.picture_url !== ""
            ) {
                setPictureURL(owner.picture_url)
            }
        } else {
            setPictureURL("")
        }
    }, [owner, api, users]);

    async function escalate({
        type,
        content,
        files,
        source,
        channelID,
    }: {
        type: EditorActionType;
        content: string;
        files: (UploadedFile | UploadedFileWithMetadata)[];
        source?: string;
        channelID?: string;
        subject?: string;
    }): Promise<SendMessageResponse> {
        if (!authInfoRef.current.isLoggedIn) {
            throw new Error("User not logged in");
        }
        if (userID === undefined) {
            throw new Error("User ID not found");
        }
        if (type !== EditorActionType.Escalation) {
            throw new Error(
                `Editor Action Type ${type} is not supported for this editor`,
            );
        }
        if (!source) {
            throw new Error("No source is defined");
        }

        const images: UploadedFile[] = files?.filter(
            (file) => file.type === "image",
        ) as UploadedFile[];

        const filesToUpload = files?.filter((file) => {
            return file.type === "file";
        });
        const formData = new FormData();

        // Append non-file fields
        for (const fileData of filesToUpload) {
            formData.append(
                "files",
                (fileData as UploadedFileWithMetadata).file,
                (fileData as UploadedFileWithMetadata).file_name,
            );
        }

        const requestData: CreateEscalation = {
            parent_id: ticket.id,
            escalation_type: createType ?? "",
            message: content,
            title: title,
            user: userID,
            images: images,
        };
        if (source === "Web") {
            if (teamOwner !== "NoTeamOwner") {
                requestData.team_owner = teamOwner;
            }
            if (owner) {
                requestData.owner = owner.id
            }
        } else if (source === "Gmail") {
            requestData.gmail_integration_fields = {
                to: properties.get("to") ?? [],
                cc: properties.get("cc") ?? [],
                bcc: properties.get("bcc") ?? [],
                subject: properties.get("subject") ?? [],
            };
            requestData.integration_id = integrationID;
        } else if (source === "Slack") {
            requestData.channel = channel?.key;
        }

        formData.append("data", JSON.stringify(requestData));
        const response = await apiFormData.post(
            URLS.serverUrl + API.createEscalation,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );
        const id = response.data?.data?.id ?? "";
        return { id, url: "" };
    }

    const createEscalationMutation = useMutation({
        mutationFn: escalate,
        onSuccess: () => {
            setDialogOpen(false);
            setTitle(`${ticket.ticket_identifier}-${ticket.number} Escalation`);
            setTimeout(() => {
                setLoading(false);
                if (refetchTicketData) {
                    refetchTicketData();
                }
                if (refetchThreadData) {
                    refetchThreadData();
                }
            }, 3000);
            toast({
                title: "Escalation Created",
                description: "Escalation created successfully",
            });
        },
        onError: (error) => {
            setLoading(false);
            console.error("Error creating escalation:", error);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
        onSettled: () => {
            setLoading(false);
            setIsSendDisabled(false);
            setUploadedFiles([]);
        },
    });

    const handleSend = (
        mrkdwn: string,
        files: (UploadedFile | UploadedFileWithMetadata)[],
        type: EditorActionType,
        source?: string,
        channelID?: string,
        recipient?: string,
        integration_id?: string,
        subject?: string,
        new_source_specific_id?: string,
    ): Promise<SendMessageResponse> => {
        if (mrkdwn === undefined || !isSendDisabled) {
            return Promise.reject(new Error("Invalid message"));
        }
        setLoading(true);

        return new Promise((resolve, reject) => {
            createEscalationMutation.mutate(
                {
                    type,
                    content: mrkdwn,
                    files,
                    source,
                    channelID,
                    subject,
                },
                {
                    onSuccess: (data) => resolve(data),
                    onError: (error) => reject(error),
                },
            );
        });
    };

    const handleFileUpload = (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            {
                file_name: file_name,
                file_type: file_type,
                file_size: file_size,
                file: file,
                type: "file",
            },
        ]);
    };
    const handleImageUpload = (src: string, altText: string) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            { src: src, alt: altText, type: "image" },
        ]);
    };

    const handleDeleteFile = (
        fileToDelete: UploadedFile | UploadedFileWithMetadata,
    ) => {
        const newUploadedFiles = uploadedFiles.filter((file) => {
            if (file.type === "image") {
                return (
                    (file as UploadedFile).alt !==
                    (fileToDelete as UploadedFile).alt
                );
            } else if (file.type === "file") {
                return (
                    (file as UploadedFileWithMetadata).file_name !==
                    (fileToDelete as UploadedFileWithMetadata).file_name
                );
            }
            return true;
        });
        setUploadedFiles(newUploadedFiles);
        if (newUploadedFiles.length === 0) {
            setIsSendDisabled(false);
        }
    };

    const [properties, setProperties] = useState<Map<string, EmailRecipient[]>>(
        new Map([
            ["to", []],
            ["subject", title ? [{ email: title, name: title }] : []],
            ["from", []],
        ]),
    );
    const [propertiesOptions, setPropertiesOptions] = useState<
        Map<string, ScopeResponse[]>
    >(new Map());

    useEffect(() => {
        const gmailData = channelsQuery?.data?.get("Gmail");
        if (gmailData && gmailData.length >= 1) {
            setProperties((prevProperties) => {
                const updatedProperties = new Map(prevProperties);
                // Only set "from" if it doesn't exist or is empty
                if (
                    !prevProperties.has("from") ||
                    !prevProperties.get("from")?.length ||
                    prevProperties.get("from")?.length === 0
                ) {
                    const firstOption = gmailData[0];
                    updatedProperties.set("from", [
                        {
                            email: firstOption.name ?? "",
                            name: firstOption.name ?? "",
                            integrationID: firstOption.key,
                        },
                    ]);
                    setIntegrationID(firstOption.key);
                }
                return updatedProperties;
            });
        }

        setPropertiesOptions(new Map([["from", gmailData ?? []]]));
    }, [channelsQuery?.data]);

    // Add a ref to measure the ReplyFromPlugin height
    const replyPluginRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const requestData = {
            types: [
                "GithubDiscussion",
                "GitHubTicket",
                "Linear",
                "Jira",
                "Intercom",
            ],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        const enabledInts: string[] = [];
                        if (orgInfo.GitHubTicket) {
                            enabledInts.push("GitHubTicket");
                        }
                        if (orgInfo.GithubDiscussion) {
                            enabledInts.push("GithubDiscussion");
                        }
                        if (orgInfo.Linear) {
                            enabledInts.push("Linear");
                        }
                        if (orgInfo.Jira) {
                            enabledInts.push("Jira");
                        }
                        if (orgInfo.Intercom) {
                            enabledInts.push("Intercom");
                        }
                        setEnabledIntegrations(enabledInts);
                        if (enabledInts.length > 0) {
                            setSelectedInt(enabledInts[0]);
                        }
                    }
                } else {
                    console.log("failed to get integrations");
                }
            })
            .catch((res) => {
                console.log("failed to get integrations");
            });
    }, [api]);

    useEffect(() => {
        if (selectedInt !== "Select Integration") {
            setIsDestinationsLoading(true);
            setIsIssueTypesLoading(true);
            const requestData = {
                type: selectedInt,
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setDestinations(dataItems);
                    if (dataItems.length > 0) {
                        setSelectedDest(dataItems[0]);
                    }
                })
                .catch((res) => {
                    console.log("could not find sources");
                })
                .finally(() => {
                    setIsDestinationsLoading(false);
                });
        }
    }, [selectedInt, api]);

    useEffect(() => {
        if (
            (selectedInt === "Intercom" || selectedInt === "Jira") &&
            selectedDest.name !== "Select Destination"
        ) {
            setIsIssueTypesLoading(true);
            const requestData = {
                type: selectedInt,
                project_key: selectedDest.key,
            };
            api.post(URLS.serverUrl + API.getIssueTypes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: IssueType[] = res.data.data;
                    setIssueTypes(dataItems ?? []);
                    if (dataItems.length > 0) {
                        setIssueType(dataItems[0]);
                    }
                })
                .catch((res) => {
                    console.error("Error in grabbing issue types");
                })
                .finally(() => {
                    setIsIssueTypesLoading(false);
                });
        } else {
            setIssueTypes([]);
        }
    }, [selectedDest, api]);

    const createExternalIssue = async () => {
        // Submit the external issue
        toast({
            title: "Creating external issue...",
            variant: "default",
            className: "loading",
        });
        if (selectedInt === "Select Integration") {
            toast({
                title: "Please select an integration",
                variant: "destructive",
            });
            return;
        }
        if (selectedDest.name === "Select Destination") {
            toast({
                title: "Please select a destination",
                variant: "destructive",
            });
            return;
        }
        if (!issueType && ["Intercom", "Jira"].includes(selectedInt)) {
            toast({
                title: "Please select a ticket type",
                variant: "destructive",
            });
            return;
        }
        if (!priority && ["Intercom"].includes(selectedInt)) {
            toast({
                title: "Please select a priority, even if it's None",
                variant: "destructive",
            });
            return;
        }
        try {
            let payload: PublishTicketPayload;
            let existingExtIssues: ExternalIssues[];
            if (ticket) {
                payload = {
                    connection: selectedInt,
                    title: ticket.title,
                    source: "Web",
                    user: userID,
                    question: ticket.query,
                    url: ticket.url,
                    comments: [],
                    system_id: ticket.id,
                    system_type: PublishTicketType.Issue,
                    destination: selectedDest,
                    issue_type: issueType,
                };
                if (priority !== "None" && priority !== "") {
                    payload.priority = priority;
                }
                existingExtIssues = ticket.external_issues;
            } else {
                toast({
                    title: "Please provide an issue or insight",
                    variant: "destructive",
                });
                console.error(
                    "no ticket/issue nor insight was provided to create the external issue for",
                );
                return;
            }

            const response = await api.post(
                URLS.serverUrl + API.publishExternalIssue,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            if (response.status === 200) {
                const res: PublishTicketResponse = response.data.data;
                // Insight Display stays updated based on the insightState
                if (refetchTicketData) {
                    refetchTicketData();
                }
                if (refetchThreadData) {
                    refetchThreadData();
                }
                setSelectedDest({
                    key: "",
                    name: "Select Destination",
                });
                setIssueType(undefined);
                setPriority("None");
                toast({
                    title: "External Issue Created",
                    description: "External issue created successfully",
                    variant: "default",
                });
            } else {
                console.log(
                    `Failed to create external issue: ${response.status}`,
                );
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            }
        } catch (error) {
            console.error("Error creating external issue:", error);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        }
    };

    const { sourceUsers, sourceUsersLoaded, hasNextPage } = useSourceUsers({
        source: createType ?? "",
        channelID: channel?.key ?? "",
        includeDefaults: true,
    });

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <AttributesBadge>
                        <PlusIcon strokeWidth={1.5} className="h-3 w-3" />
                        <p className="text-xs text-[#60646c]">Create</p>
                    </AttributesBadge>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-44 text-[#60646c]">
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger className="flex items-center gap-2 text-xs">
                            <SquareArrowUpIcon
                                strokeWidth={1.5}
                                className="h-3.5 w-3.5 text-red-700 flex-shrink-0"
                            />
                            <span>Escalation</span>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                                {enabledEscalations.includes("Web") && (
                                    <DropdownMenuItem
                                        className="text-xs px-2 py-1.5 hover:bg-muted"
                                        onSelect={() => {
                                            setCreateType("Web");
                                            setDialogOpen(true);
                                        }}
                                    >
                                        Via Assembly
                                    </DropdownMenuItem>
                                )}
                                {enabledEscalations.includes("Gmail") && (
                                    <DropdownMenuItem
                                        className="text-xs px-2 py-1.5 hover:bg-muted"
                                        onSelect={() => {
                                            setCreateType("Gmail");
                                            setDialogOpen(true);
                                        }}
                                    >
                                        Via Email
                                    </DropdownMenuItem>
                                )}
                                {enabledEscalations.includes("Slack") && (
                                    <DropdownMenuItem
                                        className="text-xs px-2 py-1.5 hover:bg-muted"
                                        onSelect={() => {
                                            setCreateType("Slack");
                                            setDialogOpen(true);
                                        }}
                                    >
                                        Via Slack
                                    </DropdownMenuItem>
                                )}
                            </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                    </DropdownMenuSub>
                    <DropdownMenuItem
                        className="flex items-center gap-2 text-xs px-2 py-1.5 hover:bg-muted"
                        onSelect={() => {
                            setCreateType("External");
                            setDialogOpen(true);
                        }}
                    >
                        <PencilRulerIcon
                            strokeWidth={1.5}
                            className="h-3.5 w-3.5 text-[#5e6ad2] flex-shrink-0"
                        />
                        External Issue
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogContent
                    className={`p-10 ${ESCALATE_TYPES.includes(createType ?? "") ? "max-w-[1000px]" : "max-w-[500px]"} max-h-[625px]`}
                >
                    {ESCALATE_TYPES.includes(createType ?? "") ? (
                        <>
                            <div className="flex items-center gap-1.5 text-lg font-semibold">
                                <SquareArrowUpIcon
                                    strokeWidth={1.5}
                                    className="h-5 w-5 text-red-700 flex-shrink-0"
                                />
                                {`Escalate ${ticket.ticket_identifier}-${ticket.number} via`}
                                <Select
                                    value={createType}
                                    onValueChange={handleSelectChange}
                                >
                                    <SelectTrigger className="w-[120px] text-[#5e6ad2] text-lg focus:outline-none focus:ring-0 font-medium px-2 py-1 rounded border-none outline-none hover:white">
                                        <SelectValue placeholder="Select Escalation Source..." />
                                    </SelectTrigger>
                                    <SelectContent className="w-[275px]">
                                        {enabledEscalations.includes("Web") && (
                                            <SelectItem value="Web">
                                                Assembly
                                            </SelectItem>
                                        )}
                                        {enabledEscalations.includes(
                                            "Gmail",
                                        ) && (
                                                <SelectItem value="Gmail">
                                                    Email
                                                </SelectItem>
                                            )}
                                        {enabledEscalations.includes(
                                            "Slack",
                                        ) && (
                                                <SelectItem value="Slack">
                                                    Slack
                                                </SelectItem>
                                            )}
                                    </SelectContent>
                                </Select>
                            </div>
                            {createType === "Web" && (
                                <div className="-mb-5 flex items-center gap-2">
                                    <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#e2e8f0] outline-1 hover:bg-[#eceefb] hover:text-[#5e6ad2] active:bg-[#eceefb] active:text-[#5e6ad2]">
                                        <div className="flex flex-col items-start gap-1">
                                            <div className="flex flex-row items-center justify-center gap-1 -mt-1">
                                                <div className="text-destructive pl-0.5">
                                                    *
                                                </div>
                                                <p className="text-xs text-black w-[100px]">
                                                    with team owner
                                                </p>
                                                <Select
                                                    defaultValue={teamOwner}
                                                    onValueChange={(
                                                        value: string,
                                                    ) => setTeamOwner(value)}
                                                >
                                                    <SelectTrigger className="w-fit text-[#5e6ad2] focus:outline-none focus:ring-0 text-xs font-medium py-1.5 px-0 rounded border-none outline-none hover:white">
                                                        <SelectValue placeholder="Select a team..." />
                                                    </SelectTrigger>
                                                    <SelectContent className="w-[220px]">
                                                        <SelectItem
                                                            value={
                                                                "NoTeamOwner"
                                                            }
                                                        >
                                                            <div className="text-xs font-normal flex flex-row items-center gap-2 px-1 py-0">
                                                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                                                    <HouseIcon className="text-iris9 w-2 h-2 !size-2" />
                                                                </div>
                                                                No Team Owner
                                                            </div>
                                                        </SelectItem>
                                                        {teams
                                                            .filter(
                                                                (team) =>
                                                                    !(
                                                                        team.team_name ===
                                                                        "General" ||
                                                                        team.id ===
                                                                        team.org_id
                                                                    ),
                                                            )
                                                            ?.map((team) => (
                                                                <SelectItem
                                                                    key={
                                                                        team.id
                                                                    }
                                                                    value={
                                                                        team.id
                                                                    }
                                                                >
                                                                    <div className="text-xs font-normal flex flex-row items-center gap-2 px-1 py-0">
                                                                        <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                                            <UsersIcon className="text-red9 w-2 h-2 !size-2" />
                                                                        </div>
                                                                        {
                                                                            team.team_name
                                                                        }
                                                                    </div>
                                                                </SelectItem>
                                                            ))}
                                                    </SelectContent>
                                                </Select>
                                                <p className="text-xs text-black w-[70px] pl-1">
                                                    and owner
                                                </p>
                                                <Popover modal={true}>
                                                    <PopoverTrigger asChild>
                                                        <div className="text-xs flex items-center justify-between">
                                                            <div className="flex items-center gap-1">
                                                                <div
                                                                    className={
                                                                        "lb-avatar rounded-lg w-4 h-3"
                                                                    }
                                                                >
                                                                    {pictureURL ? (
                                                                        <img
                                                                            className="lb-avatar-image"
                                                                            src={pictureURL}
                                                                            alt={owner?.first_name[0]}
                                                                        />
                                                                    ) : <AvatarIcon className="w-3 h-3" />}
                                                                    <span>{owner?.first_name[0] ?? ""}</span>
                                                                </div>
                                                                <div className="lb-comment-author text-xs font-normal">
                                                                    {owner ? `${owner?.first_name} ${owner?.last_name}` : "No Assignee"}
                                                                </div>
                                                            </div>
                                                            <CaretSortIcon className="h-4 w-4 opacity-50" />
                                                        </div>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-[200px] p-0">
                                                        <Command className="max-h-[200px] overflow-y-auto">
                                                            <CommandInput
                                                                placeholder="Search assignee..."
                                                                className="h-8 text-xs"
                                                            />
                                                            <CommandList>
                                                                <CommandEmpty>No assignee found.</CommandEmpty>
                                                                <CommandGroup>
                                                                    {myUser && (
                                                                        <CommandItem
                                                                            className="text-xs"
                                                                            onSelect={(currentValue) => {
                                                                                setOwner(myUser);
                                                                            }}
                                                                        >
                                                                            <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                                                                <AvatarIcon className="w-3 h-3" />

                                                                                <span className="lb-comment-author text-xs font-normal">
                                                                                    Assign to Me
                                                                                </span>
                                                                            </div>
                                                                        </CommandItem>
                                                                    )}
                                                                    <CommandItem
                                                                        className="text-xs"
                                                                        onSelect={(currentValue) => {
                                                                            setOwner(undefined);
                                                                        }}
                                                                    >
                                                                        <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                                                            <AvatarIcon className="w-3 h-3" />

                                                                            <span className="lb-comment-author text-xs font-normal">
                                                                                No Assignee
                                                                            </span>
                                                                        </div>
                                                                    </CommandItem>
                                                                    {users.map((user) => (
                                                                        <CommandItem
                                                                            key={user.id}
                                                                            value={`${user.first_name} ${user.last_name}`}
                                                                            onSelect={(currentValue) => {
                                                                                setOwner(user);
                                                                            }}
                                                                        >
                                                                            <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                                                                <div
                                                                                    className={"lb-avatar rounded-lg w-4 h-3"}
                                                                                >
                                                                                    {user.picture_url && (
                                                                                        <img
                                                                                            className="lb-avatar-image"
                                                                                            src={user.picture_url}
                                                                                            alt={`${user.first_name} ${user.last_name}`}
                                                                                        />
                                                                                    )}
                                                                                    <span>{`${user.first_name} ${user.last_name}`}</span>
                                                                                </div>
                                                                                <span className="lb-comment-author text-xs font-normal">
                                                                                    {`${user.first_name} ${user.last_name}`}
                                                                                </span>
                                                                            </div>
                                                                        </CommandItem>
                                                                    ))}
                                                                </CommandGroup>
                                                            </CommandList>
                                                        </Command>
                                                    </PopoverContent>
                                                </Popover>
                                            </div>
                                        </div>
                                    </Badge>
                                </div>
                            )}
                            {createType === "Slack" && (
                                <div className="-mb-5 flex items-center gap-2">
                                    <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#e2e8f0] outline-1 hover:bg-[#eceefb] hover:text-[#5e6ad2] active:bg-[#eceefb] active:text-[#5e6ad2] py-2">
                                        <div className="flex flex-col items-start gap-1">
                                            <div className="flex flex-row items-center justify-center gap-1 -mt-1">
                                                <div className="text-destructive pl-0.5">
                                                    *
                                                </div>
                                                <p className="text-xs text-black w-[20px]">
                                                    via
                                                </p>
                                                <Popover modal={true}>
                                                    <PopoverTrigger asChild>
                                                        <div className="text-xs flex items-center justify-between">
                                                            {channel
                                                                ? `#${channel.name}`
                                                                : "Select channel..."}
                                                            <CaretSortIcon className="h-4 w-4 opacity-50" />
                                                        </div>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-[200px] p-0">
                                                        <Command className="max-h-[200px] overflow-y-auto">
                                                            <CommandInput
                                                                placeholder="Search channel..."
                                                                className="h-8 text-xs"
                                                            />
                                                            {/* TODO: figure out why scrolling doesn't work */}
                                                            <CommandList>
                                                                <CommandEmpty>
                                                                    No channel
                                                                    found.
                                                                </CommandEmpty>
                                                                {(
                                                                    channelsQuery?.data ??
                                                                    new Map()
                                                                )
                                                                    .get(
                                                                        "Slack",
                                                                    )
                                                                    ?.map(
                                                                        (
                                                                            c: ScopeResponse,
                                                                        ) => (
                                                                            <CommandItem
                                                                                key={
                                                                                    c.key
                                                                                }
                                                                                value={
                                                                                    c.name
                                                                                }
                                                                                onSelect={() => {
                                                                                    setChannel(
                                                                                        c,
                                                                                    );
                                                                                }}
                                                                                className="text-xs justify-between"
                                                                            >
                                                                                {`#${c.name}`}
                                                                                {c.key ===
                                                                                    channel?.key && (
                                                                                        <CheckIcon className="h-4 w-4" />
                                                                                    )}
                                                                            </CommandItem>
                                                                        ),
                                                                    )}
                                                            </CommandList>
                                                        </Command>
                                                    </PopoverContent>
                                                </Popover>
                                            </div>
                                        </div>
                                    </Badge>
                                </div>
                            )}
                            <Card className="rounded-tr-lg rounded-bl-lg rounded-br-lg focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0 flex flex-col p-2 mb-2 shadow-sm shadow-[#f3f4f6] color-[#f3f4f6] border relative">
                                {createType === "Gmail" && (
                                    <div
                                        ref={replyPluginRef}
                                        className="w-full bg-white rounded-t-lg"
                                    >
                                        <ReplyFromPlugin
                                            properties={properties}
                                            setProperties={setProperties}
                                            showSubject={true}
                                            propertiesOptions={
                                                propertiesOptions
                                            }
                                            setIntegrationID={setIntegrationID}
                                        />
                                    </div>
                                )}
                                {createType === "Web" && (
                                    <div className="mx-3 my-2 flex items-center">
                                        <div className="text-md font-semibold w-[60px] mr-2">
                                            Title:{" "}
                                        </div>
                                        <Input
                                            type="title"
                                            value={title}
                                            onChange={(e) =>
                                                setTitle(e.target.value)
                                            }
                                            placeholder="Escalation Interaction Title..."
                                            className="max-w-full p-2 rounded border-none"
                                        />
                                    </div>
                                )}
                                <Editor
                                    className="max-h-full overflow-scroll scrollbar-white"
                                    enableAIResponse={false}
                                    handleSubmit={handleSend}
                                    isToggled={isToggled}
                                    setIsSendDisabled={setIsSendDisabled}
                                    isSendDisabled={isSendDisabled}
                                    setIsToggled={setIsToggled}
                                    loading={loading}
                                    handleFileUpload={handleFileUpload}
                                    handleImageUpload={handleImageUpload}
                                    handleDeleteFile={handleDeleteFile}
                                    uploadedFiles={uploadedFiles}
                                    source={createType ?? ""}
                                    editorType={EditorType.Escalation}
                                    channelID={channel?.key}
                                    fullyDisableSend={
                                        createType === "Web"
                                            ? teamOwner === undefined
                                            : createType === "Gmail"
                                                ? integrationID === undefined ||
                                                !properties.has("to") ||
                                                (properties.get("to") ?? [])
                                                    .length === 0
                                                : createType === "Slack"
                                                    ? channel === undefined
                                                    : false
                                    }
                                    mentionOptions={sourceUsers}
                                    mentionOptionsLoaded={sourceUsersLoaded}
                                />
                            </Card>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center gap-1.5 text-lg font-semibold">
                                <PencilRulerIcon
                                    strokeWidth={1.5}
                                    className="h-5 w-5 text-[#5e6ad2] flex-shrink-0"
                                />
                                {`Create External Issue for ${ticket.ticket_identifier}-${ticket.number}`}
                            </div>
                            <div className="prose max-w-none text-xs flex flex-col gap-4 p-2 mt-1">
                                <div className="flex items-center gap-2">
                                    <div className="text-sm text-muted-foreground min-w-[120px]">
                                        Integration
                                    </div>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <div>
                                                <AttributesBadge>
                                                    <DestIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                        }}
                                                        justify="start"
                                                    />
                                                    {integrationLabelMap.get(
                                                        selectedInt,
                                                    ) ?? selectedInt}
                                                    <TriangleDownIcon className="h-3 w-3" />
                                                </AttributesBadge>
                                            </div>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            side="bottom"
                                            align="end"
                                            className="w-[200px] p-2"
                                        >
                                            {enabledIntegrations.map(
                                                (integration) => {
                                                    const IssueSvgImage: React.ElementType =
                                                        integrationBackEndDataMappingToSvg.get(
                                                            integration,
                                                        ) ?? ReaderIcon;
                                                    return (
                                                        <DropdownMenuItem
                                                            key={integration}
                                                            className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                            onSelect={() => {
                                                                setSelectedInt(
                                                                    integration,
                                                                );
                                                                setPriority(
                                                                    "None",
                                                                );
                                                            }}
                                                        >
                                                            <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                                <IssueSvgImage
                                                                    style={{
                                                                        width: "15px",
                                                                        height: "15px",
                                                                    }}
                                                                    justify="start"
                                                                />
                                                                {integrationLabelMap.get(
                                                                    integration,
                                                                ) ??
                                                                    integration}
                                                            </div>
                                                        </DropdownMenuItem>
                                                    );
                                                },
                                            )}
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="text-sm text-muted-foreground min-w-[120px]">
                                        Destination
                                    </div>
                                    {isDestinationsLoading ? (
                                        <Skeleton>
                                            <Text>{LOREM_IPSUM}</Text>
                                        </Skeleton>
                                    ) : (
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <div>
                                                    <AttributesBadge>
                                                        {selectedDest.name}
                                                        <TriangleDownIcon className="h-3 w-3" />
                                                    </AttributesBadge>
                                                </div>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent
                                                side="bottom"
                                                align="end"
                                                className="w-[200px] h-[180px] p-2 overflow-y-auto"
                                            >
                                                {destinations.map((channel) => (
                                                    <DropdownMenuItem
                                                        key={channel.key}
                                                        className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                        onSelect={() =>
                                                            setSelectedDest(
                                                                channel,
                                                            )
                                                        }
                                                    >
                                                        <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                            {channel.name}
                                                        </div>
                                                    </DropdownMenuItem>
                                                ))}
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    )}
                                </div>

                                {/* Only allow ticket type selection for Intercom and Jira */}
                                {["Intercom", "Jira"].includes(selectedInt) && (
                                    <div className="flex items-center gap-2">
                                        <div className="text-sm text-muted-foreground min-w-[120px]">
                                            Ticket Type
                                        </div>
                                        {isIssueTypesLoading ? (
                                            <Skeleton>
                                                <Text>{LOREM_IPSUM}</Text>
                                            </Skeleton>
                                        ) : (
                                            <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <div>
                                                        <AttributesBadge>
                                                            {issueType?.name ??
                                                                ""}
                                                            <TriangleDownIcon className="h-3 w-3" />
                                                        </AttributesBadge>
                                                    </div>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent
                                                    side="bottom"
                                                    align="end"
                                                    className="w-[200px] p-2"
                                                >
                                                    {issueTypes.map((it) => (
                                                        <DropdownMenuItem
                                                            key={it.id}
                                                            className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                            onSelect={() =>
                                                                setIssueType(it)
                                                            }
                                                        >
                                                            <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                                {it.name}
                                                            </div>
                                                        </DropdownMenuItem>
                                                    ))}
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        )}
                                    </div>
                                )}

                                {/* Only allow priority selection for Intercom */}
                                {selectedInt === "Intercom" && (
                                    <div className="flex items-center gap-2">
                                        <div className="text-sm text-muted-foreground min-w-[120px] flex items-center gap-1">
                                            Priority
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0 text-destructive hover:bg-sidebar" />
                                                    </TooltipTrigger>
                                                    <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col max-w-[250px]">
                                                        Ticket Type must have a
                                                        valid Priority attribute
                                                        if not selecting None.
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <div>
                                                    <AttributesBadge>
                                                        {priority}
                                                        <TriangleDownIcon className="h-3 w-3" />
                                                    </AttributesBadge>
                                                </div>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent
                                                side="bottom"
                                                align="end"
                                                className="w-[200px] p-2"
                                            >
                                                {[
                                                   "None",
                                                    "Low",
                                                    "Normal",
                                                    "High",
                                                    "Urgent"
                                                ].map((p) => (
                                                    <DropdownMenuItem
                                                        key={p}
                                                        className="py-1 hover:bg-muted cursor-pointer flex items-center"
                                                        onSelect={() =>
                                                            setPriority(p)
                                                        }
                                                    >
                                                        <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details text-[13px]">
                                                            {p}
                                                        </div>
                                                    </DropdownMenuItem>
                                                ))}
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                )}

                                <div className="flex items-center justify-between gap-2 mt-3">
                                    <div />
                                    <DialogClose asChild>
                                        <Button
                                            size="lg"
                                            onClick={createExternalIssue}
                                            className="text-sm px-4 h-7"
                                        >
                                            Create External Issue
                                        </Button>
                                    </DialogClose>
                                </div>
                            </div>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default InteractionCreateButton;
