import { Card, CardContent, CardHeader } from "@/component/shadcn/ui/card";
import { Input } from "@/component/shadcn/ui/input";
import { Separator } from "@/component/shadcn/ui/separator";
import { Badge } from "@radix-ui/themes";
import { Handle, Position } from "@xyflow/react";
import { WaitIcon } from "../Icons";
import { handleStyle } from "../Icons";

import { PlusIcon, TimerIcon } from "lucide-react";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { sourcesFilterOptions } from "@/IssuesTable/constants";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import {
    type Category,
    FilterIncludeType,
    type GetTopicsResponse,
    type ScopeResponse,
    type Workflow,
    WorkflowKind,
    type WorkflowsFilterType,
} from "@/interfaces/serverData";
import {
    WorkflowNodesDropdownTrigger,
    getTopicColors,
} from "@/utilities/methods";
import { Cross1Icon, TrashIcon } from "@radix-ui/react-icons";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const WaitNode: React.FC<{
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: any;
    isConnectable: boolean;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    onUpdate: (id: string, metadata: any) => void;
    workflowKind: WorkflowKind;
    categoriesQuery: UseQueryResult<Category[], Error>;
    topicsQuery: UseQueryResult<GetTopicsResponse[], Error>;
    workflow?: Workflow;
}> = ({
    data,
    isConnectable,
    onUpdate,
    workflowKind,
    categoriesQuery,
    topicsQuery,
    workflow,
}) => {
        const [timeUnit, setTimeUnit] = useState<string>(
            data.metadata?.unit ?? (workflow ? undefined : "days"),
        );
        const [timeInput, setTimeInput] = useState<number>(
            data.metadata?.time ?? (workflow ? undefined : 30),
        );
        const [filterComboType, setFilterComboType] = useState<string>(
            data.metadata?.filterComboType ?? (workflow ? undefined : "all"),
        );
        const [activeMenu, setActiveMenu] = useState<string | null>(null);
        const [selectedValues, setSelectedValues] = useState<WorkflowsFilterType[]>(
            [
                {
                    type: "",
                    enabled: true,
                    id: crypto.randomUUID(),
                    includeType: FilterIncludeType.Is,
                },
            ],
        );
        const [filters, setFilters] = useState<
            Map<
                string,
                Set<{
                    label: string;
                    value: string;
                    key: string;
                    color: string;
                    channels?: ScopeResponse[];
                }>
            >
        >(data.metadata?.filters ?? new Map());
        const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");
        const [hasWhileFilters, setHasWhileFilters] = useState<boolean>(
            data.metadata?.subtype === "while" || false,
        ); // Just allow 1 while filter block for now

        // Update node's metadata
        const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            setTimeInput(Number(e.target.value));
            const updatedMetadata = { ...data.metadata, time: e.target.value };
            onUpdate(data.id, updatedMetadata);
        };
        const handleTimeUnit = (value: string) => {
            setTimeUnit(value);
            const updatedMetadata = { ...data.metadata, unit: value };
            onUpdate(data.id, updatedMetadata);
        };

        const handleFilterComboTypeChange = (value: string) => {
            const updatedMetadata = { ...data.metadata, filterComboType: value };
            setFilterComboType(value);
            onUpdate(data.id, updatedMetadata); // Update the metadata of the node
        };

        const handleFilterSelectChange = (value: string, id: string) => {
            const newSelectedValues = [...selectedValues];
            const index = newSelectedValues.findIndex((v) => v.id === id);
            // If setting the last filter to a value, automatically add another filter
            if (index === newSelectedValues.length - 1 && value !== "") {
                newSelectedValues.push({
                    type: "",
                    enabled: true,
                    id: crypto.randomUUID(),
                    includeType: FilterIncludeType.Is,
                });
            }
            newSelectedValues[index] = { type: value, enabled: true, id };
            setSelectedValues(newSelectedValues);
            setActiveMenu(`${value}_${id}`);
            const currentFilters = new Map(filters);
            for (const key of currentFilters.keys()) {
                if (key.split("_")[1] === id) {
                    currentFilters.delete(key);
                }
            }
            currentFilters.set(`${value}_${id}`, new Set());
            setFilters(currentFilters);
        };

        const handleFilterItemSelect =
            (
                type: string,
                option: {
                    label: string;
                    value: string;
                    key: string;
                    color: string;
                },
                index?: number,
            ) =>
                () => {
                    setActiveMenu(option.value);
                    const newFilters = new Map(filters);
                    newFilters.set(`${type}_${index}`, new Set([option]));
                    setFilters(newFilters);
                };

        const handleDeleteFilter = (value: string, id: string) => {
            const newSelectedValues = [...selectedValues];

            // Update filters
            const newFilters = new Map(filters);
            newFilters.delete(`${value}_${id}`);
            setFilters(newFilters);

            setSelectedValues(newSelectedValues.filter((v) => v.id !== id));
        };

        const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const updatedMetadata = { ...data.metadata, name: e.target.value };
            onUpdate(data.id, updatedMetadata); // Update the metadata of the node
            setNameVal(e.target.value);
        };

        const handleAddWhileFilters = () => {
            setHasWhileFilters(true);
            setFilterComboType("all");
            const updatedMetadata = {
                ...data.metadata,
                subtype: "while",
                filterComboType: "all",
            };
            onUpdate(data.id, updatedMetadata);
        };

        const handleRemoveWhileFilters = () => {
            setHasWhileFilters(false);
            setSelectedValues([
                {
                    type: "",
                    enabled: true,
                    id: crypto.randomUUID(),
                    includeType: FilterIncludeType.Is,
                },
            ]);
            setFilterComboType("all");
            const updatedMetadata = {
                ...data.metadata,
                subtype: "none",
                filterComboType: "all",
            };
            onUpdate(data.id, updatedMetadata);
            setFilters(new Map());
        };

        useEffect(() => {
            const updatedMetadata = {
                ...data.metadata,
                filters: filters,
                subtype: hasWhileFilters ? "while" : "none",
                filterComboType: filterComboType,
            };
            onUpdate(data.id, updatedMetadata);
        }, [filters, onUpdate]);

        useEffect(() => {
            if (data.metadata?.filters) {
                const newSelectedValues: WorkflowsFilterType[] = [];
                for (const [key, value] of data.metadata.filters) {
                    const [type, id] = key.split("_");
                    newSelectedValues.push({
                        type: type,
                        enabled: true,
                        id: id,
                        includeType: FilterIncludeType.Is,
                    });
                }
                // Add an additional "Add a filter"
                newSelectedValues.push({
                    type: "",
                    enabled: true,
                    id: crypto.randomUUID(),
                    includeType: FilterIncludeType.Is,
                });

                setSelectedValues(newSelectedValues);
            }
        }, [workflow]);

        return (
            <div className="flex flex-col items-start">
                <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
                    <div className="flex flex-row items-center justify-center gap-1">
                        <TimerIcon
                            className=" text-[#5e6ad2]"
                            strokeWidth={1.5}
                            size={12}
                        />
                        <p className="text-xs">Wait</p>
                    </div>
                </Badge>
                <Card
                    className={`w-[475px] shadow-none border rounded-tr-lg rounded-bl-lg rounded-br-lg z-10 ${data.errorStyle}`}
                >
                    <CardHeader className="flex flex-col gap-1 space-y-0 px-5 pb-2">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex items-center gap-2 w-full">
                                    <WaitIcon />
                                    <p className="text-xs font-medium flex-shrink-0">
                                        Wait for:
                                    </p>
                                </div>
                                <div className="flex items-center border border-[#D8D8D8] rounded-md bg-white h-9 w-fit">
                                    <Input
                                        type="number"
                                        value={timeInput}
                                        onChange={handleTimeInput}
                                        className={
                                            "px-3 w-[5rem] max-w-full text-center bg-transparent border-none outline-none focus:outline-none focus:ring-0"
                                        }
                                        min="1"
                                    />

                                    <Separator
                                        orientation="vertical"
                                        className="bg-[#D8D8D8] w-[1px] h-8"
                                    />

                                    <Select
                                        defaultValue={timeUnit}
                                        onValueChange={handleTimeUnit}
                                    >
                                        <SelectTrigger className="w-full focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1]">
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="seconds">
                                                Seconds
                                            </SelectItem>
                                            <SelectItem value="minutes">
                                                Minutes
                                            </SelectItem>
                                            <SelectItem value="hours">
                                                Hours
                                            </SelectItem>
                                            <SelectItem value="days">
                                                Days
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>

                                {!hasWhileFilters && workflowKind === WorkflowKind.Interactions && (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div>
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger
                                                            asChild
                                                        >
                                                            <Button className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 px-1.5 h-7 hover:text-none hover:bg-gray-200">
                                                                <PlusIcon className="w-4 h-4" />
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent>
                                                            <DropdownMenuItem
                                                                className="flex items-center gap-1.5"
                                                                onClick={
                                                                    handleAddWhileFilters
                                                                }
                                                            >
                                                                Add
                                                                <div className="text-semibold text-[#5B5BD6]">
                                                                    While
                                                                </div>
                                                                Filters
                                                            </DropdownMenuItem>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col">
                                                <div className="text-sm">
                                                    Add Filters
                                                </div>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                )}
                            </div>
                            {hasWhileFilters && (
                                <Card className="flex flex-col items-start gap-3 w-full p-4 shadow-none border rounded-lg rounded-br-lg relative">
                                    <Button
                                        className="absolute top-1 right-1 px-1.5 bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 h-7 hover:text-none hover:bg-gray-200"
                                        onClick={handleRemoveWhileFilters}
                                    >
                                        <Cross1Icon className="w-3 h-3" />
                                    </Button>
                                    <div className="flex items-center gap-2 w-full">
                                        <div className="flex items-center gap-3 w-full">
                                            <p className="text-xs font-medium flex-shrink-0">
                                                while
                                            </p>
                                            <Select
                                                defaultValue={filterComboType}
                                                onValueChange={
                                                    handleFilterComboTypeChange
                                                }
                                            >
                                                <SelectTrigger className="w-[40px] focus:outline-none focus:ring-0 text-xs font-medium hover:bg-muted px-2 py-1 rounded outline outline-1 outline-[#eeeff1] h-5">
                                                    <SelectValue />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="all">
                                                        all
                                                    </SelectItem>
                                                    <SelectItem value="any">
                                                        any
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <p className="text-xs font-medium flex-grow">
                                                of these filters match:
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-3 w-full">
                                        {selectedValues.map((filterType) => {
                                            const val =
                                                Array.from(
                                                    filters.get(
                                                        `${filterType.type}_${filterType.id}_${filterType.includeType}`,
                                                    ) ?? [],
                                                )[0]?.value ?? "";
                                            if (!filterType.enabled) {
                                                return null; // If disabled, do not render this element
                                            }
                                            return (
                                                <div
                                                    className="flex items-center gap-3 w-full ml-2"
                                                    key={filterType.id}
                                                >
                                                    <Select
                                                        value={filterType.type}
                                                        onValueChange={(value) =>
                                                            handleFilterSelectChange(
                                                                value,
                                                                filterType.id,
                                                            )
                                                        }
                                                    >
                                                        <SelectTrigger
                                                            className={`w-[100px] text-xs font-medium hover:bg-muted px-2 py-1 rounded h-5 ${filterComboType ===
                                                                "all" &&
                                                                filterType.type &&
                                                                filterType.type !==
                                                                "Topic" &&
                                                                filterType.type !==
                                                                "Reply" &&
                                                                Array.from(
                                                                    filters.keys(),
                                                                )
                                                                    .filter(
                                                                        (key) =>
                                                                            key !==
                                                                            `${filterType.type}_${filterType.id}_${filterType.includeType}`,
                                                                    )
                                                                    .some(
                                                                        (key) =>
                                                                            key.split(
                                                                                "_",
                                                                            )[0] ===
                                                                            filterType.type,
                                                                    )
                                                                ? "outline outline-1 outline-red-500"
                                                                : "outline outline-1 outline-[#eeeff1]"
                                                                }`}
                                                        >
                                                            <SelectValue placeholder="Add a filter..." />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectItem value="Status">
                                                                Status
                                                            </SelectItem>
                                                            <SelectItem value="Reply">
                                                                No New Reply
                                                            </SelectItem>
                                                        </SelectContent>
                                                    </Select>

                                                    {filterType.type !== "" && (
                                                        <>
                                                            <p className="text-xs font-medium w-[32px] text-center">
                                                                {filterType.type ===
                                                                    "Reply"
                                                                    ? "from"
                                                                    : "stays"}
                                                            </p>
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger
                                                                    asChild
                                                                    type="button"
                                                                    onChange={() =>
                                                                        setActiveMenu(
                                                                            `${filterType.type}_${filterType.id}_${filterType.includeType}`,
                                                                        )
                                                                    }
                                                                >
                                                                    <Button
                                                                        variant="outline"
                                                                        className={`${!filters.get(`${filterType.type}_${filterType.id}_${filterType.includeType}`)?.size && "outline outline-1 outline-red-500"} mr-2 justify-between h-8 text-xs px-3 overflow-hidden text-ellipsis break-word w-[150px]`}
                                                                    >
                                                                        {WorkflowNodesDropdownTrigger(
                                                                            filterType.type,
                                                                            filters,
                                                                            [],
                                                                            [],
                                                                            [],
                                                                            [],
                                                                            categoriesQuery.data ??
                                                                            [],
                                                                            getTopicColors(
                                                                                topicsQuery.data ??
                                                                                [],
                                                                            ),
                                                                            filterType.id,
                                                                        )}
                                                                    </Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent
                                                                    align="start"
                                                                    className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                                                >
                                                                    <FilterDropdownElement
                                                                        type={
                                                                            sourcesFilterOptions
                                                                                .map(
                                                                                    (
                                                                                        opt,
                                                                                    ) =>
                                                                                        opt.type,
                                                                                )
                                                                                .includes(
                                                                                    val,
                                                                                )
                                                                                ? val
                                                                                : filterType.type
                                                                        }
                                                                        categories={
                                                                            categoriesQuery.data ??
                                                                            []
                                                                        }
                                                                        filters={
                                                                            filters
                                                                        }
                                                                        handleItemSelect={
                                                                            handleFilterItemSelect
                                                                        }
                                                                        topics={getTopicColors(
                                                                            topicsQuery.data ??
                                                                            [],
                                                                        )}
                                                                        users={[]}
                                                                        customerGroups={[]}
                                                                        teams={[]}
                                                                        isSavedViewFilter={
                                                                            false
                                                                        }
                                                                        channels={
                                                                            new Map()
                                                                        }
                                                                        index={
                                                                            filterType.id
                                                                        }
                                                                    />
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                            <Button
                                                                variant="outline"
                                                                className="px-1.5"
                                                                onClick={() =>
                                                                    handleDeleteFilter(
                                                                        filterType.type,
                                                                        filterType.id,
                                                                    )
                                                                }
                                                            >
                                                                <TrashIcon className="w-3.5 h-3.5" />
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Card>
                            )}
                        </div>
                    </CardHeader>

                    <CardContent className="flex flex-col gap-2 pt-4">
                        <Separator />
                        <Input
                            className="text-xs border-none px-0"
                            placeholder="Name..."
                            value={nameVal}
                            onChange={handleNameChange}
                        />
                    </CardContent>

                    <Handle
                        type="source"
                        position={Position.Top}
                        id="a"
                        isConnectable={isConnectable}
                        className={`${handleStyle} top-[18px]`}
                    />
                    <Handle
                        type="source"
                        position={Position.Bottom}
                        id="b"
                        isConnectable={isConnectable}
                        className={handleStyle}
                    />
                </Card>
            </div>
        );
    };

export default WaitNode;