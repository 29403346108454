import type { SharedInfo, UserResponse } from "@/interfaces/serverData";
import { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type { ComponentProps } from "react";

export type BadgeColor = (typeof badgePropDefs.color.values)[number];

interface AvatarProps extends ComponentProps<"div"> {
    user: UserResponse | SharedInfo | undefined;
}

export function getColor(username: string): BadgeColor {
    const colors = badgePropDefs.color.values.slice(3);

    // Calculate sum of character codes for all letters
    let sum = 0;
    for (let i = 0; i < username.length; i++) {
        const char = username[i].toLowerCase();
        const code = char.charCodeAt(0) - "a".charCodeAt(0);
        if (code >= 0 && code <= 25) {
            // Only count letters
            sum += code;
        }
    }

    // Use sum to determine color index
    return colors[sum % colors.length];
}

export function Avatar({ user, className, ...props }: AvatarProps) {
    function getUserName(user: UserResponse | SharedInfo | undefined) {
        if (!user) return "Anonymous";
        
        if ('username' in user) {
            // UserResponse type
            const name = user.name ?? user.username ?? user.email ?? "Anonymous";
            return name.trim() || "Anonymous";
        } else {
            return user.name?.trim() || "Anonymous";
        }
    }
    return (
        <div
            className={`lb-avatar  ${className ? className : "w-7 h-7"}`}
            {...props}
            aria-hidden
        >
            {user?.avatar && (
                <img
                    className="lb-avatar-image"
                    src={user.avatar}
                    alt={getUserName(user)}
                />
            )}
            <span className="lb-avatar-fallback" aria-hidden>
                {getUserName(user).charAt(0).toUpperCase()}
            </span>
        </div>
        // introduce this when colors work!
        // <AvatarRadix.Root
        //     className={`inline-flex size-[25px] select-none items-center justify-center overflow-hidden rounded-full bg-${color}3 align-middle`}
        //     color={color}
        // >
        //     {user.avatar && (
        //         <AvatarRadix.Image src={user.avatar} alt={getUserName(user)} />
        //     )}
        //     <AvatarRadix.Fallback
        //         className={`leading-1 flex size-full items-center justify-center text-[11px] font-medium text-${color}11`}
        //     >
        //         {getUserName(user).charAt(0).toUpperCase()}
        //     </AvatarRadix.Fallback>
        // </AvatarRadix.Root>
    );
}
