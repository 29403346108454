import { Card, CardContent, CardHeader } from "@/component/shadcn/ui/card";
import { useApi } from "@/interfaces/api";
import {
    Box,
    Flex,
    Heading,
    Skeleton,
    Switch,
    Text,
    Link
} from "@radix-ui/themes";

import { useEffect, useState } from "react";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { Button } from "@/component/shadcn/ui/button";
import { Separator } from "@/component/shadcn/ui/separator";
import { useSearch } from "@/pages/SearchContext";
import { ArrowUpIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import { DropdownMenu, DropdownMenuItem, DropdownMenuContent, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { Select, SelectValue, SelectTrigger, SelectContent, SelectItem } from "@/component/shadcn/ui/select";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { API, loadingTypes, URLS } from "@/constant";
import { GetMilestonePreferencesResponse, GetSystemNotificationResponse } from "@/interfaces/notificationsServerData";
import { FeatureType } from "@/interfaces/notificationsServerData";
import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";

const milestoneFormSchema = z.object({
    milestoneDateType: z.enum(["before", "after"]),
    milestoneDateValue: z.number().min(1),
    milestoneDateUnit: z.enum(["hours", "days", "minutes", "seconds"]),
    taskDateType: z.enum(["before", "after"]),
    taskDateValue: z.number().min(1),
    taskDateUnit: z.enum(["hours", "days", "minutes", "seconds"]),
});

type MilestoneForm = z.infer<typeof milestoneFormSchema>;

const MilestonePreferences: React.FC<{userID: string}> = ({userID}) => {
    const { searchBarFilters, loading } = useSearch();
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const api = useApi();
    const { toast } = useToast();
    const { register, handleSubmit, watch, setValue } = useForm<MilestoneForm>({
        resolver: zodResolver(milestoneFormSchema),
        defaultValues: {
            milestoneDateType: "before",
            milestoneDateValue: 1,
            milestoneDateUnit: "hours",
            taskDateType: "before",
            taskDateValue: 1,
            taskDateUnit: "hours"
        }
    });

    const milestoneDateType = watch("milestoneDateType");
    const milestoneDateValue = watch("milestoneDateValue");
    const milestoneDateUnit = watch("milestoneDateUnit");

    const taskDateType = watch("taskDateType");
    const taskDateValue = watch("taskDateValue");
    const taskDateUnit = watch("taskDateUnit");

    useEffect(() => {
        api.get(URLS.serverUrl + API.getSystemNotifications, {
            params: {
                feature_type: FeatureType.MILESTONE,
                settings_type: "User",
                settings_type_id: userID,
            },
        }).then((res) => {
            const response : GetMilestonePreferencesResponse = res.data.data;
            setValue("milestoneDateType", response.metadata.milestone_preferences.date_type as "before" | "after");
            setValue("milestoneDateValue", response.metadata.milestone_preferences.date_value as number);
            setValue("milestoneDateUnit", response.metadata.milestone_preferences.date_unit as "hours" | "days" | "minutes" | "seconds");
            setValue("taskDateType", response.metadata.task_preferences.date_type as "before" | "after");
            setValue("taskDateValue", response.metadata.task_preferences.date_value as number);
            setValue("taskDateUnit", response.metadata.task_preferences.date_unit as "hours" | "days" | "minutes" | "seconds");
        });
    }, [api, userID, setValue]);

    const onSubmit = async (data: MilestoneForm) => {
        try {
            await api.post(URLS.serverUrl + API.createOrUpdateSystemNotifications, {
                enabled: true,
                metadata: { 
                    milestone_preferences: {
                        date_type: data.milestoneDateType,
                        date_value: data.milestoneDateValue,
                        date_unit: data.milestoneDateUnit
                    },
                    task_preferences: {
                        date_type: data.taskDateType,
                        date_value: data.taskDateValue,
                        date_unit: data.taskDateUnit
                    }
                },
                settings_type: "User",
                settings_type_id: userID,
                feature_type: FeatureType.MILESTONE
            });
            toast({
                title: "Success",
                description: "Milestone preferences updated",
            });
        } catch (error) {
            toast({
                title: "Error", 
                description: "Failed to update milestone preferences",
                variant: "destructive"
            });
        }
    };

    const [enabled, setEnabled] = useState(false);

    const [loadingState, setLoadingState] = useState<loadingTypes>(loadingTypes.loading);
    useEffect(() => {
        api.get(URLS.serverUrl + API.getSystemNotifications, {
            params: {
                settings_type: "User",
                settings_type_id: userID,
                feature_type: FeatureType.SYSTEM
            },
        }).then((res) => {
            const response : GetSystemNotificationResponse = res.data.data;
            const enabledValue = response.enabled && response.metadata.reminders_and_deadlines;
            setEnabled(enabledValue);
            setLoadingState(loadingTypes.loaded);
        }).catch(() => {
            setLoadingState(loadingTypes.error);
        });
    }, [api, userID, setValue]);

    return (
        <div>
            <Flex direction="column" align="center" justify="center">
                <Box mt="7" width="54%">
                <Flex direction={"column"} align={"start"} className="w-full" mb="3">
                        <Heading size="5" align="left">
                            Milestones
                        </Heading>

                        <Text mb="15px" size="2">
                            Milestones are used to track tasks per account
                        </Text>
                </Flex>
                    {!loading || loadingState === loadingTypes.loading ? (
                        <Skeleton>
                            <Text>
                                {[...Array(2)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Text>
                        </Skeleton>
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex direction="column" gap="4">
                                <Card className="shadow-none rounded-lg">
                                    <CardHeader className="flex flex-row items-center justify-between">
                                        <div className="text-sm font-medium pt-2">
                                            Notifications Enabled
                                        </div>
                                        {enabled ? (
                                            <Switch checked={true} />
                                        ) : (
                                            <DialogTriggerButton className="text-xs h-6 my-0" onClick={() => {
                                                window.open("https://dashboard.askassembly.app/preferences/notifications", "_blank")
                                            }}>
                                                Enable Reminders and Deadlines
                                            </DialogTriggerButton>
                                        )}
                                    </CardHeader>
                                    <Separator />
                                    <div className={enabled ? "opacity-100" : "opacity-50 pointer-events-none"}>
                                    <CardHeader>
                                            <div className="flex flex-col">
                                                <div className="text-sm font-medium flex flex-row items-center gap-2">
                                                    Get alerts when milestones are due
                                                </div>
                                                <div className="text-xs text-gray11">
                                                    Set custom rules:
                                                </div>
                                            </div>
                                    </CardHeader>
                                    <CardContent className="flex flex-col gap-4">
                                        <div className="flex flex-row gap-4 items-center"> 
                                        <div className="text-xs font-medium flex flex-row items-center gap-2">
                                            Notify me
                                        </div>

                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button variant="outline" className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-1 px-2 justify-start data-[state=open]:bg-muted shadow-none h-7 items-center">
                                                   {milestoneDateType}
                                                    <TriangleDownIcon className="h-3 w-3" />
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <DropdownMenuItem key="after" className="text-xs" onClick={() => {
                                                    setValue("milestoneDateType", "after");
                                                    handleSubmit(onSubmit)();
                                                }}>
                                                    after
                                                </DropdownMenuItem>
                                                <DropdownMenuItem key="before" className="text-xs" onClick={() => {
                                                    setValue("milestoneDateType", "before");
                                                    handleSubmit(onSubmit)();
                                                }}>
                                                    before
                                                </DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>

                                        <div className="flex flex-row gap-3">
                                            <div className="flex flex-col items-center gap-2">
                                                <div className="flex items-center outline outline-1 outline-slate-200 rounded-md px-2 bg-white h-7 min-h-[28px]">
                                                <Input
                                                    type="number"
                                                    {...register("milestoneDateValue", { valueAsNumber: true })}
                                                    onChange={(e) => {
                                                        setValue("milestoneDateValue", parseInt(e.target.value));
                                                    }}
                                                    onBlur={() => {
                                                        handleSubmit(onSubmit)();
                                                    }}
                                                    className="h-6 px-3 max-w-full text-center bg-transparent focus:ring-0 focus:ring-offset-0 border-none outline-none"
                                                    min="1"
                                                    style={{
                                                        width: `${Math.max(5, milestoneDateValue.toString().length)}rem`,
                                                    }}
                                                />

                                                    <Separator
                                                        orientation="vertical"
                                                        className="bg-[#D8D8D8] w-[1px] h-8 mx-3"
                                                    />

                                                    <Select
                                                        value={milestoneDateUnit}
                                                        onValueChange={(value: "hours" | "days" | "minutes" | "seconds") => {
                                                            setValue("milestoneDateUnit", value);
                                                            handleSubmit(onSubmit)();
                                                        }}
                                                    >
                                                        <SelectTrigger className="text-xs text-gray-700 h-2 bg-white border-none focus:ring-0 focus:ring-offset-0 cursor-pointer mx-0 w-full">
                                                            <SelectValue placeholder="Select a unit" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectItem value="hours">{milestoneDateValue === 1 ? "hour" : "hours"}</SelectItem>
                                                            <SelectItem value="days">{milestoneDateValue === 1 ? "day" : "days"}</SelectItem>
                                                            <SelectItem value="minutes">{milestoneDateValue === 1 ? "minute" : "minutes"}</SelectItem>
                                                            <SelectItem value="seconds">{milestoneDateValue === 1 ? "second" : "seconds"}</SelectItem>
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-xs font-medium">
                                            of a milestone due date
                                        </div>
                                        </div>
                                        <div className="flex flex-row gap-4 items-center"> 
                                        <div className="text-xs font-medium flex flex-row items-center gap-2">
                                            Notify me
                                        </div>

                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button variant="outline" className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-1 px-2 justify-start data-[state=open]:bg-muted shadow-none h-7 items-center">
                                                   {taskDateType}
                                                    <TriangleDownIcon className="h-3 w-3" />
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <DropdownMenuItem key="after" className="text-xs" onClick={() => {
                                                    setValue("taskDateType", "after");
                                                    handleSubmit(onSubmit)();
                                                }}>
                                                    after
                                                </DropdownMenuItem>
                                                <DropdownMenuItem key="before" className="text-xs" onClick={() => {
                                                    setValue("taskDateType", "before");
                                                    handleSubmit(onSubmit)();
                                                }}>
                                                    before
                                                </DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>

                                        <div className="flex flex-row gap-3">
                                            <div className="flex flex-col items-center gap-2">
                                                <div className="flex items-center outline outline-1 outline-slate-200 rounded-md px-2 bg-white h-7 min-h-[28px]">
                                                <Input
                                                    type="number"
                                                    {...register("taskDateValue", { valueAsNumber: true })}
                                                    onChange={(e) => {
                                                        setValue("taskDateValue", parseInt(e.target.value));
                                                    }}
                                                    onBlur={() => {
                                                        handleSubmit(onSubmit)();
                                                    }}
                                                    className="h-6 px-3 max-w-full text-center bg-transparent focus:ring-0 focus:ring-offset-0 border-none outline-none"
                                                    min="1"
                                                    style={{
                                                        width: `${Math.max(5, taskDateValue.toString().length)}rem`,
                                                    }}
                                                />

                                                    <Separator
                                                        orientation="vertical"
                                                        className="bg-[#D8D8D8] w-[1px] h-8 mx-3"
                                                    />

                                                    <Select
                                                        value={taskDateUnit}
                                                        onValueChange={(value: "hours" | "days" | "minutes" | "seconds") => {
                                                            setValue("taskDateUnit", value);
                                                            handleSubmit(onSubmit)();
                                                        }}
                                                    >
                                                        <SelectTrigger className="text-xs text-gray-700 h-2 bg-white border-none focus:ring-0 focus:ring-offset-0 cursor-pointer mx-0 w-full">
                                                            <SelectValue placeholder="Select a unit" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectItem value="hours">{taskDateValue === 1 ? "hour" : "hours"}</SelectItem>
                                                            <SelectItem value="days">{taskDateValue === 1 ? "day" : "days"}</SelectItem>
                                                            <SelectItem value="minutes">{taskDateValue === 1 ? "minute" : "minutes"}</SelectItem>
                                                            <SelectItem value="seconds">{taskDateValue === 1 ? "second" : "seconds"}</SelectItem>
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-xs font-medium">
                                            of a task due date
                                        </div>
                                        </div>
                                    </CardContent>
                                    </div>
                                </Card>
                            </Flex>
                        </form>
                    )}
                </Box>
            </Flex>
        </div>
    );
};

export default MilestonePreferences;
