import { Badge } from "@/component/shadcn/ui/badge";
import { Card } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { Input } from "@/component/shadcn/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { toast } from "@/component/shadcn/ui/use-toast";
import { Editor } from "@/component/textEditor/Editor";
import { API, ContactsAPI, URLS } from "@/constant";
import { useSourceUsers } from "@/hooks/use-sourceUsers";
import { useApi, useApiFormData } from "@/interfaces/api";
import {
    type Account,
    type AccountUpdatePayload,
    ContractType,
    EditorActionType,
    EditorType,
    type Integration,
    type PublishMessage,
    type ScopeResponse,
    type SendMessageResponse,
    type UploadedFile,
    type UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { capitalizeType } from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";

export interface SendNewMessageDialogProps {
    triggerElement: React.ReactNode;
    accountType: string;
    account: Account | undefined;
    updateData: (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => Promise<void>;
    contactsCombinedData?: Account[];
    customerCompany?: Account;
}

export const SendNewMessageDialog = ({
    triggerElement,
    accountType,
    account,
    updateData,
    contactsCombinedData,
    customerCompany,
}: SendNewMessageDialogProps) => {
    const apiFormData = useApiFormData();
    const api = useApi();

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    useEffect(() => {
        authInfoRef.current = authInfo;
    }, [authInfo]);

    const [source, setSource] = useState<string>();
    const [channelID, setChannelID] = useState<string>();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [integrationID, setIntegrationID] = useState<string>();
    const [gmailScopes, setGmailScopes] = useState<ScopeResponse[]>();
    const [isSendDisabled, setIsSendDisabled] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<
        (UploadedFile | UploadedFileWithMetadata)[]
    >([]);
    const [isToggled, setIsToggled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [subject, setSubject] = useState(
        account ? `Assembly <> ${account.name}` : "",
    );

    useEffect(() => {
        if (accountType === "company" && account?.preferred_communication) {
            const source = Object.keys(account.preferred_communication)[0];
            const keyName: ScopeResponse = Object.values(
                account.preferred_communication,
            )[0];
            setSource(source);
            if (keyName === undefined) {
                return;
            }
            if (source === "Gmail") {
                if (keyName.key !== keyName.name) {
                    // For gmail, if a specific integration id is stored
                    // then the value would be {key: integrationID, name: emailAddress}
                    // otherwise both values would just be emailAddress
                    setIntegrationID(keyName.key);
                }
                setChannelID(keyName.name);
            } else {
                setChannelID(keyName.key);
            }
        } else if (accountType === "customer" && account?.domain) {
            setSource("Gmail");
            setChannelID(account.domain);
            if (
                customerCompany?.preferred_communication &&
                Object.keys(customerCompany.preferred_communication)[0] ===
                "Gmail"
            ) {
                const keyName: ScopeResponse = Object.values(
                    customerCompany.preferred_communication,
                )[0];
                if (keyName.key !== keyName.name) {
                    setIntegrationID(keyName.key);
                }
            }
        }
    }, [account]);

    const publishMessageMutation = useMutation({
        mutationFn: sendMessage,
        onSuccess: () => {
            setDialogOpen(false);
            setSubject(account ? `Assembly <> ${account.name}` : "");
            setTimeout(() => {
                setLoading(false);
                updateData(true, true, true);
            }, 10000);
            toast({
                title: "Message sent",
                description: "Message sent successfully.",
            });

            // If source of sent message was gmail,
            // update the key of the preferred communciation method to be the integrationID
            if (source === "Gmail" && accountType === "company") {
                if (
                    account?.preferred_communication &&
                    Object.keys(account.preferred_communication)[0] === "Gmail"
                ) {
                    const newPrefComm = new Map<string, ScopeResponse>();
                    const gmailScopeResponse = Object.values(
                        account?.preferred_communication,
                    )[0];
                    if (
                        gmailScopeResponse &&
                        integrationID &&
                        integrationID !== "" &&
                        gmailScopeResponse.key !== integrationID
                    ) {
                        gmailScopeResponse.key = integrationID;
                        newPrefComm.set("Gmail", gmailScopeResponse);

                        const requestData: AccountUpdatePayload = {
                            id: account?.id ?? "",
                            user: "System",
                            name: account?.name ?? "",
                            domain: account?.domain ?? "",
                            image_url: account?.image_url ?? "",
                            contract_value: account?.contract_value ?? 0,
                            contract_type: account?.contract_type
                                ? (account.contract_type as ContractType)
                                : ContractType.Month,
                            contacts: contactsCombinedData,
                            preferred_communication:
                                Object.fromEntries(newPrefComm),
                        };

                        // Save the update
                        api.patch(
                            `${URLS.serverUrl}${ContactsAPI.editCompany.url}/${account?.id}`,
                            requestData,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            },
                        ).then((res) => {
                            if (res.status !== 200) {
                                console.error(
                                    "could not update the integration ID of the preferred gmail integration",
                                );
                            } else {
                                console.log(
                                    "updated the preferred communication's gmail integration",
                                );
                            }
                        });
                    }
                }
            }
        },
        onError: (error) => {
            setLoading(false);
            console.error("Error publishing message:", error);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
        onSettled: () => {
            setLoading(false);
            setIsSendDisabled(false);
            setUploadedFiles([]);
        },
    });

    async function sendMessage({
        type,
        content,
        files,
        source,
        channelID,
    }: {
        type: EditorActionType;
        content: string;
        files: (UploadedFile | UploadedFileWithMetadata)[];
        source?: string;
        channelID?: string;
        subject?: string;
    }): Promise<SendMessageResponse> {
        if (!authInfoRef.current.isLoggedIn) {
            throw new Error("User not logged in");
        }

        const userId = authInfoRef.current.user?.userId;
        if (userId === undefined) {
            throw new Error("User ID not found");
        }
        if (type !== EditorActionType.New) {
            throw new Error(
                `Editor Action Type ${type} is not supported for this editor`,
            );
        }
        if (!source) {
            throw new Error("No source is defined");
        }
        if (!channelID) {
            throw new Error("No channel id is defined");
        }

        const images: UploadedFile[] = files?.filter(
            (file) => file.type === "image",
        ) as UploadedFile[];

        const filesToUpload = files?.filter((file) => {
            return file.type === "file";
        });
        const formData = new FormData();

        // Append non-file fields
        for (const fileData of filesToUpload) {
            formData.append(
                "files",
                (fileData as UploadedFileWithMetadata).file,
                (fileData as UploadedFileWithMetadata).file_name,
            );
        }

        const requestData: PublishMessage = {
            message: content,
            source: source,
            user: userId,
            source_specific_id: source === "Gmail" ? "" : channelID,
            integration_id: integrationID ?? "Standard",
            images: images,
        };
        if (source === "Gmail") {
            requestData.gmail_integration_fields = {
                to: [
                    {
                        email: channelID,
                    },
                ],
            };
            requestData.subject = subject;
        }

        formData.append("data", JSON.stringify(requestData));
        const response = await apiFormData.post(
            URLS.serverUrl + API.publishMessage,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );
        const id = response.data?.data?.id ?? "";
        return { id, url: "" };
    }

    const handleSend = (
        mrkdwn: string,
        files: (UploadedFile | UploadedFileWithMetadata)[],
        type: EditorActionType,
        source?: string,
        channel?: ScopeResponse,
        recipient?: string,
        integration_id?: string,
        subject?: string,
        new_source_specific_id?: string,
    ): Promise<SendMessageResponse> => {
        if (mrkdwn === undefined || !isSendDisabled) {
            return Promise.reject(new Error("Invalid message"));
        }
        setLoading(true);

        return new Promise((resolve, reject) => {
            publishMessageMutation.mutate(
                {
                    type,
                    content: mrkdwn,
                    files,
                    source,
                    channelID: channel?.key,
                    subject,
                },
                {
                    onSuccess: (data) => resolve(data),
                    onError: (error) => reject(error),
                },
            );
        });
    };

    const handleFileUpload = (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            {
                file_name: file_name,
                file_type: file_type,
                file_size: file_size,
                file: file,
                type: "file",
            },
        ]);
    };
    const handleImageUpload = (src: string, altText: string) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            { src: src, alt: altText, type: "image" },
        ]);
    };

    const handleDeleteFile = (
        fileToDelete: UploadedFile | UploadedFileWithMetadata,
    ) => {
        const newUploadedFiles = uploadedFiles.filter((file) => {
            if (file.type === "image") {
                return (
                    (file as UploadedFile).alt !==
                    (fileToDelete as UploadedFile).alt
                );
            } else if (file.type === "file") {
                return (
                    (file as UploadedFileWithMetadata).file_name !==
                    (fileToDelete as UploadedFileWithMetadata).file_name
                );
            }
            return true;
        });
        setUploadedFiles(newUploadedFiles);
        if (newUploadedFiles.length === 0) {
            setIsSendDisabled(false);
        }
    }

    const getMessage = (type: string, account?: Account) => {
        if (!account) {
            if (type === "company") {
                return `No preferred communication method is set, please set one first on the contacts page right next to the "Send Checkin" button.`;
            } else {
                // type is "customer"
                return `No preferred communication method is set, please set one first on the contacts page right next to the "Send Checkin" button.`;
            }
        }
        let source = "";
        let channel = "";
        if (type === "company") {
            const prefComm = account.preferred_communication;
            if (!prefComm) {
                return `No preferred communication method is set for ${account.name}, please set one first on the contacts page right next to the "Send Checkin" button.`;
            }
            source = Object.keys(prefComm)[0];
            channel = Object.values(prefComm)[0]?.name;
        } else {
            // type is "customer"
            const email = account.domain;
            if (!email) {
                return `No email is set for ${account.name}, please set add one first.`;
            }
            source = "Gmail";
            channel = email;
        }

        if (["Slack", "CommunitySlack", "Discord"].includes(source)) {
            channel = `#${channel}`;
        }
        const Icon = integrationBackEndDataMappingToSvg.get(source);

        return (
            <div className="flex flex-col items-start gap-0">
                <div className="flex items-center gap-1.5">
                    This message will be sent to
                    <Badge
                        className="flex items-center gap-1 py-1 text-[12px] border-gray-300"
                        variant="outline"
                    >
                        {account.image_url !== "" &&
                            account.image_url !== undefined && (
                                <div className="lb-avatar rounded w-5 h-5">
                                    <img
                                        className="lb-avatar-image w-4 h-4 my-0.5"
                                        src={account.image_url}
                                        alt={account.name}
                                    />
                                </div>
                            )}
                        {account.name}
                    </Badge>
                    through
                    <Badge
                        className="flex items-center gap-1 py-1 text-[12px] border-gray-300"
                        variant="outline"
                    >
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="flex items-center gap-1">
                                        {Icon ? <Icon className="w-4 h-4" /> : `${source}`}
                                        <div className="text-[#5B5BD6]">{channel}</div>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent
                                    className="bg-white text-gray11 flex flex-col opacity-100 z-100 max-w-[300px]"
                                    asChild
                                >
                                    <Card className="bg-white text-gray11 px-3 py-1.5 flex gap-1 flex-col text-left align-start opacity-100">
                                        {type === "company"
                                            ? "To send the message through a different source, change the company's preferred communication method on the Attributes Page."
                                            : "To send the message to a different email, change the customer's email."}
                                    </Card>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </Badge>
                </div>
            </div>
        );
    };

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const integrationsResponse: Integration[] = res.data.data;
                    const dataItems: ScopeResponse[] = [];
                    for (const integration of integrationsResponse) {
                        const scope: ScopeResponse = {
                            key: integration.id,
                            name: integration.unique_name,
                        };
                        dataItems.push(scope);
                    }
                    setGmailScopes(dataItems);
                }
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
            });
    }, []);

    useEffect(() => {
        if (subject === "" && account) {
            setSubject(`Assembly <> ${account.name}`);
        }
    }, [account]);


    const { sourceUsers, sourceUsersLoaded, hasNextPage } = useSourceUsers({
        source: source ?? "",
        channelID: channelID,
        includeDefaults: true,
    });

    return (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen} modal={true}>
            <DialogTrigger
                asChild
                onClick={(e) => {
                    setDialogOpen(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                {triggerElement}
            </DialogTrigger>

            <DialogContent className="py-[40px] px-[50px] max-w-[1200px] max-h-[750px] flex flex-col gap-1.5">
                <div className="text-xl font-semibold">{`Send ${capitalizeType(accountType)} Checkin`}</div>
                {(
                    accountType === "company"
                        ? account?.preferred_communication
                        : account?.domain
                ) ? (
                    <div className="flex flex-col gap-4">
                        <div className="text-md relative">
                            {getMessage(accountType, account)}
                        </div>
                        {source === "Gmail" && (
                            <div className="-mb-5 flex items-center gap-2">
                                <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#e2e8f0] outline-1 hover:bg-[#eceefb] hover:text-[#5e6ad2] active:bg-[#eceefb] active:text-[#5e6ad2]">
                                    <div className="flex flex-col items-start gap-1">
                                        <div className="flex flex-row items-center justify-center gap-1 -mt-1">
                                            <div className="text-destructive pl-0.5">
                                                *
                                            </div>
                                            <p className="text-xs text-black">
                                                via
                                            </p>
                                            <Select
                                                defaultValue={integrationID}
                                                onValueChange={(
                                                    value: string,
                                                ) => setIntegrationID(value)}
                                            >
                                                <SelectTrigger className="text-[#5e6ad2] focus:outline-none focus:ring-0 text-xs font-medium px-2 py-1 rounded border-none outline-none hover:white">
                                                    <SelectValue placeholder="Select an Email..." />
                                                </SelectTrigger>
                                                <SelectContent className="w-[275px]">
                                                    {gmailScopes?.map(
                                                        (integration) => (
                                                            <SelectItem
                                                                key={
                                                                    integration.key
                                                                }
                                                                value={
                                                                    integration.key
                                                                }
                                                            >
                                                                {
                                                                    integration.name
                                                                }
                                                            </SelectItem>
                                                        ),
                                                    )}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </div>
                                </Badge>
                            </div>
                        )}
                        <Card className="rounded-tr-lg rounded-bl-lg rounded-br-lg focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0 flex flex-col p-2 mb-2 shadow-sm shadow-[#f3f4f6] color-[#f3f4f6] border relative">
                            {source === "Gmail" && (
                                <div className="mx-3 my-2 flex items-center">
                                    <div className="text-md font-semibold w-[60px] mr-2">
                                        Subject:{" "}
                                    </div>
                                    <Input
                                        type="subject"
                                        value={subject}
                                        onChange={(e) =>
                                            setSubject(e.target.value)
                                        }
                                        placeholder="Email Subject..."
                                        className="max-w-full p-2 rounded border-none"
                                    />
                                </div>
                            )}
                            <Editor
                                className="max-h-full overflow-scroll scrollbar-white"
                                enableAIResponse={false}
                                handleSubmit={handleSend}
                                isToggled={isToggled}
                                setIsSendDisabled={setIsSendDisabled}
                                isSendDisabled={isSendDisabled}
                                setIsToggled={setIsToggled}
                                loading={loading}
                                handleFileUpload={handleFileUpload}
                                handleImageUpload={handleImageUpload}
                                handleDeleteFile={handleDeleteFile}
                                uploadedFiles={uploadedFiles}
                                source={source ?? ""}
                                editorType={EditorType.New}
                                channel={{
                                    key: channelID ?? "",
                                    name: channelID ?? ""
                                }}
                                customerAccount={
                                    accountType === "customer"
                                        ? account
                                        : undefined
                                }
                                companyAccount={
                                    accountType === "company"
                                        ? account
                                        : undefined
                                }
                                fullyDisableSend={
                                    source === "Gmail" &&
                                    (!integrationID || integrationID === "")
                                }
                                mentionOptions={sourceUsers}
                                mentionOptionsLoaded={sourceUsersLoaded}
                            />
                        </Card>
                    </div>
                ) : (
                    <div className="text-md">
                        {getMessage(accountType, account)}
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};
