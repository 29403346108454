import Clock from "@/component/Timer";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    HistoryResponse,
    IssueScore,
    SharedInfo,
    Teams,
    Ticket,
    TicketTeams,
} from "@/interfaces/serverData";
import { DataList } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { memo } from "react";
import { AssigneeDropdown } from "./AssigneeDropdown";
import CustomerProfile from "./CustomerProfile";
import { ExternalIssuesSection } from "./ExternalIssuesSection";
import InsightsSection from "./InsightsSection";
import InternalNotes from "./InternalNote";
import { LinkedIssuesSection } from "./LinkedIssuesSection";
import SimilarIssuesSection from "./SimilarIssuesSection";
import { TeamOwnerDropdown } from "./TeamOwnerDropdown";
import { TeamsDropdown } from "./TeamsDropdown";
import { TicketStatusDropdown } from "./TicketStatusDropdown";
import { TicketTopicsDropdown } from "./TicketTopicsDropdown";
import TimerComponent from "./TimerComponent";

interface SidebarCardContentProps {
    type: string;
    analytics: Ticket;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    userID: string;
    customerUserInfo: SharedInfo | undefined;
    account?: Account;
    similarIssues?: IssueScore[];
    loadingSimilarIssues?: boolean;
    genTitle?: string;
    genDescription?: string;
    teamID?: string;
    orgID?: string;
    timerEnabled?: boolean;
    teamsQuery?: UseQueryResult<Teams[], Error>;
    sidebarSize?: number;
}

function SidebarCardContent({
    type,
    analytics,
    refetchTicketData,
    refetchThreadData,
    userID,
    customerUserInfo,
    account,
    similarIssues,
    loadingSimilarIssues,
    genTitle,
    genDescription,
    teamID,
    orgID,
    timerEnabled,
    teamsQuery,
    sidebarSize,
}: SidebarCardContentProps) {
    const api = useApi();
    const source: string = analytics.source ?? "";
    const { toast } = useToast();

    const toggleTeam = (team: Teams, analyticsTeams: Teams[]) => {
        const isExistingTeam = analyticsTeams.find(
            (t) => t.id === team.id && t.team_name === team.team_name,
        );
        let added_teams: string[] = [];
        let removed_teams: string[] = [];
        // Handle General Team
        if (team.id === orgID) {
            // Can't untoggle general team, just needs to click another team to untoggle General
            if (!isExistingTeam) {
                // Remove all existing teams
                removed_teams = analyticsTeams.map((t: Teams) => t.id);
                analytics.teams = [];
            }
        } else {
            added_teams = isExistingTeam ? [] : [team.id];
            removed_teams = isExistingTeam ? [team.id] : [];
            if (isExistingTeam) {
                analytics.teams = analyticsTeams.filter(
                    (t) => t.id !== team.id,
                );
            } else {
                analytics.teams = [...analytics.teams, team];
            }
        }
        const requestData: TicketTeams = {
            ids: [analytics.id],
            added_teams: added_teams,
            removed_teams: removed_teams,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetchTicketData();
                refetchThreadData();
                console.log("Updated teams successfully");
                toast({
                    title: "Updated Team!",
                    description: "Team updated successfully",
                });
            } else {
                console.log("Call to update teams failed");
                toast({
                    title: "Error",
                    description: "Failed to update teams",
                    variant: "destructive",
                });
            }
        });
    };

    const refetch = (success: boolean, message: string) => {
        if (success) {
            refetchTicketData();
            refetchThreadData();
        }

        if (success) {
            toast({
                title: "Ticket Updated Successfully",
                description: message,
            });
        } else {
            toast({
                title: "Error",
                description: "Failed to update ticket",
                variant: "destructive",
            });
        }
    };

    switch (type) {
        case "Attributes": {
            return (
                <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                    {analytics.breaching !== "" &&
                        analytics.breaching !== null &&
                        analytics.breaching !== undefined && (
                            <DataList.Item
                                align="center"
                                className="w-full flex flex-wrap gap-2"
                            >
                                <DataList.Label className="text-xs min-w-[70px]">
                                    SLA
                                </DataList.Label>
                                <DataList.Value>
                                    <Clock
                                        targetTime={analytics.breaching}
                                        businessHoursID={
                                            analytics.business_hours_id
                                        }
                                        api={api}
                                        status={analytics.ticket_status}
                                    />
                                </DataList.Value>
                            </DataList.Item>
                        )}
                    {timerEnabled === true && (
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap gap-2"
                        >
                            <DataList.Label className="text-xs min-w-[70px]">
                                Timer
                            </DataList.Label>
                            <DataList.Value>
                                <TimerComponent
                                    id={analytics.id}
                                    refetchThreadData={refetchThreadData}
                                    sidebarSize={sidebarSize}
                                />
                            </DataList.Value>
                        </DataList.Item>
                    )}
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap gap-2"
                    >
                        <DataList.Label className="text-xs min-w-[70px]">
                            Status
                        </DataList.Label>
                        <DataList.Value>
                            <TicketStatusDropdown
                                key={analytics.ticket_status}
                                ticket={analytics}
                                userID={userID}
                                refetchThreadData={refetchThreadData}
                                refetchTicketData={refetchTicketData}
                                sidebarSize={sidebarSize}
                            />
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap gap-2"
                    >
                        <DataList.Label className="text-xs min-w-[70px]">
                            Owner
                        </DataList.Label>
                        <DataList.Value>
                            <AssigneeDropdown
                                ticket={analytics}
                                userID={userID}
                                refetchThreadData={refetchThreadData}
                                sidebarSize={sidebarSize}
                            />
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap gap-2"
                    >
                        <DataList.Label className="text-xs min-w-[70px]">
                            Team Owner
                        </DataList.Label>
                        <DataList.Value>
                            <TeamOwnerDropdown
                                ticket={analytics}
                                userID={userID}
                                teams={teamsQuery?.data ?? []}
                                refetchThreadData={refetchThreadData}
                                refetchTicketData={refetchTicketData}
                                sidebarSize={sidebarSize ?? 0}
                            />
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap gap-2"
                    >
                        <DataList.Label className="text-xs min-w-[70px]">
                            Viewers
                        </DataList.Label>
                        <DataList.Value>
                            <TeamsDropdown
                                selectedTeams={analytics.teams ?? []}
                                teams={teamsQuery?.data || []}
                                toggleTeam={toggleTeam}
                                defaultIsWorkspace={false}
                                orgID={orgID}
                                collapsed={true}
                            />
                        </DataList.Value>
                    </DataList.Item>
                </DataList.Root>
            );
        }
        case "Labels": {
            return (
                <div className="flex flex-row flex-wrap">
                    <TicketTopicsDropdown
                        ticket={analytics}
                        userID={userID}
                        refetchThreadData={refetchThreadData}
                        teamID={teamID}
                    />
                </div>
            );
        }
        case "Customer Profile": {
            return (
                <div className="mb-2">
                    <CustomerProfile
                        ticketID={analytics.id}
                        userInfo={customerUserInfo}
                        source={source}
                        source_unique_name={analytics.source_unique_name}
                        account={account}
                        userID={userID}
                        refetch={refetch}
                        sidebarSize={sidebarSize}
                    />
                </div>
            );
        }
        case "External Issues": {
            return (
                <div className="-mt-1">
                    <ExternalIssuesSection ticket={analytics} userID={userID} />
                </div>
            );
        }
        case "Linked Issues": {
            return (
                <div className="-mt-1 mb-1">
                    <LinkedIssuesSection
                        ticket={analytics}
                        refetchTicketData={refetchTicketData}
                        userID={userID}
                    />
                </div>
            );
        }
        case "Insights": {
            return (
                <div className="-mt-1">
                    <InsightsSection
                        issue={analytics}
                        refetchTicketData={refetchTicketData}
                        userID={userID}
                    />
                </div>
            );
        }
        case "Internal Notes": {
            // 10/23: Phasing out internal notes so removed adding and editing capabilities
            return (
                <div className="mb-2">
                    <InternalNotes
                        ticketId={analytics.id}
                        internalNote={analytics.internal_note}
                        source={source}
                        userId={userID}
                    />
                </div>
            );
        }
        case "View Similar Interactions": {
            return (
                <div>
                    <SimilarIssuesSection
                        issue={analytics}
                        userId={userID}
                        similarIssues={similarIssues ?? []}
                        loadingSimilarIssues={loadingSimilarIssues ?? false}
                        teamID={teamID}
                        genTitle={genTitle ?? ""}
                        genDescription={genDescription ?? ""}
                        refetchTicketData={refetchTicketData}
                    />
                </div>
            );
        }
        default:
            return <div />;
    }
}

export default memo(SidebarCardContent);
