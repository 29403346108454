import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/component/shadcn/ui/alert-dialog";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    Query,
    Teams,
    Ticket,
} from "@/interfaces/serverData";
import { getBadgeForTeam } from "@/utilities/methods";
import { Cross2Icon } from "@radix-ui/react-icons";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { HouseIcon, UsersIcon } from "lucide-react";
import { useEffect, useState } from "react";

interface TeamOwnerDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    teams: Teams[];
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    sidebarSize: number;
}

export function TeamOwnerDropdown<TData extends Query>({
    ticket,
    userID,
    teams,
    refetchThreadData,
    refetchTicketData,
    sidebarSize,
}: TeamOwnerDropdownProps<TData>) {
    const api = useApi();

    const [selectedTeam, setSelectedTeam] = useState<Teams>();
    const [changeTODialogOpen, setChangeTODialogOpen] = useState(false);

    useEffect(() => {
        if (ticket.team_owner_id) {
            const team = teams.find((team) => team.id === ticket.team_owner_id);
            if (team) {
                setSelectedTeam(team);
            }
        }
    }, [ticket.team_owner_id]);

    function saveTeamOwner() {
        const requestData = {
            ids: [ticket.id],
            source: "Web",
            user_id: userID,
            team_owner_id: selectedTeam?.id ?? "",
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                console.log("Updated team owner");
                setTimeout(() => {
                    refetchThreadData();
                    refetchTicketData();
                }, 1000);
                setChangeTODialogOpen(false);
            } else {
                console.log("Call to update tag failed");
            }
        });
    }

    return (
        <div className="text-xs flex items-center">
            <Popover>
                <PopoverTrigger asChild>
                    <div>
                        {selectedTeam ? (
                            sidebarSize < 20 ? (
                                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                    <UsersIcon className="text-red9 w-2 h-2 !size-2" />
                                </div>
                            ) : (
                                getBadgeForTeam(selectedTeam.team_name, true)
                            )
                        ) : sidebarSize < 20 ? (
                            <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                <HouseIcon className="text-iris9 w-2 h-2 !size-2" />
                            </div>
                        ) : (
                            getBadgeForTeam("Unassigned...", true)
                        )}
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                    <Command>
                        <CommandInput
                            placeholder="Search team..."
                            className="h-8 text-xs"
                        />
                        <CommandList>
                            <CommandEmpty>No team found.</CommandEmpty>
                            <CommandGroup>
                                <CommandItem
                                    onSelect={() => {
                                        setSelectedTeam(undefined);
                                        setChangeTODialogOpen(true);
                                    }}
                                >
                                    <div className="text-xs font-normal flex flex-row items-center gap-2 px-1 py-0">
                                        <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                            <HouseIcon className="text-iris9 w-2 h-2 !size-2" />
                                        </div>
                                        No Team Owner
                                    </div>
                                </CommandItem>
                                {teams
                                    .filter(
                                        (team) =>
                                            !(
                                                team.team_name === "General" &&
                                                team.id === team.org_id
                                            ),
                                    )
                                    .map((team) => (
                                        <CommandItem
                                            key={team.id}
                                            value={team.team_name}
                                            onSelect={() => {
                                                setSelectedTeam(team);
                                                setChangeTODialogOpen(true);
                                            }}
                                        >
                                            <div className="text-xs font-normal flex flex-row gap-2 px-1 py-0">
                                                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                    <UsersIcon className="text-red9 w-2 h-2 !size-2" />
                                                </div>
                                                {team.team_name}
                                            </div>
                                        </CommandItem>
                                    ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            <AlertDialog
                open={changeTODialogOpen}
                onOpenChange={setChangeTODialogOpen}
            >
                <AlertDialogContent>
                    <div>
                        <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                            <AlertDialogTitle>
                                {selectedTeam
                                    ? `Change Team Owner to "${selectedTeam?.team_name}"?`
                                    : "Unassign Team Owner?"}
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                                All scheduled notifications, slas, workflows, and
                                autoclosing jobs for this ticket will be
                                canceled. The next action/message on this ticket
                                will pick up the new team owner's settings. This
                                action cannot be undone.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                    </div>
                    <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                            onClick={saveTeamOwner}
                            className="bg-red-500 text-white hover:bg-red-700"
                        >
                            Change Team Owner
                        </AlertDialogAction>
                        <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                            <Cross2Icon />
                        </AlertDialogCancel>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
}
