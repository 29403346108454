import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import { Card } from "@/component/shadcn/ui/card";
import { getColor } from "@/component/textEditor/ReplyFromPlugin";
import type {
    Account,
    AccountsLabel,
    CompanyEnrichmentResponse,
    GetUserResponse,
    IconEntry,
    ScopeResponse,
} from "@/interfaces/serverData";
import { TeamBadges } from "@/pages/WorkspacePreferences/TeamBadges";
import { getBadgeForAccountsLabel, getBadgeForAccountsTag } from "@/utilities/methods";
import { AvatarIcon, CalendarIcon } from "@radix-ui/react-icons";
import { Badge, DataList } from "@radix-ui/themes";
import type { UseQueryResult } from "@tanstack/react-query";
import { NewspaperIcon } from "lucide-react";
import { useEffect, useState } from "react";
import React from "react";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";
import { PrefCommDropdown } from "./PrefCommDropdown";

export interface AttributesProps {
    orgID: string;
    userID: string;
    account: Account;
    accountType: "Company" | "Customer";
    contacts: Account[];
    updateData: (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => Promise<void>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
    loadingEnrichment: boolean;
    enrichedData?: CompanyEnrichmentResponse;
}

export const AttributesPage = ({
    orgID,
    userID,
    account,
    accountType,
    contacts,
    updateData,
    usersQuery,
    channelsQuery,
    loadingEnrichment,
    enrichedData,
}: AttributesProps) => {
    const foundUser: GetUserResponse | undefined = (usersQuery?.data ?? []).find(
        (user) => user.id === account?.assignee_user_id,
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const renewalDate = account?.renewal_date
        ? new Date(account?.renewal_date)
        : new Date();
    const formattedRenewalDate = renewalDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timeZone: "UTC"
    });
    const trialDate = account?.trial_expiration_date
        ? new Date(account?.trial_expiration_date)
        : new Date();
    const formattedTrialDate = trialDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timeZone: "UTC"
    });

    const [serviceIcons, setServiceIcons] = useState<Set<IconEntry>>(
        new Set<IconEntry>(),
    );
    useEffect(() => {
        const serviceNames = Object.keys(account?.metadata ?? {});
        const serviceIcons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                serviceIcons.add({
                    Component: IconComponent,
                    props: {
                        width: 18,
                        height: 18,
                        style: {
                            marginLeft: "-2px",
                            marginRight: "-2px",
                        },
                    },
                });
            }
        });
        setServiceIcons(serviceIcons);
    }, [account?.metadata]);


    return (
        <div>
            {!loadingEnrichment && enrichedData && <Card
                className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none px-5 py-4 flex flex-col gap-2 w-full mr-15"
            >
                <div className="flex justify-between items-start ">
                    <div className="text-sm font-semibold">
                        Company Summary
                    </div>
                    <NewspaperIcon className="h-4 w-4" />
                </div>

                <div className="flex flex-col gap-1 mx-1">

                    {enrichedData?.headline && (
                        <div className="text-sm">{enrichedData?.headline}</div>
                    )}

                    {enrichedData?.summary && (
                        <div className="text-sm">{enrichedData?.summary}</div>
                    )}

                    <div className="flex flex-row flex-wrap gap-2 pt-1">
                        {enrichedData?.latest_funding_stage && (
                            <Badge variant="soft" color="gray">Latest Funding Stage: {enrichedData?.latest_funding_stage?.replace(/_/g, ' ')}</Badge>
                        )}


                        {enrichedData?.total_funding_raised && (
                            <Badge variant="soft" color="green">Total Funding Raised: ${enrichedData?.total_funding_raised}</Badge>
                        )}

                        {enrichedData?.last_funding_date && (
                            <Badge variant="soft" color="blue">Last Funding Date: {enrichedData?.last_funding_date}</Badge>
                        )}

                        {enrichedData?.number_funding_rounds && (
                            <Badge variant="soft" color="iris">{enrichedData?.number_funding_rounds} Funding Round(s)</Badge>
                        )}

                    </div>

                    {enrichedData?.tags && (
                        <div className="flex flex-wrap gap-2 pt-2">{enrichedData?.tags.map((tag, index) => (
                            <Badge key={tag} variant="soft" color={getColor(index)} className="h-5">
                                {tag}
                            </Badge>
                        ))}</div>
                    )}
                </div>
            </Card>}
            <div className="flex items-start gap-5 p-3">
                <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none py-5 px-7 flex flex-col gap-3">
                    <div className="text-md font-semibold">Default Attributes</div>
                    <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Assignee
                            </DataList.Label>
                            <DataList.Value>
                                {account.assignee_user_id && account.assignee_user_id !== "" && pictureURL ? (
                                    <div className="flex items-center gap-2 text-sm">
                                        <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                            <img
                                                className="lb-avatar-image"
                                                src={pictureURL}
                                                alt={userName}
                                            />
                                            <span>{userName ?? ""}</span>
                                        </div>
                                        {userName}
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-2 text-sm">
                                        <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                            <AvatarIcon className="w-5 h-5" />
                                        </div>
                                        No Assignee
                                    </div>
                                )}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Preferred
                                <br />
                                Communication:
                            </DataList.Label>
                            <DataList.Value>
                                <PrefCommDropdown
                                    company={account}
                                    contactsCombinedData={contacts}
                                    channelsQuery={channelsQuery}
                                    userID={userID}
                                    updateData={updateData}
                                />
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Contract Value
                            </DataList.Label>
                            <DataList.Value className="text-sm">
                                {(account?.contract_value || account?.contract_type) && (
                                    <Badge
                                        color="mint"
                                        size="2"
                                        radius="medium"
                                        variant="soft"
                                        className="text-xs items-center"
                                    >
                                        <p>
                                            {account.contract_type && account.contract_value
                                                ? `${account.contract_type} | $${account.contract_value}`
                                                : account.contract_type
                                                    ? account.contract_type
                                                    : account.contract_value
                                                        ? `$${account.contract_value}`
                                                        : null}
                                        </p>
                                    </Badge>

                                )}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Renewal Date
                            </DataList.Label>
                            <DataList.Value className="text-sm">
                                {account?.renewal_date && (
                                    <div className="flex items-center gap-2">
                                        <CalendarIcon />
                                        {formattedRenewalDate}
                                    </div>
                                )}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Trial Expiration Date
                            </DataList.Label>
                            <DataList.Value className="text-sm">
                                {account?.trial_expiration_date && (
                                    <div className="flex items-center gap-2">
                                        <CalendarIcon />
                                        {formattedTrialDate}
                                    </div>
                                )}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5 p-0"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Labels
                            </DataList.Label>
                            <DataList.Value className="-ml-2 flex flex-wrap items-center gap-2 w-full max-w-[calc(100%-195px)] overflow-hidden flex-grow py-3">
                                {account?.labels?.map((label: AccountsLabel) =>
                                    <div className="flex-shrink-0" key={label.id}>{getBadgeForAccountsLabel(label)}</div>
                                )}
                                {account?.tags?.map((tag: AccountsLabel) =>
                                    <div className="flex-shrink-0" key={tag.id}>{getBadgeForAccountsTag(tag)}</div>
                                )}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                ID in Tool
                            </DataList.Label>
                            <DataList.Value className="text-sm">
                                {account?.id_in_tool}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5 p-0"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Source Specific IDs
                            </DataList.Label>
                            <DataList.Value className="-ml-2 flex flex-wrap items-center gap-2 w-full max-w-[calc(100%-195px)] py-3">
                                {account?.source_specific_ids?.length > 0 && (
                                    account.source_specific_ids.map((ssid) => {
                                        const SourceSvgImage: React.ElementType | undefined = integrationBackEndDataMappingToSvg.get(ssid.source ?? "Unknown");
                                        return (
                                            <ScnBadge key={ssid.id} variant="outline" className="flex items-center gap-1.5 py-1 font-normal flex-shrink-0 border-iris7">
                                                {SourceSvgImage && ssid.source && <SourceSvgImage className="w-4 h-4" />}
                                                {ssid.source_specific_id}
                                            </ScnBadge>
                                        );
                                    })
                                )}
                            </DataList.Value>
                        </DataList.Item>
                        {accountType === "Company" ? <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Number of Seats
                            </DataList.Label>
                            <DataList.Value className="text-sm">
                                {account?.number_of_seats}
                            </DataList.Value>
                        </DataList.Item>
                            :
                            <DataList.Item
                                align="center"
                                className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                            >
                                <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                    Role
                                </DataList.Label>
                                <DataList.Value className="text-sm">
                                    {account?.role}
                                </DataList.Value>
                            </DataList.Item>
                        }
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Sources
                            </DataList.Label>
                            <DataList.Value>
                                {account?.metadata && (
                                    <div className="flex flex-row mx-1">
                                        {Array.from(serviceIcons).map((icon) =>
                                            React.createElement(
                                                icon.Component,
                                                icon.props,
                                            ),
                                        )}
                                    </div>
                                )}
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item
                            align="center"
                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                        >
                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                Teams
                            </DataList.Label>
                            <DataList.Value>
                                <TeamBadges
                                    teams={account?.teams ?? []}
                                    defaultIsWorkspace={false}
                                />
                            </DataList.Value>
                        </DataList.Item>
                    </DataList.Root>
                </Card>
                <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none py-5 px-7 flex flex-col gap-3">
                    <div className="text-md font-semibold">Custom Attributes</div>
                    {account?.additional_properties ? (
                        <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                            {Object.entries(account?.additional_properties).map(
                                ([key, value], index) => (
                                    <DataList.Item
                                        align="center"
                                        className={`w-full flex border-b border-l border-r border-gray-300 m-0 -my-2.5 ${index === 0 ? "border-t border-gray-300" : ""}`}
                                        key={key}
                                    >
                                        <DataList.Label className="text-sm w-[250px] border-r border-gray-300 p-2.5">
                                            {key}
                                        </DataList.Label>
                                        <DataList.Value className="text-sm py-2.5 px-3 flex-1 min-w-0 break-words whitespace-normal overflow-hidden">
                                            {String(value)}
                                        </DataList.Value>
                                    </DataList.Item>
                                ),
                            )}
                        </DataList.Root>
                    ) : (
                        <div className="text-sm">
                            No additional properties.
                        </div>
                    )}
                </Card>
            </div>

        </div>
    );
};
