import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import { Badge } from "@radix-ui/themes";
import { Handle, Position } from "@xyflow/react";
import { BoxIcon, MessageCircleReply, TargetIcon } from "lucide-react";
import { TriggerIcon, handleStyle } from "../Icons";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from "@/component/shadcn/ui/separator";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import {
    type AccountsLabel,
    type Category,
    type GetTopicsResponse,
    type Workflow,
    WorkflowKind,
} from "@/interfaces/serverData";
import {
    WorkflowNodesDropdownTrigger,
    getItem,
    getLowercase,
    getTopicColors,
    getTypeFromActionType,
} from "@/utilities/methods";
import {
    CalendarIcon,
    CaretDownIcon,
    CheckIcon,
    InfoCircledIcon,
    Pencil2Icon,
} from "@radix-ui/react-icons";
import type { UseQueryResult } from "@tanstack/react-query";
import { format } from "date-fns";
import { type SetStateAction, useEffect, useState } from "react";

interface TriggerNodeProps {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: any;
    isConnectable: boolean;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    onUpdate: (id: string, metadata: any) => void;
    categoriesQuery: UseQueryResult<Category[], Error>;
    topicsQuery: UseQueryResult<GetTopicsResponse[], Error>;
    accountsLabelsQuery: UseQueryResult<AccountsLabel[], Error>;
    workflowKind: WorkflowKind;
    orgID: string;
    workflow?: Workflow;
    defaultTrigger?: boolean;
}

const TriggerNode: React.FC<TriggerNodeProps> = ({
    data,
    isConnectable,
    onUpdate,
    categoriesQuery,
    topicsQuery,
    accountsLabelsQuery,
    workflowKind,
    orgID,
    workflow,
    defaultTrigger,
}) => {
    console.log("trigger node metadata is", data.metadata)
    const FROM_TO_SUBTYPES = [
        "status_updated",
        "tag_updated",
        "category_updated",
        "company_type_updated",
        "tier_updated",
        "stage_updated",
        "account_tag_updated"
    ];
    const REPLY_SENT_OPTIONS = ["Customer", "Team", "Internal"];
    const defaultTriggerType = (workflowKind: WorkflowKind) => {
        switch (workflowKind) {
            case WorkflowKind.Interactions:
                return "issue_created";
            case WorkflowKind.Accounts:
                return "account_created";
            default:
                return "time_is";
        }
    };
    const [triggerType, setTriggerType] = useState<string>(
        data.metadata?.type ??
        (workflow ? undefined : defaultTriggerType(workflowKind)),
    );

    const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");
    const [date, setDate] = useState<Date | undefined>(
        data.metadata?.date ? new Date(data.metadata?.date) : new Date(),
    );

    const [replySentSelectedTypes, setReplySentSelectedTypes] = useState<
        string[]
    >(data.metadata?.item_types ?? []);

    // Time is
    const [time, setTime] = useState<string>(data.metadata?.time ?? `${String(date?.getHours()).padStart(2, "0")}:${String(date?.getMinutes()).padStart(2, "0")}`);
    const [timezone, setTimezone] = useState<{
        value: string;
        label: string;
    }>(
        data.metadata?.timezone ?? {
            value: "America/Los_Angeles",
            label: "America/Los Angeles",
        },
    ); // TODO: start timezone at local timezone?
    const [repeatType, setRepeatType] = useState<string>(
        data.metadata?.repeat?.type ?? "does_not_repeat",
    );
    const [customTimeInput, setCustomTimeInput] = useState<number>(
        data.metadata?.repeat?.freq?.time ?? 2,
    );
    const [customTimeUnit, setCustomTimeUnit] = useState<string>(
        data.metadata?.repeat?.freq?.unit ?? "weeks",
    );
    const [customEnds, setCustomEnds] = useState<string>(
        data.metadata?.repeat?.end?.type ?? "never",
    );
    const [endsAfterTimeInput, setEndsAfterTimeInput] = useState<number>(
        data.metadata?.repeat?.end?.count ?? 10,
    );
    const [endDate, setEndDate] = useState<Date | undefined>(() => {
        if (data.metadata?.end?.time) {
            return new Date(data.metadata?.end?.time);
        }
        // Default to the selected date + 1 year and 1 day
        if (date) {
            const newDate = new Date(date);
            newDate.setFullYear(newDate.getFullYear() + 1);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        }
        return new Date(); // Default to the current date if no `date` is provided
    });

    // Time before renewal
    const [timeUnit, setTimeUnit] = useState<string>(
        data.metadata?.time_unit ?? (workflow ? undefined : "days"),
    );
    const [timeInput, setTimeInput] = useState<number>(
        data.metadata?.time_input ?? (workflow ? undefined : 30),
    );
    const [militaryTime, setMilitaryTime] = useState<string>(data.metadata?.militaryTime ?? `${String(date?.getHours()).padStart(2, "0")}:${String(date?.getMinutes()).padStart(2, "0")}`);

    const [fromFilters, setFromFilters] = useState<
        Map<
            string,
            Set<{ label: string; value: string; key: string; color: string }>
        >
    >(
        data.metadata?.from
            ? new Map([
                [
                    getTypeFromActionType(triggerType),
                    new Set([
                        {
                            color: "",
                            label: data.metadata?.from.name,
                            value: data.metadata?.from.name,
                            key: data.metadata?.from.key,
                        },
                    ]),
                ],
            ])
            : new Map([
                [
                    getTypeFromActionType(triggerType),
                    new Set([
                        {
                            color: "",
                            label: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                            value: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                            key: "*ANY*",
                        },
                    ]),
                ],
            ]),
    );
    const [toFilters, setToFilters] = useState<
        Map<
            string,
            Set<{ label: string; value: string; key: string; color: string }>
        >
    >(
        data.metadata?.to
            ? new Map([
                [
                    getTypeFromActionType(triggerType),
                    new Set([
                        {
                            color: "",
                            label: data.metadata?.to.name,
                            value: data.metadata?.to.name,
                            key: data.metadata?.to.key,
                        },
                    ]),
                ],
            ])
            : new Map([
                [
                    getTypeFromActionType(triggerType),
                    new Set([
                        {
                            color: "",
                            label: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                            value: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                            key: "*ANY*",
                        },
                    ]),
                ],
            ]),
    );
    const [boundaryPercentInput, setBoundaryPercentInput] = useState<number>(
        data.metadata?.boundaryPercentInput ?? (workflow ? undefined : 20),
    );
    const handleBoundaryPercentInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBoundaryPercentInput(Number(e.target.value));
        const updatedMetadata = { ...data.metadata, boundaryPercentInput: e.target.value };
        onUpdate(data.id, updatedMetadata);
    };
    const [boundaryNumberInput, setBoundaryNumberInput] = useState<number>(
        data.metadata?.boundaryNumberInput ?? (workflow ? undefined : 3),
    );
    const handleBoundaryNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBoundaryNumberInput(Number(e.target.value));
        const updatedMetadata = { ...data.metadata, boundaryNumberInput: e.target.value };
        onUpdate(data.id, updatedMetadata);
    };
    const [boundary, setBoundary] = useState<string>(
        data.metadata?.boundary ??
        (workflow ? undefined : "dropped"),
    );
    const handleBoundary = (value: string) => {
        setBoundary(value);
        const updatedMetadata = { ...data.metadata, boundary: value };
        onUpdate(data.id, updatedMetadata);
    };
    const [timeOverTime, setTimeOverTime] = useState<string>(
        data.metadata?.timeOverTime ??
        (workflow ? undefined : "week"),
    );
    const handleTimeOverTime = (value: string) => {
        setTimeOverTime(value);
        const updatedMetadata = { ...data.metadata, timeOverTime: value };
        onUpdate(data.id, updatedMetadata);
    };

    // Update node's metadata
    const handleSelectChange = (value: string) => {
        setTriggerType(value);
        // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
        let updatedMetadata;
        if (value === "time_is") {
            const currDate = new Date();
            setDate(currDate);
            const currTime = `${String(currDate?.getHours()).padStart(2, "0")}:${String(currDate?.getMinutes()).padStart(2, "0")}`;
            setTime(currTime);
            updatedMetadata = {
                type: value,
                timezone: timezone,
                date: currDate,
                time: currTime,
                repeat: { type: "does_not_repeat" },
            };
        } else if (value === "time_before_renewal") {
            setTimeUnit("days")
            setTimeInput(30)
            const currMilitaryTime = `${String(date?.getHours()).padStart(2, "0")}:${String(date?.getMinutes()).padStart(2, "0")}`
            setMilitaryTime(currMilitaryTime)
            updatedMetadata = {
                type: value,
                timezone: timezone,
                time_unit: "days",
                time_input: 30,
                militaryTime: currMilitaryTime
            };

        } else if (["usage_changed", "balance_changed"].includes(value)) {
            setBoundary("dropped")
            setBoundaryPercentInput(20)
            setTimeOverTime("week")
            updatedMetadata = {
                type: value,
                boundary: "dropped",
                boundaryPercentInput: 20,
                timeOverTime: "week",
            };
        } else if (value === "health_score_changed") {
            setBoundary("dropped")
            setBoundaryNumberInput(3)
            updatedMetadata = {
                type: value,
                boundary: "dropped",
                boundaryNumberInput: 3,
            };
        } else {
            updatedMetadata = { type: value };
        }
        onUpdate(data.id, updatedMetadata);
        setFromFilters(
            data.metadata?.filters?.from ??
            new Map([
                [
                    getTypeFromActionType(value),
                    new Set([
                        {
                            color: "",
                            label: `Any ${getLowercase(getTypeFromActionType(value))}`,
                            value: `Any ${getLowercase(getTypeFromActionType(value))}`,
                            key: "*ANY*",
                        },
                    ]),
                ],
            ]),
        );
        setToFilters(
            data.metadata?.filters?.to ??
            new Map([
                [
                    getTypeFromActionType(value),
                    new Set([
                        {
                            color: "",
                            label: `Any ${getLowercase(getTypeFromActionType(value))}`,
                            value: `Any ${getLowercase(getTypeFromActionType(value))}`,
                            key: "*ANY*",
                        },
                    ]),
                ],
            ]),
        );

        if (value !== "time_is") {
            // Reset all custom repeat fields back to the default
            setRepeatType("does_not_repeat");
            setCustomTimeInput(2);
            setCustomTimeUnit("weeks");
            setCustomEnds("never");
            setEndsAfterTimeInput(10);
            setEndDate(new Date());
        }
        if (value !== "reply_sent") {
            setReplySentSelectedTypes([]);
        }
        if (value !== "time_before_renewal") {
            setTimeUnit("days")
            setTimeInput(30)
        }
    };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMetadata = { ...data.metadata, name: e.target.value };
        onUpdate(data.id, updatedMetadata); // Update the metadata of the node
        setNameVal(e.target.value);
    };

    const handleFromItemSelect =
        (
            type: string,
            option: {
                label: string;
                value: string;
                key: string;
                color: string;
            },
        ) =>
            () => {
                const newFilters = new Map();
                newFilters.set(type, new Set([option]));
                setFromFilters(newFilters);
                const keyName = { key: option.key, name: option.label };
                const updatedMetadata = { ...data.metadata, from: keyName };
                onUpdate(data.id, updatedMetadata);
            };

    const handleToItemSelect =
        (
            type: string,
            option: {
                label: string;
                value: string;
                key: string;
                color: string;
            },
        ) =>
            () => {
                const newFilters = new Map();
                newFilters.set(type, new Set([option]));
                setToFilters(newFilters);
                const keyName = { key: option.key, name: option.label };
                const updatedMetadata = { ...data.metadata, to: keyName };
                onUpdate(data.id, updatedMetadata);
            };

    const handleReplySentTypeSelect = (type: string, all: boolean) => {
        let newReplySentTypes: string[];
        if (all) {
            newReplySentTypes = REPLY_SENT_OPTIONS;
        } else {
            if (replySentSelectedTypes.includes(type)) {
                // Uncheck / remove the type
                newReplySentTypes = replySentSelectedTypes.filter(
                    (curr) => curr !== type,
                );
            } else {
                // Add the type
                newReplySentTypes = [...replySentSelectedTypes, type];
            }
        }
        const updatedMetadata = {
            ...data.metadata,
            item_types: newReplySentTypes,
        };
        onUpdate(data.id, updatedMetadata);
        setReplySentSelectedTypes(newReplySentTypes);
    };

    const handleTimeChange = (e: {
        target: { value: SetStateAction<string> };
    }) => {
        const updatedMetadata = { ...data.metadata, time: e.target.value };
        onUpdate(data.id, updatedMetadata);
        setTime(e.target.value);
    };

    const handleDateChange = (date: Date | undefined) => {
        const updatedMetadata = { ...data.metadata, date: date };
        onUpdate(data.id, updatedMetadata);
        setDate(date);
    };

    const handleTimezoneOnSelect = (tz: { label: string; value: string }) => {
        setTimezone(tz);
        setTzSearch("");
        const updatedMetadata = {
            ...data.metadata,
            timezone: { value: tz.value, label: tz.label },
        };
        onUpdate(data.id, updatedMetadata);
    };

    const handleRepeatTypeChange = (value: string) => {
        // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
        let repeatVal;
        if (value === "custom") {
            repeatVal = {
                type: value,
                freq: { time: customTimeInput, unit: customTimeUnit },
                end: {
                    type: "never",
                },
            };
        } else {
            repeatVal = { type: value };

            // Reset all custom repeat fields back to the default
            setCustomTimeInput(2);
            setCustomTimeUnit("weeks");
            setCustomEnds("never");
            setEndsAfterTimeInput(10);
            setEndDate(new Date());
        }
        const updatedMetadata = { ...data.metadata, repeat: repeatVal };
        onUpdate(data.id, updatedMetadata);
        setRepeatType(value);
    };

    const handleCustomTimeInputChange = (e: {
        target: { value: SetStateAction<string> };
    }) => {
        const currRepeat = data.metadata?.repeat;
        currRepeat.freq.time = e.target.value;
        const updatedMetadata = { ...data.metadata, repeat: currRepeat };
        onUpdate(data.id, updatedMetadata);
        setCustomTimeInput(Number(e.target.value));
    };

    const handleCustomTimeUnitChange = (value: string) => {
        const currRepeat = data.metadata?.repeat;
        currRepeat.freq.unit = value;
        const updatedMetadata = { ...data.metadata, repeat: currRepeat };
        onUpdate(data.id, updatedMetadata);
        setCustomTimeUnit(value);
    };

    const handleCustomEndChange = (value: string) => {
        // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
        let end;
        if (value === "never") {
            end = { type: value };
        } else if (value === "on") {
            let newDate: Date;
            if (date) {
                newDate = new Date(date);
                newDate.setFullYear(newDate.getFullYear() + 1);
                newDate.setDate(newDate.getDate() + 1);
            } else {
                newDate = new Date();
            }
            setEndDate(newDate);
            end = { type: value, time: newDate };
        } else if (value === "after") {
            end = { type: value, count: endsAfterTimeInput };
        }

        const currRepeat = data.metadata?.repeat;
        currRepeat.end = end;
        const updatedMetadata = { ...data.metadata, repeat: currRepeat };
        onUpdate(data.id, updatedMetadata);
        setCustomEnds(value);
    };

    const handleEndsAfterTimeInputChange = (e: {
        target: { value: SetStateAction<string> };
    }) => {
        const currRepeat = data.metadata?.repeat;
        currRepeat.end.count = e.target.value;
        const updatedMetadata = { ...data.metadata, repeat: currRepeat };
        onUpdate(data.id, updatedMetadata);
        setEndsAfterTimeInput(Number(e.target.value));
    };

    const handleEndDateChange = (date: Date | undefined) => {
        const currRepeat = data.metadata?.repeat;
        currRepeat.end.time = date;
        const updatedMetadata = { ...data.metadata, repeat: currRepeat };
        onUpdate(data.id, updatedMetadata);
        setEndDate(date);
    };

    const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimeInput(Number(e.target.value));
        const updatedMetadata = { ...data.metadata, time_input: e.target.value };
        onUpdate(data.id, updatedMetadata);
    };
    const handleTimeUnit = (value: string) => {
        setTimeUnit(value);
        const updatedMetadata = { ...data.metadata, time_unit: value };
        onUpdate(data.id, updatedMetadata);
    };
    const handleMilitaryTimeChange = (e: {
        target: { value: SetStateAction<string> };
    }) => {
        const updatedMetadata = { ...data.metadata, militaryTime: e.target.value };
        onUpdate(data.id, updatedMetadata);
        setMilitaryTime(e.target.value);
    };

    // Set the populated topic's colors once topics query is updated
    useEffect(() => {
        if (fromFilters.has("Topic")) {
            const existingFromTopic = Array.from(
                fromFilters.get("Topic") ?? [],
            )[0];
            const fromTopic: GetTopicsResponse | undefined = (
                topicsQuery.data ?? []
            ).find((t) => t.id === existingFromTopic.key);
            if (fromTopic) {
                // Update values based on the newest version of the topic. The workflow might have an outdated topic name
                const newFromTopic = {
                    color: fromTopic.color ?? existingFromTopic.color,
                    label: fromTopic.topic_name ?? existingFromTopic.label,
                    value: fromTopic.topic_name ?? existingFromTopic.value,
                    key: existingFromTopic.key,
                };
                setFromFilters(new Map([["Topic", new Set([newFromTopic])]]));
            }
        }
        if (toFilters.has("Topic")) {
            const existingToTopic = Array.from(toFilters.get("Topic") ?? [])[0];
            const toTopic: GetTopicsResponse | undefined = (
                topicsQuery.data ?? []
            ).find((t) => t.id === existingToTopic.key);
            if (toTopic) {
                // Update values based on the newest version of the topic. The workflow might have an outdated topic name
                const newToTopic = {
                    color: toTopic.color ?? existingToTopic.color,
                    label: toTopic.topic_name ?? existingToTopic.label,
                    value: toTopic.topic_name ?? existingToTopic.value,
                    key: existingToTopic.key,
                };
                setToFilters(new Map([["Topic", new Set([newToTopic])]]));
            }
        }
    }, [topicsQuery.data]);

    // Set the populated category's colors once categories query is updated
    useEffect(() => {
        if (fromFilters.has("Tag")) {
            const existingFromCat = Array.from(fromFilters.get("Tag") ?? [])[0];
            const fromCat: Category | undefined = (
                categoriesQuery.data ?? []
            ).find((t) => t.id === existingFromCat.key);
            if (fromCat) {
                // Update values based on the newest version of the topic. The workflow might have an outdated topic name
                const newFromCat = {
                    color: fromCat.color ?? existingFromCat.color,
                    label: fromCat.name ?? existingFromCat.label,
                    value: fromCat.name ?? existingFromCat.value,
                    key: existingFromCat.key,
                };
                setFromFilters(
                    new Map([
                        [
                            getTypeFromActionType(triggerType),
                            new Set([newFromCat]),
                        ],
                    ]),
                );
            }
        }
        if (toFilters.has("Tag")) {
            const existingToCat = Array.from(toFilters.get("Tag") ?? [])[0];
            const toCat: Category | undefined = (
                categoriesQuery.data ?? []
            ).find((t) => t.id === existingToCat.key);
            if (toCat) {
                // Update values based on the newest version of the topic. The workflow might have an outdated topic name
                const newToCat = {
                    color: toCat.color ?? existingToCat.color,
                    label: toCat.name ?? existingToCat.label,
                    value: toCat.name ?? existingToCat.value,
                    key: existingToCat.key,
                };
                setToFilters(
                    new Map([
                        [
                            getTypeFromActionType(triggerType),
                            new Set([newToCat]),
                        ],
                    ]),
                );
            }
        }
    }, [categoriesQuery.data]);

    const timezones = Intl.supportedValuesOf("timeZone").map((tz) => ({
        value: tz,
        label: tz.replace("_", " "), // Format for display, replace underscores with spaces
    }));
    const [tzSearch, setTzSearch] = useState(""); // State for timezone search input
    const filteredTimezones = timezones.filter(
        (timezone) =>
            timezone.label.toLowerCase().includes(tzSearch.toLowerCase()) ||
            timezone.value.toLowerCase().includes(tzSearch.toLowerCase()),
    );

    useEffect(() => {
        if (data.metadata?.itemTypes) {
            setReplySentSelectedTypes(data.metadata.itemTypes);
        }
    }, [data.metadata?.itemTypes]);

    return (
        <div className="flex flex-col items-start">
            <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
                <div className="flex flex-row items-center justify-center gap-1">
                    <TargetIcon
                        className="text-[#5e6ad2]"
                        strokeWidth={1.5}
                        size={12}
                    />
                    <p className="text-xs">Trigger</p>
                </div>
            </Badge>
            <Card
                className={`w-[475px] shadow-none border rounded-tr-lg rounded-bl-lg rounded-br-lg z-10 ${data.errorStyle}`}
            >
                <CardHeader className="flex flex-col gap-2 w-full">
                    <div className="flex items-center space-x-2">
                        <TriggerIcon />
                        <CardTitle>
                            <div className="flex items-center gap-2">
                                <p className="text-xs font-medium">When:</p>
                                <Select
                                    defaultValue={triggerType}
                                    onValueChange={handleSelectChange}
                                >
                                    <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1] w-[225px]">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {workflowKind ===
                                            WorkflowKind.Interactions && (
                                                <>
                                                    <SelectItem value="issue_created">
                                                        Interaction Created
                                                    </SelectItem>
                                                    <SelectItem value="reply_sent">
                                                        Reply Sent
                                                    </SelectItem>
                                                    <SelectItem value="category_updated">
                                                        Category Updated
                                                    </SelectItem>
                                                    <SelectItem value="tag_updated">
                                                        Tag Updated
                                                    </SelectItem>
                                                    <SelectItem value="status_updated">
                                                        Status Updated
                                                    </SelectItem>
                                                </>
                                            )}
                                        {workflowKind === WorkflowKind.Accounts && (
                                            <>
                                                <SelectItem value="account_created">
                                                    Account Created
                                                </SelectItem>
                                                <SelectItem value="company_type_updated">
                                                    Company Type Updated
                                                </SelectItem>
                                                <SelectItem value="stage_updated">
                                                    Stage Updated
                                                </SelectItem>
                                                <SelectItem value="account_tag_updated">
                                                    Tag Updated
                                                </SelectItem>
                                                <SelectItem value="tier_updated">
                                                    Tier Updated
                                                </SelectItem>
                                                <SelectItem value="time_before_renewal">
                                                    Time Before Renewal Date is
                                                </SelectItem>
                                            </>
                                        )}
                                        <SelectItem value="time_is">
                                            Time is
                                        </SelectItem>
                                        {workflowKind === WorkflowKind.Accounts && [
                                            "fc12e902-f8ee-4176-947b-7f716469d1d6",
                                            "01HQSDHJ9ZR783V0VKT59M110V",
                                            "7631a851-e28d-46ac-bb57-0805f570f7fa",
                                        ].includes(orgID) && (
                                                <>
                                                    <SelectItem value="health_score_changed">
                                                        Health Score Changed
                                                    </SelectItem>
                                                    <SelectItem value="balance_changed">
                                                        Balance Changed
                                                    </SelectItem>
                                                    <SelectItem value="usage_changed">
                                                        Usage Changed
                                                    </SelectItem>
                                                </>
                                            )}
                                    </SelectContent>
                                </Select>
                            </div>
                        </CardTitle>
                    </div>
                    {FROM_TO_SUBTYPES.includes(triggerType) && (
                        <div className="flex items-center gap-2 w-full ml-1">
                            <p className="text-xs font-medium">from</p>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild type="button">
                                    {WorkflowNodesDropdownTrigger(
                                        getTypeFromActionType(triggerType),
                                        fromFilters,
                                        [],
                                        [],
                                        [],
                                        [],
                                        categoriesQuery.data ?? [],
                                        getTopicColors(topicsQuery.data ?? []),
                                        accountsLabelsQuery.data ?? []
                                    )}
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    <FilterDropdownElement
                                        type={getTypeFromActionType(
                                            triggerType,
                                        )}
                                        categories={categoriesQuery.data ?? []}
                                        filters={fromFilters}
                                        handleItemSelect={handleFromItemSelect}
                                        topics={getTopicColors(
                                            topicsQuery.data ?? [],
                                        )}
                                        // TODO if values are needed, pass in actual values
                                        users={[]}
                                        customerGroups={[]}
                                        teams={[]}
                                        isSavedViewFilter={false}
                                        channels={new Map()}
                                        templates={[]}
                                        extraOptions={[
                                            {
                                                color: "",
                                                label: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                                                value: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                                                key: "*ANY*",
                                            },
                                        ]}
                                        interactionTypes={[]}
                                        accountLabels={accountsLabelsQuery.data ?? []}
                                    />
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <p className="text-xs font-medium">to</p>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild type="button">
                                    {WorkflowNodesDropdownTrigger(
                                        getTypeFromActionType(triggerType),
                                        toFilters,
                                        [],
                                        [],
                                        [],
                                        [],
                                        categoriesQuery.data ?? [],
                                        getTopicColors(topicsQuery.data ?? []),
                                        accountsLabelsQuery.data ?? []
                                    )}
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    <FilterDropdownElement
                                        type={getTypeFromActionType(
                                            triggerType,
                                        )}
                                        categories={categoriesQuery.data ?? []}
                                        filters={toFilters}
                                        handleItemSelect={handleToItemSelect}
                                        topics={getTopicColors(
                                            topicsQuery.data ?? [],
                                        )}
                                        // TODO if values are needed, pass in actual values
                                        users={[]}
                                        customerGroups={[]}
                                        teams={[]}
                                        isSavedViewFilter={false}
                                        channels={new Map()}
                                        templates={[]}
                                        extraOptions={[
                                            {
                                                color: "",
                                                label: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                                                value: `Any ${getLowercase(getTypeFromActionType(triggerType))}`,
                                                key: "*ANY*",
                                            },
                                        ]}
                                        interactionTypes={[]}
                                        accountLabels={accountsLabelsQuery.data ?? []}
                                    />
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    )}
                    {triggerType === "reply_sent" && (
                        <div className="flex items-center gap-2 w-full ml-1">
                            <p className="text-xs font-medium">from</p>
                            <DropdownMenu>
                                <DropdownMenuTrigger className="text-xs">
                                    {!replySentSelectedTypes ||
                                        replySentSelectedTypes.length === 0 ? (
                                        "Select Reply Type(s)..."
                                    ) : (
                                        <div className="flex items-center gap-1 flex-wrap">
                                            {replySentSelectedTypes.map(
                                                (type, index) => (
                                                    <div
                                                        key={type}
                                                        className="flex items-center gap-1"
                                                    >
                                                        {type}
                                                        <TooltipProvider>
                                                            <Tooltip>
                                                                <TooltipTrigger
                                                                    asChild
                                                                >
                                                                    <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0 text-primary -ml-0.5" />
                                                                </TooltipTrigger>
                                                                <TooltipContent className="py-2.5 px-4 flex flex-col w-[250px]">
                                                                    {(() => {
                                                                        switch (
                                                                        type
                                                                        ) {
                                                                            case "Customer":
                                                                                return "Replies from external customers that would move the ticket to Needs Response.";
                                                                            case "Team":
                                                                                return "Replies from internal team members that would move the ticket to Open.";
                                                                            case "Internal":
                                                                                return "Internal message replies sent through Assembly.";
                                                                            default:
                                                                                return "Replies";
                                                                        }
                                                                    })()}
                                                                </TooltipContent>
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                        {index <
                                                            replySentSelectedTypes.length -
                                                            2 && ","}
                                                        {index ===
                                                            replySentSelectedTypes.length -
                                                            2 && "or"}
                                                    </div>
                                                ),
                                            )}
                                            <Pencil2Icon className="text-muted-foreground w-3 h-3" />
                                        </div>
                                    )}
                                </DropdownMenuTrigger>

                                <DropdownMenuContent
                                    align="start"
                                    className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    <DropdownMenuItem
                                        onSelect={() =>
                                            handleReplySentTypeSelect("", true)
                                        }
                                        className="flex items-center gap-1 text-[13px]"
                                    >
                                        <BoxIcon className="w-3 h-3" />
                                        Any
                                    </DropdownMenuItem>
                                    {REPLY_SENT_OPTIONS.map((type) => {
                                        return (
                                            <DropdownMenuItem
                                                key={type}
                                                onSelect={() =>
                                                    handleReplySentTypeSelect(
                                                        type,
                                                        false,
                                                    )
                                                }
                                                className="flex justify-between items-center gap-1 text-[13px]"
                                            >
                                                <div className="flex items-center gap-1">
                                                    <MessageCircleReply className="w-3 h-3" />
                                                    {type}
                                                </div>

                                                {replySentSelectedTypes.includes(
                                                    type,
                                                ) && (
                                                        <CheckIcon className="h-4 w-4" />
                                                    )}
                                            </DropdownMenuItem>
                                        );
                                    })}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    )}
                    {triggerType === "time_before_renewal" && (
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center gap-2 text-xs">
                                    <div className="flex items-center border border-[#D8D8D8] rounded-md bg-white h-8 w-fit -mr-0.5">
                                        <Input
                                            type="number"
                                            value={timeInput}
                                            onChange={handleTimeInput}
                                            className={
                                                "px-2 w-[3.5rem] max-w-full text-center bg-transparent border-none outline-none focus:outline-none focus:ring-0 text-xs"
                                            }
                                            min="1"
                                        />
                                        <Separator
                                            orientation="vertical"
                                            className="bg-[#D8D8D8] w-[1px] h-8"
                                        />
                                        <Select
                                            defaultValue={timeUnit}
                                            onValueChange={handleTimeUnit}
                                        >
                                            <SelectTrigger className="w-full focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 pl-1.5 pr-1 py-0.5 rounded outline outline-1 outline-[#eeeff1]">
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="days">
                                                    Days
                                                </SelectItem>
                                                <SelectItem value="weeks">
                                                    Weeks
                                                </SelectItem>
                                                <SelectItem value="months">
                                                    Months
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    and time is
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger
                                                asChild
                                            >
                                                <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0 text-primary -ml-1" />
                                            </TooltipTrigger>
                                            <TooltipContent className="py-2.5 px-4 flex flex-col w-[180px]">
                                                The exact time of day that this workflow will run.
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                    <Input
                                        type="time"
                                        id="time"
                                        value={militaryTime}
                                        onChange={handleMilitaryTimeChange}
                                        className="w-[75px] px-1.5 text-xs border-none hover:bg-accent -mx-2"
                                    />
                                    <DropdownMenu>
                                        <DropdownMenuTrigger
                                            asChild
                                            className="text-xs w-[100px] truncate"
                                        >
                                            <Button
                                                variant="outline"
                                                className="flex items-center gap-1"
                                            >
                                                {timezone.label ||
                                                    "Select Timezone"}
                                                <CaretDownIcon />
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="max-h-50 w-[175px] text-xs">
                                            <Command className="rounded-md shadow-md text-xs pb-1">
                                                <CommandInput
                                                    placeholder="Filter timezones"
                                                    onValueChange={setTzSearch}
                                                />
                                                <CommandList className="space-y-1">
                                                    <CommandEmpty className="px-4 py-2">
                                                        No timezones found
                                                    </CommandEmpty>
                                                    {filteredTimezones.map((tz) => (
                                                        <CommandItem
                                                            key={tz.value}
                                                            onSelect={() =>
                                                                handleTimezoneOnSelect(
                                                                    tz,
                                                                )
                                                            }
                                                            className="px-3 py-1.5"
                                                        >
                                                            <div className="flex items-center gap-2">
                                                                {tz.label}
                                                                {tz.value ===
                                                                    timezone.value && (
                                                                        <CheckIcon className="h-4 w-4" />
                                                                    )}
                                                            </div>
                                                        </CommandItem>
                                                    ))}
                                                </CommandList>
                                            </Command>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                                <div className="flex items-center gap-3 text-xs">

                                </div>
                            </div>
                        </div>
                    )}
                    {triggerType === "time_is" && (
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2">
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant="outline"
                                            className="flex items-center gap-2 text-xs"
                                        >
                                            {date ? (
                                                format(date, "EEEE, MMM dd")
                                            ) : (
                                                <span>Select Date</span>
                                            )}
                                            <CalendarIcon className="h-3 w-3" />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <Calendar
                                            mode="single"
                                            selected={date}
                                            onSelect={handleDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                                <Input
                                    type="time"
                                    id="time"
                                    value={time}
                                    onChange={handleTimeChange}
                                    className="w-25 text-xs border-none hover:bg-accent"
                                />
                                <DropdownMenu>
                                    <DropdownMenuTrigger
                                        asChild
                                        className="text-xs w-[100px] truncate"
                                    >
                                        <Button
                                            variant="outline"
                                            className="flex items-center gap-1"
                                        >
                                            {timezone.label ||
                                                "Select Timezone"}
                                            <CaretDownIcon />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="max-h-50 w-[175px] text-xs">
                                        <Command className="rounded-md shadow-md text-xs pb-1">
                                            <CommandInput
                                                placeholder="Filter timezones"
                                                onValueChange={setTzSearch}
                                            />
                                            <CommandList className="space-y-1">
                                                <CommandEmpty className="px-4 py-2">
                                                    No timezones found
                                                </CommandEmpty>
                                                {filteredTimezones.map((tz) => (
                                                    <CommandItem
                                                        key={tz.value}
                                                        onSelect={() =>
                                                            handleTimezoneOnSelect(
                                                                tz,
                                                            )
                                                        }
                                                        className="px-3 py-1.5"
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            {tz.label}
                                                            {tz.value ===
                                                                timezone.value && (
                                                                    <CheckIcon className="h-4 w-4" />
                                                                )}
                                                        </div>
                                                    </CommandItem>
                                                ))}
                                            </CommandList>
                                        </Command>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                            <div className="flex items-center gap-2">
                                <Select
                                    defaultValue={repeatType}
                                    onValueChange={handleRepeatTypeChange}
                                >
                                    <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 rounded w-[175px] border-none">
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="does_not_repeat">
                                            Does not repeat
                                        </SelectItem>
                                        <SelectItem value="daily">
                                            Daily
                                        </SelectItem>
                                        <SelectItem value="weekly">
                                            {date
                                                ? `Weekly on ${format(date, "EEEE")}`
                                                : "Weekly"}
                                        </SelectItem>
                                        <SelectItem value="monthly">
                                            Monthly
                                        </SelectItem>
                                        <SelectItem value="annually">
                                            Annually
                                        </SelectItem>
                                        <SelectItem value="custom">
                                            Custom
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            {repeatType === "custom" && (
                                <Card className="flex flex-col gap-2 rounded-lg shadow-none py-2">
                                    <div className="flex items-center gap-2 text-xs px-3">
                                        <div className="w-[80px]">
                                            Repeat every
                                        </div>
                                        <Card className="flex items-center rounded-lg shadow-none p-0">
                                            <Input
                                                type="number"
                                                value={customTimeInput}
                                                onChange={
                                                    handleCustomTimeInputChange
                                                }
                                                className="px-3 w-[4rem] max-w-full text-center border-none outline-none focus:outline-none focus:ring-0 text-xs"
                                                min="1"
                                            />
                                            <Separator
                                                orientation="vertical"
                                                className="bg-[#D8D8D8] w-[1px] h-8"
                                            />
                                            <Select
                                                defaultValue={customTimeUnit}
                                                onValueChange={
                                                    handleCustomTimeUnitChange
                                                }
                                            >
                                                <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded border-none w-[75px] text-xs">
                                                    <SelectValue />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="seconds">
                                                        Seconds
                                                    </SelectItem>
                                                    <SelectItem value="minutes">
                                                        Minutes
                                                    </SelectItem>
                                                    <SelectItem value="hours">
                                                        Hours
                                                    </SelectItem>
                                                    <SelectItem value="days">
                                                        Days
                                                    </SelectItem>
                                                    <SelectItem value="weeks">
                                                        Weeks
                                                    </SelectItem>
                                                    <SelectItem value="months">
                                                        Months
                                                    </SelectItem>
                                                    <SelectItem value="years">
                                                        Years
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </Card>
                                    </div>
                                    <div className="flex items-center gap-2 text-xs px-3">
                                        <div className="w-[80px]">Ends</div>
                                        <Select
                                            defaultValue={customEnds}
                                            onValueChange={
                                                handleCustomEndChange
                                            }
                                        >
                                            <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded border-none w-[75px] text-xs">
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="never">
                                                    Never
                                                </SelectItem>
                                                <SelectItem value="on">
                                                    On
                                                </SelectItem>
                                                <SelectItem value="after">
                                                    After
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                        {customEnds === "after" && (
                                            <Card className="flex items-center gap-0 rounded-lg shadow-none pr-3">
                                                <Input
                                                    type="number"
                                                    value={endsAfterTimeInput}
                                                    onChange={
                                                        handleEndsAfterTimeInputChange
                                                    }
                                                    className="w-[4rem] max-w-full text-center border-none outline-none focus:outline-none focus:ring-0 text-xs"
                                                    min="1"
                                                />
                                                Occurences
                                            </Card>
                                        )}
                                        {customEnds === "on" && (
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Card className="flex items-center gap-0 rounded-lg shadow-none">
                                                        <Button
                                                            variant="outline"
                                                            className="flex items-center gap-2 text-xs border"
                                                        >
                                                            {endDate ? (
                                                                format(
                                                                    endDate,
                                                                    "MMMM dd, yyyy",
                                                                )
                                                            ) : (
                                                                <span>
                                                                    Select Date
                                                                </span>
                                                            )}
                                                            <CalendarIcon className="h-3 w-3" />
                                                        </Button>
                                                    </Card>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar
                                                        mode="single"
                                                        selected={endDate}
                                                        onSelect={
                                                            handleEndDateChange
                                                        }
                                                    />
                                                </PopoverContent>
                                            </Popover>
                                        )}
                                    </div>
                                </Card>
                            )}
                        </div>
                    )}
                    {["balance_changed",
                        "usage_changed"
                    ].includes(triggerType) && (
                            <div className="flex items-center gap-2 w-full ml-2">
                                <p className="text-xs font-medium">{getItem(triggerType)}</p>
                                <Select
                                    value={boundary}
                                    onValueChange={handleBoundary}
                                >
                                    <SelectTrigger className="w-[80px] focus:outline-none focus:ring-0 text-xs font-medium hover:bg-muted px-2 py-1 rounded outline outline-1 outline-[#eeeff1] h-5">
                                        <SelectValue placeholder="dropped..." />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="dropped">
                                            dropped
                                        </SelectItem>
                                        <SelectItem value="raised">
                                            raised
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                                <Input
                                    type="number"
                                    value={boundaryPercentInput}
                                    onChange={handleBoundaryPercentInput}
                                    className={
                                        "text-xs px-1 w-[3.5rem] h-[2rem] max-w-full text-center bg-transparent outline-none focus:outline-none focus:ring-0"
                                    }
                                    min="1"
                                />
                                <div className="text-xs mr-1">
                                    %
                                </div>
                                <Select
                                    value={timeOverTime}
                                    onValueChange={handleTimeOverTime}
                                >
                                    <SelectTrigger className="w-[140px] focus:outline-none focus:ring-0 text-xs font-medium hover:bg-muted px-2 py-1 rounded outline outline-1 outline-[#eeeff1] h-5">
                                        <SelectValue placeholder="time..." />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="day">
                                            day over day
                                        </SelectItem>
                                        <SelectItem value="week">
                                            week over week
                                        </SelectItem>
                                        <SelectItem value="month">
                                            month over month
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        )}
                    {["health_score_changed"
                    ].includes(triggerType) && (
                            <div className="flex items-center gap-2 w-full ml-2">
                                <p className="text-xs font-medium">{getItem(triggerType)}</p>
                                <Select
                                    value={boundary}
                                    onValueChange={handleBoundary}
                                >
                                    <SelectTrigger className="w-[120px] focus:outline-none focus:ring-0 text-xs font-medium hover:bg-muted px-2 py-1 rounded outline outline-1 outline-[#eeeff1] h-5">
                                        <SelectValue placeholder="dropped below..." />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="dropped">
                                            dropped below
                                        </SelectItem>
                                        <SelectItem value="raised">
                                            raised above
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                                <Input
                                    type="number"
                                    value={boundaryNumberInput}
                                    onChange={handleBoundaryNumberInput}
                                    className={
                                        "text-xs px-1 w-[3.5rem] h-[2rem] max-w-full text-center bg-transparent outline-none focus:outline-none focus:ring-0"
                                    }
                                    min="1"
                                />
                            </div>
                        )}
                </CardHeader>

                <CardContent className="flex flex-col gap-2">
                    <Separator />
                    <Input
                        className="text-xs border-none px-0"
                        placeholder="Name..."
                        value={nameVal}
                        onChange={handleNameChange}
                    />
                </CardContent>

                {!defaultTrigger && (
                    <Handle
                        type="source"
                        position={Position.Top}
                        id="a"
                        isConnectable={isConnectable}
                        className={`${handleStyle} top-[18px]`}
                    />
                )}
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id="b"
                    isConnectable={isConnectable}
                    className={handleStyle}
                />
            </Card>
        </div>
    );
};

export default TriggerNode;
