import { useToast } from "@/component/shadcn/ui/use-toast";
import { ContactsAPI, URLS } from "@/constant";
import useUser from "@/hooks/use-users";
import { useApi } from "@/interfaces/api";
import {
    type ChecklistCountsResponse,
    type GetOnboardingResponse,
    type ListOnboardingChecklistPayload,
    type OnboardingChecklist,
    type OnboardingChecklistPayload,
    OnboardingChecklistType,
    type PersonalTasks,
} from "@/interfaces/onboardingServerData";
import { Box, Callout, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useQuery,
} from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import {
    MilestoneCard,
    getMilestoneProgressValue,
} from "./Onboarding/MilestoneCard";

import type { Account } from "@/interfaces/serverData";
import {
    handleDeleteIssue,
    saveIssue,
    saveOnboardingItem,
    setOnboardingState,
} from "./Onboarding/methods";

import { NoTasksPrompt } from "@/IssuesTable/NoTasksPrompt";
import { Tabs, TabsList, TabsTrigger } from "@/component/shadcn/ui/tabs";
import { getCustomerMilestonesIcons } from "@/utilities/methods";
import type { OrgMemberInfo } from "@propelauth/react";
import type { DateRange } from "react-day-picker";
import { IssueListType } from "../AdminQueriesPage";
import { OnboardingTable } from "./Onboarding/OnboardingTable/OnboardingTable";
import { addOnboardingItem } from "./Onboarding/methods";
export interface TasksInboxProps {
    org: OrgMemberInfo | undefined;
    userID: string;
    listType: IssueListType;
}

export type ListOnboardingChecklistResponse = PersonalTasks[];

export const TasksPersonalPage = ({
    org,
    userID,
    listType = IssueListType.Inbox,
}: TasksInboxProps) => {
    const api = useApi();
    const { toast } = useToast();

    const fetchMilestones =
        async (): Promise<ListOnboardingChecklistResponse> => {
            try {
                const payload: ListOnboardingChecklistPayload = {
                    assignee_user_id_param: [userID],
                };
                const { url } = ContactsAPI.listOnboardingChecklist;
                const response = await api.post(
                    `${URLS.serverUrl}${url}`,
                    payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    },
                );
                if (response.data === null) {
                    return [];
                }
                if (response.status === 200) {
                    return response.data.data;
                }
                return [];
            } catch (error) {
                console.error("Error fetching queries:", error);
                return [];
            }
        };

    const {
        data: onboardingData,
        isLoading: loadingOnboardingData,
        isError: errorOnboardingData,
        refetch: refetchOnboardingData,
    } = useQuery({
        queryKey: ["milestones", userID],
        queryFn: fetchMilestones,
    });

    const [onboardingItems, setOnboardingItems] = useState<
        Map<string, PersonalTasks>
    >(new Map(onboardingData?.map((item) => [item.id, item])));

    useEffect(() => {
        setOnboardingItems(
            new Map(onboardingData?.map((item) => [item.id, item])),
        );
    }, [onboardingData]);

    const [selectedItems, setSelectedItems] = useState<PersonalTasks[]>([]);

    const wrappedSetOnboardingState = useCallback(
        (newState: Partial<PersonalTasks>, oldItem: PersonalTasks) => {
            setOnboardingState(
                newState as Partial<OnboardingChecklist>,
                oldItem as OnboardingChecklist,
                selectedItems as OnboardingChecklist[],
                (
                    updater: (
                        prev: Map<string, OnboardingChecklist>,
                    ) => Map<string, OnboardingChecklist>,
                ) => {
                    setOnboardingItems((prev) => {
                        const result = updater(
                            prev as unknown as Map<string, OnboardingChecklist>,
                        );
                        return result as unknown as Map<string, PersonalTasks>;
                    });
                },
                (items) =>
                    setSelectedItems(items as unknown as PersonalTasks[]),
            );
        },
        [selectedItems, setOnboardingItems, setSelectedItems],
    );

    const wrappedHandleDeleteIssue = useCallback(
        (
            userID: string,
            issueIds: string[],
            onboardingMainItemID: string,
            deleteOnUI?: (ids: string[]) => void,
        ) => {
            handleDeleteIssue(
                userID,
                issueIds,
                selectedItems as OnboardingChecklist[],
                api,
                onboardingMainItemID,
                deleteOnUI,
            );
        },
        [selectedItems, api],
    );

    const wrappedSaveIssue = useCallback(
        (
            type: string,
            payload: Partial<OnboardingChecklistPayload>,
            userID: string,
            updateIssueState: (
                newState: Partial<OnboardingChecklist>,
                oldItem: OnboardingChecklist,
            ) => void,
            refetch: (
                options?: RefetchOptions,
            ) => Promise<QueryObserverResult<GetOnboardingResponse, Error>>,
            issueId: string,
            item: OnboardingChecklist,
            onboardingMainItemId: string,
            milestoneId: string,
        ) => {
            saveIssue(
                type,
                payload,
                userID,
                updateIssueState,
                refetch,
                issueId,
                item,
                onboardingMainItemId,
                milestoneId,
                selectedItems as OnboardingChecklist[],
                api,
            );
        },
        [selectedItems, api],
    );

    const wrappedSaveOnboardingItem = useCallback(
        (
            issue: Partial<OnboardingChecklist>,
            column: string,
            value: string,
            onboardingMainItemId: string,
        ) => {
            saveOnboardingItem(
                issue,
                column,
                value,
                onboardingMainItemId,
                selectedItems as OnboardingChecklist[],
                api,
                userID,
            );
        },
        [selectedItems, api, userID],
    );

    const [interactionFilters, setInteractionFilters] =
        useState<ListOnboardingChecklistPayload>({});

    const fetchChecklistCounts = async (
        onboardingType: OnboardingChecklistType,
    ): Promise<ChecklistCountsResponse> => {
        if (listType === IssueListType.Inbox) {
            interactionFilters.assignee_user_id_param = [userID];
        }
        interactionFilters.onboarding_type_param = [onboardingType];
        const { url } = ContactsAPI.listOnboardingChecklistCounts;
        const response = await api.post(
            `${URLS.serverUrl}${url}`,
            interactionFilters,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );

        if (response.status === 200) {
            return response.data.data;
        }
        return {
            total_count: 0,
            completed_count: 0,
        };
    };

    const addOnboardingItemWrapper = useCallback(
        async (
            onboardingItem: OnboardingChecklistPayload,
            parentId: string,
        ): Promise<string> => {
            const accountType = "Company";
            const personalTask = onboardingItem as unknown as PersonalTasks;
            const accountProp = {
                id: personalTask.onboarding_company_id,
            } as Account;
            try {
                const added = await addOnboardingItem(
                    onboardingItem,
                    parentId,
                    accountType,
                    accountProp,
                    api,
                );
                return added;
            } catch (err) {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
                return "";
            }
        },
        [api, toast],
    );

    const [inputFilters, setInputFilters] = useState<Map<string, Set<string>>>(
        () => {
            const defaultFilters = new Map<string, Set<string>>();
            defaultFilters.set("Task Status", new Set());
            return defaultFilters;
        },
    );

    const [deadlineRange, setDeadlineRange] = useState<DateRange | undefined>({
        from: new Date(new Date().setDate(new Date().getDate() - 7)),
    });

    const {
        data: milestoneCounts,
        isLoading: loadingMilestoneCounts,
        isError: errorMilestoneCounts,
        refetch: refetchMilestoneCounts,
    } = useQuery({
        queryKey: ["milestoneCounts", interactionFilters, userID, listType],
        queryFn: () => fetchChecklistCounts(OnboardingChecklistType.Milestone),
    });

    const {
        data: taskCounts,
        isLoading: loadingTaskCounts,
        isError: errorTaskCounts,
        refetch: refetchTaskCounts,
    } = useQuery({
        queryKey: ["taskCounts", interactionFilters, userID, listType],
        queryFn: () => fetchChecklistCounts(OnboardingChecklistType.Task),
    });

    useEffect(() => {
        refetchMilestoneCounts();
        refetchTaskCounts();
    }, [
        interactionFilters,
        userID,
        listType,
        refetchMilestoneCounts,
        refetchTaskCounts,
    ]);

    const [incomingData, setIncomingData] = useState<OnboardingChecklist[]>(
        onboardingData ?? [],
    );
    useEffect(() => {
        setIncomingData(onboardingData ?? []);
    }, [onboardingData]);

    const [activeTab, setActiveTab] = useState<string>("Tasks");

    const { data: users } = useUser();

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    if (!org) {
        return null;
    }
    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="5" height="100%" width="99%">
                <div className="flex flex-col mx-3">
                    <div className="flex items-center ml-5 mb-5 justify-between">
                        <div>
                            <Heading
                                size="5"
                                align="left"
                                className="flex items-center gap-2"
                            >
                                Your Milestones
                            </Heading>
                            <Text mb="12px" size="2">
                                Manage and track your tasks for all your
                                accounts here.
                            </Text>
                        </div>
                    </div>

                    <Tabs
                        defaultValue="Tasks"
                        value={activeTab}
                        onValueChange={setActiveTab}
                    >
                        <TabsList className="bg-[#545A92] bg-opacity-5 items-center rounded-[10px] mb-4">
                            {["Tasks", "Milestones"].map((status) => {
                                const Icon = getCustomerMilestonesIcons(status);
                                const label = status;
                                return (
                                    <TabsTrigger
                                        key={status}
                                        value={status}
                                        className={`text-xs h-5 py-1.5 px-2 font-normal rounded-[8px]  ${activeTab === status ? "bg-white text-[#2C2E4A] border border-solid border-[#F2EFFA]" : "bg-transparent text-[#191A2C] border border-transparent"}`}
                                    >
                                        {Icon && (
                                            <Icon className="h-4 w-4 mr-2" />
                                        )}
                                        {label}
                                        <span className="ml-1 rounded-[4px] bg-[#545A92] bg-opacity-5 px-2 py-0.5 text-xs font-normal h-6 items-center justify-center flex">
                                            {status === "Milestones"
                                                ? milestoneCounts?.total_count
                                                : taskCounts?.total_count}
                                        </span>
                                    </TabsTrigger>
                                );
                            })}
                        </TabsList>
                    </Tabs>

                    {/* <div className="flex mx-2 mb-1 flex-wrap gap-1">
                     <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                className="flex items-center h-8 gap-2 text-xs border border-solid border-input shadow-none"
                            >
                                <CalendarIcon className="h-3 w-3" />
                                {deadlineRange?.from ? (
                                    format(deadlineRange.from, "PP")
                                ) : (
                                    <span>Oldest</span>
                                )}
                                <div>to</div>
                                {deadlineRange?.to ? (
                                    format(deadlineRange.to, "PP")
                                ) : (
                                    <span>Latest</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="range"
                                selected={deadlineRange}
                                onSelect={setDeadlineRange}
                            />
                        </PopoverContent>
                    </Popover>
                    <MilestoneFilterItems
                        filters={inputFilters ?? new Map()}
                        setFilters={setInputFilters}
                        categories={[]}
                        topics={[]}
                        users={[]}
                        customerGroups={[]}
                        teams={[]}
                        savedViewFilters={inputFilters ?? new Map()}
                        isGeneralTeam={
                            listType === IssueListType.Issues ||
                            listType === IssueListType.CreateView ||
                            listType === IssueListType.Inbox
                        }
                        channels={new Map()}
                        interactionTypes={[]}
                        listType={listType}
                        defaultFiltersParam={["Task Status"]}
                    />
                </div> */}
                </div>

                <div className="flex flex-col gap-6">
                    {errorOnboardingData && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}
                    {loadingOnboardingData && (
                        <Flex
                            maxWidth="85%"
                            style={{ paddingLeft: "20px", paddingTop: "20px" }}
                        >
                            <Text>
                                <Skeleton maxWidth="85%">
                                    {[...Array(6)].map((_, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <Text key={index}>{loremIpsum}</Text>
                                    ))}
                                </Skeleton>
                            </Text>
                        </Flex>
                    )}
                    {activeTab === "Milestones" &&
                    !loadingOnboardingData &&
                    onboardingItems &&
                    milestoneCounts &&
                    milestoneCounts.total_count > 0 ? (
                        <>
                            {Array.from(onboardingItems.values())
                                .filter(
                                    (item) =>
                                        item.onboarding_type ===
                                        OnboardingChecklistType.Milestone,
                                )
                                .map((item) => {
                                    const progress = getMilestoneProgressValue(
                                        item.total_tasks ?? 0,
                                        item.completed_count ?? 0,
                                    );
                                    const incompleteCount = Array.from(
                                        onboardingItems.values(),
                                    )
                                        .filter(
                                            (i) =>
                                                i.onboarding_type ===
                                                    OnboardingChecklistType.Milestone &&
                                                getMilestoneProgressValue(
                                                    i.total_tasks ?? 0,
                                                    i.completed_count ?? 0,
                                                ) !== 1,
                                        )
                                        .indexOf(item);

                                    return (
                                        <MilestoneCard
                                            key={item.id}
                                            accountType={
                                                item.onboarding_company_id
                                                    ? "Company"
                                                    : "Customer"
                                            }
                                            accountProp={
                                                {
                                                    id:
                                                        item.onboarding_company_id ??
                                                        item.onboarding_customer_id,
                                                } as Account
                                            }
                                            item={item as OnboardingChecklist}
                                            users={users ?? []}
                                            saveIssue={wrappedSaveIssue}
                                            userID={userID}
                                            isSelected={false}
                                            onSelect={() => {}}
                                            refetch={
                                                refetchOnboardingData as unknown as (
                                                    options?: RefetchOptions,
                                                ) => Promise<
                                                    QueryObserverResult<
                                                        GetOnboardingResponse,
                                                        Error
                                                    >
                                                >
                                            }
                                            updateIssueState={(
                                                newState: Partial<OnboardingChecklist>,
                                                oldItem: OnboardingChecklist,
                                            ) =>
                                                wrappedSetOnboardingState(
                                                    newState as unknown as Partial<PersonalTasks>,
                                                    oldItem as unknown as PersonalTasks,
                                                )
                                            }
                                            deleteIssues={
                                                wrappedHandleDeleteIssue
                                            }
                                            setSheetOpen={() => {}}
                                            addIssue={addOnboardingItemWrapper}
                                            setTopLevelEditing={() => {}}
                                            accountOnboardingParentId={
                                                item.onboarding_id
                                            }
                                            isOpen={
                                                progress !== 1 &&
                                                incompleteCount < 3
                                            }
                                            org={org}
                                            saveIssueNew={
                                                wrappedSaveOnboardingItem
                                            }
                                            entityName={item.entity_name}
                                            imageUrl={item.image_url}
                                            showEntityName={true}
                                        />
                                    );
                                })}
                        </>
                    ) : (
                        activeTab === "Milestones" &&
                        !loadingOnboardingData &&
                        !loadingMilestoneCounts && (
                            <NoTasksPrompt
                                userID={userID}
                                listType={listType}
                                type="Milestones"
                            />
                        )
                    )}

                    {activeTab === "Tasks" &&
                    !loadingOnboardingData &&
                    onboardingItems &&
                    taskCounts &&
                    taskCounts.total_count > 0 ? (
                        <OnboardingTable
                            userID={userID}
                            users={users ?? []}
                            data={incomingData.filter(
                                (item) =>
                                    item.onboarding_type ===
                                    OnboardingChecklistType.Task,
                            )}
                            setData={setIncomingData}
                            saveToDB={true}
                            addIssue={addOnboardingItemWrapper}
                            onboardingMainItemId={""}
                            deleteIssues={wrappedHandleDeleteIssue}
                            isPersonalTasks={true}
                            addRowEnabled={false}
                            saveIssue={wrappedSaveOnboardingItem}
                        />
                    ) : (
                        activeTab === "Tasks" &&
                        !loadingOnboardingData &&
                        !loadingTaskCounts && (
                            <NoTasksPrompt
                                userID={userID}
                                listType={listType}
                                type="Tasks"
                            />
                        )
                    )}
                </div>
            </Box>
        </Flex>
    );
};
