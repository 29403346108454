import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import { Dialog, DialogContent } from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { Textarea } from "@/component/shadcn/ui/textarea";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi, useApiFormData } from "@/interfaces/api";
import type {
    Category,
    CreateTicketFromUIPayload,
    CreateTicketFromUIResponse,
    GetTeamResponse,
    GetUserResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { getStatusIcon } from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    CrossCircledIcon,
    DotFilledIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type { QueryClient } from "@tanstack/react-query";
import { HouseIcon, UsersIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import FilterDropdownElement from "./FilterDropdownElement";
import { ticketStatuses } from "./constants";

const DropdownTrigger = (
    type: string,
    categories: Category[],
    filters: Map<string, Set<string>>,
    users: GetUserResponse[],
    teams: Teams[],
    topics: Topic[],
) => {
    switch (type) {
        case "Status": {
            const statusSet = filters.get("Status");
            const currStatus =
                statusSet && statusSet.size === 1
                    ? Array.from(statusSet)[0]
                    : "Unknown";
            const StatusIcon = getStatusIcon(currStatus ?? "Unknown");
            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                >
                    <div className="flex items-center gap-2">
                        <div className="flex items-center gap-2">
                            <StatusIcon className="w-4 h-4" />
                            {ticketStatuses.find(
                                (status) => status.value === currStatus,
                            )?.label ?? "Unknown"}
                        </div>
                    </div>
                </Badge>
            );
        }
        case "Assignee": {
            const assigneeSet = filters.get("Assignee");
            const currAssignee =
                assigneeSet && assigneeSet.size === 1
                    ? Array.from(assigneeSet)[0]
                    : "noAssignee";
            const assigneeInfo = users.find((user) => user.id === currAssignee);
            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl w-100"
                >
                    <div className="flex items-center gap-2">
                        <div className="lb-avatar rounded-lg w-4 h-4">
                            {assigneeInfo?.picture_url ? (
                                <img
                                    className="lb-avatar-image"
                                    src={assigneeInfo?.picture_url}
                                    alt={assigneeInfo?.username}
                                />
                            ) : (
                                <AvatarIcon className="w-4 h-4" />
                            )}
                        </div>
                        <span className="lb-comment-author text-xs font-normal font-destructive">
                            {assigneeInfo
                                ? `${assigneeInfo?.first_name} ${assigneeInfo?.last_name}`
                                : "No Assignee"}
                        </span>
                    </div>
                </Badge>
            );
        }
        case "Tag": {
            const tagSet = filters.get("Tag");
            const currTag =
                tagSet && tagSet.size === 1
                    ? Array.from(tagSet)[0]
                    : "AI_MATCH_TAG";
            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                >
                    <div className="flex flex-row items-center">
                        {currTag === "AI_MATCH_TAG" ? (
                            <>
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                                <p className="pl-0.5">AI Match Category</p>
                            </>
                        ) : (
                            <>
                                <ComponentBooleanIcon
                                    color={
                                        categories.find(
                                            (category) =>
                                                category.name === currTag,
                                        )?.color ?? "gray"
                                    }
                                />
                                <p className="pl-0.5">{currTag}</p>
                            </>
                        )}
                    </div>
                </Badge>
            );
        }
        case "Topic": {
            const topicList = Array.from(filters.get("Topic") ?? []);
            const hasAI = topicList.includes("AI_MATCH_TOPIC");
            const hasNone = topicList.includes("NONE");

            const renderTopicIcon = (topic: string) => {
                if (topic === "AI_MATCH_TOPIC") {
                    return (
                        <>
                            <img
                                src={SparklesIcon}
                                alt="AI Match Icon"
                                className="h-4 w-4"
                            />
                            <p className="pl-1">AI Match Tag</p>
                        </>
                    );
                }
                if (topic === "NONE") {
                    return (
                        <>
                            <CrossCircledIcon />
                            <p className="pl-1">No Tags</p>
                        </>
                    );
                }
                const topicInfo = topics.find((t) => t.value === topic);
                return (
                    <>
                        <DotFilledIcon
                            color={
                                topicInfo?.color && topicInfo?.color !== ""
                                    ? topicInfo?.color
                                    : "#9B9EF0"
                            }
                            style={{ transform: "scale(1.8)" }}
                        />
                        <p className="pl-0.3">{topicInfo?.label || topic}</p>
                    </>
                );
            };

            const renderTopics = () => {
                if (topicList.length === 1) {
                    return renderTopicIcon(topicList[0]);
                }
                return (
                    <>
                        {topicList.map((topic) => {
                            const color = topics.find(
                                (t) => t.value === topic,
                            )?.color;
                            return (
                                <div
                                    className="flex flex-row items-center"
                                    key={topic}
                                >
                                    <DotFilledIcon
                                        color={
                                            color && color !== ""
                                                ? color
                                                : "#9B9EF0"
                                        }
                                        style={{ transform: "scale(1.8)" }}
                                        className="-mx-1"
                                    />
                                </div>
                            );
                        })}
                        <p className="pl-1">{topicList.length} topics</p>
                    </>
                );
            };

            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                >
                    <div className="flex flex-row items-center">
                        {hasAI && renderTopicIcon("AI_MATCH_TOPIC")}
                        {hasNone && renderTopicIcon("NONE")}
                        {!(hasAI || hasNone) && renderTopics()}
                    </div>
                </Badge>
            );
        }
        case "TeamOwner": {
            const teamSet = filters.get("TeamOwner");
            const currTeam =
                teamSet && teamSet.size === 1 && Array.from(teamSet)[0] !== ""
                    ? Array.from(teamSet)[0]
                    : "noTeamOwner";
            const teamInfo = teams.find((team) => team.id === currTeam);
            return (
                <div>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0.5 px-2 py-1 text-xs rounded-xl"
                    >
                        <div className="flex flex-row items-center gap-1.5">
                            {currTeam === "noTeamOwner" ? (
                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                    <HouseIcon
                                        className="text-iris9"
                                        strokeWidth={1.5}
                                        size={10}
                                    />
                                </div>
                            ) : (
                                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                    <UsersIcon
                                        className="text-red9"
                                        strokeWidth={1.5}
                                        size={10}
                                    />
                                </div>
                            )}
                            {currTeam === "noTeamOwner"
                                ? "No Team Owner"
                                : teamInfo?.team_name}
                        </div>
                    </Badge>
                </div>
            );
        }
    }
};

interface CreateIssuePopupProps {
    topics: Topic[];
    categories: Category[];
    userID: string;
    queryClient: QueryClient;
    dialogIsOpen: boolean;
    setDialogIsOpen: (value: React.SetStateAction<boolean>) => void;
    teams: Teams[];
    teamID?: string;
}

export function CreateIssuePopup({
    categories,
    topics,
    userID,
    queryClient,
    dialogIsOpen,
    setDialogIsOpen,
    teams,
    teamID,
}: CreateIssuePopupProps) {
    const apiFormData = useApiFormData();
    const api = useApi();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [title, setTitle] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [selectedTag, setSelectedTag] = useState<string>("AI_MATCH_TAG");
    const [selectedStatus, setSelectedStatus] = useState<string>("Open");
    const [selectedTopic, setSelectedTopic] = useState<string[]>([
        "AI_MATCH_TOPIC",
    ]);
    const [selectedAssignee, setSelectedAssignee] = useState<string>("");
    const [selectedTeamOwner, setSelectedTeamOwner] = useState<string>(
        teamID ?? "",
    );
    const [users, setUsers] = useState<GetUserResponse[]>([]);
    const filters = new Map<string, Set<string>>();
    filters.set("Tag", new Set([selectedTag]));
    filters.set("Status", new Set([selectedStatus]));
    filters.set("Assignee", new Set([selectedAssignee]));
    filters.set("Topic", new Set(selectedTopic));
    filters.set("TeamOwner", new Set([selectedTeamOwner]));
    const dropdownTypes = ["Status", "Assignee", "Tag", "Topic", "TeamOwner"];

    function clearForm() {
        setTitle("");
        setMessage("");
        setSelectedTag("AI_MATCH_TAG");
        setSelectedStatus("Open");
        setSelectedTopic(["AI_MATCH_TOPIC"]);
        setSelectedAssignee("");
        setSelectedTeamOwner("");
    }

    const handleItemSelect =
        (
            type: string,
            option: {
                label: string;
                value: string;
                key: string;
                color: string;
            },
        ) =>
            () => {
                console.log(
                    "in handle item select with type",
                    type,
                    " and option",
                    option,
                );
                switch (type) {
                    case "Tag": {
                        setSelectedTag(option.value);
                        filters.set("Tag", new Set([option.value]));
                        return;
                    }
                    case "Status": {
                        setSelectedStatus(option.value);
                        filters.set("Status", new Set([option.value]));
                        return;
                    }
                    case "Topic": {
                        // Can only suggest AI Match Topics of any number of other topics
                        if (
                            option.value === "AI_MATCH_TOPIC" ||
                            option.value === "NONE"
                        ) {
                            setSelectedTopic([option.value]);
                            filters.set("Topic", new Set([option.value]));
                        } else if (
                            selectedTopic.includes("AI_MATCH_TOPIC") ||
                            selectedTopic.includes("NONE")
                        ) {
                            setSelectedTopic([option.value]);
                            filters.set("Topic", new Set([option.value]));
                        } else {
                            const isSelected = selectedTopic.includes(option.value);
                            let newTopicsSelected: string[];

                            if (isSelected) {
                                newTopicsSelected =
                                    selectedTopic.filter(
                                        (t) => t !== option.value,
                                    ) || [];
                            } else {
                                newTopicsSelected = [
                                    ...(selectedTopic || []),
                                    option.value,
                                ];
                            }
                            setSelectedTopic(newTopicsSelected);
                            filters.set("Topic", new Set(newTopicsSelected));
                        }
                        return;
                    }
                    case "Assignee": {
                        setSelectedAssignee(option.value);
                        filters.set("Assignee", new Set([option.value]));
                        return;
                    }
                    case "TeamOwner": {
                        setSelectedTeamOwner(option.value);
                        filters.set("TeamOwner", new Set([option.value]));
                        return;
                    }
                    default:
                        console.log(
                            "handleItemSelect does not support type ",
                            type,
                        );
                }
            };

    const CreateIssue = async () => {
        try {
            setFeedbackMessage("Creating the issue...");
            if (message.trim() === "") {
                setFeedbackMessage(
                    "Enter a message, the message field cannot be empty",
                );
                return;
            }
            const getTopicIDs = (
                selectedTopic: string[],
                topics: Topic[],
            ): string[] => {
                if (selectedTopic.length === 1) {
                    if (selectedTopic.includes("NONE")) {
                        return [];
                    }
                    if (selectedTopic.includes("AI_MATCH_TOPIC")) {
                        return selectedTopic;
                    }
                }

                return selectedTopic
                    .map((name) => topics.find((t) => t.label === name)?.value)
                    .filter((id): id is string => id !== undefined);
            };

            const payload: CreateTicketFromUIPayload = {
                title: title,
                message: message,
                user: userID,
                status: selectedStatus,
                assignee_user_id:
                    selectedAssignee === "noAssignee" ? "" : selectedAssignee,
                team_owner_id:
                    selectedTeamOwner === "noTeamOwner"
                        ? ""
                        : selectedTeamOwner,
                tag: selectedTag === "AI_MATCH_TAG" ? "" : selectedTag,
                topic: getTopicIDs(selectedTopic, topics),
            };

            const formData = new FormData();
            formData.append("data", JSON.stringify(payload));
            const response = await apiFormData.post(
                URLS.serverUrl + API.createTicketFromUI,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );

            if (response.status === 200) {
                const res: CreateTicketFromUIResponse = response.data.data;
                console.log("created issue successfully with response", res);
                setFeedbackMessage(
                    `Successfully created Issue ${res.org_specific_id}`,
                );
                clearForm();
                queryClient.refetchQueries({
                    queryKey: ["queries"],
                    exact: true,
                });
            } else {
                console.log(`Failed to create issue: ${response.status}`);
                setFeedbackMessage("Failed to create the issue");
            }
        } catch (error) {
            console.error("Error creating issue:", error);
            setFeedbackMessage("Failed to create the issue");
        }

        setTimeout(() => {
            setDialogIsOpen(false);
        }, 2000);
    };

    // Users based on the team owner for create issue
    useEffect(() => {
        if (selectedTeamOwner && selectedTeamOwner !== "") {
            const [url] = TeamsAPI.getTeam;
            api.get(`${URLS.serverUrl}${url}/${selectedTeamOwner}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            }).then((res) => {
                if (res.status === 200) {
                    const teamInfo: GetTeamResponse = res.data.data;
                    setUsers(teamInfo.members);
                    if (selectedAssignee && !teamInfo.members.find(user => user.id === selectedAssignee)) {
                        setSelectedAssignee("NoAssignee")
                    }
                }
            });
        } else {
            api.post(URLS.serverUrl + API.getAllUsers, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                if (res.status === 200) {
                    setUsers(res.data.data);
                } else {
                    console.log("Call to update tag failed");
                }
            });
        }
    }, [api, selectedTeamOwner]);

    return (
        <Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
            <DialogContent className="w-full max-w-3xl">
                <div>
                    <Input
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full py-1 text-lg border-none outline-none focus:ring-0"
                        placeholder="Issue Title"
                    />
                    <Textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="min-h-[100px] mb-4 w-full text-sm border-none outline-none focus:ring-0"
                        placeholder="Starting Message..."
                        rows={9}
                    />

                    <div className="flex flex-col gap-3">
                        <div className="flex items-center gap-1.5">
                            {dropdownTypes.map((type) => {
                                return (
                                    <DropdownMenu key={type}>
                                        <DropdownMenuTrigger
                                            asChild
                                            type="button"
                                        >
                                            {DropdownTrigger(
                                                type,
                                                categories,
                                                filters,
                                                users,
                                                teams,
                                                topics,
                                            )}
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            align="start"
                                            className="fixed max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                        >
                                            <FilterDropdownElement
                                                type={type}
                                                categories={categories}
                                                filters={filters}
                                                handleItemSelect={
                                                    handleItemSelect
                                                }
                                                topics={topics}
                                                users={users}
                                                extraOptions={
                                                    type === "Tag"
                                                        ? [
                                                            {
                                                                label: "AI Match Category",
                                                                value: "AI_MATCH_TAG",
                                                                color: "",
                                                                key: "AI_MATCH_TAG",
                                                            },
                                                        ]
                                                        : type === "Topic"
                                                            ? [
                                                                {
                                                                    label: "AI Match Tag",
                                                                    value: "AI_MATCH_TOPIC",
                                                                    color: "",
                                                                    key: "AI_MATCH_TOPIC",
                                                                },
                                                                {
                                                                    label: "No Tags",
                                                                    value: "NONE",
                                                                    color: "",
                                                                    key: "NONE",
                                                                },
                                                            ]
                                                            : []
                                                }
                                                customerGroups={[]}
                                                teams={teams}
                                                isSavedViewFilter={false}
                                                channels={new Map()}
                                                interactionTypes={[]}
                                            />
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                );
                            })}
                        </div>
                        <div className="flex flex-col items-end">
                            <DialogTriggerButton onClick={CreateIssue}>
                                Create Issue
                                <PlusIcon />
                            </DialogTriggerButton>
                            {feedbackMessage && (
                                <p
                                    className={`mt-1 text-right italic text-xs ${feedbackMessage.includes("Failed") ||
                                        feedbackMessage.includes("message")
                                        ? "destructive"
                                        : "text-primary"
                                        }`}
                                >
                                    {feedbackMessage}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
