import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { ContactsAPI } from "@/constant";
import { URLS } from "@/constant";
import { useDebounce } from "@/hooks/useDebounce";
import { useApi } from "@/interfaces/api";
import type { Account, FilterIncludeType, ListCustomersResponse } from "@/interfaces/serverData";
import { cn } from "@/lib/utils";
import { CheckIcon } from "@radix-ui/react-icons";
import { Spinner } from "@radix-ui/themes";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";

interface ContactDropdownProps {
    contacts?: Account[];
    selectedContactIDs: string[];
    selectedContacts?: Account[]; // Can optionally pass in the full contact if available
    teamID?: string;
    editing: boolean;
    setEditing: (editing: boolean) => void;
    setContact: (
        c: Account,
        contacts: Account[],
        setSelectedContacts: React.Dispatch<React.SetStateAction<Account[]>>,
        index?: number,
        includeType?: FilterIncludeType
    ) => void;
    index?: number;
    includeType?: FilterIncludeType,
    width?: number;
    includeOutline?: boolean;
    condensed?: boolean;
    condensedBadges?: boolean;
    companyID?: string;
    saveOnCheck?: (contactID: string) => void;
}

export const ContactDropdown = ({
    contacts,
    selectedContactIDs,
    selectedContacts: propsSelectedContacts,
    teamID,
    editing,
    setEditing,
    setContact,
    index,
    includeType,
    width,
    includeOutline = true,
    condensed = true,
    condensedBadges = false,
    companyID,
    saveOnCheck,
}: ContactDropdownProps) => {
    const [contactsSearchQueryValue, setContactsSearchQueryValue] =
        useState<string>("");
    const [selectedContacts, setSelectedContacts] = useState<Account[]>(
        propsSelectedContacts ?? [],
    );

    const api = useApi();
    const debouncedSearchQuery = useDebounce(contactsSearchQueryValue, 300);

    const fetchContacts = async ({
        pageParam = 0,
        query = "",
    }: {
        pageParam?: number;
        query?: string;
    }): Promise<ListCustomersResponse> => {
        try {
            let endpoint = "";
            if (companyID && companyID !== "") {
                endpoint = `/company/${companyID}`;
            } else if (teamID && teamID !== "") {
                endpoint = `/team/${teamID}`;
            }
            
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(
                `${URLS.serverUrl}${url}${endpoint}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 5,
                        offset: pageParam,
                        query: query,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: contactsData,
        fetchNextPage: fetchContactsNextPage,
        hasNextPage: contactsHasNextPage,
        isFetchingNextPage: isFetchingContactsNextPage,
        refetch: refetchContacts,
        isLoading: contactsIsLoading,
        isError: contactsIsError,
    } = useInfiniteQuery({
        queryKey: ["contacts_query", debouncedSearchQuery],
        queryFn: ({ pageParam = 0 }) =>
            fetchContacts({ pageParam, query: debouncedSearchQuery }),
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined;
        },
        initialPageParam: 0,
        refetchOnWindowFocus: true,
    });

    

    const getBadgeForContact = (contact: Account, condensed: boolean) => {
        return (
            <div className="flex items-center gap-1">
                {contact.image_url !== "" &&
                    contact.image_url !== undefined ? (
                        <div className="lb-avatar rounded w-5 h-5">
                            <img
                                className="lb-avatar-image"
                                src={contact.image_url}
                                alt={contact.name}
                            />
                        </div>
                    ) : (
                        <div className="h-5"></div>
                    )}
                <div className="flex flex-col items-start gap-1 max-w-[160px]">
                    {contact.name !== "" && contact.name !== undefined ? (
                        <p className="text-xs pl-1 font-semibold truncate w-full">
                            {contact.name}
                        </p>
                    ) : (
                        <p className="text-xs pl-1 font-semibold truncate w-full">
                            <i>Untitled</i>
                        </p>
                    )}
                </div>
                {!condensed && contact.company && (
                    <Badge
                        className="text-muted-foreground flex items-center gap-1"
                        variant="outline"
                    >
                        {contact.company.image_url !== "" &&
                            contact.company.image_url !== undefined && (
                                <div className="lb-avatar rounded w-5 h-5">
                                    <img
                                        className="lb-avatar-image w-4 h-4 my-0.5"
                                        src={contact.company.image_url}
                                        alt={contact.company.name}
                                    />
                                </div>
                            )}
                        {contact.company.name}
                    </Badge>
                )}
            </div>
        );
    };

    const contactsCombinedData =
        contactsData && Array.isArray(contactsData.pages)
            ? [
                ...(contacts && contacts.length > 0 ? contacts : []),
                ...contactsData.pages
                    .filter((page) => page !== null && page !== undefined)
                    .flatMap((page) =>
                        Array.isArray(page.data)
                            ? page.data.filter(
                                (item) =>
                                    item !== null && item !== undefined,
                            )
                            : [],
                    ),
            ]
            : contacts
                ? contacts
                : [];

    useEffect(() => {
        if (
            !propsSelectedContacts &&
            selectedContacts?.length !== selectedContactIDs.length
        ) {
            let endpoint = "";
            if (teamID && teamID !== "") {
                endpoint = `/team/${teamID}`;
            }
            const idsParam =
                selectedContactIDs.length > 0
                    ? selectedContactIDs.join(",")
                    : "";

            const { url, method } = ContactsAPI.listCustomers;
            api.get(`${URLS.serverUrl}${url}${endpoint}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    limit: 100,
                    offset: 0,
                    ids: idsParam,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSelectedContacts(res.data.data.data);
                    }
                })
                .catch((res) => {
                    console.log("Could not grab initial selected contacts");
                });
        }
    }, [propsSelectedContacts]);

    const [showAll, setShowAll] = useState(false);

    return (
        <div className="flex items-center gap-1 flex-row flex-wrap">
            {(selectedContacts?.length ?? 0) > 1 && (
                <div className="flex flex-row gap-2 flex-wrap items-center">
                    {selectedContacts?.slice(0, showAll ? undefined : 4).map((contact: Account) => {
                        return (
                            <div
                            key={contact.id}
                            className={`${includeOutline && "outline mr-2 outline-1 outline-gray-300"} ${condensedBadges ? "h-6" : ""} flex items-center px-2 rounded-md text-xs min-w-[${width ?? 170}px] w-[${width ?? 170}px]`}
                        >
                            {getBadgeForContact(contact, condensedBadges)}
                        </div>
                        );
                    })}
                
                {(selectedContacts?.length ?? 0) > 4 && (
                    <Button 
                        variant="ghost" 
                        size="sm" 
                        className="text-xs self-start"
                        onClick={() => setShowAll(!showAll)}
                        type="button"
                    >
                        {showAll ? "Show Less" : `Show ${selectedContacts?.length - 4} More`}
                    </Button>
                )}
                </div>
            )}
            <Popover modal={true}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        className="flex flex-col items-start gap-1 p-0.5 h-7"
                    >
                        {(selectedContacts?.length ?? 0) === 1 ? (
                            <div className="flex flex-row gap-2 flex-wrap">
                                {selectedContacts?.map((contact: Account) => {
                                    return (
                                        <div
                                        key={contact.id}
                                        className={`${includeOutline && "outline mr-2 outline-1 outline-gray-300"} justify-between p-2 rounded-md text-xs min-w-[${width ?? 170}px] w-[${width ?? 170}px]`}
                                    >
                                        {getBadgeForContact(contact, condensed)}
                                    </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div
                                className={`${includeOutline && "outline mr-2 outline-1 outline-gray-300"} justify-between text-xs p-2 rounded-md min-w-[${width ?? 170}px] w-[${width ?? 170}px]`}
                            >
                                Select contacts...
                            </div>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0">
                    <Command>
                        <CommandInput
                            placeholder="Search Contact..."
                            className="h-7 text-xs"
                            value={contactsSearchQueryValue}
                            onValueChange={(value) => {
                                setContactsSearchQueryValue(value);
                            }}
                        />
                        <CommandList className="max-h-[200px]">
                            {contactsIsLoading ? (
                                <CommandEmpty>
                                    <div className="flex items-center justify-center py-1">
                                        <Spinner className="h-3 w-3 animate-spin" />
                                    </div>
                                </CommandEmpty>
                            ) : (
                                <CommandEmpty>No contact found.</CommandEmpty>
                            )}
                            {/* todo: add a "no contact" select option */}
                            <CommandGroup>
                                {contactsCombinedData.map((c) => (
                                    <CommandItem
                                        key={c.id}
                                        value={c.name}
                                        onSelect={() => {
                                            setContact(
                                                c,
                                                selectedContacts,
                                                setSelectedContacts,
                                                index,
                                                includeType
                                            );
                                        }}
                                        className="text-xs"
                                    >
                                        {getBadgeForContact(c, condensed)}
                                        <Check
                                            className={cn(
                                                "ml-auto",
                                                selectedContactIDs?.some(
                                                    (contactID) =>
                                                        c.id === contactID,
                                                )
                                                    ? "opacity-100"
                                                    : "opacity-0",
                                            )}
                                        />
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                            {contactsHasNextPage && (
                                <CommandGroup>
                                    <CommandItem
                                        className="text-xs"
                                        onSelect={() => {
                                            if (
                                                contactsHasNextPage &&
                                                !isFetchingContactsNextPage
                                            ) {
                                                fetchContactsNextPage();
                                            }
                                        }}
                                    >
                                        Show More...
                                    </CommandItem>
                                </CommandGroup>
                            )}
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>

            {editing && setEditing && (
                <div className="flex flex-row items-center gap-3">
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-6 w-6 hover:bg-iris4"
                        aria-label="Cancel edit"
                        onClick={() => {
                            setEditing(false);
                        }}
                    >
                        <X className="h-3 w-3 text-red-500" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-6 w-6 hover:bg-iris4"
                        aria-label="Save contact"
                        onClick={() => {
                            setEditing(false);
                            if (selectedContacts && selectedContacts.length > 0) {
                                saveOnCheck?.(selectedContacts[0].id);
                            }
                        }}
                    >
                        <CheckIcon className="h-3 w-3 text-green-500" />
                    </Button>
                </div>
            )}
        </div>
    );
};
