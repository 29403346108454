import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { toast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Template } from "@/interfaces/serverData";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import {
    Cross2Icon,
    Pencil2Icon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Callout, Skeleton, Text } from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { FileSignature } from "lucide-react";
import { useState } from "react";
import { Editor } from "./Templates/InteractionsTemplatesEditor";
import { templatesInitialConfig } from "./constants";

interface SignatureProps {
    userID: string;
}

function SignatureComponent({ userID }: SignatureProps) {
    const api = useApi();
    const [signatureIsOpen, setSignatureIsOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [markdwn, setMarkdwn] = useState<string>("");
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const fetchPersonalTemplates = async (): Promise<Template[]> => {
        const response: AxiosResponse<{ data: Template[] }> = await api.get(
            `${URLS.serverUrl}${API.getPersonalTemplates}/${userID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch data");
    };

    const {
        data: signatures = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<Template[]>({
        queryKey: [`personalTemplates_${userID}`],
        queryFn: () => fetchPersonalTemplates(),
        refetchInterval: 30000, // refetch every 30 secs
        refetchOnWindowFocus: true,
    });

    const createSignatureMutation = useMutation({
        mutationFn: async (input: {
            template: string;
            metadata: Map<string, string>;
        }) => {
            const newSignature = {
                name: "Signature",
                template: input.template,
                team_ids: [],
                personal: true,
                user_id: userID,
                metadata: input.metadata,
                template_type: "Interactions",
            };
            console.log("new signature is", newSignature);
            const response: AxiosResponse = await api.put(
                `${URLS.serverUrl}${API.addTemplate}`,
                newSignature,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            return response;
        },
        onSuccess: () => {
            refetch();
            setSignatureIsOpen(false);
            toast({
                title: "Created Signature!",
                description: "The signature has been created successfully.",
            });
        },
        onError: () => {
            setSignatureIsOpen(false);
            toast({
                title: "Failed to create signature",
                description:
                    "Oops! Something's wrong. Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const updateSignatureMutation = useMutation({
        mutationFn: async (updatedTemplate: Template) => {
            const response: AxiosResponse = await api.patch(
                `${URLS.serverUrl}${API.editTemplate}/${updatedTemplate.id}`,
                updatedTemplate,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            return response;
        },
        onSuccess: () => {
            refetch();
            setSignatureIsOpen(false);
            toast({
                title: "Updated Signature!",
                description: "The signature has been updated successfully.",
            });
        },
        onError: () => {
            setSignatureIsOpen(false);
            toast({
                title: "Failed to update signature",
                description:
                    "Oops! Something's wrong. Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const deleteSignatureMutation = useMutation({
        mutationFn: async (templateID: string) => {
            const response: AxiosResponse = await api.delete(
                `${URLS.serverUrl}${API.deleteTemplate}/${templateID}`,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            return response;
        },
        onSuccess: () => {
            refetch();
            setDeleteDialogOpen(false);
            toast({
                title: "Deleted Signature!",
                description: "The signature has been deleted successfully.",
            });
        },
        onError: () => {
            setDeleteDialogOpen(false);
            toast({
                title: "Failed to delete signature",
                description:
                    "Oops! Something's wrong. Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const handleEditorChanges = (markdwn: string) => {
        setMarkdwn(markdwn);
    };

    const handleSave = (signatures: Template[]) => {
        if (!signatures || signatures?.length === 0) {
            createSignatureMutation.mutate({
                template: markdwn,
                metadata: new Map(),
            });
        } else {
            updateSignatureMutation.mutate({
                ...signatures[0],
                template: markdwn,
            });
        }
    };

    return (
        <Card className="rounded-lg shadow-none w-full ">
            <CardContent className="p-6">
                {isLoading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {isError && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {!isLoading && !isError && (
                    <div className="flex flex-col gap-3">
                        <div className="flex items-start justify-between">
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-2 text-md font-semibold">
                                    Signature
                                    <FileSignature className="w-5 h-5" />
                                </div>
                                <div className="text-muted-foreground text-xs">
                                    Your personal email signature
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                {/* Delete Signature */}
                                {!isLoading &&
                                    !isError &&
                                    signatures &&
                                    signatures.length > 0 && (
                                        <AlertDialog
                                            open={deleteDialogOpen}
                                            onOpenChange={setDeleteDialogOpen}
                                        >
                                            <AlertDialogTrigger asChild>
                                                <DialogTriggerButton>
                                                    Delete
                                                    <TrashIcon />
                                                </DialogTriggerButton>
                                            </AlertDialogTrigger>
                                            <AlertDialogContent>
                                                <div>
                                                    <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                        <AlertDialogTitle>
                                                            Are you absolutely
                                                            sure?
                                                        </AlertDialogTitle>
                                                        <AlertDialogDescription>
                                                            This action cannot
                                                            be undone. This will
                                                            permanently delete
                                                            your signature
                                                        </AlertDialogDescription>
                                                    </AlertDialogHeader>
                                                </div>
                                                <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                    <AlertDialogCancel
                                                        onClick={() =>
                                                            setDeleteDialogOpen(
                                                                false,
                                                            )
                                                        }
                                                    >
                                                        Cancel
                                                    </AlertDialogCancel>
                                                    <AlertDialogAction
                                                        onClick={() => {
                                                            deleteSignatureMutation.mutate(
                                                                signatures[0]
                                                                    ?.id,
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </AlertDialogAction>
                                                    <AlertDialogCancel
                                                        className="shadow-none absolute top-0 right-2 px-2 py-2 "
                                                        onClick={() =>
                                                            setDeleteDialogOpen(
                                                                false,
                                                            )
                                                        }
                                                    >
                                                        <Cross2Icon />
                                                    </AlertDialogCancel>
                                                </AlertDialogFooter>
                                            </AlertDialogContent>
                                        </AlertDialog>
                                    )}

                                <Dialog
                                    open={signatureIsOpen}
                                    onOpenChange={setSignatureIsOpen}
                                >
                                    <DialogTrigger asChild>
                                        {!signatures ||
                                        signatures?.length === 0 ? (
                                            <DialogTriggerButton>
                                                Add Signature
                                                <PlusIcon />
                                            </DialogTriggerButton>
                                        ) : (
                                            <DialogTriggerButton>
                                                Edit
                                                <Pencil2Icon />
                                            </DialogTriggerButton>
                                        )}
                                    </DialogTrigger>
                                    <DialogContent className="max-w-[1350px] w-full">
                                        <div className="mx-8 my-3">
                                            <DialogHeader className="justify-left text-left items-left pb-5">
                                                <DialogTitle>
                                                    {!signatures ||
                                                    signatures?.length === 0
                                                        ? "Add Signature"
                                                        : "Edit Signature"}
                                                </DialogTitle>
                                            </DialogHeader>
                                            <LexicalComposer
                                                initialConfig={
                                                    templatesInitialConfig
                                                }
                                            >
                                                {!signatures ||
                                                signatures?.length === 0 ? (
                                                    <Editor
                                                        handleEditorChanges={
                                                            handleEditorChanges
                                                        }
                                                        className="col-span-4 border rounded-sm min-h-[250px]"
                                                        source="Signature"
                                                    />
                                                ) : (
                                                    <Editor
                                                        handleEditorChanges={
                                                            handleEditorChanges
                                                        }
                                                        initialText={
                                                            signatures[0]
                                                                .template
                                                        }
                                                        className="col-span-4 border rounded-sm min-h-[250px]"
                                                        source="Signature"
                                                    />
                                                )}
                                            </LexicalComposer>
                                            <DialogFooter className="justify-end text-end items-end">
                                                <Button
                                                    className="bg-iris9"
                                                    type="button"
                                                    onClick={() =>
                                                        handleSave(signatures)
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </DialogFooter>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            </div>
                        </div>
                        {!isLoading &&
                            !isError &&
                            signatures &&
                            signatures.length > 0 && (
                                <LexicalComposer
                                    initialConfig={templatesInitialConfig}
                                >
                                    <Editor
                                        handleEditorChanges={
                                            handleEditorChanges
                                        }
                                        initialText={signatures[0].template}
                                        className="col-span-4 border rounded-sm min-h-[250px]"
                                        source="Signature"
                                        enableToolbar={false}
                                    />
                                </LexicalComposer>
                            )}
                    </div>
                )}
            </CardContent>
        </Card>
    );
}

export default SignatureComponent;
