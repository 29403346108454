"use client";

import AttributesBadge from "@/component/AttributesBadge";
import { Button } from "@/component/shadcn/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import { API, URLS, type loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    CsvRow,
    ExcelRow,
    HistoryResponse,
    MyFile,
    ScopeResponse,
    Teams,
    Template,
    Ticket,
    UserResponse,
} from "@/interfaces/serverData";
import type { Account } from "@/interfaces/serverData";
import { arraysAreEqual } from "@/utilities/methods";
import {
    ChatBubbleIcon,
    ChevronDownIcon,
    EyeOpenIcon,
    StackIcon,
} from "@radix-ui/react-icons";
import { Tabs } from "@radix-ui/themes";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { ActivityIcon, ChevronUpIcon, EyeOffIcon, MessageCircleReply } from "lucide-react";
import mammoth from "mammoth";
import Papa from "papaparse";
import { memo, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { Action } from "./Action";
import { Comment } from "./MyComment";
import { MyComposer } from "./MyComposer";
const areEqual = (prevProps: RoomProps, nextProps: RoomProps) => {
    return (
        prevProps.originalTicket === nextProps.originalTicket &&
        prevProps.source === nextProps.source &&
        prevProps.source_specific_id === nextProps.source_specific_id &&
        prevProps.title === nextProps.title &&
        arraysAreEqual(
            prevProps.threadData ?? [],
            nextProps.threadData ?? [],
        ) &&
        prevProps.update === nextProps.update &&
        prevProps.setUpdate === nextProps.setUpdate &&
        prevProps.refetchTicketData === nextProps.refetchTicketData &&
        prevProps.refetchThreadData === nextProps.refetchThreadData &&
        prevProps.showComposer === nextProps.showComposer &&
        prevProps.url === nextProps.url &&
        prevProps.subtractHeight === nextProps.subtractHeight &&
        prevProps.teamsQuery === nextProps.teamsQuery &&
        prevProps.customerUserInfo === nextProps.customerUserInfo &&
        prevProps.account === nextProps.account &&
        prevProps.gmailScopes === nextProps.gmailScopes &&
        prevProps.sourceUsers === nextProps.sourceUsers &&
        prevProps.sourceUsersLoaded === nextProps.sourceUsersLoaded
    );
};

interface RoomProps {
    originalTicket: Ticket;
    source: string;
    classNameThread?: string;
    className?: string;
    classNameComposer?: string;
    title: string;
    source_specific_id: string;
    source_unique_name: string;
    threadData: HistoryResponse[];
    update: boolean;
    setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    showComposer: boolean;
    userID: string;
    url?: string;
    subtractHeight?: number;
    teamsQuery?: UseQueryResult<Teams[], Error>;
    customerUserInfo?: UserResponse;
    account?: Account;
    gmailScopes?: ScopeResponse[];
    sourceUsers?: ScopeResponse[];
    sourceUsersLoaded?: loadingTypes;
}

function Room({
    originalTicket,
    source,
    source_specific_id,
    source_unique_name,
    classNameThread = "",
    className = "",
    classNameComposer = "",
    title,
    threadData,
    update,
    setUpdate,
    refetchTicketData,
    refetchThreadData,
    showComposer,
    userID,
    url,
    subtractHeight,
    teamsQuery,
    customerUserInfo,
    account,
    gmailScopes,
    sourceUsers,
    sourceUsersLoaded,
}: RoomProps) {
    const api = useApi();
    const supported_publish_list = [
        "Slack",
        "CommunitySlack",
        "Discord",
        "Gmail",
        "GitHubTicket",
        "GithubDiscussion",
        "Web",
        "Intercom",
    ];
    const [isCollapsed, setIsCollapsed] = useState(true);

    const renderComment = (
        comment: HistoryResponse,
        state: "open" | "closed" = "open",
    ) => {
        switch (comment.type) {
            case "Message":
            case "Internal":
                return (
                    <li className="mb-4 ml-6 ms-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-[#eceefb] rounded-full -start-3 ring-8 ring-white">
                            {comment.type === "Internal" && (
                                <EyeOffIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />
                            )}
                            {comment.type === "Message" && (
                                <ChatBubbleIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />
                            )}
                        </span>
                        <Comment
                            className={
                                comment.type === "Internal"
                                    ? "bg-[#f2f2f2]"
                                    : "bg-[#ffffff]"
                            }
                            key={comment.id}
                            comment={comment}
                            source={source}
                            filesMap={filesMap}
                            isInternal={comment.type === "Internal"}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            collapsed={state}
                        />
                    </li>
                );
            case "Action":
                return (
                    <li className="mb-4 ml-6 ms-6">
                        <Action
                            key={comment.id}
                            comment={comment}
                            teamsQuery={teamsQuery}
                            className="px-4"
                        />
                    </li>
                );
            default:
        }
    };

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [threadData, source_specific_id]);

    const [filesMap, setFilesMap] = useState<Map<string, MyFile[]>>(new Map());

    const [selectedFile, setSelectedFile] = useState<MyFile>();

    useEffect(() => {
        const handleImageClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            if (target.classList.contains("inline-email-image")) {
                const fileId = target.getAttribute("data-file-id");
                const publicUrl = target.getAttribute("data-public-url");
                const commentID = target.getAttribute("data-comment-id") ?? "";

                if (fileId) {
                    const file = filesMap
                        .get(commentID)
                        ?.find((f) => f.id === fileId);
                    if (file) setSelectedFile(file);
                } else if (publicUrl) {
                    const imgElement = target as HTMLImageElement;
                    setSelectedFile({
                        id: imgElement.id,
                        name: imgElement.alt || "Image",
                        mimetype: "image/jpeg",
                        url_local: publicUrl,
                        url_private: publicUrl,
                        url_private_download: publicUrl,
                        isPublicUrl: true,
                        user: "",
                    });
                }
            }
        };

        document.addEventListener("click", handleImageClick);

        return () => {
            document.removeEventListener("click", handleImageClick);
        };
    }, [filesMap, selectedFile]);

    // Fetches inline images and all attachments
    const fetchFile = async () => {
        // Populate initial files map with the tags
        const initialMap = new Map(filesMap);
        // biome-ignore lint/complexity/noForEach: <explanation>
        threadData.forEach((comment) => {
            // Check if the comment ID already exists in the map
            if (!initialMap.has(comment.id)) {
                // Initialize the map with existing files only if the comment ID is not present
                initialMap.set(comment.id, comment.files || []); // Ensure it's an array
            }
        });
        setFilesMap(initialMap); // Set the initial filesMap
        let newFilesMap = new Map(initialMap);

        // Users are more focused on the latest messages, which is where the scroll bar starts too
        for (const comment of [...threadData].reverse()) {
            if (comment.files) {
                for (const file of comment.files) {
                    let existingFiles = newFilesMap.get(comment.id) || [];
                    const fileAlreadyExists = existingFiles.some(
                        (existingFile) => existingFile.id === file.id,
                    );
                    const fileAlreadyExistsAndHasUrlLocal = existingFiles.some(
                        (existingFile) =>
                            existingFile.id === file.id &&
                            existingFile.url_local,
                    );

                    if (file.bytes) {
                        const base64Data = file.bytes;
                        const mimeType = file.mimetype;

                        try {
                            // Decode Base64 to Blob
                            const byteCharacters = atob(base64Data);
                            const byteNumbers = new Array(byteCharacters.length)
                                .fill(0)
                                .map((_, i) => byteCharacters.charCodeAt(i));
                            const byteArray = new Uint8Array(byteNumbers);
                            const blob = new Blob([byteArray], {
                                type: mimeType,
                            });
                            const fileUrl = URL.createObjectURL(blob);

                            // Create new file object with local URL
                            const newFile = {
                                ...file,
                                url_local: fileUrl,
                            };

                            if (fileAlreadyExists) {
                                existingFiles = existingFiles.map((f) =>
                                    f.id === file.id ? newFile : f,
                                );
                            } else {
                                existingFiles.push(newFile);
                            }
                        } catch (error) {
                            console.error(
                                "Error decoding Base64 bytes:",
                                error,
                            );
                        }
                    } else if (!fileAlreadyExistsAndHasUrlLocal) {
                        // Handle file with "bytes" property
                        if (file.url_local !== "") {
                            // Handle cases with existing url_local
                            if (fileAlreadyExists) {
                                existingFiles = existingFiles.map((f) =>
                                    f.id === file.id ? file : f,
                                );
                            } else {
                                existingFiles.push(file);
                            }
                        } else {
                            // Handle cases where we need to fetch the file
                            const requestData = {
                                id: file.id,
                                source_specific_id: source_specific_id,
                                source_unique_name: source_unique_name,
                                url: file.url_private_download,
                                source: source, // Should be either "Slack", "CommunitySlack", "Gmail", or "Discord" only
                                mimetype: file.mimetype,
                                name: file.name,
                            };

                            try {
                                const res = await api.post(
                                    URLS.serverUrl + API.getFile,
                                    requestData,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        responseType: "blob",
                                    },
                                );

                                if (res.status === 200) {
                                    const fileBlob = res.data;
                                    const fileHeaders = res.headers;
                                    if (fileBlob.size > 0) {
                                        const fileUrl =
                                            URL.createObjectURL(fileBlob);
                                        const newFile = {
                                            ...file,
                                            url_local: fileUrl,
                                            width:
                                                fileHeaders["x-image-width"] ??
                                                "",
                                            height:
                                                fileHeaders["x-image-height"] ??
                                                "",
                                        };

                                        const sizeLimit = 1024 * 1024; // 1 MB

                                        if (
                                            file.mimetype ===
                                            "application/msword" ||
                                            file.mimetype ===
                                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                // Newer Word formats
                                                const arrayBuffer =
                                                    await fileBlob.arrayBuffer();

                                                // Convert to html and create a blob url
                                                const result =
                                                    await mammoth.convertToHtml(
                                                        {
                                                            arrayBuffer,
                                                        },
                                                    );
                                                const htmlBlob = new Blob(
                                                    [result.value],
                                                    {
                                                        type: "text/html",
                                                    },
                                                );
                                                const htmlBlobUrl =
                                                    URL.createObjectURL(
                                                        htmlBlob,
                                                    );
                                                newFile.html_url = htmlBlobUrl;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        } else if (
                                            file.mimetype ===
                                            "application/vnd.ms-excel" ||
                                            file.mimetype ===
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                const arrayBuffer =
                                                    await fileBlob.arrayBuffer();
                                                // Read the XLSX file
                                                const workbook = XLSX.read(
                                                    arrayBuffer,
                                                    {
                                                        type: "array",
                                                    },
                                                );
                                                // Only previewing the first sheet
                                                const firstSheetName =
                                                    workbook.SheetNames[0];
                                                const worksheet =
                                                    workbook.Sheets[
                                                    firstSheetName
                                                    ];
                                                // Convert to JSON
                                                const jsonData =
                                                    XLSX.utils.sheet_to_json<ExcelRow>(
                                                        worksheet,
                                                    );
                                                newFile.excel_rows = jsonData;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        } else if (
                                            file.mimetype.startsWith("text/csv")
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                // Read in the blob as text
                                                const text =
                                                    await fileBlob.text();
                                                const results =
                                                    Papa.parse<CsvRow>(text, {
                                                        header: true,
                                                    });
                                                newFile.csv_rows = results.data;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        }

                                        if (
                                            file.mimetype ===
                                            "application/msword" ||
                                            file.mimetype ===
                                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                // Newer Word formats
                                                const arrayBuffer =
                                                    await fileBlob.arrayBuffer();

                                                // Convert to html and create a blob url
                                                const result =
                                                    await mammoth.convertToHtml(
                                                        {
                                                            arrayBuffer,
                                                        },
                                                    );
                                                const htmlBlob = new Blob(
                                                    [result.value],
                                                    {
                                                        type: "text/html",
                                                    },
                                                );
                                                const htmlBlobUrl =
                                                    URL.createObjectURL(
                                                        htmlBlob,
                                                    );
                                                newFile.html_url = htmlBlobUrl;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        } else if (
                                            file.mimetype ===
                                            "application/vnd.ms-excel" ||
                                            file.mimetype ===
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                const arrayBuffer =
                                                    await fileBlob.arrayBuffer();
                                                // Read the XLSX file
                                                const workbook = XLSX.read(
                                                    arrayBuffer,
                                                    {
                                                        type: "array",
                                                    },
                                                );
                                                // Only previewing the first sheet
                                                const firstSheetName =
                                                    workbook.SheetNames[0];
                                                const worksheet =
                                                    workbook.Sheets[
                                                    firstSheetName
                                                    ];
                                                // Convert to JSON
                                                const jsonData =
                                                    XLSX.utils.sheet_to_json<ExcelRow>(
                                                        worksheet,
                                                    );
                                                newFile.excel_rows = jsonData;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        } else if (
                                            file.mimetype.startsWith("text/csv")
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                // Read in the blob as text
                                                const text =
                                                    await fileBlob.text();
                                                const results =
                                                    Papa.parse<CsvRow>(text, {
                                                        header: true,
                                                    });
                                                newFile.csv_rows = results.data;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        }

                                        if (fileAlreadyExists) {
                                            existingFiles = existingFiles.map(
                                                (f) =>
                                                    f.id === newFile.id
                                                        ? newFile
                                                        : f,
                                            );
                                        } else {
                                            existingFiles.push(newFile);
                                        }
                                    } else {
                                        console.error("Received empty blob.");
                                        return null;
                                    }
                                } else {
                                    console.error(
                                        "Call to get image failed with status:",
                                        res.status,
                                    );
                                    return null;
                                }
                            } catch (error) {
                                console.error("Error fetching image:", error);
                                return null;
                            }
                        }
                        newFilesMap.set(comment.id, existingFiles);
                        setFilesMap(newFilesMap);
                    }
                }
                newFilesMap = new Map(newFilesMap);
            }
        }
    };
    useEffect(() => {
        // Current support: all slack and discord file types
        if (
            source === "Slack" ||
            source === "CommunitySlack" ||
            source === "Discord" ||
            source === "Gmail" ||
            source === "API" ||
            source === "Web"
        ) {
            fetchFile();
        }
    }, [threadData]);

    const fetchPersonalTemplates = async (): Promise<Template[]> => {
        const response: AxiosResponse<{ data: Template[] }> = await api.get(
            `${URLS.serverUrl}${API.getPersonalTemplates}/${userID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch data");
    };

    const {
        data: signatures = [],
        isLoading,
        isError,
        refetch: refetchSignatures,
    } = useQuery<Template[]>({
        queryKey: [`personalTemplates_${userID}`],
        queryFn: () => fetchPersonalTemplates(),
        refetchInterval: 30000, // refetch every 30 secs
        refetchOnWindowFocus: true,
    });

    const renderCollapsedThread = (
        comments: HistoryResponse[],
        thread_type: "All" | "Activity" | "Internal" | "Thread",
    ) => {
        if (comments.length <= 3) {
            return comments.map((comment) => renderComment(comment));
        }

        const messageUsed = thread_type !== "Activity" ? "message" : "action";

        const firstComment: HistoryResponse = comments[0];
        let middleComments: HistoryResponse[] = [];
        let lastComments: HistoryResponse[] = [];
        switch (thread_type) {
            case "All": {
                // Find last 2 non-Action messages
                const lastTwoMessageIndices = comments
                    .slice(1)
                    .map((comment, index) => ({ comment, index: index + 1 })) // Add 1 to account for slice(1)
                    .filter(({ comment }) => comment.type === "Message")
                    .slice(-2)
                    .map(({ index }) => index);
                if (lastTwoMessageIndices.length === 0) {
                    middleComments = comments.slice(1, -2);
                    lastComments = comments.slice(-2);
                    break;
                } else if (lastTwoMessageIndices.length < 2) {
                    lastComments = comments.slice(1);
                    break;
                } else if (lastTwoMessageIndices.length >= 2) {
                    // Get all comments from first to before first of last 2 messages
                    middleComments = comments.slice(
                        1,
                        lastTwoMessageIndices[0],
                    );
                    // Get all comments from first of last 2 messages to end
                    lastComments = comments.slice(lastTwoMessageIndices[0]);
                    break;
                }
                break;
            }
            default: {
                middleComments = comments.slice(1, -2);
                lastComments = comments.slice(-2);
                break;
            }
        }

        return (
            <>
                {renderComment(firstComment)}
                {middleComments.length > 0 && (
                    <li className="mb-4 ml-6 ms-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-green3 rounded-full -start-3 ring-8 ring-white">
                            {isCollapsed ? (
                                <ChevronDownIcon className="w-2.5 h-2.5 text-green9" />
                            ) : (
                                <ChevronUpIcon className="w-2.5 h-2.5 text-green9" />
                            )}
                        </span>
                        <div className="flex items-center w-full">
                            <div className="h-[1px] bg-slate-300 flex-1" />
                            <Button
                                variant="outline"
                                size="sm"
                                className="flex items-center justify-center outline outline-1 outline-slate-300 rounded-full data-[state=open]:bg-muted px-3 h-6 hover:text-none"
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                {isCollapsed ? (
                                    <>
                                        <span className="mr-2">
                                            Show {middleComments.length} more{" "}
                                            {messageUsed}s
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="mr-2">
                                            Hide {messageUsed}s
                                        </span>
                                    </>
                                )}
                            </Button>
                            <div className="h-[1px] bg-slate-300 flex-1" />
                        </div>
                    </li>
                )}
                {!isCollapsed && (
                    <ol className="mt-2">
                        {middleComments.map((comment) =>
                            renderComment(comment, "closed"),
                        )}
                    </ol>
                )}
                {lastComments.map((comment) => renderComment(comment))}
            </>
        );
    };

    return (
        <div className={`w-full lb-root flex flex-col relative flex-1 ${className}`}>
            <div className="flex-1">
                <Tabs.Root defaultValue="all">
                    <Tabs.List className="sticky top-0 bg-white z-10 border-b border-gray-200">
                        <Tabs.Trigger
                            value="all"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <StackIcon className="group-hover:text-[#5e6ad2]" />
                                <p className="group-hover:text-[#5e6ad2]">
                                    All
                                </p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="activity"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <ActivityIcon
                                    className="group-hover:text-[#5e6ad2]"
                                    size={16}
                                    strokeWidth={1.5}
                                />
                                <p className="group-hover:text-[#5e6ad2]">
                                    Activity
                                </p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="internal"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <EyeOpenIcon
                                    className="group-hover:text-[#5e6ad2]"
                                    fontSize={16}
                                    strokeWidth={1.5}
                                />
                                <p className="group-hover:text-[#5e6ad2]">
                                    Internal
                                </p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="thread"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <ChatBubbleIcon className="group-hover:text-[#5e6ad2]" />
                                <p className="group-hover:text-[#5e6ad2]">
                                    Thread
                                </p>
                                {originalTicket.customer_replies + originalTicket.owner_replies !== 0 &&
                                    (
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div>
                                                        <AttributesBadge className="py-0">
                                                            <div className="flex items-center gap-1 text-[11px] text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                                {`${originalTicket.owner_replies + originalTicket.customer_replies}`}
                                                                <MessageCircleReply className="w-3.5 h-3.5" />
                                                            </div>
                                                        </AttributesBadge>
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="text-[11px] max-w-[150px] flex flex-col">
                                                    <div className="flex items-center gap-1">
                                                        {`Customer: ${originalTicket.customer_replies}`}
                                                        <MessageCircleReply className="w-3.5 h-3.5" />
                                                    </div>
                                                    <div className="flex items-center gap-1">
                                                        {`Team: ${originalTicket.owner_replies} `}
                                                        <MessageCircleReply className="w-3.5 h-3.5" />
                                                    </div>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    )}
                            </div>
                        </Tabs.Trigger>
                    </Tabs.List>
                    <div
                        ref={scrollRef}
                        className="flex-grow overflow-y-auto"
                        style={{
                            maxHeight: `calc(100vh - ${subtractHeight}px)`,
                        }}
                    >
                        <Tabs.Content
                            value="thread"
                            className={`${classNameThread}`}
                        >
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {renderCollapsedThread(
                                    threadData.filter(
                                        (comment) => comment.type === "Message",
                                    ),
                                    "Thread",
                                )}
                            </ol>
                        </Tabs.Content>
                        <Tabs.Content value="activity" className="pt-4">
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {renderCollapsedThread(
                                    threadData.filter(
                                        (comment) => comment.type === "Action",
                                    ),
                                    "Activity",
                                )}
                            </ol>
                        </Tabs.Content>
                        <Tabs.Content value="internal" className="pt-4">
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {renderCollapsedThread(
                                    threadData.filter(
                                        (comment) =>
                                            comment.type === "Internal",
                                    ),
                                    "Internal",
                                )}
                            </ol>
                        </Tabs.Content>
                        <Tabs.Content
                            value="all"
                            className={`${classNameThread}`}
                        >
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {renderCollapsedThread(threadData, "All")}
                            </ol>
                        </Tabs.Content>
                    </div>
                </Tabs.Root>
            </div>
            <div className={classNameComposer}>
                {showComposer &&
                    supported_publish_list.includes(source) &&
                    source_specific_id !== "" &&
                    source_specific_id !== undefined &&
                    source_specific_id != null && (
                        <MyComposer
                            originalTicket={originalTicket}
                            source={source}
                            source_specific_id={source_specific_id}
                            update={update}
                            setUpdate={setUpdate}
                            title={title}
                            refetchTicketData={refetchTicketData}
                            refetchThreadData={refetchThreadData}
                            customerUserInfo={customerUserInfo}
                            account={account}
                            gmailScopes={gmailScopes}
                            signature={
                                signatures && signatures.length === 1
                                    ? signatures[0].template
                                    : undefined
                            }
                            sourceUsers={sourceUsers}
                            sourceUsersLoaded={sourceUsersLoaded}
                        />
                    )}
            </div>
        </div>
    );
}

export default memo(Room, areEqual);
