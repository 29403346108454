import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    SaveNotificationSettingsResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import { ChevronDownIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { Badge, DataList, Text } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import type React from "react";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";

import { cn } from "@/lib/utils";
import { Check } from "lucide-react";

import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { useToast } from "@/component/shadcn/ui/use-toast";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { Skeleton } from "@/component/shadcn/ui/skeleton";
import {
    Select,
    SelectContent,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { Switch } from "@/component/shadcn/ui/switch";
import { Button } from "@/component/shadcn/ui/button";
import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";

export enum SlackAlertsType {
    SLACK = "Slack",
    OMNICHANNEL = "Omnichannel",
}

interface SlackAlertsProps {
    enabled: boolean;
    channel: ScopeResponse | undefined;
    loadingState: loadingTypes;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<any, Error>>;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    inheritedFromOrg: boolean;
    teamID?: string;
    type?: SlackAlertsType;
}

const configRouteMap = new Map<SlackAlertsType, string>([
    [SlackAlertsType.SLACK, API.saveNotificationSettings],
    [SlackAlertsType.OMNICHANNEL, API.saveOmnichannelSettings],
]);

const configTitleMap = new Map<SlackAlertsType, string>([
    [SlackAlertsType.SLACK, "Slack Notifications"],
    [SlackAlertsType.OMNICHANNEL, "Slack OmniChannel"],
]);

const SlackAlerts: React.FC<SlackAlertsProps> = ({
    enabled,
    channel,
    loadingState,
    refetch,
    isOpen,
    onOpenChange,
    inheritedFromOrg,
    teamID,
    type = SlackAlertsType.SLACK,
}) => {
    const [slackChannel, setSlackChannel] = useState<ScopeResponse | undefined>(
        channel,
    );
    const [slackEnabled, setSlackEnabled] = useState<boolean>(enabled);
    const [savedSlackChannel, setSavedSlackChannel] = useState<
        ScopeResponse | undefined
    >(channel);
    const [savedSlackEnabledValue, setSavedSlackEnabledValue] =
        useState<boolean>(enabled);
    const [loading, setLoading] = useState<loadingTypes>(loadingState);

    const api = useApi();
    const { toast } = useToast();

    // Fetch the Slack scopes using useQuery
    const [cursor, setCursor] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const [scopeData, setScopeData] = useState<ScopeResponse[]>([]);
    const [scopeLoading, setScopeLoading] = useState<boolean>(false);

    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

    const fetchSlackChannels = useCallback(() => {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        const requestData = {
            type: "Slack",
            cursor: cursor,
        };
        api.post(serverUrl + API.getScopesPaginated, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const channelData = res.data.data.scopes;
                const newCursor = res.data.data.cursor;
                setScopeData((prevChannels) => [
                    ...prevChannels,
                    ...channelData,
                ]);
                setCursor(newCursor);
                setHasMore(!!newCursor);
                setLoading(loadingTypes.loaded);
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                console.log(res);
            });
    }, [api, cursor]);

    useEffect(() => {
        if (hasMore) {
            fetchSlackChannels();
        }
    }, [fetchSlackChannels, hasMore]);

    useEffect(() => {
        setSlackEnabled(enabled);
        setSavedSlackEnabledValue(enabled);
        setSlackChannel(channel);
        setSavedSlackChannel(channel);
        setLoading(loadingTypes.loaded);
    }, [loadingState, channel, enabled]);

    const channelOptions = scopeData || [];

    function saveSettings() {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        // omnichannel and notification settings reponses identical for now
        const requestData: SaveNotificationSettingsResponse = {
            enabled: slackEnabled,
            metadata: { channel: slackChannel }, // Send full object here
        };
        if (teamID) {
            requestData.team_id = teamID;
        }
        api.post(`${serverUrl}${configRouteMap.get(type)}/Slack`, requestData, {
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (res.status === 200) {
                    const slackResData = res.data.data;
                    setSlackEnabled(slackResData.enabled);
                    setSavedSlackEnabledValue(slackResData.enabled);
                    setSavedSlackChannel(slackResData.channel);
                    setLoading(loadingTypes.loaded);
                    refetch();
                    toast({
                        title: "Updated Settings!",
                        description:
                            "Your settings have been updated successfully.",
                    });
                } else {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                    });
                }
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                });
            });
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <Card
                className={`py-6 px-2 ${inheritedFromOrg && "bg-muted"} shadow-none rounded-md`}
            >
                <CardContent>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-row items-center gap-1">
                                <SlackSvg className="max-h-5 max-w-5" />
                                <h2 className="text-md font-medium">
                                    {configTitleMap.get(type)}
                                </h2>
                            </div>

                            {!inheritedFromOrg && (
                                <DialogTrigger className="self-end justify-self-end">
                                    <DialogTriggerButton>
                                        Edit
                                        <Pencil2Icon />
                                    </DialogTriggerButton>
                                </DialogTrigger>
                            )}
                        </div>
                        {savedSlackEnabledValue && savedSlackChannel && (
                            <Card
                                className={`mx-6 ${inheritedFromOrg && "bg-muted"} shadow-none`}
                            >
                                <CardContent className="py-6 px-6 ">
                                    <DataList.Root>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">
                                                Status
                                            </DataList.Label>
                                            <DataList.Value>
                                                <Badge
                                                    color="jade"
                                                    variant="soft"
                                                    radius="full"
                                                >
                                                    Enabled
                                                </Badge>
                                            </DataList.Value>
                                        </DataList.Item>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">
                                                Notification Channel
                                            </DataList.Label>
                                            <DataList.Value>
                                                <p className="text-iris11">{`#${savedSlackChannel.name}`}</p>
                                            </DataList.Value>
                                        </DataList.Item>
                                    </DataList.Root>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                </CardContent>
            </Card>

            <DialogContent style={{ maxWidth: 450 }}>
                <DialogTitle>{configTitleMap.get(type)} Settings</DialogTitle>
                <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                        <Text>Enable {configTitleMap.get(type)}</Text>
                        <Switch
                            checked={slackEnabled}
                            onCheckedChange={(value) => setSlackEnabled(value)}
                        />
                    </div>
                    {slackEnabled &&
                        (scopeLoading ||
                            loadingState === loadingTypes.loading) && (
                            <Skeleton>
                                <Select>
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Select Slack channel" />
                                    </SelectTrigger>
                                    <SelectContent />
                                </Select>
                            </Skeleton>
                        )}

                    {slackEnabled &&
                        !scopeLoading &&
                        loadingState === loadingTypes.loaded && (
                            <Popover
                                open={popoverOpen}
                                onOpenChange={setPopoverOpen}
                            >
                                <PopoverTrigger asChild>
                                    <Button
                                        aria-expanded={popoverOpen}
                                        variant="outline"
                                        color="gray"
                                        className="h-9 text-sm"
                                    >
                                        {slackChannel?.name ??
                                            "Select Slack channel..."}
                                        <ChevronDownIcon />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-full p-0">
                                    <Command>
                                        <CommandInput
                                            placeholder="Search framework..."
                                            className="h-9"
                                        />
                                        <CommandList>
                                            <CommandEmpty>
                                                No framework found.
                                            </CommandEmpty>
                                            <CommandGroup>
                                                {channelOptions.map(
                                                    (framework) => (
                                                        <CommandItem
                                                            key={framework.key}
                                                            value={
                                                                framework.name
                                                            }
                                                            onSelect={(
                                                                currentValue,
                                                            ) => {
                                                                const selectedChannel =
                                                                    channelOptions.find(
                                                                        (channel: {
                                                                            name: string;
                                                                        }) =>
                                                                            channel.name ===
                                                                            currentValue,
                                                                    );
                                                                setSlackChannel(
                                                                    selectedChannel,
                                                                );
                                                            }}
                                                        >
                                                            {framework.name}
                                                            <Check
                                                                className={cn(
                                                                    "ml-auto",
                                                                    slackChannel?.name ===
                                                                        framework.name
                                                                        ? "opacity-100"
                                                                        : "opacity-0",
                                                                )}
                                                            />
                                                        </CommandItem>
                                                    ),
                                                )}
                                            </CommandGroup>
                                        </CommandList>
                                    </Command>
                                </PopoverContent>
                            </Popover>
                            // <Select.Root
                            //     value={slackChannel?.name ?? ""}
                            //     onValueChange={(name) => {
                            //         const selectedChannel = channelOptions.find(
                            //             (channel: { name: string }) =>
                            //                 channel.name === name,
                            //         );
                            //         setSlackChannel(selectedChannel);
                            //     }}
                            // >
                            //     <Select.Trigger
                            //         className="w-full"
                            //         placeholder="Select Slack channel"
                            //     />
                            //     <Select.Content>
                            //         {channelOptions.map(
                            //             (channel: {
                            //                 name: string;
                            //                 key: string;
                            //             }) => {
                            //                 return (
                            //                     <Select.Item
                            //                         key={channel.key}
                            //                         value={channel.name}
                            //                     >
                            //                         #{channel.name}
                            //                     </Select.Item>
                            //                 );
                            //             },
                            //         )}
                            //     </Select.Content>
                            // </Select.Root>
                        )}

                    {loading === loadingTypes.loaded && (
                        <div className="flex justify-end gap-3 mt-4">
                            <DialogClose>
                                <Button
                                    className="iris text-white"
                                    onClick={saveSettings}
                                >
                                    Save
                                </Button>
                            </DialogClose>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SlackAlerts;
