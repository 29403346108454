import { Button } from "@/component/shadcn/ui/button";
import { Skeleton } from "@/component/shadcn/ui/skeleton";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import Nango from "@nangohq/frontend";
import { useAuthInfo } from "@propelauth/react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Link2Icon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as GoogleSheetSvg } from "../../../../images/integrations/googlesheet.svg";
import { NANGO_GOOGLE_SHEET_INTEGRATION_ID } from "../../Integrations/GoogleSheetIntegration";

interface GoogleSpreadsheetConnectionProps {
    onCancel?: () => void;
    onSuccess?: (fileIds: string[]) => void;
    spreadsheetID?: string;
}

// Define types for the Google Picker API
declare global {
    interface Window {
        gapi: any;
        google: any;
    }
}

export const GoogleSpreadsheetConnection = ({
    onCancel,
    onSuccess,
    spreadsheetID,
}: GoogleSpreadsheetConnectionProps) => {
    const { toast } = useToast();
    const api = useApi();
    const [pickerInited, setPickerInited] = useState(false);
    const [tokenClient, setTokenClient] = useState<any>(null);
    const [connectionId, setConnectionId] = useState<string | null>(null);

    const { user } = useAuthInfo();

    const [loadingState, setLoadingState] = useState(loadingTypes.loading);

    useEffect(() => {
        const requestData = {
            types: ["GoogleSheet"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.GoogleSheet?.[0]?.slack_team_id) {
                            setConnectionId(
                                orgInfo.GoogleSheet[0].slack_team_id,
                            );
                        }
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const connect = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            const requestData = {
                user_id: user?.userId,
                user_email: user?.email,
                allowed_integrations: [NANGO_GOOGLE_SHEET_INTEGRATION_ID],
            };

            api.post(
                URLS.serverUrl + API.createNangoSessionToken,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((getSessionTokenResult) => {
                    if (getSessionTokenResult.status === 200) {
                        const nango = new Nango({
                            connectSessionToken:
                                getSessionTokenResult.data.data.token,
                        });
                        nango
                            .auth(NANGO_GOOGLE_SHEET_INTEGRATION_ID)
                            .then((nangoAuthResult) => {
                                console.log("successfully connected to nango!");
                                const createIntegrationData = {
                                    connection_id: nangoAuthResult.connectionId,
                                    provider_config_key:
                                        nangoAuthResult.providerConfigKey,
                                    integration_type: "GoogleSheet",
                                };
                                // nango OAuth returns a connection_id. We need to store this connection ID in DB
                                // use this connectionID to create a Nango Integration
                                api.post(
                                    URLS.serverUrl + API.createNangoIntegration,
                                    createIntegrationData,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    },
                                ).then((createIntegrationResult) => {
                                    if (
                                        createIntegrationResult.status === 200
                                    ) {
                                        toast({
                                            title: "Successfully created Google Sheet Integration",
                                            description:
                                                "The page will refresh shortly.",
                                        });
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 3000); // 3 seconds and then force refreesh the screen
                                    } else {
                                        toast({
                                            title: "Failed to create Google Sheet Integration",
                                            description:
                                                "Please try again at a later time.",
                                            variant: "destructive",
                                        });
                                    }
                                });
                            })
                            .catch((error) => {
                                // Show failure UI.
                                console.log(error);
                                toast({
                                    title: "Failed to initialize Google Sheet Integration",
                                    description:
                                        "Please try again at a later time.",
                                    variant: "destructive",
                                });
                            });
                    } else {
                        toast({
                            title: "Failed to initialize Google Sheet Integration",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                        setLoadingState(2);
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Failed to get Nango Session Token",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                    reject(res);
                });
        });
    };

    const generateCid = () => uuidv4();

    const handleCancel = () => {
        onCancel?.();
    };

    useEffect(() => {
        // Load the Google Picker API
        const script1 = document.createElement("script");
        script1.src = "https://apis.google.com/js/api.js";
        script1.async = true;
        script1.defer = true;
        script1.onload = () => {
            window.gapi.load("picker", () => {
                setPickerInited(true);
            });
        };

        // Load the Google Identity Services API
        const script2 = document.createElement("script");
        script2.src = "https://accounts.google.com/gsi/client";
        script2.async = true;
        script2.defer = true;
        script2.onload = () => {
            initializeTokenClient();
        };

        document.body.appendChild(script1);
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    const initializeTokenClient = () => {
        const client = window.google?.accounts?.oauth2?.initTokenClient({
            client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
            scope: "https://www.googleapis.com/auth/drive.file",
            callback: "", // Will be set later
        });
        setTokenClient(client);
    };

    const createPicker = (accessToken: string) => {
        console.log(process.env.REACT_APP_GOOGLE_DRIVE_PICKER_API_KEY);
        const picker = new window.google.picker.PickerBuilder()
            .addView(window.google.picker.ViewId.SPREADSHEETS)
            .setOAuthToken(accessToken)
            .setDeveloperKey(process.env.REACT_APP_GOOGLE_DRIVE_PICKER_API_KEY)
            .setAppId("723060305599")
            .setCallback((data: any) => {
                if (data.action === window.google.picker.Action.PICKED) {
                    const fileIds = data.docs.map((doc: any) => doc.id);
                    onSuccess?.(fileIds);
                } else if (data.action === window.google.picker.Action.CANCEL) {
                    handleCancel();
                }
            })
            .build();
        picker.setVisible(true);
    };

    const handleClick = async () => {
        try {
            if (!pickerInited || !tokenClient) {
                toast({
                    title: "Google Picker not initialized",
                    description: "Please try again in a moment",
                    variant: "destructive",
                });
                return;
            }

            if (!connectionId && connectionId !== "" && connectionId !== null) {
                await connect();
            }

            if (!connectionId) {
                toast({
                    title: "Failed to establish connection",
                    description: "Please try again",
                    variant: "destructive",
                });
                return;
            }

            await api
                .post(
                    URLS.serverUrl + API.getNangoSessionToken,
                    {
                        unique_name: "Standard",
                        integration_type: "GoogleSheet",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                )
                .then((res) => {
                    const sessionToken = res.data;
                    createPicker(sessionToken);
                })
                .catch((error) => {
                    toast({
                        title: "Failed to open picker",
                        description: "Please try again later",
                        variant: "destructive",
                    });
                });
        } catch (error) {
            toast({
                title: "Failed to connect to Google Sheet",
                description: "Please try again later",
                variant: "destructive",
            });
        }
    };

    return loadingState === loadingTypes.loaded && spreadsheetID ? (
        <Badge
            color={"gray"}
            size="1"
            radius="medium"
            variant="outline"
            className="h-8 my-0 ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex flex-row items-center"
        >
            <GoogleSheetSvg className="w-5 h-5" />
            <p className="text-sm text-green10">Connected</p>
            <Button
                variant="ghost"
                size="sm"
                className="p-0 my-0"
                onClick={() => {
                    window.open(
                        `https://docs.google.com/spreadsheets/d/${spreadsheetID}`,
                        "_blank",
                    );
                }}
            >
                <Link2Icon />
            </Button>
        </Badge>
    ) : loadingState === loadingTypes.loaded && !spreadsheetID ? (
        <Button
            className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
            size="sm"
            variant="outline"
            onClick={handleClick}
        >
            Select Google Spreadsheet
        </Button>
    ) : loadingState === loadingTypes.loading ? (
        <Skeleton className="h-8 w-20" />
    ) : (
        <Badge
            color={"red"}
            size="1"
            radius="medium"
            variant="outline"
            className="h-8 my-0 ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex flex-row items-center"
        >
            <ExclamationTriangleIcon className="h-3 w-3" color="#E5484D" />
            Error Loading Spreadsheet
        </Badge>
    );
};
