import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    InteractionCountsResponse,
    InteractionFilters,
    Label,
    QueriesWithPaginationResponse,
    Query,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import type { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import {
    arraysAreEqual,
} from "@/utilities/methods";
import {
    saveAssignee,
    saveStatus,
    saveTag,
    toggleTopicSelection,
} from "@/utilities/methods";
import { QueryObserverResult, InfiniteData, RefetchOptions, type UseQueryResult, useQuery } from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import { IssueContextMenu } from "./IssueContextMenu";
import MemoizedIssuesListCard from "./IssuesListCard";
import { IssuesListHeaderCard } from "./IssuesListHeaderCard";
import { NoInteractionsPrompt } from "./NoInteractionsPrompt";
import { HeaderType } from "./constants";

const areTopicsEqual = (topic1: Topic, topic2: Topic): boolean => {
    return (
        topic1.color === topic2.color &&
        topic1.label === topic2.label &&
        topic1.value === topic2.value
    );
};

const getStableItemData = (
    listItems: { key: string; content: JSX.Element }[],
    selectedIssues: IssueGroupInfo[],
    handleIssueSelect: (
        checked: boolean,
        issueId: string,
        group: string,
    ) => void,
) => ({
    items: listItems,
    selectedIssues,
    handleIssueSelect,
});

const areEqual = (prevProps: IssuesListProps, nextProps: IssuesListProps) => {
    return (
        arraysAreEqual(prevProps.issues, nextProps.issues) &&
        prevProps.topics.length === nextProps.topics.length &&
        prevProps.topics.every((topic, index) =>
            areTopicsEqual(topic, nextProps.topics[index]),
        ) &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.userID === nextProps.userID &&
        arraysAreEqual(
            prevProps.usersQuery.data ?? [],
            nextProps.usersQuery.data ?? [],
        ) &&
        arraysAreEqual(
            prevProps.customerGroupsQuery.data ?? [],
            nextProps.customerGroupsQuery.data ?? [],
        ) &&
        prevProps.refetch === nextProps.refetch &&
        prevProps.onFilterChange === nextProps.onFilterChange &&
        prevProps.onGroupChange === nextProps.onGroupChange &&
        prevProps.inputFilters === nextProps.inputFilters &&
        prevProps.inputGrouping === nextProps.inputGrouping &&
        prevProps.listType === nextProps.listType &&
        prevProps.viewID === nextProps.viewID &&
        prevProps.viewName === nextProps.viewName &&
        prevProps.listHeight === nextProps.listHeight &&
        prevProps.interactionCounts === nextProps.interactionCounts &&
        prevProps.setInteractionFilters === nextProps.setInteractionFilters &&
        prevProps.setTeamIDsFromFilter === nextProps.setTeamIDsFromFilter &&
        prevProps.interactionTypesQuery === nextProps.interactionTypesQuery
    );
};

export enum Grouping {
    Status = "ticket_status",
    Owner = "assignee_user_id",
    Source = "source",
    None = "None",
}

interface IssuesListProps {
    issues: Query[];
    topics: Topic[];
    topicsMap: Map<string, GetTopicsResponse>;
    userID: string;
    listType: IssueListType;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
    customerGroupsQuery: UseQueryResult<CustomerGroup[], Error>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >;
    onFilterChange?: (filters: Map<string, Set<string>>) => void; // New prop
    onGroupChange?: (grouping: Grouping) => void; // New prop
    inputFilters?: Map<string, Set<string>>;
    inputGrouping?: string;
    viewID?: string;
    viewName?: string
    teamID?: string;
    listHeight?: number;
    searchQuery: string;
    interactionCounts?: InteractionCountsResponse;
    setInteractionFilters: React.Dispatch<
        React.SetStateAction<InteractionFilters>
    >;
    setTeamIDsFromFilter: React.Dispatch<
        React.SetStateAction<string[] | undefined>
    >;
    interactionTypesQuery: UseQueryResult<Label[], Error>
    activeStatusTab?: string;
}

export interface IssueGroupInfo {
    issueId: string;
    group: string;
    issueTopics?: string[];
}

function IssuesList({
    issues,
    topics,
    topicsMap,
    userID,
    listType,
    usersQuery,
    channelsQuery,
    customerGroupsQuery,
    teamsQuery,
    refetch,
    onFilterChange,
    onGroupChange,
    inputFilters,
    inputGrouping,
    viewID,
    viewName,
    teamID,
    listHeight,
    searchQuery,
    interactionCounts,
    setInteractionFilters,
    setTeamIDsFromFilter,
    interactionTypesQuery,
    activeStatusTab
}: IssuesListProps) {
    const [issuesByGroup, setIssuesByGroup] = useState<
        Map<string, Map<string, Query>>
    >(new Map()); // Map<groupKey, Map<issueId, issue>>

    const api = useApi();
    const categoryOptionsQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    // Default: Grouping by status
    const [grouping, setGrouping] = useState<Grouping>(
        (inputGrouping as Grouping) ?? Grouping.Status,
    );
    const [groupingExpanded, setGroupingExpanded] = useState<
        Map<string, boolean>
    >(new Map());
    const listRef = useRef<VariableSizeList>(null);

    const [, setListWidth] = useState<number>(window.innerWidth);
    const containerRef = useRef<HTMLDivElement>(null);

    const [selectedIssues, setSelectedIssues] = useState<IssueGroupInfo[]>([]);
    const [lastSelectedIssue, setLastSelectedIssue] =
        useState<IssueGroupInfo | null>(null);

    const handleIssueSelect = useCallback(
        (
            checked: boolean,
            issueId: string,
            group: string,
            event?: React.MouseEvent,
        ) => {
            const currentIssue = { issueId, group };

            setSelectedIssues((prev) => {
                if (event?.shiftKey && lastSelectedIssue) {
                    // Flatten the issues into a single array with their indices
                    const flattenedIssues: IssueGroupInfo[] = [];
                    issuesByGroup.forEach((groupIssues, groupKey) =>
                        groupIssues.forEach((issue, id) =>
                            flattenedIssues.push({
                                issueId: id,
                                group: groupKey,
                                issueTopics: issue.topic,
                            }),
                        ),
                    );

                    // Find the range of indices between the last selected issue and the current one
                    const startIdx = flattenedIssues.findIndex(
                        (issue) =>
                            issue.issueId === lastSelectedIssue.issueId &&
                            issue.group === lastSelectedIssue.group,
                    );
                    const endIdx = flattenedIssues.findIndex(
                        (issue) =>
                            issue.issueId === currentIssue.issueId &&
                            issue.group === currentIssue.group,
                    );

                    if (startIdx === -1 || endIdx === -1) return prev;

                    const [rangeStart, rangeEnd] = [
                        Math.min(startIdx, endIdx),
                        Math.max(startIdx, endIdx),
                    ];

                    const issuesInRange = flattenedIssues.slice(
                        rangeStart,
                        rangeEnd + 1,
                    );

                    if (checked) {
                        // Add new range to the selection, avoiding duplicates
                        const newSelection = new Map(
                            prev.map((issue) => [
                                `${issue.group}-${issue.issueId}`,
                                issue,
                            ]),
                        );
                        for (const issue of issuesInRange) {
                            newSelection.set(
                                `${issue.group}-${issue.issueId}`,
                                issue,
                            );
                        }
                        return Array.from(newSelection.values());
                    } else {
                        // Remove range from the selection
                        const rangeSet = new Set(
                            issuesInRange.map(
                                (issue) => `${issue.group}-${issue.issueId}`,
                            ),
                        );
                        return prev.filter(
                            (issue) =>
                                !rangeSet.has(
                                    `${issue.group}-${issue.issueId}`,
                                ),
                        );
                    }
                }

                if (checked) {
                    return [...prev, currentIssue];
                } else {
                    return prev.filter(
                        (issue) =>
                            issue.issueId !== issueId || issue.group !== group,
                    );
                }
            });
            setSingleSelectedIssue(undefined);
            setLastSelectedIssue(currentIssue);
        },
        [lastSelectedIssue],
    );

    // const handleSelectAll = useCallback( // add support later
    //     (checked: boolean) => {
    //         if (checked) {
    //             const allVisibleIssueIds = new Set<string>();
    //             issuesByGroup.forEach((groupIssues) => {
    //                 groupIssues.forEach((issue) => {
    //                     allVisibleIssueIds.add(issue.id);
    //                 });
    //             });
    //             setSelectedIssues(allVisibleIssueIds);
    //         } else {
    //             setSelectedIssues(new Set());
    //         }
    //     },
    //     [issuesByGroup],
    // );

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                const newWidth = containerRef.current.offsetWidth;
                setListWidth(newWidth);
                if (listRef.current) {
                    listRef.current.resetAfterIndex(0);
                }
            }
        };

        updateWidth();

        const resizeObserver = new ResizeObserver(updateWidth);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const result = new Map<string, Map<string, Query>>();
        const mustKeys = activeStatusTab ? [activeStatusTab] : [
            "Breaching",
            "NeedsResponse",
            "Escalated",
            "Open",
            "Closed",
        ];

        // Initialize with empty arrays for mustKeys if grouping by Status
        if (grouping === Grouping.Status) {
            // biome-ignore lint/complexity/noForEach: <explanation>
            mustKeys.forEach((key) => {
                result.set(key, new Map());
            });
        }

        for (const issue of issues) {
            // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
            let groupVal;

            // Determine group value based on current grouping
            if (grouping === Grouping.Owner) {
                const assignedUser = usersQuery.data?.find(
                    (user) => user.id === issue.assignee_user_id,
                );
                groupVal = assignedUser
                    ? `${assignedUser.first_name} ${assignedUser.last_name}`
                    : "Unassigned";
            } else {
                groupVal =
                    grouping === Grouping.None
                        ? "All"
                        : issue[grouping as keyof Query];
            }

            const groupKey = groupVal ? groupVal.toString() : "None";
            if (!result.has(groupKey)) {
                result.set(groupKey, new Map());
            }
            if (!result.get(groupKey)?.has(issue.id)) {
                result.get(groupKey)?.set(issue.id, issue);
            } else {
                result.get(groupKey)?.set(issue.id, issue);
            }
        }
        setIssuesByGroup(result);

        // Preserve existing expansion states when updating groupingExpanded
        setGroupingExpanded((prevExpanded) => {
            const newGroupingExpanded = new Map<string, boolean>();
            result.forEach((_, key) => {
                // Keep previous state if it exists, otherwise default to true for Breaching/Escalated/Open and false for Closed
                // If activeStatusTab is set, always expand that status
                newGroupingExpanded.set(
                    key,
                    activeStatusTab === key ? true : (
                        prevExpanded.has(key)
                            ? // biome-ignore lint/style/noNonNullAssertion: <explanation>
                            prevExpanded.get(key)!
                            : [
                                "Breaching",
                                "NeedsResponse",
                                "Escalated",
                                "Open",
                            ].includes(key)
                    )
                );
            });
            return newGroupingExpanded;
        });
    }, [grouping, usersQuery.data, issues, activeStatusTab]);

    const updateExpanded = useCallback((groupKey: string, value: boolean) => {
        setGroupingExpanded((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(groupKey, value);
            return newMap;
        });
    }, []);

    const headerHeight = 40;
    const customHeight = 54;

    const getIdealHeight = (height: number): number => {
        if (height > 800) {
            return height * 0.76;
        } else {
            const digit = Math.floor(height / 100);

            return height * (0.1 * digit);
        }
    };

    const getItemSize = useCallback(
        (index: number) => {
            const groupKeys = Array.from(issuesByGroup.keys());
            let currentIndex = 0;

            for (const groupKey of groupKeys) {
                if (index === currentIndex) {
                    return headerHeight;
                }
                currentIndex++;
                if (groupingExpanded.get(groupKey)) {
                    const groupSize = issuesByGroup.get(groupKey)?.size || 0;
                    if (index < currentIndex + groupSize) {
                        return customHeight;
                    }
                    currentIndex += groupSize;
                }
            }
            return 0;
        },
        [issuesByGroup, groupingExpanded],
    );

    const setIssueStateHelper = useCallback(
        (
            newState: Partial<Query>,
            updatedMap: Map<string, Map<string, Query>>,
            issueGroup: IssueGroupInfo,
            newGroup?: string,
        ) => {
            const currentIssue = updatedMap
                .get(issueGroup.group)
                ?.get(issueGroup.issueId);

            if (!currentIssue) {
                console.error(`Issue with ID ${issueGroup.issueId} not found.`);
                return;
            }

            const updatedIssue = {
                ...currentIssue,
                ...newState,
                bot_category:
                    newState.bot_category || currentIssue.bot_category,
                topic: newState.topic
                    ? newState.topic.sort()
                    : currentIssue.topic,
                ticket_status:
                    newState.ticket_status || currentIssue.ticket_status,
                assignee_user_id:
                    newState.assignee_user_id || currentIssue.assignee_user_id,
            };

            if (newGroup && newGroup !== issueGroup.group) {
                // Remove from old group
                updatedMap.get(issueGroup.group)?.delete(issueGroup.issueId);

                // Add to new group using existing map
                if (!updatedMap.has(newGroup)) {
                    updatedMap.set(newGroup, new Map<string, Query>());
                }
                updatedMap.get(newGroup)?.set(issueGroup.issueId, updatedIssue);
                issueGroup.group = newGroup;
            } else {
                // Update in current group
                updatedMap
                    .get(issueGroup.group)
                    ?.set(issueGroup.issueId, updatedIssue);
            }
        },
        [],
    );

    const saveIssue = useCallback(
        (
            type: string,
            value: string,
            api: AxiosInstance,
            updateIssueState: (
                newState: Partial<Query>,
                issueGroup: IssueGroupInfo,
                newGroup?: string,
            ) => void,
            userID: string,
            refetch: () => void,
            issueId: string,
            issueState?: Query,
        ) => {
            let issueGroups: IssueGroupInfo[];
            if (selectedIssues.length === 0 && issueState) {
                issueGroups = [
                    {
                        issueId: issueState.id,
                        group: issueState.ticket_status,
                        issueTopics: issueState.topic,
                    },
                ];
            } else {
                issueGroups = selectedIssues;
            }
            switch (type) {
                case "Tag":
                    return saveTag(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                    );
                case "Add Topic":
                    return toggleTopicSelection(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                        true,
                    );
                case "Remove Topic":
                    return toggleTopicSelection(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                        false,
                    );
                case "Status":
                    return saveStatus(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                        refetch
                    );
                case "Assignee":
                    return saveAssignee(
                        value,
                        api,
                        issueGroups,
                        updateIssueState,
                        userID,
                    );
                default:
                    return null;
            }
        },
        [selectedIssues],
    );

    const setIssueState = useCallback(
        (
            newState: Partial<Query>,
            issueGroup: IssueGroupInfo,
            newGroup?: string,
        ) => {
            setIssuesByGroup((prevIssuesMap) => {
                const updatedMap = new Map(
                    Array.from(prevIssuesMap).map(([k, v]) => [k, new Map(v)]),
                );
                setIssueStateHelper(newState, updatedMap, issueGroup, newGroup);
                return updatedMap;
            });
            setSelectedIssues([]);
        },
        [setIssueStateHelper],
    );

    const openContextMenuForSingleIssue = useCallback(
        (issue: Query) => {
            // Only open the menu for the issue if no issue are bulk selected
            if (selectedIssues.length === 0) {
                setSingleSelectedIssue(issue);
            }
        },
        [selectedIssues],
    );

    const listItems = useMemo(() => {
        const items: { key: string; content: JSX.Element }[] = [];
        issuesByGroup.forEach((groupIssues, groupKey) => {
            // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
            let headerKey;
            if (
                [
                    "Closed",
                    "NeedsResponse",
                    "Escalated",
                    "Open",
                    "Breaching",
                ].includes(groupKey)
            ) {
                headerKey = HeaderType.Status;
            } else {
                headerKey = HeaderType.Other;
            }
            items.push({
                key: `header-${groupKey}`,
                content: (
                    <IssuesListHeaderCard
                        key={`header-${
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            groupKey
                            }`}
                        title={groupKey}
                        value={groupKey}
                        header_type={headerKey}
                        isExpanded={groupingExpanded}
                        updateExpanded={updateExpanded}
                        numIssues={searchQuery.trim() === ""
                            ? interactionCounts?.header_counts?.get(groupKey)
                            : groupIssues.size
                        }
                    />
                ),
            });

            if (groupingExpanded.get(groupKey)) {
                let count = 0
                // biome-ignore lint/complexity/noForEach: <explanation>
                groupIssues.forEach((issue) => {
                    count += 1
                    items.push({
                        key: issue.id,
                            content: (
                            <MemoizedIssuesListCard
                                key={issue.id}    
                                interactionTypes={interactionTypesQuery.data ?? []}
                                issue={issue}
                                    users={usersQuery?.data ?? []}
                                    teams={teamsQuery?.data ?? []}
                                    refetch={refetch}
                                    listType={listType}
                                    teamID={teamID}
                                    viewID={viewID}
                                isSelected={selectedIssues.some(
                                    (selectedIssue) =>
                                        selectedIssue.issueId === issue.id &&
                                        selectedIssue.group === groupKey,
                                )}
                                    onSelect={handleIssueSelect}
                                openContextMenuForSingleIssue={
                                    openContextMenuForSingleIssue
                                }
                                />
                            ),
                });
        });
            }
        });
        return items;
    }, [
        issuesByGroup,
        groupingExpanded,
        interactionTypesQuery.data,
        usersQuery?.data,
        teamsQuery?.data,
        refetch,
        listType,
        teamID,
        viewID,
        selectedIssues,
        handleIssueSelect,
        openContextMenuForSingleIssue,
        searchQuery,
        interactionCounts?.header_counts,
        updateExpanded
    ]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [grouping, groupingExpanded, issuesByGroup]);

    const itemData = useMemo(
        () => getStableItemData(listItems, selectedIssues, handleIssueSelect),
        [listItems, selectedIssues, handleIssueSelect],
    );

    // Create a stable item renderer
    const ItemRenderer = useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const item = itemData.items[index];
            return (
                <div style={style} key={item.key}>
                    {item.content}
                </div>
            );
        },
        [itemData],
    );

    const [singleSelectedIssue, setSingleSelectedIssue] = useState<Query>();
    const updateIssueState = useCallback(
        (
            newState: Partial<Query>,
            issueGroup: IssueGroupInfo,
            newGroup?: string,
        ) => {
            setIssueState(
                newState,
                issueGroup,
                newGroup === "ClosedNoWorkflows" ? "Closed" : newGroup,
            );
        },
        [setIssueState],
    );

    return (
        <div
            className="mb-2 rounded-sm w-full flex flex-col"
            ref={containerRef}
        >
            {/* <FilterBar
                issues={issues}
                setFilters={updateFilters}
                categories={categoryOptionsQuery.data ?? []}
                filters={filters}
                topics={topics}
                users={usersQuery?.data ?? []}
                customerGroups={customerGroupsQuery.data ?? []}
                teams={teamsQuery?.data ?? []}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                savedViewFilters={inputFilters ?? new Map()}
                isGeneralTeam={
                    listType === IssueListType.Issues ||
                    listType === IssueListType.Inbox
                }
                channels={channelsQuery?.data ?? new Map()}
            /> */}

            {interactionCounts?.total_count === 0 ? (
                <NoInteractionsPrompt
                    userID={userID}
                    listType={listType}
                    teamsQuery={teamsQuery}
                    viewName={viewName}
                    teamID={teamID}
                />
            ) : (<ScrollArea className="w-full">
                <IssueContextMenu
                    topics={topics}
                    users={usersQuery?.data ?? []}
                    teams={teamsQuery?.data ?? []}
                    categories={categoryOptionsQuery?.data ?? []}
                    bulk={selectedIssues.length > 1}
                    selectedIssues={selectedIssues}
                    updateIssueState={updateIssueState}
                    userID={userID}
                    refetch={refetch}
                    singleSelectedIssue={singleSelectedIssue}
                    listType={listType}
                    teamID={teamID ?? ""}
                >
                    <VariableSizeList
                        ref={listRef}
                        width={"100%"}
                        height={
                            listHeight ?? getIdealHeight(window.innerHeight)
                        }
                        itemCount={listItems.length}
                        itemSize={getItemSize}
                        overscanCount={5}
                        itemData={itemData}
                    >
                        {ItemRenderer}
                    </VariableSizeList>
                </IssueContextMenu>
            </ScrollArea>
            )}
        </div>
    );
}

export default memo(IssuesList, areEqual);
