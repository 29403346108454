import { Button } from "@/component/shadcn/ui/button";
import { API, ContactsAPI, TeamsAPI, URLS } from "@/constant";
import { useDebounce } from "@/hooks/useDebounce";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    GetUserResponse,
    ListAccountsResponse,
    Teams,
} from "@/interfaces/serverData";
import { AccountsDataTable } from "@/pages/WorkspacePreferences/DataTable/accounts-data-table";
import { DataTableToolbarDebounce } from "@/pages/WorkspacePreferences/DataTable/data-table-toolbar-debounce";
import { TeamBadges } from "@/pages/WorkspacePreferences/TeamBadges";
import { getBadgeForTeam, getNavLink } from "@/utilities/methods";
import { type OrgMemberInfo, useAuthInfo } from "@propelauth/react";
import { PlusIcon } from "@radix-ui/react-icons";
import {
    Box,
    Callout,
    DropdownMenu,
    Flex,
    Heading,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import {
    useInfiniteQuery,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import type { Row } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountPopup from "./AccountPopup";
import { generateCustomerColumms } from "./CustomersTable/columns";
import { NoAccountsPopup } from "./NoAccountsPopup";

export enum AccountsListType {
    Accounts = "accounts",
    Inbox = "inbox",
    Team = "team",
}

export interface AccountsProps {
    org: OrgMemberInfo | undefined;
    userID: string;
    listType: AccountsListType;
}

export const AccountsPage = ({ org, userID, listType }: AccountsProps) => {
    const teamID = window.location.pathname.split("/")[2] || "";
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const [loadingState, setLoadingState] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    const getEndpoint = (accountsType: AccountsListType) => {
        if (accountsType === AccountsListType.Team) {
            return `/team/${teamID}`;
        } else if (accountsType === AccountsListType.Inbox) {
            return `/assignee/${userID}`;
        }
        return "";
    };

    const fetchAccountsCompanies = async ({
        pageParam = 0,
        accountsType,
        query,
    }: {
        pageParam?: number;
        accountsType: AccountsListType;
        query: string;
    }): Promise<ListAccountsResponse> => {
        console.log("fetching companies", pageParam, accountsType, query);
        try {
            const { url, method } = ContactsAPI.listAccountsCompanies;
            const endpoint = getEndpoint(accountsType);
            const response = await api.get(
                `${URLS.serverUrl}${url}${endpoint}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 50,
                        offset: pageParam,
                        query: query,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const fetchAccountsContacts = async ({
        pageParam = 0,
        accountsType,
        query,
    }: {
        pageParam?: number;
        accountsType: AccountsListType;
        query: string;
    }): Promise<ListAccountsResponse> => {
        console.log("fetching accounts contacts");
        try {
            const endpoint = getEndpoint(accountsType);
            const { url, method } = ContactsAPI.listAccountsContacts;
            const response = await api.get(
                `${URLS.serverUrl}${url}${endpoint}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 50,
                        offset: pageParam,
                        query: query,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const [input, setInput] = useState("");
    const debouncedInput = useDebounce(input, 500);

    useEffect(() => {
        if (input !== debouncedInput) {
            setPageIndex(0);
            // Reset both queries when input changes
            queryClient.resetQueries({
                queryKey: [
                    ...accountQueryConfig[listType].queryKey,
                    "companies",
                ],
            });
            queryClient.resetQueries({
                queryKey: [
                    ...accountQueryConfig[listType].queryKey,
                    "contacts",
                ],
            });
        }
    }, [input, debouncedInput, listType, queryClient]);

    const accountQueryConfig = {
        [AccountsListType.Accounts]: {
            queryKey: ["accounts"],
        },
        [AccountsListType.Team]: {
            queryKey: [`teamAccounts_${teamID}`],
        },
        [AccountsListType.Inbox]: {
            queryKey: ["myAccounts"],
        },
    };

    const {
        data: companiesData,
        fetchNextPage: fetchNextCompaniesPage,
        hasNextPage: hasNextCompaniesPage,
        isFetchingNextPage: isFetchingNextCompaniesPage,
        refetch: refetchCompanies,
        isLoading: isLoadingCompanies,
    } = useInfiniteQuery({
        queryKey: [
            ...accountQueryConfig[listType].queryKey,
            "companies",
            debouncedInput,
        ],
        queryFn: ({ pageParam = 0 }) =>
            fetchAccountsCompanies({
                pageParam,
                accountsType: listType,
                query: debouncedInput,
            }),
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined;
        },
        initialPageParam: 0,
    });

    const {
        data: contactsData,
        fetchNextPage: fetchNextContactsPage,
        hasNextPage: hasNextContactsPage,
        isFetchingNextPage: isFetchingNextContactsPage,
        refetch: refetchContacts,
    } = useInfiniteQuery({
        queryKey: [
            ...accountQueryConfig[listType].queryKey,
            "contacts",
            debouncedInput,
        ],
        queryFn: ({ pageParam = 0 }) =>
            fetchAccountsContacts({
                pageParam,
                accountsType: listType,
                query: debouncedInput,
            }),
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined;
        },
        initialPageParam: 0,
        // enabled: !hasNextCompaniesPage && !isLoadingCompanies,
    });

    // Fetch all the data

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const processPages = (data: any) => {
        if (!data || !Array.isArray(data.pages)) return [];
        return data.pages
            .filter((page) => page !== null && page !== undefined)
            .flatMap((page) =>
                Array.isArray(page.data)
                    ? page.data.filter(
                          (item) => item !== null && item !== undefined,
                      )
                    : [],
            );
    };

    const processNewPages = (page: any) => {
        if (!page) return [];
        return page
            ? page.data.filter((item) => item !== null && item !== undefined)
            : [];
    };

    const [combinedData, setCombinedData] = useState<Account[]>([]);

    useEffect(() => {
        const lastCompanyPage =
            companiesData?.pages[companiesData.pages.length - 1];
        const lastContactPage =
            contactsData?.pages[contactsData.pages.length - 1];

        // When input changes or on initial load, process all pages
        if (companiesData?.pages.length === 1) {
            const companiesPages = processPages(companiesData);
            const contactsPages =
                !hasNextCompaniesPage && !isLoadingCompanies
                    ? processPages(contactsData)
                    : [];
            setCombinedData([...companiesPages, ...contactsPages]);
            return;
        }

        // For pagination: append new pages
        const isNewCompanyPage =
            lastCompanyPage && companiesData.pages.length > 1;
        const isNewContactPage =
            lastContactPage && contactsData?.pages.length > 1;

        if (isNewCompanyPage || isNewContactPage) {
            setCombinedData((prev) => [
                ...prev,
                ...(isNewCompanyPage ? processNewPages(lastCompanyPage) : []),
                ...(isNewContactPage &&
                !hasNextCompaniesPage &&
                !isLoadingCompanies
                    ? processNewPages(lastContactPage)
                    : []),
            ]);
        }
    }, [companiesData, contactsData, hasNextCompaniesPage, isLoadingCompanies]);

    // Reset combined data when input changes
    useEffect(() => {
        if (companiesData?.pages.length === 1) {
            const companiesPages = processPages(companiesData);
            const contactsPages =
                !hasNextCompaniesPage && !isLoadingCompanies
                    ? processPages(contactsData)
                    : [];
            setCombinedData([...companiesPages, ...contactsPages]);
        }
    }, [
        debouncedInput,
        companiesData,
        contactsData,
        hasNextCompaniesPage,
        isLoadingCompanies,
    ]);

    useEffect(() => {
        if (companiesData && (!hasNextCompaniesPage ? contactsData : true)) {
            setLoadingState(1);
        }
    }, [companiesData, contactsData, hasNextCompaniesPage]);

    const api = useApi();

    const updateData = async () => {
        queryClient.refetchQueries({
            queryKey: [
                ...accountQueryConfig[listType].queryKey,
                "companies",
                debouncedInput,
            ],
            exact: true,
        });
        queryClient.refetchQueries({
            queryKey: [
                ...accountQueryConfig[listType].queryKey,
                "contacts",
                debouncedInput,
            ],
            exact: true,
        });
    };

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const [rowState, setRowState] = useState<Map<string, Account>>(new Map());

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const handleRowClick = (row: Row<Account>) => {
        navigate(getNavLink(row.original, listType, teamID));
    };

    if (!org) {
        return null;
    }

    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="5" height="100%" width="97%">
                <div className="flex items-center ml-5 justify-between">
                    <div>
                        <Heading
                            size="5"
                            align="left"
                            className="flex items-center gap-2"
                        >
                            Accounts
                            {
                                // For Team view
                                (listType === AccountsListType.Team &&
                                    teamID && (
                                        <TeamBadges
                                            teams={(
                                                teamsQuery.data ?? []
                                            ).filter(
                                                (team) => team.id === teamID,
                                            )}
                                            defaultIsWorkspace={false}
                                        />
                                    )) ||
                                    // For regular view
                                    (listType === AccountsListType.Accounts &&
                                        getBadgeForTeam("General"))
                            }
                        </Heading>
                    </div>
                    <DataTableToolbarDebounce
                        placeholder="Filter Accounts By Name..."
                        column="name"
                        input={input}
                        setInput={setInput}
                    />
                    <div className="flex items-center mr-5">
                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger>
                                <Button
                                    className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                    size="sm"
                                    variant="outline"
                                >
                                    Add
                                    <PlusIcon />
                                </Button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Company
                                        </DropdownMenu.Item>
                                    }
                                    editing={false}
                                    type="Company"
                                    updateData={updateData}
                                    teamsQuery={teamsQuery}
                                    usersQuery={usersQuery}
                                    userID={userID}
                                    orgID={org.orgId}
                                    listType={listType}
                                />
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Customer
                                        </DropdownMenu.Item>
                                    }
                                    editing={false}
                                    type="Customer"
                                    updateData={updateData}
                                    teamsQuery={teamsQuery}
                                    usersQuery={usersQuery}
                                    userID={userID}
                                    orgID={org.orgId}
                                    listType={listType}
                                />
                            </DropdownMenu.Content>
                        </DropdownMenu.Root>
                    </div>
                </div>

                {loadingState === 0 && (
                    <Flex
                        maxWidth="85%"
                        style={{ paddingLeft: "20px", paddingTop: "20px" }}
                    >
                        <Text>
                            <Skeleton maxWidth="85%">
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Skeleton>
                        </Text>
                    </Flex>
                )}
                {loadingState === 2 && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === 1 &&
                    combinedData &&
                    combinedData.length > 0 && (
                        <div className="flex flex-col gap-2 mx-5">
                            <AccountsDataTable<Account, string>
                                columns={generateCustomerColumms(
                                    rowState,
                                    usersQuery.data ?? [],
                                )}
                                data={combinedData}
                                handleRowClick={handleRowClick}
                                pageIndex={pageIndex}
                                setPageIndex={setPageIndex}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                updateData={updateData}
                                teamsQuery={teamsQuery}
                                usersQuery={usersQuery}
                                userID={userID}
                                orgID={org.orgId}
                                listType={listType}
                                input={input}
                                setInput={setInput}
                                hasNextPage={
                                    hasNextCompaniesPage || hasNextContactsPage
                                }
                                isFetchingNextPage={
                                    isFetchingNextCompaniesPage ||
                                    isFetchingNextContactsPage
                                }
                                fetchNextPage={async () => {
                                    if (hasNextCompaniesPage) {
                                        await fetchNextCompaniesPage();
                                    } else if (hasNextContactsPage) {
                                        await fetchNextContactsPage();
                                    }
                                }}
                            />
                        </div>
                    )}
                {loadingState === 1 &&
                    combinedData &&
                    combinedData.length === 0 && (
                        <div className="flex items-center justify-center min-h-[calc(100vh-200px)]">
                            <NoAccountsPopup
                                userID={userID}
                                listType={listType}
                                teamsQuery={teamsQuery}
                                teamID={teamID}
                            />
                        </div>
                    )}
            </Box>
        </Flex>
    );
};
