import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    type IssueType,
    type OrgInfoResponse,
    integrationLabelMap,
} from "@/interfaces/serverData";
import type {
    GetConnectionsResponse,
    ObjectType,
} from "@/interfaces/serverData";
import type { ScopeResponse } from "@/interfaces/serverData";
import type { BotConnection } from "@/interfaces/serverData";
import {
    Cross2Icon,
    MixerHorizontalIcon,
    PlusIcon,
    ReloadIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    DataList,
    Flex,
    Separator,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlackSvg } from "../../../images/integrations/slack.svg";
import type { BotMetadata } from "../../../interfaces/serverData";
import type { metadata } from "../IntegrationsManagementPage";
import "../toast_styles.css";
import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import IntegrationHeader from "./IntegrationHeader";
import Shortcuts from "./Shortcuts";
import SlackConfigPage from "./SlackConfigPage";

interface SlackIntegrationProps {
    orgId: string;
}

const SlackIntegration: React.FC<SlackIntegrationProps> = ({ orgId }) => {
    const slackContinue = () => {
        const client_id = process.env.REACT_APP_SLACK_CLIENT_ID;
        //dev mode: TODO - change this to prod mode when ready
        // const redirect_url =
        //     "https://redirectmeto.com/http://localhost:3001/admin/integrations/slack";
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/slack`;
        const state = process.env.REACT_APP_SLACK_STATE;
        const slack_url = `https://slack.com/oauth/v2/authorize?state=${state}&client_id=${client_id}&&redirect_uri=${redirect_url}&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,commands,files:read,files:write,groups:history,groups:read,im:history,im:read,links:read,mpim:history,pins:read,reactions:read,reactions:write,team:read,users:read,users:read.email&user_scope=`;
        window.open(slack_url, "_self");
    };

    const [enabledIntegrations, setEnabledIntegrations] = useState<string[]>(
        [],
    );

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
        slack_team_id: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );

    const [loadingStateIntegrationEnabled, setLoadingStateIntegrationEnabled] =
        useState<number>(loadingTypes.loading);
    const api = useApi();
    const { toast } = useToast();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    const [askAIEnabled, setAskAIEnabled] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);

    const [loadingStateShortCuts, setLoadingStateShortCuts] = useState<number>(
        loadingTypes.loading,
    );
    const [asmTicketChannels, setAsmTicketChannels] = useState<ScopeResponse[]>(
        [],
    );
    const [triggerBasedTicketChannels, setTriggerBasedTicketChannels] =
        useState<ScopeResponse[]>([]);
    const integrationToEmoji: { [key: string]: string } = {
        GitHubTicket: "\u{1F63F}",
        Linear: "\u{1F39F}",
        Jira: "\u{1F516}",
        "Intercom Low Priority": "\u{1F7E2}",
        "Intercom Normal Priority": "\u{1F7E1}",
        "Intercom High Priority": "\u{1F7E0}",
        "Intercom Urgent Priority": "\u{1F534}",
        Intercom: "\u{1F7E3}",
    };

    function saveSettings(
        askAIEnabledInput: boolean,
        partialBotMetadata: Partial<BotMetadata>,
    ) {
        const requestData: BotMetadata = {
            bot_type: "Slack",
            responding_enabled: askAIEnabledInput,
            ...partialBotMetadata,
        };
        // Populate the past settings when the toggle was on
        if (askAIEnabledInput) {
            requestData.auto_escalate = autoEscalate;
            requestData.preview_message = previewMessage;
            requestData.auto_respond = autoRespond;
            requestData.channels_selected = selectedChannels;
            requestData.confidence_enabled = confidenceScore;
            requestData.icon_url = iconUrl;
        }
        setAskAIEnabled(askAIEnabledInput);

        api.post(
            `${URLS.serverUrl}${API.saveBotSettingsV2}/slack`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                } else {
                    toast({
                        title: "Updated Settings!",
                        description:
                            "Your settings have been updated successfully.",
                    });
                    // Hit the 'initialize_bot' endpoint when Ask AI is enabled
                    if (askAIEnabledInput) {
                        api.post(
                            URLS.serverUrl + API.initializeBot,
                            {},
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            },
                        );
                    }
                    connectionsQuery.refetch();
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    }

    function updateShortcuts(
        connectionInput: string,
        connectionMetadata: ScopeResponse[],
        connectionPriority: string | undefined,
        connectionIssueType: IssueType | undefined,
        connectionObjectType?: ObjectType | undefined,
        existingConnectionID?: string,
        intercomSyncOnly?: boolean,
        selectedSyncChannels?: ScopeResponse[],
    ) {
        const requestData: BotConnection = {
            bot_type: "Slack",
            // TODO: change this to a unique name for the Slack connection
            connection_unique_name: "Standard",
            connection_source: connectionInput,
            connection_channels: connectionMetadata,
            issue_types: connectionIssueType ? [connectionIssueType] : [],
            operation: "Add",
            object_type: connectionObjectType,
            intercom_sync_only: intercomSyncOnly,
            selected_sync_channels: selectedSyncChannels,
        };
        if (
            connectionPriority &&
            connectionPriority !== "" &&
            connectionPriority !== "None"
        ) {
            requestData.connection_priority = connectionPriority;
        }
        if (existingConnectionID) {
            requestData.operation = "Edit";
            requestData.existing_connection_id = existingConnectionID;
        }

        api.post(URLS.serverUrl + API.updateConnection, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                } else {
                    if (existingConnectionID) {
                        toast({
                            title: "Updated Shortcut!",
                            description:
                                "Your shortcut has been updated successfully.",
                        });
                    } else {
                        toast({
                            title: "Added Shortcut!",
                            description:
                                "Your shortcut has been added successfully.",
                        });
                    }
                }
                connectionsQuery.refetch();
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    }

    useEffect(() => {
        const requestData = {
            types: ["Slack", "GitHubTicket", "Linear", "Jira", "Intercom"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.Slack) {
                            setIntegrationEnabled(true);
                        }
                        if (orgInfo.Slack?.[0]?.scopes) {
                            for (const repo of orgInfo.Slack[0].scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Slack?.[0]?.date) {
                                const date: Date = parseISO(
                                    orgInfo.Slack?.[0]?.date ?? "",
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;
                            }

                            if (orgInfo.Slack?.[0]?.slack_team_id) {
                                temp.slack_team_id =
                                    orgInfo.Slack?.[0]?.slack_team_id ?? "";
                            }
                        }

                        setAdditionalMetadata(temp);
                        const enabledInts: string[] = [];
                        if (orgInfo.GitHubTicket) {
                            enabledInts.push("GitHubTicket");
                        }
                        if (orgInfo.Linear) {
                            enabledInts.push("Linear");
                        } else if (orgInfo.Jira) {
                            // can only have linear or jira
                            enabledInts.push("Jira");
                        }
                        if (orgInfo.Intercom) {
                            enabledInts.push("Intercom");
                        }

                        setEnabledIntegrations(enabledInts);

                        setAdditionalMetadata(temp);
                        setLoadingStateIntegrationEnabled(loadingTypes.loaded);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingStateIntegrationEnabled(loadingTypes.error);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingStateIntegrationEnabled(loadingTypes.error);
            });
    }, [api]);

    const [autoEscalate, setAutoEscalate] = useState<boolean>(false);
    const [previewMessage, setPreviewMessage] = useState<boolean>(false);
    const [autoRespond, setAutoRespond] = useState<boolean>(false);
    const [indexCode, setIndexCode] = useState<boolean>(true);
    const [confidenceScore, setConfidenceScore] = useState<boolean>(true);
    const [iconUrl, setIconUrl] = useState<string>("");
    const [intercomSyncEnabled, setIntercomSyncEnabled] =
        useState<boolean>(false);

    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        [],
    );
    const [selectedSlackSyncChannels, setSelectedSlackSyncChannels] = useState<
        ScopeResponse[]
    >([]);

    const [errDialogOpen, setErrDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (integrationEnabled) {
            const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
            api.get(`${serverUrl}${API.getBotSettingsV2}/Slack`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        const settingsData: BotMetadata = res.data.data;
                        setAskAIEnabled(
                            settingsData.responding_enabled ?? false,
                        );
                        setAutoEscalate(settingsData.auto_escalate ?? false);
                        setAutoRespond(settingsData.auto_respond ?? false);
                        setConfidenceScore(
                            settingsData.confidence_enabled ?? false,
                        );
                        setPreviewMessage(
                            settingsData.preview_message ?? false,
                        );
                        setIconUrl(settingsData.icon_url ?? "");
                        if (
                            settingsData.auto_respond === true &&
                            settingsData.channels_selected !== null
                        ) {
                            setSelectedChannels(
                                settingsData.channels_selected ?? [],
                            );
                        }

                        setAsmTicketChannels(
                            settingsData.asm_ticket_channels ?? [],
                        );
                        setTriggerBasedTicketChannels(
                            settingsData.trigger_based_ticket_channels ?? [],
                        );

                        setIndexCode(settingsData.index_code ?? true);
                        setIntercomSyncEnabled(
                            settingsData.intercom_sync_enabled ?? false,
                        );

                        setLoadingState(loadingTypes.loaded);
                    } else {
                        setErrDialogOpen(true);
                    }
                })
                .catch((res) => {
                    setErrDialogOpen(true);
                });
        }
        setLoadingState(loadingTypes.none);
    }, [integrationEnabled]);

    const navigate = useNavigate();
    const addMoreIndexingRepositories = () => {
        navigate("/admin/integrations/add/slack");
    };
    const addMoreListeningRepositories = () => {
        navigate("/admin/integrations/update/slack");
    };
    const addMoreTriggerBasedRepositories = () => {
        navigate("/admin/integrations/updatetrigger/slack");
    };

    const [refreshPage, setRefreshPage] = useState<boolean>(false);

    const connectionsQuery = useQuery<GetConnectionsResponse[]>({
        queryKey: ["connections_slack"],
        queryFn: async () => {
            const requestData = {
                bot_type: "Slack",
            };
            const response = await api.post(
                URLS.serverUrl + API.getConnections,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            return response.data.data ?? [];
        },
    });

    useEffect(() => {
        if (refreshPage) {
            // keep this here to force update get connections after an update goes through
        }
        if (asmTicketChannels.length !== 0) {
            connectionsQuery.refetch();
            setLoadingStateShortCuts(loadingTypes.loaded);
        } else {
            setLoadingStateShortCuts(loadingTypes.loaded);
        }
    }, [asmTicketChannels, api, refreshPage]);

    useEffect(() => {
        if (connectionsQuery.data) {
            const syncConnection = connectionsQuery.data?.filter(
                (connection) => connection.intercom_sync_only === true,
            );
            if (syncConnection) {
                setSelectedSlackSyncChannels(
                    syncConnection[0]?.connected_integrations
                        ?.selected_sync_channels ?? [],
                );
            }
        }
    }, [connectionsQuery.data]);

    const deleteShortcutMutation = useMutation({
        mutationFn: async (id: string) => {
            const response: AxiosResponse = await api.delete(
                `${URLS.serverUrl}${API.deleteConnection}/${id}`,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            return response;
        },
        onSuccess: () => {
            connectionsQuery.refetch();
            toast({
                title: "Deleted Shortcut!",
                description: "Your shortcut has been deleted successfully.",
            });
        },
        onError: () => {
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    return (
        <div>
            <Box mt={"5%"} ml={"24%"} mr={"24%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Slack"
                        description="Stay in sync with all customer bugs/issues, or feature request threads"
                        SvgIcon={SlackSvg}
                    />
                    {loadingStateIntegrationEnabled === loadingTypes.loaded && (
                        <Flex
                            style={{
                                width: "100%",
                                justifyContent: "flex-end",
                            }}
                        >
                            {!integrationEnabled ? (
                                <Button onClick={slackContinue}>Enable</Button>
                            ) : (
                                <Flex gap="2" direction="column">
                                    <Button disabled={true}>Enabled</Button>
                                    <Button
                                        size="1"
                                        onClick={slackContinue}
                                        mb={"20px"}
                                    >
                                        <ReloadIcon /> Refresh Scopes
                                    </Button>
                                </Flex>
                            )}
                        </Flex>
                    )}
                </Flex>
                {loadingStateIntegrationEnabled === loadingTypes.loading && (
                    <Flex
                        style={{
                            width: "100%",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Flex gap="2" direction="column" mb={"20px"}>
                            <Skeleton>
                                <Button>Enabled</Button>
                            </Skeleton>
                        </Flex>
                    </Flex>
                )}
                {loadingStateIntegrationEnabled === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                                marginBottom: "20px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"center"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction="column">
                                        <Text size={"2"}>
                                            Customer Channels Selected:{" "}
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Channels that Assembly will listen
                                            to and create tickets for all
                                            messages.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreListeningRepositories}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Channels
                                    </Button>
                                </Flex>
                                {asmTicketChannels?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {asmTicketChannels?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {asmTicketChannels.map((item) => (
                                            <Badge key={item.name}>
                                                {item.name}
                                            </Badge>
                                        ))}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                                marginBottom: "20px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"center"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction="column">
                                        <Text size={"2"}>
                                            Reaction Triggered Customer
                                            Channels:{" "}
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Channels where reacting to top level
                                            messages with{" "}
                                            <span style={{ fontSize: "1.5em" }}>
                                                📝
                                            </span>{" "}
                                            creates an Assembly interaction.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={
                                            addMoreTriggerBasedRepositories
                                        }
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Channels
                                    </Button>
                                </Flex>
                                {triggerBasedTicketChannels?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {triggerBasedTicketChannels?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {triggerBasedTicketChannels.map(
                                            (item) => (
                                                <Badge key={item.name}>
                                                    {item.name}
                                                </Badge>
                                            ),
                                        )}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                        <Card
                            style={{
                                padding: "20px",
                                marginBottom: "20px",
                            }}
                        >
                            <Flex
                                direction={"row"}
                                justify={"between"}
                                align={"center"}
                            >
                                <Flex direction={"column"}>
                                    <Text size={"2"}>Index Code</Text>
                                    <Text size={"1"}>
                                        Enable this to index code snippets in
                                        the conversations.
                                    </Text>
                                </Flex>
                                <Switch
                                    checked={indexCode}
                                    onCheckedChange={(checked) => {
                                        setIndexCode(checked);
                                        saveSettings(askAIEnabled, {
                                            index_code: checked,
                                            intercom_sync_enabled:
                                                intercomSyncEnabled,
                                        });
                                    }}
                                />
                            </Flex>
                        </Card>
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction="column">
                                        <Text size={"2"}>
                                            Slack Channels Indexed:{" "}
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Index channels to add to your
                                            knowledge base.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreIndexingRepositories}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Channels
                                    </Button>
                                </Flex>
                                {additionalMetadata?.scopes?.length > 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                <Flex
                                    style={{
                                        paddingRight: "20px",
                                        paddingLeft: "20px",
                                    }}
                                    direction="row"
                                    gap="3"
                                    align="center"
                                    wrap="wrap"
                                >
                                    {additionalMetadata.scopes.map((item) => (
                                        <Badge key={item}>{item}</Badge>
                                    ))}{" "}
                                </Flex>
                            </Flex>
                        </Card>
                        <Flex
                            direction="column"
                            style={{
                                justifyContent: "flex-end",
                                alignItems: "end",
                                marginBottom: "20px",
                            }}
                            gap="3"
                        >
                            {additionalMetadata.indexedOn && (
                                <Flex direction={"row"}>
                                    <Text size={"1"}>
                                        Last Updated:{" "}
                                        {additionalMetadata.indexedOn}{" "}
                                    </Text>
                                </Flex>
                            )}
                        </Flex>

                        {loadingState === loadingTypes.loaded && (
                            <Card
                                style={{
                                    padding: "20px",
                                    marginBottom: "20px",
                                }}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    gap="3"
                                    align={askAIEnabled ? "center" : "start"}
                                >
                                    <Flex direction={"column"}>
                                        <Text size={"2"}>Ask AI </Text>
                                        <Text size={"1"}>
                                            Enable our Assembly Slack bot to
                                            auto-respond to questions within
                                            Slack.
                                        </Text>
                                    </Flex>
                                    <Flex
                                        direction={"column"}
                                        gap="3"
                                        align={"end"}
                                    >
                                        <Switch
                                            checked={askAIEnabled}
                                            onCheckedChange={(checked) => {
                                                setAskAIEnabled(checked);
                                                saveSettings(checked, {
                                                    index_code: indexCode,
                                                    intercom_sync_enabled:
                                                        intercomSyncEnabled,
                                                });
                                            }}
                                        />

                                        {askAIEnabled && (
                                            <SlackConfigPage
                                                autoEscalate={autoEscalate}
                                                setAutoEscalate={
                                                    setAutoEscalate
                                                }
                                                previewMessage={previewMessage}
                                                setPreviewMessage={
                                                    setPreviewMessage
                                                }
                                                autoRespond={autoRespond}
                                                setAutoRespond={setAutoRespond}
                                                indexCode={indexCode}
                                                setIndexCode={setIndexCode}
                                                confidenceScore={
                                                    confidenceScore
                                                }
                                                setConfidenceScore={
                                                    setConfidenceScore
                                                }
                                                iconUrl={iconUrl}
                                                setIconUrl={setIconUrl}
                                                channels={asmTicketChannels}
                                                selectedChannels={
                                                    selectedChannels
                                                }
                                                setSelectedChannels={
                                                    setSelectedChannels
                                                }
                                                errDialogOpen={errDialogOpen}
                                                setErrDialogOpen={
                                                    setErrDialogOpen
                                                }
                                                isMain={true}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Card>
                        )}

                        {loadingStateShortCuts === loadingTypes.loading && (
                            <Skeleton>
                                <Text>
                                    {[...Array(2)].map((_, index) => (
                                        <Text key={null}>{loremIpsum}</Text>
                                    ))}
                                </Text>
                            </Skeleton>
                        )}

                        {loadingStateShortCuts === loadingTypes.loaded && (
                            <Card
                                style={{
                                    paddingRight: "0px",
                                    paddingLeft: "0px",
                                }}
                                mb="5"
                            >
                                <Flex
                                    direction={"column"}
                                    gap="2"
                                    mb="2"
                                    justify={"between"}
                                >
                                    <Flex
                                        direction={"row"}
                                        justify={"between"}
                                        align={"center"}
                                        style={{
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            paddingTop: "5px",
                                            paddingBottom: "5px",
                                        }}
                                    >
                                        <Text size={"2"}>Add Shortcut: </Text>
                                        <Shortcuts
                                            triggerElement={
                                                <Button
                                                    variant="ghost"
                                                    className="py-1"
                                                >
                                                    <PlusIcon />
                                                </Button>
                                            }
                                            enabledIntegrations={
                                                enabledIntegrations
                                            }
                                            saveSettings={updateShortcuts}
                                            description={`React to the slack message with the integration's emoji and we'll automatically create a ticket in the specified integration.`}
                                            botType="Slack"
                                        />
                                    </Flex>
                                    <Text
                                        style={{
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                        }}
                                        size="1"
                                        color="gray"
                                    >
                                        React to the slack message with the
                                        integration's emoji and we'll
                                        automatically create a ticket in the
                                        specified integration.
                                    </Text>
                                    {connectionsQuery.data?.filter(
                                        (item) =>
                                            item.intercom_sync_only === false,
                                    ).length !== 0 && (
                                        <Separator
                                            size={"4"}
                                            mt="1"
                                            mb="1"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                    {connectionsQuery.data?.filter(
                                        (item) =>
                                            item.intercom_sync_only === false,
                                    ).length !== 0 && (
                                        <Flex
                                            style={{
                                                paddingRight: "20px",
                                                paddingLeft: "20px",
                                            }}
                                            direction="column"
                                            gap="3"
                                            align="start"
                                            wrap="wrap"
                                        >
                                            <DataList.Root className="w-full">
                                                {connectionsQuery.data
                                                    ?.filter(
                                                        (item) =>
                                                            item.intercom_sync_only ===
                                                            false,
                                                    )
                                                    .map((item) => {
                                                        let integration =
                                                            item
                                                                ?.connected_integrations
                                                                ?.connection;
                                                        if (
                                                            item
                                                                .connected_integrations
                                                                .priority &&
                                                            item
                                                                .connected_integrations
                                                                .priority !== ""
                                                        ) {
                                                            integration = `${integration} ${item.connected_integrations.priority} Priority`;
                                                        }
                                                        return (
                                                            <DataList.Item
                                                                key={
                                                                    integration
                                                                }
                                                            >
                                                                <DataList.Label className="min-w-[88px]">
                                                                    {`${integrationLabelMap.get(integration) ?? integration} ${integrationToEmoji[integration]}`}
                                                                </DataList.Label>
                                                                <DataList.Value className="flex justify-between gap-1 w-full">
                                                                    <div className="flex-1">
                                                                        <div className="flex items-center gap-1">
                                                                            {item?.connected_integrations?.scopes?.map(
                                                                                (
                                                                                    channel,
                                                                                ) => (
                                                                                    <TooltipProvider
                                                                                        key={
                                                                                            channel.key
                                                                                        }
                                                                                    >
                                                                                        <Tooltip>
                                                                                            <TooltipTrigger
                                                                                                asChild
                                                                                            >
                                                                                                <Badge
                                                                                                    radius="full"
                                                                                                    className="px-2"
                                                                                                >
                                                                                                    {
                                                                                                        channel?.name
                                                                                                    }
                                                                                                </Badge>
                                                                                            </TooltipTrigger>
                                                                                            <TooltipContent className="bg-[#5B5BD6] py-2 px-3 flex flex-col">
                                                                                                Channel/Workspace
                                                                                            </TooltipContent>
                                                                                        </Tooltip>
                                                                                    </TooltipProvider>
                                                                                ),
                                                                            )}
                                                                            {item
                                                                                ?.connected_integrations
                                                                                ?.object_type ===
                                                                            "Conversation" ? (
                                                                                <Badge
                                                                                    radius="full"
                                                                                    className="px-2"
                                                                                    color="purple"
                                                                                >
                                                                                    Conversation
                                                                                </Badge>
                                                                            ) : (
                                                                                item?.connected_integrations?.issue_types?.map(
                                                                                    (
                                                                                        issueType,
                                                                                    ) => (
                                                                                        <TooltipProvider
                                                                                            key={
                                                                                                issueType.id
                                                                                            }
                                                                                        >
                                                                                            <Tooltip>
                                                                                                <TooltipTrigger
                                                                                                    asChild
                                                                                                >
                                                                                                    <Badge
                                                                                                        radius="full"
                                                                                                        key={
                                                                                                            issueType.id
                                                                                                        }
                                                                                                        color="purple"
                                                                                                        className="px-2"
                                                                                                    >
                                                                                                        {
                                                                                                            issueType?.name
                                                                                                        }
                                                                                                    </Badge>
                                                                                                </TooltipTrigger>
                                                                                                <TooltipContent className="bg-[#5B5BD6] py-2 px-3 flex flex-col">
                                                                                                    Ticket
                                                                                                    Type
                                                                                                </TooltipContent>
                                                                                            </Tooltip>
                                                                                        </TooltipProvider>
                                                                                    ),
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center gap-3">
                                                                        <div>
                                                                            <Shortcuts
                                                                                triggerElement={
                                                                                    <Button variant="ghost">
                                                                                        <MixerHorizontalIcon className="w-3 h-3 mr-0" />
                                                                                        Edit
                                                                                    </Button>
                                                                                }
                                                                                enabledIntegrations={
                                                                                    enabledIntegrations
                                                                                }
                                                                                saveSettings={
                                                                                    updateShortcuts
                                                                                }
                                                                                description={`React to the slack message with the integration's emoji and we'll automatically create a ticket in the specified integration.`}
                                                                                botType="Slack"
                                                                                existingConnection={
                                                                                    item
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <AlertDialog>
                                                                                <AlertDialogTrigger
                                                                                    asChild
                                                                                >
                                                                                    <Button variant="ghost">
                                                                                        <TrashIcon className="w-3.5 h-3.5 text-gray-600" />
                                                                                    </Button>
                                                                                </AlertDialogTrigger>
                                                                                <AlertDialogContent
                                                                                    onClick={(
                                                                                        e,
                                                                                    ) =>
                                                                                        e.stopPropagation()
                                                                                    }
                                                                                >
                                                                                    <div>
                                                                                        <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                                                            <AlertDialogTitle>
                                                                                                Are
                                                                                                you
                                                                                                absolutely
                                                                                                sure?
                                                                                            </AlertDialogTitle>
                                                                                            <AlertDialogDescription>
                                                                                                {`This action cannot be undone. This will permanently delete this shortcut: ${integration} ${integrationToEmoji[integration]}`}
                                                                                            </AlertDialogDescription>
                                                                                        </AlertDialogHeader>
                                                                                    </div>
                                                                                    <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                                                        <AlertDialogCancel
                                                                                            onClick={(
                                                                                                e,
                                                                                            ) => {
                                                                                                e.stopPropagation();
                                                                                            }}
                                                                                        >
                                                                                            Cancel
                                                                                        </AlertDialogCancel>
                                                                                        <AlertDialogAction
                                                                                            onClick={(
                                                                                                e,
                                                                                            ) => {
                                                                                                e.stopPropagation();
                                                                                                deleteShortcutMutation.mutate(
                                                                                                    item.id,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            Delete
                                                                                        </AlertDialogAction>
                                                                                        <AlertDialogCancel
                                                                                            className="shadow-none absolute top-2 right-2 px-2 py-0 "
                                                                                            onClick={(
                                                                                                e,
                                                                                            ) => {
                                                                                                e.stopPropagation();
                                                                                            }}
                                                                                        >
                                                                                            <Cross2Icon />
                                                                                        </AlertDialogCancel>
                                                                                    </AlertDialogFooter>
                                                                                </AlertDialogContent>
                                                                            </AlertDialog>
                                                                        </div>
                                                                    </div>
                                                                </DataList.Value>
                                                            </DataList.Item>
                                                        );
                                                    })}{" "}
                                            </DataList.Root>
                                        </Flex>
                                    )}
                                </Flex>
                            </Card>
                        )}
                        {/* card available only for Render and assembly local / prod */}
                        {
                            // orgId === "830bebb5-32cf-4347-a552-b1002fd7386a" ||
                            (orgId === "fc12e902-f8ee-4176-947b-7f716469d1d6" ||
                                orgId ===
                                    "7631a851-e28d-46ac-bb57-0805f570f7fa") && (
                                <Card
                                    style={{
                                        padding: "20px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Flex
                                        direction={"row"}
                                        justify={"between"}
                                        gap="3"
                                    >
                                        <Flex direction={"column"}>
                                            <Text size={"2"}>
                                                Sync Slack conversations to
                                                Intercom
                                            </Text>
                                            <Text size={"1"}>
                                                Have Assembly auto-sync messages
                                                from all Slack channels to
                                                Intercom.
                                            </Text>
                                        </Flex>
                                        <Flex
                                            direction={"column"}
                                            gap="3"
                                            align={"end"}
                                        >
                                            <Switch
                                                checked={intercomSyncEnabled}
                                                onCheckedChange={(
                                                    syncEnabledChecked,
                                                ) => {
                                                    setIntercomSyncEnabled(
                                                        syncEnabledChecked,
                                                    );
                                                    saveSettings(askAIEnabled, {
                                                        index_code: indexCode,
                                                        intercom_sync_enabled:
                                                            syncEnabledChecked,
                                                    });
                                                }}
                                            />
                                            {intercomSyncEnabled && (
                                                <Shortcuts
                                                    triggerElement={
                                                        <DialogTriggerButton>
                                                            <MixerHorizontalIcon className="w-3 h-3 mr-0" />
                                                            Edit
                                                        </DialogTriggerButton>
                                                    }
                                                    enabledIntegrations={
                                                        enabledIntegrations
                                                    }
                                                    saveSettings={
                                                        updateShortcuts
                                                    }
                                                    description={`React to the slack message with the integration's emoji and we'll automatically create a ticket in the specified integration.`}
                                                    botType="Slack"
                                                    existingConnection={
                                                        connectionsQuery.data?.filter(
                                                            (item) =>
                                                                item.intercom_sync_only ===
                                                                true,
                                                        )?.[0]
                                                    }
                                                    channels={asmTicketChannels?.concat(
                                                        triggerBasedTicketChannels,
                                                    )}
                                                    selectedChannels={
                                                        selectedSlackSyncChannels
                                                    }
                                                    setSelectedChannels={
                                                        setSelectedSlackSyncChannels
                                                    }
                                                    title="Sync Slack conversations to Intercom"
                                                />
                                            )}
                                        </Flex>
                                    </Flex>
                                </Card>
                            )
                        }
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default SlackIntegration;
