import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import { ContactsAPI, loadingTypes, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    type AccountsLabel,
    LabelsType,
    type Teams,
} from "@/interfaces/serverData";
import {
    getAccountsLabelIcon,
    getAccountsLabelType,
} from "@/utilities/methods";
import {
    CaretDownIcon,
    CaretUpIcon,
    LinkBreak1Icon,
    Pencil2Icon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { Badge } from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { BookmarkIcon, HotelIcon, UsersIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountsLabelsDialog } from "./AccountsLabelsDialog";
import { Topics } from "./Topics";
import { TagsRowState } from "./DataTable/constants";
import { toast } from "@/component/shadcn/ui/use-toast";
import { AccountsTags } from "./AccountsTags";

interface AccountsLabelsProps {
    teams: Teams[];
}

export const AccountsLabels: React.FC<AccountsLabelsProps> = ({ teams }) => {
    const defaultBadgeColor = "#9B9EF0";
    const { id } = useParams(); // team ID
    const api = useApi();

    const [labelsByGroup, setLabelsByGroup] = useState<
        Map<LabelsType, AccountsLabel[]>
    >(new Map());
    const [labelGroupIsOpen, setLabelGroupIsOpen] = useState<
        Map<string, boolean>
    >(
        new Map([
            [LabelsType.Tier, false],
            [LabelsType.Stage, false],
            [LabelsType.CompanyType, false],
            ["Tags", false],
        ]),
    );
    const [currentTeam, setCurrentTeam] = useState<Teams | undefined>();
    const [tagRowState, setTagRowState] = useState<Map<string, AccountsLabel>>(
        new Map(),
    );
    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );

    useEffect(() => {
        if (id) {
            const team = teams.filter((team) => team.id === id);
            if (team.length === 1) {
                setCurrentTeam(team[0]);
            } else {
                setCurrentTeam(undefined);
            }
        } else {
            setCurrentTeam(undefined);
        }
    }, [id, teams]);

    const updateLabelGroupIsOpen = (
        updates: Partial<Record<string, boolean>>,
    ) => {
        setLabelGroupIsOpen((prevState) => {
            const newMap = new Map(prevState);
            // biome-ignore lint/complexity/noForEach: <explanation>
            Object.entries(updates).forEach(([key, value]) => {
                newMap.set(key as LabelsType, value ?? false);
            });
            return newMap;
        });
    };

    const tagUpdateRowState = (id: string, newState: Partial<AccountsLabel>) => {
        setTagRowState((prevState) => {
            const currentState = prevState.get(id) || {
                id: "",
                color: "",
                name: "",
                description: "",
                teams: [],
                type: LabelsType.AccountTag,
                count: 0
            };
            return new Map(prevState).set(id, {
                ...currentState,
                ...newState,
            });
        });
    };

    // Account Labels
    const fetchAccountsLabels = async (): Promise<AccountsLabel[]> => {
        const { url } = ContactsAPI.getAccountsLabels;
        const response: AxiosResponse<{ data: AccountsLabel[] }> =
            await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch accounts labels");
    };
    const fetchTeamAccountsLabels = async (): Promise<AccountsLabel[]> => {
        const { url } = ContactsAPI.getAccountsLabels;
        const response = await api.get(`${URLS.serverUrl}${url}/team/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };
    const {
        data: accountsLabels = [],
        isLoading: accountsLabelsIsLoading,
        isError: accountsLabelsIsError,
        refetch: refetchAccountsLabels,
    } = useQuery<AccountsLabel[]>({
        queryKey: id ? [`teamAccountsLabels_${id}`] : ["accountsLabels"],
        queryFn: id
            ? () => fetchTeamAccountsLabels()
            : () => fetchAccountsLabels(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    useEffect(() => {
        const tiers: AccountsLabel[] = [];
        const stages: AccountsLabel[] = [];
        const companyTypes: AccountsLabel[] = [];
        const accountTags: AccountsLabel[] = [];
        for (const label of accountsLabels) {
            switch (label.type) {
                case LabelsType.Tier:
                    tiers.push(label);
                    break;
                case LabelsType.Stage:
                    stages.push(label);
                    break;
                case LabelsType.CompanyType:
                    companyTypes.push(label);
                    break;
                case LabelsType.AccountTag:
                    accountTags.push(label);
                    break;
            }
        }
        const newMap = new Map<LabelsType, AccountsLabel[]>();
        newMap.set(LabelsType.Tier, tiers);
        newMap.set(LabelsType.Stage, stages);
        newMap.set(LabelsType.CompanyType, companyTypes);
        newMap.set(LabelsType.AccountTag, accountTags)
        setLabelsByGroup(newMap);

        const newRowState = new Map<string, AccountsLabel>();
        for (let i = 0; i < accountTags.length; i++) {
            const tag: AccountsLabel = accountTags[i];
            newRowState.set(String(i), {
                id: tag.id,
                color: tag.color ?? defaultBadgeColor,
                name: tag.name,
                description: tag.description ?? "",
                teams: tag.teams,
                type: tag.type,
                count: tag.count
            });
        }
        newRowState.set("dialog", {
            id: "",
            color: defaultBadgeColor,
            name: "",
            description: "",
            teams: [],
            type: LabelsType.AccountTag,
            count: 0
        });
        setTagRowState(newRowState);
    }, [accountsLabels]);

    return (
        <div>
            <Box mt={"3%"}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-md font-semibold ml-3">
                            Accounts Labels
                        </h2>
                    </div>
                    <AccountsLabelsDialog
                        type={"Create"}
                        triggerElement={
                            <Button
                                className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                            >
                                Add Label
                                <PlusIcon />
                            </Button>
                        }
                        teams={teams}
                        refetch={refetchAccountsLabels}
                        currentTeam={currentTeam}
                    />
                </div>
                {accountsLabelsIsLoading && (
                    <Skeleton>
                        <Text>Loading...</Text>
                    </Skeleton>
                )}

                {accountsLabelsIsError && (
                    <Callout.Root color="red">
                        <Callout.Text>
                            Failed to load Account Labels. Please try again
                            later.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {!accountsLabelsIsLoading && !accountsLabelsIsError && accountsLabels.length === 0 && (
                    <Callout.Root color="blue">
                        <Callout.Text>
                            No Account Labels available. Please add one.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {!accountsLabelsIsLoading && !accountsLabelsIsError && accountsLabels.length > 0 && (
                    <div className="space-y-4 my-5 w-full">
                        {labelsByGroup &&
                            Array.from(labelsByGroup.entries()).map(
                                ([type, labels]) => (
                                    <Card
                                        key={type}
                                        className={`py-3 px-8 shadow-none w-full flex flex-col gap-3 ${labelGroupIsOpen.get(type) && "pb-5"}`}
                                    >
                                        <div className="font-semibold text-[15px] flex items-center justify-between">
                                            <div className="flex items-center gap-1.5">
                                                {getAccountsLabelType(type)}
                                                {getAccountsLabelIcon(type)}
                                            </div>
                                            {labelGroupIsOpen.get(type) ? (
                                                <Button
                                                    type="button"
                                                    variant="ghost"
                                                    className="text-xs p-0.5"
                                                    onClick={() =>
                                                        updateLabelGroupIsOpen({
                                                            [type]: false,
                                                        })
                                                    }
                                                >
                                                    <CaretUpIcon className="w-4 h-4" />
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    variant="ghost"
                                                    className="text-xs p-0.5"
                                                    onClick={() =>
                                                        updateLabelGroupIsOpen({
                                                            [type]: true,
                                                        })
                                                    }
                                                >
                                                    <CaretDownIcon className="w-4 h-4" />
                                                </Button>
                                            )}
                                        </div>
                                        {labelGroupIsOpen.get(type) &&
                                            (type === LabelsType.AccountTag ? (
                                                <AccountsTags
                                                    data={labels}
                                                    teams={teams}
                                                    rowState={tagRowState}
                                                    updateRowState={tagUpdateRowState}
                                                    refetch={refetchAccountsLabels}
                                                    isLoading={accountsLabelsIsLoading}
                                                    isError={accountsLabelsIsError}
                                                    currentTeam={currentTeam}
                                                />
                                            ) : (
                                                labels.map((label) => (
                                                    <Card
                                                        key={label.id}
                                                        className="p-4 shadow-none w-full flex flex-col gap-2"
                                                    >
                                                        <ContextMenu>
                                                            <ContextMenuTrigger>
                                                                <div className="flex justify-between items-center">
                                                                    <div className="mr-8 flex items-center gap-3">
                                                                        {getAccountsLabelIcon(
                                                                            type,
                                                                            label.color,
                                                                            5,
                                                                        )}
                                                                        <div className="flex flex-col gap-0.5">
                                                                            <h3 className="font-semibold text-[13px] flex items-center gap-2">
                                                                                {
                                                                                    label.name
                                                                                }
                                                                                <Badge
                                                                                    color="gray"
                                                                                    size="1"
                                                                                    radius="full"
                                                                                    variant="soft"
                                                                                    className="m-0.5 text-[11px]"
                                                                                >
                                                                                    <div className="flex flex-row items-center gap-0.5">
                                                                                        <CaretUpIcon />
                                                                                        {
                                                                                            label.count
                                                                                        }
                                                                                    </div>
                                                                                </Badge>
                                                                            </h3>
                                                                            <p className="text-xs text-gray-500">
                                                                                {
                                                                                    label.description
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center gap-2.5">
                                                                        {label.teams
                                                                            .length ===
                                                                            0 ? (
                                                                            <ScnBadge
                                                                                variant="outline"
                                                                                className="gap-1 py-1 px-1.5 text-[11px] font-normal"
                                                                            >
                                                                                <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                                                                    <HotelIcon
                                                                                        className="text-blue9"
                                                                                        strokeWidth={
                                                                                            1.5
                                                                                        }
                                                                                        size={
                                                                                            10
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                Workspace
                                                                            </ScnBadge>
                                                                        ) : (
                                                                            label.teams.map(
                                                                                (
                                                                                    team,
                                                                                ) => (
                                                                                    <ScnBadge
                                                                                        variant="outline"
                                                                                        className="gap-1 py-1 px-1.5 text-[11px] font-normal"
                                                                                        key={
                                                                                            team.id
                                                                                        }
                                                                                    >
                                                                                        <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                                                            <UsersIcon
                                                                                                className="text-red9"
                                                                                                strokeWidth={
                                                                                                    1.5
                                                                                                }
                                                                                                size={
                                                                                                    10
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        {
                                                                                            team.team_name
                                                                                        }
                                                                                    </ScnBadge>
                                                                                ),
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </ContextMenuTrigger>
                                                            <ContextMenuContent className="w-60">
                                                                <AccountsLabelsDialog
                                                                    type={"Edit"}
                                                                    triggerElement={
                                                                        <ContextMenuItem
                                                                            inset
                                                                            onSelect={(
                                                                                e,
                                                                            ) =>
                                                                                e.preventDefault()
                                                                            }
                                                                            className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-2"
                                                                        >
                                                                            <Pencil2Icon className="w-3.5 h-3.5" />
                                                                            Edit
                                                                        </ContextMenuItem>
                                                                    }
                                                                    teams={teams}
                                                                    refetch={
                                                                        refetchAccountsLabels
                                                                    }
                                                                    currentTeam={
                                                                        currentTeam
                                                                    }
                                                                    editingObject={
                                                                        label
                                                                    }
                                                                />
                                                                {id ? (
                                                                    label.teams
                                                                        .length ===
                                                                        1 &&
                                                                        label.teams[0]
                                                                            .id ===
                                                                        id ? (
                                                                        <AccountsLabelsDialog
                                                                            type={
                                                                                "Delete"
                                                                            }
                                                                            triggerElement={
                                                                                <ContextMenuItem
                                                                                    onSelect={(
                                                                                        e,
                                                                                    ) =>
                                                                                        e.preventDefault()
                                                                                    }
                                                                                    className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                                                >
                                                                                    <TrashIcon className="w-4 h-4" />
                                                                                    Delete
                                                                                </ContextMenuItem>
                                                                            }
                                                                            teams={
                                                                                teams
                                                                            }
                                                                            refetch={
                                                                                refetchAccountsLabels
                                                                            }
                                                                            editingObject={
                                                                                label
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <AccountsLabelsDialog
                                                                            type={
                                                                                "Delete"
                                                                            }
                                                                            triggerElement={
                                                                                <ContextMenuItem
                                                                                    onSelect={(
                                                                                        e,
                                                                                    ) =>
                                                                                        e.preventDefault()
                                                                                    }
                                                                                    className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                                                >
                                                                                    <LinkBreak1Icon className="w-3.5 h-3.5" />
                                                                                    Remove
                                                                                    From
                                                                                    Team
                                                                                </ContextMenuItem>
                                                                            }
                                                                            teams={
                                                                                teams
                                                                            }
                                                                            refetch={
                                                                                refetchAccountsLabels
                                                                            }
                                                                            editingObject={
                                                                                label
                                                                            }
                                                                            currentTeam={
                                                                                currentTeam
                                                                            }
                                                                            noDialog={
                                                                                true
                                                                            }
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <AccountsLabelsDialog
                                                                        type={
                                                                            "Delete"
                                                                        }
                                                                        triggerElement={
                                                                            <ContextMenuItem
                                                                                onSelect={(
                                                                                    e,
                                                                                ) =>
                                                                                    e.preventDefault()
                                                                                }
                                                                                className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                                            >
                                                                                <TrashIcon className="w-4 h-4" />
                                                                                Delete
                                                                            </ContextMenuItem>
                                                                        }
                                                                        teams={
                                                                            teams
                                                                        }
                                                                        refetch={
                                                                            refetchAccountsLabels
                                                                        }
                                                                        editingObject={
                                                                            label
                                                                        }
                                                                    />
                                                                )}
                                                            </ContextMenuContent>
                                                        </ContextMenu>
                                                    </Card>
                                                ))))}
                                    </Card>
                                ),
                            )}
                    </div>
                )}
                {/* 
                {!accountsTagsIsLoading && !accountsTagsIsError && accountsTags.length > 0 && (
                    <div className="space-y-4 my-5 w-full">
                        <Card
                            className={`py-3 px-8 shadow-none w-full flex flex-col gap-3 ${labelGroupIsOpen.get("Tags") && "pb-5"}`}
                        >
                            <div className="font-semibold text-[15px] flex items-center justify-between">
                                <div className="flex items-center gap-1.5">
                                    Tags
                                    <BookmarkIcon className="w-4 h-4" />
                                </div>
                                {labelGroupIsOpen.get("Tags") ? (
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="text-xs p-0.5"
                                        onClick={() =>
                                            updateLabelGroupIsOpen({
                                                ["Tags"]: false,
                                            })
                                        }
                                    >
                                        <CaretUpIcon className="w-4 h-4" />
                                    </Button>
                                ) : (
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="text-xs p-0.5"
                                        onClick={() =>
                                            updateLabelGroupIsOpen({
                                                ["Tags"]: true,
                                            })
                                        }
                                    >
                                        <CaretDownIcon className="w-4 h-4" />
                                    </Button>
                                )}
                            </div>
                            {labelGroupIsOpen.get("Tags") &&
                                <AccountsLabels
                                    data={accountsTags}
                                    teams={teams}
                                    rowState={tagRowState}
                                    updateRowState={tagUpdateRowState}
                                    refetch={refetchAccountsLabels}
                                    saveTag={saveTag}
                                    isLoading={accountsTagsIsLoading}
                                    isError={accountsTagsIsError}
                                    currentTeam={currentTeam}
                                />}
                        </Card>
                    </div>
                )} */}
            </Box>
        </div>
    );
};
