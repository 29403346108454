export interface Onboarding {
    id: string;
    created_at: string;
    updated_at: string;
    customer_id?: string;
    company_id?: string;
    org_id: string;
    google_link: string;
}

export interface GetOnboardingResponse {
    onboarding?: Onboarding;
    onboarding_checklist?: OnboardingChecklist[];
}

export enum OnboardingChecklistType {
    Milestone = "Milestone",
    Task = "Task",
}

export enum OnboardingChecklistStatus {
    NotStarted = "Not Started",
    InProgress = "In Progress",
    Completed = "Completed",
    Paused = "Paused",
    NA = "NA",
    Cancelled = "Cancelled",
}

export const onboardingStatuses = [
    OnboardingChecklistStatus.NotStarted,
    OnboardingChecklistStatus.InProgress,
    OnboardingChecklistStatus.Completed,
    OnboardingChecklistStatus.Paused,
    OnboardingChecklistStatus.NA,
    OnboardingChecklistStatus.Cancelled,
];

export interface OnboardingChecklistPayload {
    name: string;
    description: string;
    deadline: Date | undefined;
    start_date: Date | undefined;
    parent_id?: string;
    task_status: OnboardingChecklistStatus;
    enabled: boolean;
    customer_id?: string;
    company_id?: string;
    template_id?: string;
    assignee_user_id?: string;
    user_id: string;
    onboarding_type: OnboardingChecklistType;
    google_sheet_enabled?: boolean;
}

export interface OnboardingChecklist extends OnboardingChecklistPayload {
    id: string;
    created_at: string;
    order_position: number;
    updated_at: string;
    org_id: string;
    total_tasks?: number; // most recent update
    completed_count?: number; // most recent update
    total_children?: number; // might be a bit out of date update
    completed_children?: number; // might be a bit out of date update
    onboarding_id: string;
    source_specific_id?: string;
}

export interface OnboardingChecklistHistory {
    id: string;
    created_at: string;
    content: string;
    metadata: string;
    type: string;
}

export interface PersonalTasks extends OnboardingChecklist {
    onboarding_company_id: string;
    onboarding_customer_id: string;
    onboarding_created_at: string;
    onboarding_google_link: string;
    entity_name: string;
    image_url: string;
}

export interface ChecklistCountsResponse {
    total_count: number;
    completed_count: number;
}

export interface ListOnboardingChecklistPayload {
    company_id_param?: string[];
    customer_id_param?: string[];
    template_id_param?: string[];
    onboarding_type_param?: string[];
    assignee_user_id_param?: string[];
    start_date_param?: string;
    end_date_param?: string;
    task_status_param?: string[];
    query_param?: string;
}
