import IssuesListCard from "@/IssuesTable/IssuesListCard";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { Dialog, DialogContent, DialogTrigger } from "@/component/shadcn/ui/dialog";
import { Input } from "@/component/shadcn/ui/input";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { toast } from "@/component/shadcn/ui/use-toast";
import { getColor } from "@/component/textEditor/ReplyFromPlugin";
import { API, ContactsAPI, URLS } from "@/constant";
import CalendarBadge from "@/design/CalendarBadge";
import { useApi } from "@/interfaces/api";
import {
    type GetOnboardingResponse,
    type OnboardingChecklist,
    OnboardingChecklistType,
} from "@/interfaces/onboardingServerData";
import type {
    Account,
    AccountsLabel,
    CTA,
    CompanyEnrichmentResponse,
    CustomerGroup,
    GetUserResponse,
    IconEntry,
    InteractionCountsPayload,
    InteractionFilters,
    Label,
    News,
    QueriesWithPaginationResponse,
    Query,
    ScopeResponse,
    Teams,
    Topic,
    iFrame,
} from "@/interfaces/serverData";
import {
    getBadgeForAccountsLabel,
    getBadgeForAccountsTag,
    getStatusIcon,
} from "@/utilities/methods";
import { CircularProgress } from "@heroui/progress";
import { AvatarIcon, CheckIcon } from "@radix-ui/react-icons";
import { Badge, DataList, Flex } from "@radix-ui/themes";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import {
    BarChart2,
    BookOpenText,
    CalendarFold,
    CalendarIcon,
    Clapperboard,
    LinkIcon,
    MailboxIcon,
    NewspaperIcon,
    NotepadTextIcon,
    PlusIcon,
    ReplyIcon,
    SendIcon,
    SquareCheckIcon,
    TableProperties,
    TriangleAlert,
} from "lucide-react";
import { useEffect, useState } from "react";
import React from "react";
import type { DateRange } from "react-day-picker";
import Markdown from "react-markdown";
import { useLocation, useNavigate } from "react-router-dom";
import { IssueListType } from "../AdminQueriesPage";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";
import type { AccountsListType } from "./Accounts";
import {
    getMilestoneProgressValue,
    getProgressColor,
} from "./Onboarding/MilestoneCard";
import { SendNewMessageDialog } from "./SendNewMessageDialog";

export interface Company360NewProps {
    orgID: string;
    userID: string;
    account: Account;
    tickets: Query[];
    contacts: Account[];
    updateData: (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => Promise<void>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
    customerGroupsQuery: UseQueryResult<CustomerGroup[], Error>;
    interactionTypesQuery: UseQueryResult<Label[], Error>;
    topics: Topic[];
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    topicsMap: Map<any, any>;
    setInteractionFilters: React.Dispatch<
        React.SetStateAction<InteractionFilters>
    >;
    setTeamIDsFromFilter: React.Dispatch<
        React.SetStateAction<string[] | undefined>
    >;
    listType: AccountsListType;
    loadingEnrichment: boolean;
    enrichedData?: CompanyEnrichmentResponse;
}

export const Company360New = ({
    orgID,
    userID,
    account,
    tickets,
    contacts,
    updateData,
    usersQuery,
    teamsQuery,
    channelsQuery,
    customerGroupsQuery,
    interactionTypesQuery,
    topics,
    topicsMap,
    setInteractionFilters,
    setTeamIDsFromFilter,
    listType,
    loadingEnrichment,
    enrichedData,
}: Company360NewProps) => {
    const api = useApi();
    const navigate = useNavigate();
    const location = useLocation();

    const [needsResponseCount, setNeedsResponseCount] = useState<number>();
    const [breachingCount, setBreachingCount] = useState<number>();

    // Process last interaction date
    let lastTicketDate = new Date(account.last_ticket?.created_at);
    if (
        Number.isNaN(lastTicketDate.getTime()) ||
        !account.last_ticket?.created_at
    ) {
        lastTicketDate = new Date();
    }
    const today = new Date();
    const isToday =
        lastTicketDate.getDate() === today.getDate() &&
        lastTicketDate.getMonth() === today.getMonth() &&
        lastTicketDate.getFullYear() === today.getFullYear();
    let date: string;
    if (isToday) {
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleTimeString(userLocale, {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    } else {
        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    }

    const BreachingIcon = getStatusIcon("Breaching");
    const NeedsResponseIcon = getStatusIcon("NeedsResponse");
    useEffect(() => {
        let nrCount = 0;
        let bCount = 0;

        for (const interaction of tickets) {
            if (interaction.ticket_status === "NeedsResponse") {
                nrCount += 1;
            }
            if (interaction.ticket_status === "Breaching") {
                bCount += 1;
            }
        }
        setNeedsResponseCount(nrCount);
        setBreachingCount(bCount);
    }, [tickets]);

    const [range, setRange] = useState<DateRange | undefined>({
        from: new Date(new Date().setDate(new Date().getDate() - 14)),
        to: new Date(),
    });

    // Onboarding
    const fetchMilestones = async (): Promise<GetOnboardingResponse> => {
        try {
            const { url } = ContactsAPI.getOnboardingChecklist;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    company_id: account.id,
                    onboarding_type: OnboardingChecklistType.Milestone,
                },
            });
            if (response.data === null) {
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            }
            if (response.status === 200) {
                return response.data.data;
            }
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        }
    };

    const {
        data: onboardingData,
        isLoading: loadingOnboardingData,
        isError: errorOnboardingData,
        refetch: refetchOnboardingData,
    } = useQuery({
        queryKey: ["milestones", account.id],
        queryFn: fetchMilestones,
    });

    const [onboardingItems, setOnboardingItems] = useState<
        Map<string, OnboardingChecklist>
    >(
        new Map(
            onboardingData?.onboarding_checklist?.map((item) => [
                item.id,
                item,
            ]),
        ),
    );
    useEffect(() => {
        setOnboardingItems(
            new Map(
                onboardingData?.onboarding_checklist?.map((item) => [
                    item.id,
                    item,
                ]),
            ),
        );
    }, [onboardingData]);

    const foundUser: GetUserResponse | undefined = (
        usersQuery?.data ?? []
    ).find((user) => user.id === account?.assignee_user_id);
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;
    const renewalDate = account?.renewal_date
        ? new Date(account?.renewal_date)
        : new Date();
    const formattedRenewalDate = renewalDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timeZone: "UTC",
    });
    const [serviceIcons, setServiceIcons] = useState<Set<IconEntry>>(
        new Set<IconEntry>(),
    );
    useEffect(() => {
        const serviceNames = Object.keys(account?.metadata ?? {});
        const serviceIcons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                serviceIcons.add({
                    Component: IconComponent,
                    props: {
                        width: 18,
                        height: 18,
                        style: {
                            marginLeft: "-2px",
                            marginRight: "-2px",
                        },
                    },
                });
            }
        });
        setServiceIcons(serviceIcons);
    }, [account?.metadata]);

    // Recent tickets
    const fetchRecentCompanyTickets =
        async (): Promise<QueriesWithPaginationResponse> => {
            try {
                const filters: InteractionFilters = {};
                filters.company_id = [account?.id];
                const requestData: InteractionCountsPayload = {
                    filters: filters,
                };

                const params = new URLSearchParams();
                params.append("limit", "3");
                params.append("offset", "0");

                const response: AxiosResponse<{
                    data: QueriesWithPaginationResponse;
                }> = await api.post(
                    `${URLS.serverUrl}${API.interactionsWithPagination}?${params.toString()}`,
                    requestData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    },
                );

                if (response.status === 200) {
                    return response.data.data;
                }

                return {
                    data: [],
                    has_next_page: false,
                    next_cursor: 0,
                    currentStatus: "None",
                };
            } catch (error) {
                console.error("Error fetching recent queries:", error);
                return {
                    data: [],
                    has_next_page: false,
                    next_cursor: 0,
                    currentStatus: "None",
                };
            }
        };
    const {
        data: recentTicketsData,
        isLoading: recentTicketsIsLoading,
        refetch: refetchRecentTickets,
    } = useQuery({
        queryKey: ["recent_tickets", account.id],
        queryFn: () => fetchRecentCompanyTickets(),
        refetchInterval: 60000, // refetch every 60 secs
        refetchOnWindowFocus: true,
    });

    // Call to Actions
    const fetchCTAs = async (): Promise<CTA[]> => {
        try {
            const { url } = ContactsAPI.getCTAs;
            const response = await api.get(
                `${URLS.serverUrl}${url}/${account?.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );

            if (
                response.status === 200 &&
                response.data &&
                response.data.data
            ) {
                const ctas: CTA[] = response.data.data;
                return ctas;
            }
            return [];
        } catch (error) {
            console.error("Error fetching call to actions", error);
            return [];
        }
    };
    const {
        data: ctaData,
        isLoading: loadingCtaData,
        isError: errorCtaData,
        refetch: refetchCtagData,
    } = useQuery({
        queryKey: ["cta", account.id],
        queryFn: fetchCTAs,
    });

    // News
    const fetchNews = async (): Promise<News[]> => {
        try {
            const { url } = ContactsAPI.getNews;
            const response = await api.get(
                `${URLS.serverUrl}${url}/${account?.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );

            if (
                response.status === 200 &&
                response.data &&
                response.data.data
            ) {
                const news: News[] = response.data.data;
                return news;
            }
            return [];
        } catch (error) {
            console.error("Error fetching call to actions", error);
            return [];
        }
    };
    const {
        data: newsData,
        isLoading: loadingNewsData,
        isError: errorNewsData,
        refetch: refetchNewsData,
    } = useQuery({
        queryKey: ["news", account.id],
        queryFn: fetchNews,
    });

    // iFrames
    const fetchIFrames = async (): Promise<iFrame[]> => {
        try {
            const { url } = ContactsAPI.getIFrames;
            const response = await api.get(
                `${URLS.serverUrl}${url}/${account?.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );

            if (
                response.status === 200 &&
                response.data &&
                response.data.data
            ) {
                const news: iFrame[] = response.data.data;
                return news;
            }
            return [];
        } catch (error) {
            console.error("Error fetching call to actions", error);
            return [];
        }
    };
    const {
        data: iFramesData,
        isLoading: loadingiFramesData,
        isError: erroriFramesData,
        refetch: refetchiFramesData,
    } = useQuery({
        queryKey: ["iframes", account.id],
        queryFn: fetchIFrames,
    });

    const addIFrames = async () => {
        try {
            const requestData = {
                company_id: account.id,
                src: iframeURL
            }
            const { url } = ContactsAPI.addIFrames;
            const response = await api.put(
                `${URLS.serverUrl}${url}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status !== 200) {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            } else {
                toast({
                    title: "Added iframe!",
                    description: "Added iframe successfully!",
                });
                refetchiFramesData()
                setDialogOpen(false)
            }
        } catch (error) {
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        }
    };

    // Dummy Functions for issues list card
    const onSelectRecentTickets = (
        checked: boolean,
        issueId: string,
        group: string,
        event?: React.MouseEvent<Element>,
    ): void => {
        return;
    };
    const openContextMenuRecentTickets = (issue: Query): void => {
        return;
    };

    const [dialogOpen, setDialogOpen] = useState(false);
    const [iframeURL, setiframeURL] = useState<string>("");

    return (
        <Flex direction="column" gap="4" className="py-2 px-4">
            <div className="flex items-center gap-3 justify-between w-full">
                <Card className="col-span-2 w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 pt-4 pb-3.5">
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between">
                            <div className="text-sm font-semibold">
                                Suggested Actions
                            </div>
                            <Clapperboard className="h-4 w-4" />
                        </div>
                        <div className="overflow-y-auto flex flex-col gap-2">
                            {(ctaData?.length ?? 0) > 0 ? (
                                ctaData?.map((cta) => {
                                    return (
                                        <div
                                            key={cta.id}
                                            className="w-full flex items-center gap-3"
                                        >
                                            <div>
                                                {(() => {
                                                    switch (cta.action_type) {
                                                        case "send_checkin":
                                                        case "usage_dropped":
                                                            return (
                                                                <SendNewMessageDialog
                                                                    accountType="company"
                                                                    triggerElement={
                                                                        <Button
                                                                            className="text-sm h-[1.5rem] px-2.5"
                                                                            size="sm"
                                                                            variant="secondary"
                                                                        >
                                                                            <div className="flex items-center gap-1.5 w-full">
                                                                                <SendIcon className="w-3 h-3 flex-shrink-0" />
                                                                            </div>
                                                                        </Button>
                                                                    }
                                                                    account={
                                                                        account
                                                                    }
                                                                    updateData={
                                                                        updateData
                                                                    }
                                                                />
                                                            );
                                                        case "reply_to_interaction":
                                                            return (
                                                                <Button
                                                                    className="text-sm h-[1.5rem] px-2.5"
                                                                    size="sm"
                                                                    variant="secondary"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            cta
                                                                                ?.metadata[
                                                                                "ticket"
                                                                            ]
                                                                                ? `/issue/${cta.metadata["ticket"]}`
                                                                                : `${location.pathname}/interactions`,
                                                                            {
                                                                                state: "issues",
                                                                            },
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="flex items-center gap-1.5 w-full">
                                                                        <ReplyIcon className="w-3 h-3 flex-shrink-0" />
                                                                    </div>
                                                                </Button>
                                                            );
                                                        case "upcoming_renewal":
                                                            return (
                                                                <Button
                                                                    className="text-sm h-[1.5rem] px-2.5"
                                                                    size="sm"
                                                                    variant="secondary"
                                                                >
                                                                    <div className="flex items-center gap-1.5 w-full">
                                                                        <CalendarFold className="w-3 h-3 flex-shrink-0" />
                                                                    </div>
                                                                </Button>
                                                            );
                                                        case "upcoming_milestone":
                                                            return (
                                                                <Button
                                                                    className="text-sm h-[1.5rem] px-2.5"
                                                                    size="sm"
                                                                    variant="secondary"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `${location.pathname}/milestones`,
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="flex items-center gap-1.5 w-full">
                                                                        <SquareCheckIcon className="w-3 h-3 flex-shrink-0" />
                                                                    </div>
                                                                </Button>
                                                            );
                                                        case "overdue_milestone":
                                                            return (
                                                                <Button
                                                                    className="text-sm h-[1.5rem] px-2.5"
                                                                    size="sm"
                                                                    variant="secondary"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `${location.pathname}/milestones`,
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="flex items-center gap-1.5 w-full">
                                                                        <TriangleAlert className="w-3 h-3 flex-shrink-0" />
                                                                    </div>
                                                                </Button>
                                                            );
                                                        default:
                                                            return null;
                                                    }
                                                })()}
                                            </div>
                                            <div className="text-sm">
                                                {cta.content}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="w-full flex items-center gap-3 my-0.5 text-sm">
                                    <SendNewMessageDialog
                                        accountType="company"
                                        triggerElement={
                                            <Button
                                                className="text-sm h-[1.5rem] px-2.5"
                                                size="sm"
                                                variant="secondary"
                                            >
                                                <div className="flex items-center gap-1.5 w-full">
                                                    <SendIcon className="w-3 h-3" />
                                                </div>
                                            </Button>
                                        }
                                        account={account}
                                        updateData={updateData}
                                    />
                                    Send Checkin to the company
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
                <Card className="min-w-[190px] rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start">
                    <div className="flex flex-col gap-0.5">
                        <div className="text-sm font-semibold">
                            Last Interaction
                        </div>
                        <div className="text-md">
                            {account?.last_ticket
                                ? isToday
                                    ? `Today, ${date}`
                                    : date
                                : "Never"}
                        </div>
                    </div>
                    <MailboxIcon className="h-4 w-4 text-primary" />
                </Card>
                {/* TODO: add links to interactions page with breaching filter */}
                <Card className="min-w-[180px] rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start">
                    <div className="flex flex-col gap-0.5">
                        <div className="text-sm font-semibold">Breaching</div>
                        <div className="text-md">{`${breachingCount} Interactions`}</div>
                    </div>
                    <BreachingIcon className="h-4 w-4 text-#FFE9ED" />
                </Card>
                <Card className="min-w-[180px] rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start">
                    <div className="flex flex-col gap-0.5">
                        <div className="text-sm font-semibold">
                            Needs Response
                        </div>
                        <div className="text-md">{`${needsResponseCount} Interactions`}</div>
                    </div>
                    <NeedsResponseIcon className="h-4 w-4 text-#E6E9E8" />
                </Card>
            </div>
            <div className="flex items-start gap-3 justify-between w-full">
                <div className="flex flex-col gap-3 w-3/5">
                    <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-0.5 w-full">
                        <div className="flex justify-between">
                            <div className="flex items-center gap-2 align-right">
                                <div className="text-sm font-semibold">
                                    Charts
                                </div>
                                <Dialog open={dialogOpen} onOpenChange={setDialogOpen} modal={true}>
                                    <DialogTrigger>
                                        <div>
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <div>
                                                            <Button
                                                                size="sm"
                                                                variant="secondary"
                                                                className="text-xs h-5 px-1"
                                                                onClick={() => setDialogOpen(!dialogOpen)}
                                                            >
                                                                <PlusIcon className="w-3 h-3 flex-shrink-0" />
                                                            </Button>
                                                        </div>
                                                    </TooltipTrigger>
                                                    <TooltipContent
                                                        side="top"
                                                        align="start"
                                                        className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col text-xs w-[100px] z-50"
                                                    >
                                                        Add iFrame
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                    </DialogTrigger>
                                    <DialogContent className="py-[40px] px-[50px] max-w-[650px] max-h-[750px] flex flex-col gap-3">
                                        <div className="text-lg font-semibold">
                                            Embed an iFrame
                                        </div>
                                        <div className="text-gray-600 -mt-1">
                                            Add your URL and an iframe of that graph/chart will be added to this account's dashboard.
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="flex-shrink-0 text-sm">
                                                src url:
                                            </div>
                                            <Input
                                                value={iframeURL}
                                                onChange={(e) => setiframeURL(e.target.value)}
                                                className="text-sm"
                                            />
                                        </div>
                                        <Button
                                            type="submit"
                                            className="ml-auto"
                                            onClick={addIFrames}
                                        >
                                            Submit
                                        </Button>
                                    </DialogContent>
                                </Dialog>
                            </div>
                            <BarChart2 className="h-4 w-4" />
                        </div>
                        {(iFramesData?.length ?? 0) > 0 ? (
                            iFramesData?.map((iframe) => (
                                <iframe
                                    key={iframe.src}
                                    title="Assembly Analytics"
                                    width="600"
                                    height="300"
                                    src={iframe.src}
                                    frameBorder="0"
                                    style={{ border: "0" }}
                                    allowFullScreen
                                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                                />
                            ))
                        ) : (
                            // TODO: currently using this as the default chart to show for demo purposes
                            <iframe
                                title="Assembly Analytics"
                                width="600"
                                height="300"
                                src="https://lookerstudio.google.com/embed/reporting/a8a3536f-f51a-4c1c-993f-13aca285230d/page/p_0l5vh6qxqd"
                                frameBorder="0"
                                style={{ border: "0" }}
                                allowFullScreen
                                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                            />
                        )}



                    </Card>
                    <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-0.5 w-full max-h-[350px]">
                        <div className="flex justify-between">
                            <div className="text-sm font-semibold">
                                Recent Interactions
                            </div>
                            <MailboxIcon className="h-4 w-4" />
                        </div>
                        <div className="h-full overflow-y-auto mt-2">
                            {recentTicketsData?.data?.map((ticket) => {
                                return (
                                    <IssuesListCard
                                        key={ticket.id}
                                        issue={ticket}
                                        interactionTypes={
                                            interactionTypesQuery.data ?? []
                                        }
                                        users={usersQuery?.data ?? []}
                                        listType={IssueListType.Issues}
                                        onSelect={onSelectRecentTickets}
                                        isSelected={false}
                                        openContextMenuForSingleIssue={
                                            openContextMenuRecentTickets
                                        }
                                        teams={[]}
                                        refetch={refetchRecentTickets}
                                        noCheckbox={true}
                                    />
                                );
                            })}
                        </div>
                    </Card>
                </div>

                <div className="flex flex-col gap-3 w-2/5">
                    {(newsData?.length ?? 0) > 0 && (
                        <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-2 w-full">
                            <div className="flex justify-between items-start ">
                                <div className="text-sm font-semibold">
                                    News
                                </div>
                                <NewspaperIcon className="h-4 w-4" />
                            </div>
                            <div className="flex flex-col gap-2.5 text-sm">
                                {newsData?.map((news) => {
                                    return (
                                        <div
                                            key={news.id}
                                            className="flex items-center gap-3"
                                        >
                                            <LinkIcon className="h-4 w-4 text-blue-500 flex-shrink-0" />
                                            <div>
                                                {news.url ? (
                                                    <a
                                                        href={news.url}
                                                        className="text-blue-500 hover:underline"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {news.content}
                                                    </a>
                                                ) : (
                                                    <span>{news.content}</span>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Card>
                    )}
                    {enrichedData && !loadingEnrichment && (
                        <Card
                            className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-2 w-full"
                            onClick={() =>
                                navigate(`${location.pathname}/attributes`)
                            }
                        >
                            <div className="flex justify-between items-start ">
                                <div className="text-sm font-semibold">
                                    Company Summary
                                </div>
                                <BookOpenText className="h-4 w-4" />
                            </div>

                            <div className="flex flex-col gap-1 mx-1">
                                {enrichedData?.headline && (
                                    <div className="text-sm">
                                        {enrichedData?.headline}
                                    </div>
                                )}

                                {enrichedData?.summary && (
                                    <div className="text-sm">
                                        {enrichedData?.summary}
                                    </div>
                                )}

                                <div className="flex flex-row flex-wrap gap-2 pt-1">
                                    {enrichedData?.latest_funding_stage && (
                                        <Badge variant="soft" color="gray">
                                            Latest Funding Stage:{" "}
                                            {enrichedData?.latest_funding_stage?.replace(
                                                /_/g,
                                                " ",
                                            )}
                                        </Badge>
                                    )}

                                    {enrichedData?.total_funding_raised &&
                                        enrichedData?.total_funding_raised !==
                                        0 &&
                                        enrichedData?.total_funding_raised !==
                                        null &&
                                        enrichedData?.total_funding_raised !==
                                        undefined && (
                                            <Badge variant="soft" color="green">
                                                Total Funding Raised: $
                                                {
                                                    enrichedData?.total_funding_raised
                                                }
                                            </Badge>
                                        )}

                                    {enrichedData?.last_funding_date && (
                                        <Badge variant="soft" color="blue">
                                            Last Funding Date:{" "}
                                            {enrichedData?.last_funding_date}
                                        </Badge>
                                    )}

                                    {enrichedData?.number_funding_rounds &&
                                        enrichedData?.number_funding_rounds !==
                                        0 &&
                                        enrichedData?.number_funding_rounds !==
                                        null &&
                                        enrichedData?.number_funding_rounds !==
                                        undefined && (
                                            <Badge variant="soft" color="iris">
                                                {
                                                    enrichedData?.number_funding_rounds
                                                }{" "}
                                                Funding Round(s)
                                            </Badge>
                                        )}
                                </div>

                                {enrichedData?.tags && (
                                    <div className="flex flex-wrap gap-2 pt-2">
                                        {enrichedData?.tags.map(
                                            (tag, index) => (
                                                <Badge
                                                    key={tag}
                                                    variant="soft"
                                                    color={getColor(index)}
                                                    className="h-5"
                                                >
                                                    {tag}
                                                </Badge>
                                            ),
                                        )}
                                    </div>
                                )}
                            </div>
                        </Card>
                    )}
                    {onboardingItems.size > 0 ? (
                        <Card
                            className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-2 w-full max-h-[250px]"
                            onClick={() =>
                                navigate(`${location.pathname}/milestones`)
                            }
                        >
                            <div className="flex justify-between items-start ">
                                <div className="text-sm font-semibold">
                                    Milestones
                                </div>
                                <SquareCheckIcon className="h-4 w-4" />
                            </div>
                            <div className="flex flex-col gap-2">
                                {Array.from(onboardingItems.values())
                                    .filter(
                                        (item) =>
                                            item.onboarding_type ===
                                            OnboardingChecklistType.Milestone,
                                    )
                                    .slice(0, 3)
                                    .map((item) => {
                                        const foundUser:
                                            | GetUserResponse
                                            | undefined = (
                                                usersQuery?.data ?? []
                                            ).find(
                                                (user) =>
                                                    user.id ===
                                                    item.assignee_user_id,
                                            );
                                        const pictureURL =
                                            foundUser?.picture_url ?? "";
                                        const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;
                                        return (
                                            <Card
                                                key={item.id}
                                                className="flex items-center gap-2 justify-between rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none px-4 py-3"
                                            >
                                                <div className="flex items-center gap-2">
                                                    <span className="text-[13px] font-medium">
                                                        {item.name}
                                                    </span>
                                                    <Badge
                                                        color={"gray"}
                                                        size="2"
                                                        variant="outline"
                                                        className="ring-[0.8px] text-gray-400 ring-[#E0E1E6] rounded-md"
                                                    >
                                                        {item.completed_count} /{" "}
                                                        {item.total_tasks}
                                                        <CircularProgress
                                                            aria-label="Loading..."
                                                            showValueLabel={
                                                                getMilestoneProgressValue(
                                                                    item.total_tasks ??
                                                                    0,
                                                                    item.completed_count ??
                                                                    0,
                                                                ) === 1
                                                            }
                                                            classNames={{
                                                                svg: "w-5 h-5",
                                                                track: "stroke-gray-200",
                                                                indicator:
                                                                    getProgressColor(
                                                                        item,
                                                                        item.completed_count ??
                                                                        0,
                                                                        item.total_tasks ??
                                                                        0,
                                                                    ),
                                                            }}
                                                            strokeWidth={4}
                                                            size="sm"
                                                            valueLabel={
                                                                <CheckIcon className="w-3 h-3" />
                                                            }
                                                            value={
                                                                getMilestoneProgressValue(
                                                                    item.total_tasks ??
                                                                    0,
                                                                    item.completed_count ??
                                                                    0,
                                                                ) * 100
                                                            }
                                                        />
                                                    </Badge>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    {item.deadline && (
                                                        <CalendarBadge
                                                            date={item.deadline}
                                                            iconColor="text-red-500"
                                                        />
                                                    )}

                                                    {item.assignee_user_id &&
                                                        item.assignee_user_id !==
                                                        "noAssignee" ? (
                                                        <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                                            {pictureURL && (
                                                                <img
                                                                    className="lb-avatar-image"
                                                                    src={
                                                                        pictureURL
                                                                    }
                                                                    alt={
                                                                        userName
                                                                    }
                                                                />
                                                            )}
                                                            <span>
                                                                {userName ?? ""}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <AvatarIcon className="w-6 h-6 mx-1" />
                                                    )}
                                                </div>
                                            </Card>
                                        );
                                    })}
                            </div>
                        </Card>
                    ) : account?.notes ? (
                        <Card
                            className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start"
                            onClick={() =>
                                navigate(`${location.pathname}/notes`)
                            }
                        >
                            <div className="flex flex-col gap-0.5 w-full">
                                <div className="text-sm font-semibold">
                                    Notes
                                </div>
                                <div className="flex-grow overflow-hidden">
                                    <Markdown
                                        className="text-sm text-gray-500"
                                        components={{
                                            a: ({ node, ...props }) => (
                                                <a
                                                    {...props}
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                        color: "#5B5BD6",
                                                    }}
                                                />
                                            ),
                                            code(props) {
                                                const { children } = props;
                                                return (
                                                    <code className="text-balance">
                                                        {children}
                                                    </code>
                                                );
                                            },
                                            ul: ({ children }) => (
                                                <ul className="list-disc pl-5">
                                                    {children}
                                                </ul> // Unordered list with bullets
                                            ),
                                            ol: ({ children }) => (
                                                <ol className="list-decimal pl-5">
                                                    {children}
                                                </ol> // Ordered list with numbers
                                            ),
                                            li: ({ children }) => (
                                                <li className="ml-2">
                                                    {children}
                                                </li> // Spacing for list items
                                            ),
                                            p: ({ children }) => (
                                                <p className="leading-tight">
                                                    {children}
                                                </p> // For paragraphs (if needed)
                                            ),
                                        }}
                                    >
                                        {account?.notes}
                                    </Markdown>
                                </div>
                            </div>
                            <NotepadTextIcon className="h-4 w-4" />
                        </Card>
                    ) : (
                        <Card
                            className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-2 w-full"
                            onClick={() =>
                                navigate(`${location.pathname}/attributes`)
                            }
                        >
                            <div className="flex justify-between items-start ">
                                <div className="text-sm font-semibold">
                                    Attributes
                                </div>
                                <TableProperties className="h-4 w-4" />
                            </div>
                            <div className="flex flex-col gap-2">
                                {account?.attributes_schema ? (
                                    <DataList.Root
                                        size="3"
                                        trim={"both"}
                                        className="my-2 mb-4"
                                    >
                                        <DataList.Item
                                            align="center"
                                            className="w-full flex flex-wrap border border-gray-300 m-0 -my-2.5"
                                        >
                                            <DataList.Label className="text-sm w-[250px] border-r border-gray-300 p-2.5">
                                                Contract Value
                                            </DataList.Label>
                                            <DataList.Value className="text-sm">
                                                {(account?.contract_value ||
                                                    account?.contract_type) && (
                                                        <Badge
                                                            color="mint"
                                                            size="2"
                                                            radius="medium"
                                                            variant="soft"
                                                            className="text-xs items-center"
                                                        >
                                                            <p>
                                                                {account.contract_type &&
                                                                    account.contract_value
                                                                    ? `${account.contract_type} | $${account.contract_value}`
                                                                    : account.contract_type
                                                                        ? account.contract_type
                                                                        : account.contract_value
                                                                            ? `$${account.contract_value}`
                                                                            : null}
                                                            </p>
                                                        </Badge>
                                                    )}
                                            </DataList.Value>
                                        </DataList.Item>
                                        {account?.attributes_schema?.custom?.map(
                                            (key, index) => (
                                                <DataList.Item
                                                    align="center"
                                                    className={
                                                        "w-full flex border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                                    }
                                                    key={key}
                                                >
                                                    <DataList.Label className="text-sm w-[250px] border-r border-gray-300 p-2.5">
                                                        {key}
                                                    </DataList.Label>
                                                    <DataList.Value className="text-sm py-2.5 px-3 flex-1 min-w-0 break-words whitespace-normal overflow-hidden">
                                                        {String(
                                                            account
                                                                ?.additional_properties?.[
                                                            key
                                                            ] ?? "",
                                                        )}
                                                    </DataList.Value>
                                                </DataList.Item>
                                            ),
                                        )}
                                    </DataList.Root>
                                ) : (
                                    // Default trimmed set of attributes to show
                                    <DataList.Root
                                        size="3"
                                        trim={"both"}
                                        className="my-2 mb-4"
                                    >
                                        <DataList.Item
                                            align="center"
                                            className="w-full flex flex-wrap border border-gray-300 m-0 -my-2.5"
                                        >
                                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                                Assignee
                                            </DataList.Label>
                                            <DataList.Value>
                                                {account.assignee_user_id &&
                                                    account.assignee_user_id !==
                                                    "" &&
                                                    pictureURL ? (
                                                    <div className="flex items-center gap-2 text-sm">
                                                        <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                                            <img
                                                                className="lb-avatar-image"
                                                                src={pictureURL}
                                                                alt={userName}
                                                            />
                                                            <span>
                                                                {userName ?? ""}
                                                            </span>
                                                        </div>
                                                        {userName}
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center gap-2 text-sm">
                                                        <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                                            <AvatarIcon className="w-5 h-5" />
                                                        </div>
                                                        No Assignee
                                                    </div>
                                                )}
                                            </DataList.Value>
                                        </DataList.Item>
                                        {(account?.contract_value ||
                                            account?.contract_type) && (
                                                <DataList.Item
                                                    align="center"
                                                    className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                                >
                                                    <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                                        Contract Value
                                                    </DataList.Label>
                                                    <DataList.Value className="text-sm">
                                                        <Badge
                                                            color="mint"
                                                            size="2"
                                                            radius="medium"
                                                            variant="soft"
                                                            className="text-xs items-center"
                                                        >
                                                            <p>
                                                                {account.contract_type &&
                                                                    account.contract_value
                                                                    ? `${account.contract_type} | $${account.contract_value}`
                                                                    : account.contract_type
                                                                        ? account.contract_type
                                                                        : account.contract_value
                                                                            ? `$${account.contract_value}`
                                                                            : null}
                                                            </p>
                                                        </Badge>
                                                    </DataList.Value>
                                                </DataList.Item>
                                            )}
                                        {account?.renewal_date && (
                                            <DataList.Item
                                                align="center"
                                                className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                            >
                                                <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                                    Renewal Date
                                                </DataList.Label>
                                                <DataList.Value className="text-sm">
                                                    {account?.renewal_date && (
                                                        <div className="flex items-center gap-2">
                                                            <CalendarIcon />
                                                            {
                                                                formattedRenewalDate
                                                            }
                                                        </div>
                                                    )}
                                                </DataList.Value>
                                            </DataList.Item>
                                        )}
                                        {((account?.labels &&
                                            account?.labels.length > 0) ||
                                            (account?.tags &&
                                                account?.tags.length > 0)) && (
                                                <DataList.Item
                                                    align="center"
                                                    className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                                >
                                                    <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                                        Labels
                                                    </DataList.Label>
                                                    <DataList.Value className="text-sm py-2 flex-1 min-w-0">
                                                        <div className="flex flex-wrap gap-2">
                                                            {account?.labels?.map(
                                                                (
                                                                    label: AccountsLabel,
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            label.id
                                                                        }
                                                                        className="inline-flex whitespace-nowrap"
                                                                    >
                                                                        {getBadgeForAccountsLabel(
                                                                            label,
                                                                            false,
                                                                            true,
                                                                        )}
                                                                    </div>
                                                                ),
                                                            )}
                                                            {account?.tags?.map(
                                                                (
                                                                    tag: AccountsLabel,
                                                                ) => (
                                                                    <div
                                                                        key={tag.id}
                                                                        className="inline-flex whitespace-nowrap"
                                                                    >
                                                                        {getBadgeForAccountsTag(
                                                                            tag,
                                                                        )}
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                    </DataList.Value>
                                                </DataList.Item>
                                            )}

                                        <DataList.Item
                                            align="center"
                                            className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                        >
                                            <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                                Sources
                                            </DataList.Label>
                                            <DataList.Value>
                                                {account?.metadata && (
                                                    <div className="flex flex-row mx-1">
                                                        {Array.from(
                                                            serviceIcons,
                                                        ).map((icon) =>
                                                            React.createElement(
                                                                icon.Component,
                                                                icon.props,
                                                            ),
                                                        )}
                                                    </div>
                                                )}
                                            </DataList.Value>
                                        </DataList.Item>
                                    </DataList.Root>
                                )}
                            </div>
                        </Card>
                    )}
                </div>
            </div>
        </Flex>
    );
};
