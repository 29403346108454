"use client";

import {
    type ColumnFiltersState,
    type SortingState,
    type VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import * as React from "react";

import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";

import { Button } from "@/component/shadcn/ui/button";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/component/shadcn/ui/table";
import type { GetUserResponse } from "@/interfaces/serverData";
import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { generateOnboardingColumns } from "./OnboardingTableColumns";

import {
    type OnboardingChecklist,
    OnboardingChecklistStatus,
    OnboardingChecklistType,
    type PersonalTasks,
} from "@/interfaces/onboardingServerData";
import { useEffect } from "react";

export function getEmptyRow(
    userID: string,
    parentID: string,
    onboardingID: string,
): OnboardingChecklist | PersonalTasks {
    // Initialize with one empty row
    return {
        id: "1",
        name: "",
        user_id: userID,
        description: "",
        assignee_user_id: "",
        onboarding_type: OnboardingChecklistType.Task,
        start_date: new Date(),
        deadline: undefined,
        task_status: OnboardingChecklistStatus.NotStarted,
        created_at: new Date().toISOString(),
        parent_id: parentID,
        onboarding_id: onboardingID,
        order_position: 0,
        updated_at: new Date().toISOString(),
        enabled: false,
        org_id: "",
    };
}

interface OnboardingTableProps {
    userID: string;
    users: GetUserResponse[];
    data: OnboardingChecklist[];
    setData: (data: OnboardingChecklist[] | PersonalTasks[]) => void;
    saveToDB?: boolean;
    addIssue?: (
        issue: OnboardingChecklist | PersonalTasks,
        parentId: string,
    ) => Promise<string>;
    saveIssue?: (
        issue: Partial<OnboardingChecklist>,
        columnId: string,
        value: string,
        onboardingMainItemId: string,
    ) => void;
    deleteIssues?: (
        userID: string,
        issueIDs: string[],
        onboardingMainItemId: string,
        deleteOnUI?: (ids: string[]) => void,
    ) => void;
    milestoneID?: string;
    onboardingMainItemId?: string;
    isPersonalTasks?: boolean;
    companyID?: string;
    customerID?: string;
    addRowEnabled?: boolean;
}

export function OnboardingTable({
    userID,
    users,
    data,
    setData,
    saveToDB = false,
    addIssue,
    saveIssue,
    deleteIssues,
    milestoneID = "",
    onboardingMainItemId = "",
    isPersonalTasks = false,
    addRowEnabled = true,
}: OnboardingTableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>({});
    const [rowSelection, setRowSelection] = React.useState({});
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [columnSizing, setColumnSizing] = React.useState({});

    useEffect(() => {
        // Disable button if there's data and the last row is empty
        setIsDisabled(data.length > 0 && data[data.length - 1].name === "");
    }, [data]);

    const handleAddTask = () => {
        const newRow: OnboardingChecklist | PersonalTasks = getEmptyRow(
            userID,
            milestoneID,
            onboardingMainItemId,
        );
        if (data.length > 0) {
            if (data[data.length - 1].name === "") {
                // don't allow them to add an empty row if the previous row is still empty
                return;
            }
        }
        newRow.id = (data.length + 1).toString();
        setData([...data, newRow]);
        if (saveToDB) {
            addIssue?.(newRow, milestoneID).then((id) => {
                newRow.id = id;
                setData([...data, newRow]);
            });
        }
    };

    const table = useReactTable({
        data,
        columns: generateOnboardingColumns(
            users ?? [],
            userID,
            isPersonalTasks,
        ),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onColumnSizingChange: setColumnSizing,
        onRowSelectionChange: setRowSelection,
        enableColumnResizing: true,
        columnResizeMode: "onChange",
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
            columnSizing,
        },
        // By default, getPaginationRowModel limits to 10 rows per page
        // Set initial page size to show all rows
        initialState: {
            pagination: {
                pageSize: 100, // Set a large number to effectively show all rows
            },
        },
        meta: {
            updateData: (rowIndex: number, columnId: string, value: any) => {
                const updatedData = data.map(
                    (row: OnboardingChecklist, index: number) => {
                        if (index === rowIndex) {
                            const updatedRow = {
                                ...row,
                                [columnId]: value,
                            };
                            const update: Partial<OnboardingChecklist> = {
                                [columnId]: value,
                                parent_id: milestoneID,
                                onboarding_id: row.onboarding_id,
                                id: row.id,
                            };
                            if (saveToDB) {
                                saveIssue?.(
                                    update,
                                    columnId,
                                    value,
                                    onboardingMainItemId,
                                );
                            }
                            return updatedRow;
                        }
                        return row;
                    },
                );
                setData(updatedData);
            },
        },
    });

    const selectedCountTable = table.getSelectedRowModel().rows.length;

    const deleteSelectedIssues = async () => {
        const selectedRows = table.getSelectedRowModel().rows;
        const selectedIds = selectedRows.map((row) => row.original.id);

        await deleteIssues?.(
            userID,
            selectedIds,
            onboardingMainItemId,
            (ids: string[]) => {
                const updatedData = data.filter((row) => !ids.includes(row.id));
                setData(updatedData);
                table.setRowSelection({});
            },
        );
    };

    return (
        <div className="w-full pt-2">
            <div className="rounded-md border w-full">
                <Table className="[&_tr:hover]:bg-gray-50 table-fixed w-full items-center">
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow
                                key={headerGroup.id}
                                className="bg-[#fffdf9]"
                            >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            key={header.id}
                                            className={`text-xs h-10 bg-[#fffdf9] text-gray-600 font-medium relative ${header?.id === "select" ? "" : "last:border-r-0 "}`}
                                            style={{
                                                width: header.getSize(),
                                                maxWidth: header.getSize(),
                                            }}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                            {header.column.getCanResize() && (
                                                <div
                                                    onMouseDown={header.getResizeHandler()}
                                                    onTouchStart={header.getResizeHandler()}
                                                    className={`absolute right-0 top-0 h-full w-1 cursor-col-resize select-none touch-none bg-gray-200 opacity-0 hover:opacity-100 ${
                                                        header.column.getIsResizing()
                                                            ? "bg-blue-500 opacity-100"
                                                            : ""
                                                    }`}
                                                />
                                            )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && "selected"
                                    }
                                    className="hover:bg-gray-50"
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            className={`p-0 h-10 ${cell.column.id === "select" ? "" : "border-r last:border-r-0 focus-within:outline focus-within:outline-2 focus-within:outline-[#5e6ad2] focus-within:rounded focus-within:z-20"} border-b relative `}
                                            style={{
                                                width: cell.column.getSize(),
                                                maxWidth: cell.column.getSize(),
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={table.getAllColumns().length}
                                    className="text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {addRowEnabled && (
                    <div className="w-full px-1 py-1">
                        {isDisabled ? (
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="p-0 m-0 w-full h-4 outline-none bg-[#F0F0EF] items-center justify-center rounded-full flex cursor-not-allowed opacity-50">
                                        <PlusIcon className="h-3 w-3" />
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent side="bottom">
                                    <p>
                                        You cannot add a new row until all rows
                                        have a name!
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        ) : (
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        className="p-0 m-0 w-full h-4 outline-none bg-[#F0F0EF] items-center justify-center hover:bg-[#e9e9e7] shadow-sm rounded-full flex"
                                        size="sm"
                                        variant="outline"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent form submission
                                            handleAddTask();
                                        }}
                                        type="button" // Explicitly set button type to prevent form submission
                                    >
                                        <PlusIcon className="h-3 w-3" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent side="bottom">
                                    <p>Add row</p>
                                </TooltipContent>
                            </Tooltip>
                        )}
                    </div>
                )}
                <div className="flex justify-end items-center my-2 mx-2">
                    {selectedCountTable > 0 && (
                        <Button
                            className="h-6 shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1 justify-start data-[state=open]:bg-muted shadow-sm text-red9"
                            size="sm"
                            variant="outline"
                            onClick={deleteSelectedIssues}
                        >
                            <TrashIcon className="w-4 h-4" />
                            Delete ({selectedCountTable})
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
