import { Card } from "@/component/shadcn/ui/card";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import {Link} from "@radix-ui/themes";

interface NoTasksPromptProps {
    userID: string;
    listType: IssueListType;
    type: "Tasks" | "Milestones";
}

export function NoTasksPrompt({
    userID,
    listType,
    type,
}: NoTasksPromptProps) {
    return (
        <div className="flex items-center justify-center h-full">
            <Card className="w-[400px] rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none py-7 px-10 flex flex-col gap-1">
                <div className="font-semibold">
                    No {type} Found
                </div>
                {listType === IssueListType.Inbox && (
                    <div className="text-sm">
                        Remove filters or assign {type} to yourself. Then those {type} will then populate here! You can create {type} under any account <Link href="/accounts">here</Link> &gt; Milestones.
                    </div>
                )}
            </Card>
        </div>
    );
}
