import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSub,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    type AccountsLabel,
    LabelsType,
    Teams,
} from "@/interfaces/serverData";
import { AccountsLabels } from "@/pages/WorkspacePreferences/AccountsLabels";
import {
    getAccountsLabelIcon,
    getAccountsLabelName,
    getBadgeForAccountsLabel,
    getBadgeForAccountsTag,
} from "@/utilities/methods";
import {
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
} from "@radix-ui/react-dropdown-menu";
import { CaretRightIcon, CheckIcon, InfoCircledIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface LabelsDropdownProps<TData> {
    selectedLabels: Map<LabelsType, AccountsLabel | undefined>;
    selectedTags: AccountsLabel[];
    toggleLabel: (
        label: AccountsLabel | undefined,
        selectedLabels: Map<LabelsType, AccountsLabel | undefined>,
        labelType: LabelsType
    ) => void;
    accountType: string;
}

export function LabelsDropdown<TData>({
    selectedLabels,
    selectedTags,
    toggleLabel,
    accountType,
}: LabelsDropdownProps<TData>) {
    const { team_id } = useParams(); // team ID
    const api = useApi();
    const [filteredLabelsByGroup, setFilteredLabelsByGroup] = useState<
        Map<LabelsType, AccountsLabel[]>
    >(new Map());

    const fetchAccountsLabels = async (): Promise<AccountsLabel[]> => {
        const { url } = ContactsAPI.getAccountsLabels;
        const response: AxiosResponse<{ data: AccountsLabel[] }> =
            await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch accounts labels");
    };

    const fetchTeamAccountsLabels = async (): Promise<AccountsLabel[]> => {
        const { url } = ContactsAPI.getAccountsLabels;
        const response = await api.get(`${URLS.serverUrl}${url}/team/${team_id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const {
        data: accountsLabels = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<AccountsLabel[]>({
        queryKey: team_id ? [`teamAccountsLabels_${team_id}`] : ["accountsLabels"],
        queryFn: team_id
            ? () => fetchTeamAccountsLabels()
            : () => fetchAccountsLabels(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        const newLabelsByGroup = new Map<LabelsType, AccountsLabel[]>([
            [
                LabelsType.Tier,
                accountsLabels.filter(
                    (al) => al.type === LabelsType.Tier,
                ),
            ],
            [
                LabelsType.Stage,
                accountsLabels.filter(
                    (al) => al.type === LabelsType.Stage,
                ),
            ],
        ]);
        if (accountType === "Company") {
            newLabelsByGroup.set(
                LabelsType.CompanyType,
                accountsLabels.filter(
                    (al) => al.type === LabelsType.CompanyType,
                ),
            );
        }
        newLabelsByGroup.set(
            LabelsType.AccountTag,
            accountsLabels.filter(
                (al) => al.type === LabelsType.AccountTag,
            )
        )
        setFilteredLabelsByGroup(newLabelsByGroup);
    }, [accountsLabels]);

    const noneSelected = new Map([
        [LabelsType.Tier, !selectedLabels || !selectedLabels.get(LabelsType.Tier)],
        [LabelsType.Stage, !selectedLabels || !selectedLabels.get(LabelsType.Stage)],
        [LabelsType.CompanyType, !selectedLabels || !selectedLabels.get(LabelsType.CompanyType)],
    ]);


    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                asChild
                className="hover:bg-muted col-span-3 w-full"
                type="button"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="w-full">
                    <Button
                        variant="ghost"
                        className="flex flex-wrap items-center justify-start w-full data-[state=open]:bg-muted !h-auto p-1.5 gap-2"
                        type="button"
                    >
                        {((selectedLabels.size ?? 0) > 0 || selectedTags.length > 0) ? (
                            <div className="flex flex-wrap gap-2 items-center">
                                {Array.from(selectedLabels.values()).some(label => label !== undefined) && (
                                    Array.from(selectedLabels.values()).map((label: AccountsLabel | undefined) => {
                                        if (label) {
                                            return getBadgeForAccountsLabel(label);
                                        }
                                        return null;
                                    })
                                )}
                                {selectedTags.map(tag => getBadgeForAccountsTag(tag))}
                                {selectedTags.length == 0 && !Array.from(selectedLabels.values()).some(label => label !== undefined) && "None"}
                            </div>
                        ) : (
                            "None"
                        )}
                        <Pencil2Icon />
                    </Button>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                side="bottom"
                align="start"
                className="fixed w-[300px] p-0 bg-white rounded-md shadow-lg"
            >
                <div className="h-full max-h-[200px] overflow-y-auto">
                    {Array.from(filteredLabelsByGroup.entries()).map(
                        (entry) => {
                            const type = entry[0];
                            const labels = entry[1];
                            return (
                                <DropdownMenuSub key={type}>
                                    <DropdownMenuSubTrigger
                                        className="flex items-center justify-between px-3 py-2 focus:outline-none focus:bg-accent"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className="flex items-center gap-2 py-0.5 text-xs">
                                            {getAccountsLabelIcon(type)}
                                            {getAccountsLabelName(type)}
                                        </div>
                                        <CaretRightIcon />
                                    </DropdownMenuSubTrigger>
                                    <DropdownMenuSubContent className="rounded-md border bg-popover px-2 py-1.5">
                                        {type != LabelsType.AccountTag &&
                                            <DropdownMenuItem
                                                onSelect={() => {
                                                    toggleLabel(
                                                        undefined,
                                                        selectedLabels,
                                                        type
                                                    );
                                                }}
                                                className="py-2 hover:bg-secondary cursor-pointer text-xs"
                                            >
                                                <div
                                                    className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${noneSelected.get(type) ?? false
                                                        ? "bg-primary text-primary-foreground"
                                                        : "opacity-50"
                                                        }`}
                                                >
                                                    {(noneSelected.get(type) ?? false) && <CheckIcon className="h-3 w-3" />}
                                                </div>
                                                {`No ${getAccountsLabelName(type)}`}
                                            </DropdownMenuItem>
                                        }
                                        {labels.map((label) => {
                                            const isSelected = type === LabelsType.AccountTag ?
                                                selectedTags.map(tag => tag.id).includes(label.id) :
                                                Array.from(
                                                    selectedLabels.values(),
                                                )
                                                    .map(
                                                        (
                                                            l:
                                                                | AccountsLabel
                                                                | undefined,
                                                        ) => l?.id,
                                                    )
                                                    .includes(label.id);
                                            return (
                                                <DropdownMenuItem
                                                    key={label.id}
                                                    onSelect={() => {
                                                        toggleLabel(
                                                            label,
                                                            selectedLabels,
                                                            type
                                                        );
                                                    }}
                                                    className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                                >
                                                    <div
                                                        className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${isSelected
                                                            ? "bg-primary text-primary-foreground"
                                                            : "opacity-50"
                                                            }`}
                                                    >
                                                        {isSelected && (
                                                            <CheckIcon className="h-3 w-3" />
                                                        )}
                                                    </div>
                                                    {type == LabelsType.AccountTag ?
                                                        getBadgeForAccountsTag(label) :
                                                        getBadgeForAccountsLabel(
                                                            label,
                                                            false,
                                                            true
                                                        )}
                                                </DropdownMenuItem>
                                            );
                                        })}
                                        {type == LabelsType.AccountTag && labels.length == 0 && <div className="flex items-center gap-2 text-xs max-w-[200px] p-2">
                                            No Tags Found
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger
                                                        asChild
                                                    >
                                                        <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0 text-primary -ml-0.5" />
                                                    </TooltipTrigger>
                                                    <TooltipContent className="py-2.5 px-4 flex flex-col w-[250px]">
                                                        {`You can create Account Tags on the Labels Page (Preferences -> Labels -> Accounts)`}
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>}
                                    </DropdownMenuSubContent>
                                </DropdownMenuSub>
                            );
                        },
                    )}
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
